import { FC, useMemo } from "react";

import {
  Text,
  Heading,
  Row,
  Column,
  StatusBadge,
  Box,
  UpsellButton,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useNavigate } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-blue-gradient.svg";
import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionedLinkButton } from "src/components/permission";
import {
  ConnectionsOrderBy,
  useIdentityResolutionGraphsQuery,
} from "src/graphql";
import {
  EventStats,
  ProfileStats,
} from "src/pages/identity-resolution/graph/runs";
import { IdentityResolutionStatusBadge } from "src/pages/identity-resolution/status-badge";
import {
  PageTable,
  TableColumn,
  useTableSort,
  OrderBy,
  SortOption,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { openUrl } from "src/utils/urls";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import { IdentityResolutionIcon } from "src/ui/icons";
import { useEntitlements } from "src/hooks/use-entitlement";

import { transformLegacyStatistics } from "./graph";
import { SplashPage } from "src/components/splash-page";
import { formatFriendlyDistanceToNow } from "src/utils/time";

const initialSort: SortOption<keyof ConnectionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof ConnectionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const placeholder = {
  image: genericPlaceholder,
  title: "No identity graphs",
  body: "Create a new identity graph to start resolving profiles.",
  error: "Identity graphs failed to load, please try again.",
};

export const Content: FC = () => {
  const navigate = useNavigate();
  const { selectedRows, onRowSelect } = useRowSelect();

  const orderBy = useTableSort<ConnectionsOrderBy>(initialSort, sortOptions);

  const {
    data: graphs,
    isLoading,
    error,
  } = useIdentityResolutionGraphsQuery(
    { orderBy },
    {
      select: (data) => {
        // Transform the stats property of each run inside of each graph to match the newer IdrStats
        // structure.
        const graphs = data.idr.map((graph) => {
          graph.runs = graph.runs.map((run) => {
            run.stats = transformLegacyStatistics(run.stats);
            return run;
          });

          return graph;
        });
        return graphs;
      },
    },
  );

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Last run",
        cell: ({ runs }) => {
          const lastRun = runs[0];
          if (lastRun) {
            return (
              <Column gap={1}>
                <IdentityResolutionStatusBadge status={lastRun.status} />
                {lastRun.finished_at && (
                  <Text
                    size="sm"
                    color="text.secondary"
                  >{`${formatFriendlyDistanceToNow(
                    lastRun.finished_at,
                  )}`}</Text>
                )}
              </Column>
            );
          }
          return <StatusBadge variant="inactive">Pending</StatusBadge>;
        },
      },
      {
        name: "Profiles",
        cell: ({ runs }) => {
          const lastRun = runs[0];
          return <ProfileStats stats={lastRun?.stats} />;
        },
      },
      {
        name: "Events",
        cell: ({ runs }) => {
          const lastRun = runs[0];
          return <EventStats stats={lastRun?.stats} />;
        },
      },
      {
        name: "Identity graph",
        cell: ({ name }) => (
          <Text isTruncated fontWeight="medium">
            {name ?? "Private graph"}
          </Text>
        ),
      },
      {
        name: "Source",
        cell: ({ source }) => (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon
              src={source.definition.icon}
              name={source.definition.name}
            />
            <Text isTruncated fontWeight="medium">
              {source.name}
            </Text>
          </Row>
        ),
      },
      {
        ...LastUpdatedColumn,
        breakpoint: "md",
      },
    ],
    [],
  );

  return (
    <PageTable
      header={
        <>
          <Heading size="xl">Identity resolution</Heading>
          <PermissionedLinkButton
            href="/idr/new"
            variant="primary"
            permission={{ v2: { resource: "source", grant: "can_create" } }}
            onClick={() => {}}
          >
            Add identity graph
          </PermissionedLinkButton>
        </>
      }
      columns={columns}
      data={graphs}
      error={Boolean(error)}
      loading={isLoading}
      placeholder={placeholder}
      selectedRows={selectedRows}
      onRowClick={({ id }, event) => openUrl(`/idr/${id}`, navigate, event)}
      onSelect={onRowSelect}
      sortOptions={sortOptions}
    />
  );
};

export const IdentityResolutionGraphs = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const showIdr = entitlementsData.entitlements.idr;

  const unlockIdentityResolution = () => {
    newPylonMessage("Hi, I'd like to unlock Identity Resolution!");
  };

  const trackLearnMoreClick = () => {
    analytics.track("Identity Resolution Learn More Clicked");
  };

  if (showIdr) {
    return <Content />;
  }

  return (
    <SplashPage
      // hideLogos
      icon={<IdentityResolutionIcon />}
      eyebrow="Identity Resolution"
      heading="Create 360° user profiles directly in your warehouse"
      description="Stitch your existing customer data into rich, actionable profiles directly in your data warehouse without writing a single line of code."
      actions={
        <>
          <UpsellButton onClick={unlockIdentityResolution}>
            Unlock Identity Resolution
          </UpsellButton>
          <LinkButton
            href="https://hightouch.com/platform/identity-resolution"
            onClick={trackLearnMoreClick}
          >
            Learn more
          </LinkButton>
        </>
      }
      visual={
        <Box
          as="video"
          autoPlay
          loop
          muted
          playsInline
          poster="https://cdn.sanity.io/images/pwmfmi47/production/ffb3feb8e73dcbef1b96adc3c7998c67e62bff09-1470x1232.webp"
          src="https://cdn.sanity.io/files/pwmfmi47/production/3e5c582e5f6d4844bd9610cb44c05b2e7048ef2c.mp4"
          maxHeight="100%"
          width="100%"
        />
      }
      backgroundGradient={
        <>
          <Box
            as="img"
            position="absolute"
            top={0}
            left={0}
            src={topLeftGradient}
          />
          <Box
            as="img"
            position="absolute"
            bottom={0}
            right={0}
            src={bottomRightGradient}
          />
        </>
      }
    />
  );
};
