import isEqual from "lodash/isEqual";

import {
  ConversionMetric,
  MetricWithAttributionMethodId,
  PredefinedMetric,
  SavedCampaignView,
} from "src/pages/campaigns/types";
import {
  AttributionTimeWindow,
  FilterableColumn,
  PropertyCondition,
} from "src/types/visual";
import { Delimiter } from "src/pages/campaigns/constants";
import { OrderByCampaignColumn } from "src/graphql";

export const getMetricsToMeasure = (
  predefinedMetrics: PredefinedMetric[],
  conversionMetrics: ConversionMetric[],
) => {
  const metricsToMeasure: MetricWithAttributionMethodId[] = [];

  predefinedMetrics.forEach((metric) => {
    metricsToMeasure.push({
      goalId: metric.id,
      attributionMethodId: undefined,
    });
  });

  conversionMetrics.forEach((metric) => {
    metricsToMeasure.push({
      goalId: metric.id,
      attributionMethodId: metric.attributionMethod.id,
    });
  });

  return metricsToMeasure;
};

type transformSavedViewToFiltersReturn = {
  filters: PropertyCondition[];
  selectedColumns: FilterableColumn[];
  selectedConversionMetrics: ConversionMetric[];
  selectedPredefinedMetrics: PredefinedMetric[];
  timeWindow: AttributionTimeWindow;
  sortBy: OrderByCampaignColumn;
};

export const transformSavedCampaignViewToFilters = (
  view: SavedCampaignView,
  columns: FilterableColumn[],
): transformSavedViewToFiltersReturn | undefined => {
  if (!view) {
    return undefined;
  }

  const selectedColumns = columns.filter((column) =>
    view.column_references.some((column_reference) =>
      isEqual(column_reference, column.column_reference),
    ),
  );

  const selectedConversionMetrics: ConversionMetric[] = [];
  const selectedPredefinedMetrics: PredefinedMetric[] = [];

  view.goals.forEach(({ goal, attribution_method }) => {
    if (attribution_method) {
      selectedConversionMetrics.push({
        id: goal.id,
        name: goal.name,
        description: goal.description,
        attributionMethod: {
          id: attribution_method.id,
          name: attribution_method.name,
        },
      });
    } else {
      selectedPredefinedMetrics.push({
        id: goal.id,
        name: goal.name,
        description: goal.description,
      });
    }
  });

  return {
    filters: view.filters.conditions,
    selectedColumns,
    selectedConversionMetrics,
    selectedPredefinedMetrics,
    timeWindow: view.time_window,
    sortBy: view.sort,
  };
};

export function toConversionMetricKey(conversionMetric: {
  goalId: string;
  attributionMethodId: string;
}): string {
  return `${conversionMetric.goalId}${Delimiter}${conversionMetric.attributionMethodId}`;
}

export function fromConversionMetricKey(conversionMetricKey: string): {
  goalId: string;
  attributionMethodId: string;
} {
  const [goalId, attributionMethodId] = conversionMetricKey.split(Delimiter);
  if (!goalId || !attributionMethodId) {
    throw new Error(
      `Failed to parse conversionMetricKey: ${conversionMetricKey}`,
    );
  }

  return { goalId, attributionMethodId };
}
