import { Button, EmptyState, Heading, Row } from "@hightouchio/ui";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useOutletContext,
} from "src/router";

import {
  DecisionEngineChannelConfig,
  DecisionEngineChannelType,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Suspense, useMemo } from "react";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Page } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { DecisionEngineQuery, useDecisionEngineQuery } from "src/graphql";
import { Admin } from "./admin";
import { Collections } from "./collections";
import { AddCollection } from "./collections/add";
import { Collection } from "./collections/collection";
import { Configuration } from "./configuration";
import { DecisionEngineFlows } from "./flows";
import { AddFlow } from "./flows/add";
import { Loader as Flow } from "./flows/flow";
import { AddOutcome } from "./flows/flow/outcomes/add";
import { Outcome } from "./flows/flow/outcomes/outcome";
import { InsightsLoader as Insights } from "./insights";
import { InsightsDecisions } from "./insights/decisions";
import { InsightsOverview } from "./insights/overview";
import { InsightsPersonalizations } from "./insights/personalizations";
import { Loader as Inspector } from "./inspector";
import { AddMessage } from "./flows/flow/messages/add";
import { CreateDecisionEngine } from "./new";
import { ChannelOptionsMap } from "./utils";
import { MessageIntegration } from "./flows/flow/messages/message/integration";
import { MessagePreview } from "./flows/flow/messages/message/preview";
import { FlowMessage } from "./flows/flow/messages/message";
import { MessageConfiguration } from "./flows/flow/messages/message/configuration";
import { getValuesForKeys } from "src/utils/key-value";
import { MessageCortex } from "./flows/flow/messages/message/cortex";

export type DecisionEngine = NonNullable<
  DecisionEngineQuery["decision_engines"]
>[0];

export type Channel = {
  type: DecisionEngineChannelType;
  config: DecisionEngineChannelConfig;
  messages: DecisionEngine["channels"][0]["messages"];
  destination: {
    id: string;
    name: string;
    type: string;
    definition: {
      icon: string;
      name: string;
    };
  };
};

export type OutletContext = {
  engine: DecisionEngine;
  channels: Record<DecisionEngineChannelType, Channel & { label: string }>;
  messages: Array<NonNullable<Channel>["messages"][0]>;
  tagValuesForKeys: ReturnType<typeof getValuesForKeys>;
};

const Loader = () => {
  const { data: engine, isLoading } = useDecisionEngineQuery(undefined, {
    select: (data) => data.decision_engines[0],
  });
  const navigate = useNavigate();

  const context: Partial<OutletContext> = useMemo(() => {
    const channels = engine?.channels?.reduce(
      (acc, channel) => ({
        ...acc,
        [channel.type as DecisionEngineChannelType]: {
          ...channel,
          label: ChannelOptionsMap[channel.type].label,
        },
      }),
      {} as Record<
        DecisionEngineChannelType,
        OutletContext["channels"]["email"]
      >,
    );

    const messages = engine?.channels?.flatMap((channel) => channel.messages);

    const tags = messages?.flatMap((message) => message.tags).filter(Boolean);
    const tagValuesForKeys = getValuesForKeys(tags ?? []);

    return {
      engine,
      channels,
      messages,
      tagValuesForKeys,
    };
  }, [engine]);

  if (isLoading) {
    return <PageSpinner />;
  }
  return (
    <Suspense fallback={<PageSpinner />}>
      <Outlet context={context} />
      {!engine && (
        <Page
          fullWidth
          title="AI Decisioning"
          sx={{ p: 0, bg: "base.lightBackground" }}
        >
          <Row
            justify="space-between"
            py={4}
            mb={6}
            bg="white"
            px={6}
            borderBottom="1px"
            borderColor="base.border"
          >
            <Row gap={4} align="center">
              <Heading size="xl">AI Decisioning</Heading>
            </Row>
          </Row>
          <EmptyState
            mx={6}
            title="Setup AI Decisioning"
            message="Start sending personalized messages to your customers."
            imageUrl={genericPlaceholder}
            actions={
              <Button
                variant="primary"
                onClick={() => {
                  navigate("new");
                }}
              >
                Setup
              </Button>
            }
          />
        </Page>
      )}
    </Suspense>
  );
};

const Index = () => {
  const context = useOutletContext() as OutletContext;

  if (context.engine) {
    return <Navigate to="flows" replace />;
  }

  return <Outlet context={context} />;
};

export const DecisionEngineRoutes = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route index element={<Index />} />
        <Route path="new" element={<CreateDecisionEngine />} />
        <Route path="flows" element={<DecisionEngineFlows />}>
          <Route path="new" element={<AddFlow />} />
        </Route>
        <Route path="flows/:flowId" element={<Flow />}>
          <Route path="outcomes/add" element={<AddOutcome />} />
          <Route path="outcomes/:outcomeId" element={<Outcome />} />
          <Route path="messages/add" element={<AddMessage />} />
          <Route path="messages/:messageId/*" element={<FlowMessage />}>
            <Route index element={<Navigate to="configuration" replace />} />
            <Route path="configuration" element={<MessageConfiguration />} />
            <Route path="integration" element={<MessageIntegration />} />
            <Route path="preview" element={<MessagePreview />} />
            <Route path="cortex" element={<MessageCortex />} />
          </Route>
        </Route>
        <Route path="collections/*" element={<Collections />}>
          <Route path="add" element={<AddCollection />} />
          <Route path=":collectionId" element={<Collection />} />
        </Route>
        <Route path="configuration" element={<Configuration />} />
        <Route path="inspector" element={<Inspector />} />
        <Route path="insights" element={<Insights />}>
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="overview" element={<InsightsOverview />} />
          <Route path="decisions" element={<InsightsDecisions />} />
          <Route
            path="personalizations"
            element={<InsightsPersonalizations />}
          />
        </Route>
        <Route path="admin" element={<Admin />} />
      </Route>
    </Routes>
  );
};
