import {
  Box,
  ConfirmationDialog,
  DeleteIcon,
  Paragraph,
  Row,
  Text,
  UserIcon,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { captureException } from "@sentry/react";
import pluralize from "pluralize";
import { FC, useState } from "react";
import { PermissionedIconButton } from "src/components/permission";
import { useDeleteSubsetMutation } from "src/graphql";
import { getGrantType } from "./util";
import { useIsPermissionsV2Enabled } from "src/components/permission/utils";

type Props = {
  id: string;
  name: string;
  parentModelId: string;
  userCount: number;
};

export const SubsetPreview: FC<Readonly<Props>> = ({
  id,
  name,
  parentModelId,
  userCount,
}) => {
  const permissionsV2Enabled = useIsPermissionsV2Enabled();
  const [showDeletionConfirmation, setShowDeletionConfirmation] =
    useState(false);

  return (
    <>
      <Row
        alignItems="center"
        borderRadius="sm"
        background="base.lightBackground"
        border="1px solid"
        borderColor="base.border"
        justifyContent="space-between"
        px={6}
        py={4}
      >
        <Link href={`subsets/${id}${window.location.search}`}>{name}</Link>
        <Row>
          <Row
            alignItems="center"
            borderRight="1px solid"
            borderColor="base.divider"
            pr={4}
            mr={4}
            gap={2}
          >
            <Box as={UserIcon} color="text.secondary" fontSize={20} />
            <Text color="text.secondary">
              {userCount}{" "}
              {pluralize(getGrantType(permissionsV2Enabled), userCount)}{" "}
              {pluralize("has", userCount)} access
            </Text>
          </Row>
          <PermissionedIconButton
            permission={{
              v1: { resource: "audience_schema", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModelId ?? "",
              },
            }}
            aria-label="Delete subset"
            icon={DeleteIcon}
            variant="danger"
            onClick={() => setShowDeletionConfirmation(true)}
          />
        </Row>
      </Row>
      <DeletionConfirmation
        id={id}
        name={name}
        isOpen={showDeletionConfirmation}
        onClose={() => setShowDeletionConfirmation(false)}
      />
    </>
  );
};

type DeletionConfirmationProps = {
  id: string;
  name: string;
  isOpen: boolean;
  onClose: () => void;
};

const DeletionConfirmation: FC<Readonly<DeletionConfirmationProps>> = ({
  id,
  name,
  isOpen,
  onClose,
}) => {
  const { toast } = useToast();

  const deleteSubset = useDeleteSubsetMutation();

  // TODO(nishad): prevent deletion if subset is used in audience

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Delete subset: "${name}"`}
      confirmButtonText="Delete"
      variant="danger"
      onClose={onClose}
      onConfirm={async () => {
        try {
          await deleteSubset.mutateAsync({ id });
          toast({
            id: "delete-subset",
            title: "Successfully deleted subset.",
            variant: "success",
          });
        } catch (error) {
          toast({
            id: "delete-subset",
            title: "Could not delete subset. Please try again.",
            variant: "error",
          });
          captureException(error);
        }
      }}
    >
      <Paragraph textAlign="left">
        Are you sure you want to delete the subset{" "}
        <Text fontWeight="semibold">"{name}"</Text>?
        <br />
        <br />
        This will no longer be available to use in audiences.
      </Paragraph>
    </ConfirmationDialog>
  );
};
