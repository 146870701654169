import { FC, useCallback, useState } from "react";

import {
  Avatar,
  CloseIcon,
  Column,
  ConfirmationDialog,
  DrawerBody,
  DrawerHeader,
  EmptyState,
  Heading,
  IconButton,
  Paragraph,
  Row,
  SectionHeading,
  StarBadgeIcon,
  Tag,
  Text,
} from "@hightouchio/ui";
import { useNavigate, useOutletContext, useParams } from "src/router";

import {
  useDeactivateSsoUserMutation,
  useOrganizationUserQuery,
  useRemoveUserFromOrganizationMutation,
  useUpdateUserGroupMemberMutation,
  useUserGroupsQuery,
} from "src/graphql";

import { Controller } from "react-hook-form";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { openUrl } from "src/utils/urls";
import { OutletContext } from "..";
import {
  PermissionedButton,
  PermissionedSwitch,
} from "src/components/permission";
import { Drawer } from "src/components/drawer";

export const ViewUser: FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { organization } = useOutletContext<OutletContext>();

  const [isDeleting, setIsDeleting] = useState(false);

  const { data: user } = useOrganizationUserQuery(
    { userId: userId ?? "", organizationId: organization?.id },
    {
      suspense: true,
      select: (data) => data.organization_members_distinct[0]?.user,
    },
  );

  const { data: userGroups } = useUserGroupsQuery(
    { organizationId: organization?.id },
    {
      enabled: Boolean(organization?.id),
      select: (data) => data.user_groups,
      suspense: true,
    },
  );

  const isSsoUser = Boolean(user?.auth0_auth_id);
  const updateMutation = useUpdateUserGroupMemberMutation();
  const deleteMutation = useRemoveUserFromOrganizationMutation();
  const ssoDeleteMutation = useDeactivateSsoUserMutation();

  const initialUserGroups = (userGroups?.reduce((acc, userGroup) => {
    const membership = userGroup.members?.find(
      (member) => member.user_id === user?.id && !member.is_sso,
    );
    const membershipViaSso = userGroup.members?.find(
      (member) => member.user_id === user?.id && member.is_sso,
    );
    acc[userGroup.id] = {
      is_organization_admin: userGroup.is_organization_admin,
      is_member: Boolean(membership),
      is_member_via_sso: Boolean(membershipViaSso),
      user_id: String(user?.id),
      user_group_id: String(userGroup.id),
      is_group_manager: Boolean(membership?.is_group_manager),
    };
    return acc;
  }, {}) ?? {}) as Record<
    string,
    {
      is_organization_admin: boolean;
      is_member: boolean;
      is_member_via_sso: boolean;
      user_group_id: string;
      user_id: string;
      is_group_manager: boolean;
    }
  >;

  const form = useHightouchForm({
    onSubmit: async ({ userGroups }) => {
      const groups = Object.values(userGroups);
      await updateMutation.mutateAsync({
        userId: String(user?.id),
        userGroupIdsToDelete: groups
          .filter(({ is_member }) => !is_member)
          .map(({ user_group_id }) => user_group_id),
        upsert: groups
          .filter(({ is_member }) => is_member)
          .map(({ user_id, user_group_id, is_group_manager }) => ({
            user_id,
            user_group_id,
            is_group_manager,
          })),
      });
    },
    values: {
      userGroups: initialUserGroups,
    },
  });

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/organization/groups/${id}`, navigate, event),
    [navigate],
  );

  const removeUser = async (userId: number, organizationId: number) => {
    if (user?.auth0_auth_id) {
      await ssoDeleteMutation.mutateAsync({
        targetUserId: String(userId),
      });
    } else {
      await deleteMutation.mutateAsync({
        userId: String(userId),
        organizationId: String(organizationId),
      });
    }
  };

  return (
    <>
      <Form form={form}>
        <Drawer
          isOpen={true}
          onClose={() => navigate("/organization/members")}
          size="lg"
        >
          <DrawerHeader>
            <Row
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Row gap={4} alignItems="center">
                <Avatar name={user?.name} />
                <Column>
                  <Heading>{user?.name}</Heading>
                  <Text color="text.secondary">{user?.email}</Text>
                </Column>
              </Row>
              <IconButton
                aria-label="Close drawer"
                icon={CloseIcon}
                onClick={() => navigate("/organization/members")}
              />
            </Row>
          </DrawerHeader>
          <DrawerBody>
            <Column gap={4}>
              <Column>
                <SectionHeading>Manage roles</SectionHeading>
                <Paragraph color="text.secondary">
                  Roles are composed of sets of permissions and determine what
                  the user can do in this organization.
                </Paragraph>
              </Column>
              <Controller
                name="userGroups"
                render={({ field }) => {
                  return (
                    <Table
                      data={userGroups}
                      columns={[
                        {
                          name: "Assigned",
                          max: "max-content",
                          cell: ({ id }) => {
                            const group = field.value[id];
                            const initialGroup = initialUserGroups[id];

                            const onlyMemberViaSso =
                              initialGroup?.is_member_via_sso &&
                              !initialGroup?.is_member;
                            return (
                              <Row
                                justify="center"
                                flex={1}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <PermissionedSwitch
                                  size="sm"
                                  isChecked={
                                    group.is_member || onlyMemberViaSso
                                  }
                                  isDisabled={onlyMemberViaSso}
                                  organizationPermission={{
                                    resource: "group",
                                    id,
                                  }}
                                  tooltip={
                                    onlyMemberViaSso
                                      ? "Assigned via SSO"
                                      : undefined
                                  }
                                  onChange={(value) => {
                                    field.onChange({
                                      ...field.value,
                                      [id]: {
                                        ...group,
                                        is_group_manager: false,
                                        is_member: value,
                                      },
                                    });
                                  }}
                                />
                              </Row>
                            );
                          },
                        },
                        {
                          name: "Name",
                          cell: ({ name, is_organization_admin, id }) => {
                            const initialGroup = initialUserGroups[id];

                            const onlyMemberViaSso =
                              initialGroup?.is_member_via_sso &&
                              !initialGroup?.is_member;

                            return (
                              <Row gap={2}>
                                <TextWithTooltip fontWeight="medium">
                                  <>
                                    {name}{" "}
                                    {onlyMemberViaSso && "(assigned via SSO)"}
                                  </>
                                </TextWithTooltip>
                                {is_organization_admin && <StarBadgeIcon />}
                              </Row>
                            );
                          },
                        },
                        {
                          name: "Role manager",
                          max: "max-content",
                          cell: ({ id, is_organization_admin }) => {
                            const group = field.value[id];
                            return (
                              <Row
                                justify="center"
                                flex={1}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <PermissionedSwitch
                                  size="sm"
                                  organizationPermission={{
                                    resource: "group",
                                    id,
                                  }}
                                  isDisabled={is_organization_admin}
                                  isChecked={group.is_group_manager}
                                  onChange={(value) => {
                                    field.onChange({
                                      ...field.value,
                                      [id]: {
                                        ...group,
                                        is_member: true,
                                        is_group_manager: value,
                                      },
                                    });
                                  }}
                                />
                              </Row>
                            );
                          },
                        },
                      ]}
                      onRowClick={onRowClick}
                    />
                  );
                }}
              />
              {isSsoUser && (
                <Column gap={2}>
                  <Column>
                    <SectionHeading>SSO groups</SectionHeading>
                    <Paragraph color="text.secondary">
                      This user has been assigned to the following SSO groups in
                      your identity provider. Based on the group mappings in
                      this organization, they will be automatically assigned to
                      roles.
                    </Paragraph>
                  </Column>
                  {user?.sso_user_groups?.length ? (
                    <Row gap={1} flexWrap="wrap">
                      {user?.sso_user_groups?.map((ug) => (
                        <Tag key={ug.group_id}>{ug.sso_group.name}</Tag>
                      ))}
                    </Row>
                  ) : (
                    <EmptyState
                      title="No SSO groups"
                      message="Users can be assigned to SSO groups in your identity provider (e.g. Okta, Microsoft, etc.)"
                    />
                  )}
                </Column>
              )}
            </Column>
          </DrawerBody>
          <ActionBar fit>
            <FormActions />
            <PermissionedButton
              size="lg"
              variant="warning"
              onClick={() => {
                setIsDeleting(true);
              }}
              organizationPermission={{ resource: "organization" }}
            >
              Remove user
            </PermissionedButton>
          </ActionBar>
        </Drawer>
      </Form>
      <ConfirmationDialog
        isOpen={isDeleting}
        title="Remove user"
        confirmButtonText="Remove user"
        variant="warning"
        onClose={() => {
          setIsDeleting(false);
        }}
        onConfirm={async () => {
          await removeUser(user?.id, organization?.id);
          navigate("/organization/members");
        }}
      >
        Are you sure you want to remove this user from the organization?
      </ConfirmationDialog>
    </>
  );
};
