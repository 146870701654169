import {
  ChannelConfig,
  assertedChannelType,
  MonitorStatus,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  ConfirmationDialog,
  Drawer,
  DrawerHeader,
  Heading,
  IconButton,
  Row,
  Spinner,
  useToast,
} from "@hightouchio/ui";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "src/router";
import {
  useCreateWorkspaceNotificationChannelMutation,
  useDeleteWorkspaceChannelMutation,
  useWorkspaceNotificationChannelQuery,
} from "src/graphql";
import { usePylonDrawerOffset } from "../drawer/use-pylon-drawer-offset";
import { DiscardButton, Form, SaveButton, useHightouchForm } from "../form";

import { ChannelConfigWrapper } from "./channel-config-wrapper";
import { ChannelDefinition } from "./channel-definitions";
import { ResourceSubscriptionFormTable } from "./resource-subscription-form-table";
import { useSubscribedResources } from "./use-subscribed-resources";
import { ActionBar } from "../action-bar";

export interface ChannelFormData {
  channelType: string | null;
  channelId: string | null;
  config: ChannelConfig[keyof ChannelConfig] | null;
  resourceIdsToSubscribe: {
    // We treat syncs differently here as in the
    // subscription form table you can only set sync
    // subscriptions at the default destination level
    destinations: Record<
      string,
      { subscribed: boolean; currentChannelSubscriptionId?: string }
    >;
  };
}

export const NotificationConfigurationDrawer: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  usePylonDrawerOffset({ isOpen: true, size: "lg" });

  const { channelId } = useParams<{ channelId?: string }>();
  const [exiting, setExiting] = useState(false);
  const [searchParams] = useSearchParams();

  const requestedResourceSubscriptionType = searchParams.get("resourceType");
  const requestedResourceSubscriptionId = searchParams.get("resourceId");

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();
  const { mutateAsync: deleteChannel, isLoading: deleting } =
    useDeleteWorkspaceChannelMutation();

  const {
    availableResources,
    defaultSubscriptions,
    isLoading: loadingResources,
  } = useSubscribedResources({});

  // List of destinations that are subscribed to the current channel
  const destinationsSubscribedToChannelId =
    defaultSubscriptions?.filter(
      (subscription) => subscription.channel.id === channelId,
    ) ?? [];

  const destinationToChannelMapping: Record<
    string,
    { subscribed: boolean; currentChannelSubscriptionId?: string }
  > = useMemo(() => {
    const mapping: Record<
      string,
      { subscribed: boolean; currentChannelSubscriptionId?: string }
    > = {};
    for (const destination of availableResources?.destinations ?? []) {
      // Get the subscriptions for this destination
      const destinationSubscription = destinationsSubscribedToChannelId.find(
        (subscription) =>
          subscription.parent_resource_id === destination.id.toString(),
      );
      const subscribed =
        Boolean(destinationSubscription) ||
        Boolean(
          destination.id.toString() ===
            requestedResourceSubscriptionId?.toString() &&
            requestedResourceSubscriptionType ===
              ParentResourceTypes.Destination,
        );

      mapping[destination.id] = {
        subscribed,
        currentChannelSubscriptionId: destinationSubscription?.id,
      };
    }
    return mapping;
  }, [
    availableResources?.destinations,
    destinationsSubscribedToChannelId,
    requestedResourceSubscriptionId,
    requestedResourceSubscriptionType,
  ]);

  const [confirming, setConfirming] = useState(false);

  const { data: existingChannel, isLoading: loadingExistingChannel } =
    useWorkspaceNotificationChannelQuery(
      {
        channelId: channelId || "",
      },
      {
        enabled: Boolean(channelId),
        select(data) {
          return data.workspace_notification_channels_by_pk;
        },
      },
    );

  const initialChannelType =
    existingChannel?.channel_type || searchParams.get("channelType") || null;

  const form = useHightouchForm<ChannelFormData>({
    onSubmit: async (formData) => {
      if (!channelType) return;
      try {
        assertedChannelType(channelType);
      } catch (e) {
        return;
      }
      const defaultSubscriptionsToKeep: {
        status: MonitorStatus.Unhealthy;
        parent_resource_id: string;
        parent_resource_type: ParentResourceTypes;
      }[] = [];
      const defaultSubscriptionIdsToKeep: string[] = [];

      for (const [destinationId, destination] of Object.entries(
        formData.resourceIdsToSubscribe?.destinations,
      )?.filter(([, d]) => d.subscribed) ?? []) {
        defaultSubscriptionsToKeep.push({
          status: MonitorStatus.Unhealthy,
          parent_resource_id: destinationId.toString(),
          parent_resource_type: ParentResourceTypes.Destination,
        });
        if (destination.currentChannelSubscriptionId) {
          defaultSubscriptionIdsToKeep.push(
            destination.currentChannelSubscriptionId,
          );
        }
      }

      await createWorkspaceNotificationChannel({
        defaultSubscriptionsToKeep: defaultSubscriptionIdsToKeep,
        clearDefaultSubscriptions: defaultSubscriptionIdsToKeep.length > 0,
        object: {
          channel_type: channelType,
          id: channelId,
          config: formData.config,
          // We are only supporting sync defaults for now
          notification_channel_templates: {
            data: defaultSubscriptionsToKeep,
            on_conflict: {
              constraint:
                "notification_channel_templates_channel_id_status_parent_resourc",
              update_columns: [],
            },
          },
        },

        // We haven't implemented manual subscriptions at this granularity
        // yet
        clearManualSubscriptions: false,
        manualSubscriptionsToKeep: [],
      });
      navigate("..");
    },
    values: {
      channelType: initialChannelType,
      channelId,
      config: existingChannel?.config,
      resourceIdsToSubscribe: {
        destinations: destinationToChannelMapping,
      },
    },
    defaultValues: {
      channelId,
      channelType: initialChannelType,
      config: existingChannel?.config,
      resourceIdsToSubscribe: {
        destinations: destinationToChannelMapping,
      },
    },
  });

  const channelType =
    form.watch("channelType") || searchParams.get("channelType");
  const Component =
    channelType &&
    ChannelDefinition?.[assertedChannelType(channelType)]?.component;

  const isLoading = loadingExistingChannel || loadingResources;

  return (
    <>
      <Drawer
        size="lg"
        isOpen={true}
        onClose={() => {
          setExiting(true);
        }}
      >
        <Form form={form}>
          <DrawerHeader>
            <Row
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Heading size="lg">
                {channelId ? "Recipient details" : "Create recipient"}
              </Heading>
              <IconButton
                aria-label="Close"
                icon={CloseIcon}
                onClick={() => setExiting(true)}
              />
            </Row>
          </DrawerHeader>
          <Column p={6} flex={1} gap={6} overflow="auto">
            {isLoading ? (
              <Spinner size="lg" m="auto" />
            ) : (
              <>
                {Component ? (
                  <ChannelConfigWrapper>
                    <Component />
                  </ChannelConfigWrapper>
                ) : (
                  <ChannelConfigWrapper />
                )}
                <ResourceSubscriptionFormTable />
              </>
            )}
          </Column>
          <ActionBar fit>
            <ButtonGroup>
              <SaveButton>
                {channelId ? "Save changes" : "Create recipient"}
              </SaveButton>
              <DiscardButton />
            </ButtonGroup>
            {channelId && (
              <Button
                size="lg"
                isDisabled={deleting}
                variant="warning"
                onClick={() => setConfirming(true)}
              >
                Delete recipient
              </Button>
            )}
          </ActionBar>
        </Form>
      </Drawer>
      <ConfirmationDialog
        isOpen={confirming}
        title="Delete channel"
        variant="danger"
        onClose={() => setConfirming(false)}
        confirmButtonText="Delete"
        onConfirm={async () => {
          await deleteChannel({ channelId: channelId || "" });
          toast({
            title: "Deleted channel",
            variant: "success",
            id: "workspace-notifications",
          });
          navigate("..");
        }}
      >
        {/* TODO: fetch the number of alerts this will impact */}
        Are you sure you want to delete this channel?
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={exiting}
        title="Discard changes"
        variant="warning"
        onClose={() => setExiting(false)}
        confirmButtonText="Exit"
        onConfirm={async () => {
          navigate("..");
        }}
      >
        Are you sure you want to exit? Any unsaved changes will be lost.
      </ConfirmationDialog>
    </>
  );
};
