import { FormField, TagInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

export const CampaignAttribution = () => {
  return (
    <Controller
      name="flowMessageConfig.attributionCampaignIds"
      render={({ field }) => {
        const values = field.value ?? [];
        const options = values?.map((value) => ({ label: value, value })) ?? [];

        return (
          <FormField
            isOptional
            label="Campaign attribution"
            description="Additional campaign IDs that are sending this message, for outcome attribution purposes."
          >
            <TagInput
              {...field}
              value={values}
              supportsCreatableOptions
              options={options}
              placeholder="Additional campaign IDs..."
              onCreateOption={(value) => {
                field.onChange([...values, value]);
              }}
            />
          </FormField>
        );
      }}
    />
  );
};
