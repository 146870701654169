import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import LR from "logrocket";

import { useUser } from "src/contexts/user-context";
import { useFlags } from "launchdarkly-react-client-sdk";

export const Logrocket = () => {
  const { user, featureFlags } = useUser();
  const { appLogrocketDisabled } = useFlags();

  useEffect(() => {
    if (
      import.meta.env.PROD &&
      user &&
      !featureFlags?.logrocket_disabled &&
      !appLogrocketDisabled
    ) {
      LR.init("hightouch/hightouch-app", {
        network: {
          // Remove all response bodies
          responseSanitizer: () => null,
        },
      });
      LR.identify(String(user?.id), {
        name: user?.name,
        email: user?.email,
      });
      LR.getSessionURL((sessionURL) => {
        if (sessionURL) {
          Sentry.configureScope((scope) => {
            scope.setExtra("logrocket", sessionURL);
          });
        }
      });
    }
  }, [!!user, featureFlags]);

  return null;
};
