import {
  ReactNode,
  createContext,
  useContext,
  ReactElement,
  useEffect,
  useRef,
} from "react";

import { useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { isEqual } from "lodash";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";

export type UseHightouchFormReturn<T extends FieldValues, R> = {
  submit: () => Promise<R | undefined>;
} & UseFormReturn<T>;

export type UseHightouchFormProps<T extends FieldValues, R> = {
  success?: string | boolean | ((data: T) => string);
  error?: string;
  errorMessage?: string;
  onSubmit: (data: T) => Promise<R>;
  onError?: (error: any) => any;
  values?: DeepPartial<T>;
  submitOnChange?: boolean;
} & UseFormProps<T>;

export function useHightouchForm<T extends FieldValues, R = any>({
  onSubmit,
  onError,
  values,
  success = "Changes saved",
  error = "There was a problem saving your changes",
  errorMessage,
  submitOnChange,
  ...props
}: UseHightouchFormProps<T, R>): UseHightouchFormReturn<T, R> {
  const savedValues = useRef(values);
  const { toast } = useToast();

  const form = useForm<T>({
    ...props,
    defaultValues: values ?? props.defaultValues,
  });

  const { isDirty } = form.formState;

  const submit = async () => {
    form.clearErrors();
    const result: R | undefined = await new Promise((resolve, reject) => {
      form.handleSubmit(
        async (data) => {
          let result: R | undefined;

          // Promise can be rejected if onError rethrows it
          try {
            result = await onSubmit(data);
            if (typeof success === "string" || typeof success === "function") {
              toast({
                id: "save",
                title: typeof success === "function" ? success(data) : success,
                variant: "success",
              });
            }
            resolve(result);
          } catch (e) {
            // When onError is defined, assume the callsite will handle any error is receives.
            // If no onError handler, send to sentry for tracking.
            if (!onError) {
              captureException(e);
            }

            toast({
              id: "save-error",
              title: error,
              message: errorMessage || e?.message,
              variant: "error",
            });
            // Promise can be rejected if onError rethrows it
            try {
              onError?.(e);
            } catch (e1) {
              reject(e1);
            }
          }
        },
        (errors) => {
          if (typeof error === "string" || typeof error === "function") {
            toast({
              id: "save-error",
              title: error,
              message: errorMessage,
              variant: "error",
            });
          }
          onError?.(errors);
          resolve(null as any);
        },
      )();
    });

    return result;
  };

  useEffect(() => {
    if (!isEqual(savedValues.current, values)) {
      form.reset(values, { keepIsSubmitted: true, keepTouched: true });
      savedValues.current = values;
    }
  }, [values]);

  useEffect(() => {
    if (submitOnChange && isDirty) {
      submit();
    }
  }, [submitOnChange, isDirty]);

  return { ...form, submit };
}

type HightouchFormContextType<R = any> = {
  submit: () => Promise<R>;
};

const HightouchFormContext = createContext<HightouchFormContextType>({} as any);

export const useHightouchFormContext = () => useContext(HightouchFormContext);

export const Form = <T extends FieldValues, R>({
  form,
  children,
}: {
  form: UseHightouchFormReturn<T, R>;
  children: ReactNode;
}): ReactElement => {
  const { submit, ...context } = form;

  return (
    <HightouchFormContext.Provider value={{ submit }}>
      <FormProvider {...context}>{children}</FormProvider>
    </HightouchFormContext.Provider>
  );
};
