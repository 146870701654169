import { Alert, Column, FormField, TextInput } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Controller } from "react-hook-form";
import { useOutletContext } from "src/router";

import { FlowMessageContext } from "..";
import { CampaignLink, useCampaign } from "./campaign-link";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { FC } from "react";

export const MessageCampaign: FC<
  Readonly<{
    destination: {
      type: string;
      id: string;
      config: Record<string, unknown>;
      definition: {
        icon: string;
        name: string;
      };
    };
  }>
> = ({ destination }) => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();

  const { campaign, url } = useCampaign({
    destination,
    campaignId: flowMessage.config.campaignId,
  });

  const channelDefinition = getChannelDefinition(destination.type);
  const campaignSetup = channelDefinition.campaignSetup(
    flowMessage.message.channel.config,
  );

  if (campaignSetup === "freeform") {
    return (
      <Controller
        name="config.baseMessageId"
        render={({ field }) => (
          <FormField
            label="Identifier"
            description="A unique identifier you can use to reference this message."
          >
            <TextInput {...field} placeholder="Identifier..." />
          </FormField>
        )}
      />
    );
  }

  return (
    <Column gap={4}>
      <FormField
        label="Linked campaign"
        description={`The ${destination.type} campaign associated with this message.`}
      >
        <CampaignLink
          destination={destination}
          campaignId={flowMessage.config.campaignId}
        />
      </FormField>
      {campaign && channelDefinition.isCampaignInactive(campaign) && (
        <Alert
          variant="inline"
          type="warning"
          title="Activate the campaign"
          message="Hightouch has created a campaign that must be manually activated."
          actions={<LinkButton href={url}>Go to campaign</LinkButton>}
        />
      )}
    </Column>
  );
};
