import { useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "query-string";
import { Route, Routes } from "src/router";

import { hidePylon } from "src/lib/pylon";
import { PartnerDashboard } from "src/partner/dashboard";

import { CreateSource } from "./create-source";
import { CreateSync } from "./create-sync";
import { Layout } from "./layout";
import Source from "./source";
import { SyncRoutes } from "./sync";
import {
  Brand,
  CreateSyncConfig,
  CreateSourceConfig,
  ViewSourceConfig,
} from "./types";
import { SyncRun } from "./sync/run";

export type Config = {
  brand: Brand;
  config: CreateSyncConfig | ViewSourceConfig | CreateSourceConfig;
};

export const PartnerRoutes = () => {
  const { partnerUser } = useFlags();
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    const parsed = qs.parse(window.location.search) as Record<string, string>;
    if (parsed.config) {
      const json = JSON.parse(atob(parsed.config));
      setConfig(json);
    }
  }, []);

  useEffect(() => {
    hidePylon();
  }, []);

  return (
    <Routes>
      {partnerUser && <Route index element={<PartnerDashboard />} />}
      {config && (
        <Route element={<Layout config={config} />}>
          <Route path="/sources/:id" element={<Source />} />
          <Route
            path="/sources/new"
            element={
              <CreateSource config={config?.config as CreateSourceConfig} />
            }
          />
          <Route
            path="/syncs/new"
            element={<CreateSync config={config?.config as CreateSyncConfig} />}
          />
          <Route path="/syncs/:id/*" element={<SyncRoutes />} />
          <Route path="/syncs/:sync_id/runs/:run_id" element={<SyncRun />} />
        </Route>
      )}
    </Routes>
  );
};

// Testing config
// {
//   brand: {
//     logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
//     name: "Iterable",
//   },
//   config: {
//     type: ConfigType.CreateSync,
//     allowedSourceTypes: ["snowflake"],
//     destinationId: "53630",
//     allowedModelingMethods: [],
//   },
// }
