import { FC, useState } from "react";

import {
  Box,
  Column,
  FormField,
  Heading,
  Select,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import Helmet from "react-helmet";
import { FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "src/router";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { AddFolder } from "src/components/folders/add-folder";
import { useFolders } from "src/components/folders/use-folders";
import { useHightouchForm } from "src/components/form";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import {
  ParentModel,
  ParentModelSelect,
} from "src/components/models/parent-model-select";
import { PermissionedButton } from "src/components/permission";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  ParentQuery,
  useCreateAudienceMutation,
  useParentQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { Wizard, Step } from "src/components/wizard";
import { getModelInputFromState } from "src/utils/models";
import { useAudienceCreationPermission } from "src/components/permission/creation/model";
import { CreateAudienceState } from "./types";
import { useModelState } from "src/hooks/use-model-state";
import { PageSpinner } from "src/components/loading";

export const CreateAudience: FC = () => {
  const { state } = useLocation();

  const locationState = state as CreateAudienceState | null;

  const { data, isLoading } = useParentQuery(
    {
      id: locationState?.parentModelId ?? "",
    },
    {
      enabled: Boolean(locationState?.parentModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <CreateAudienceContent
      parentModel={data}
      conditions={locationState?.conditions ?? []}
      name={locationState?.name}
      referrer={locationState?.referrer}
    />
  );
};

const CreateAudienceContent: FC<
  Readonly<
    { parentModel: ParentQuery["segments_by_pk"] | null | undefined } & Omit<
      CreateAudienceState,
      "parentModelId"
    >
  >
> = (props) => {
  const navigate = useNavigate();
  const [parentModel, setParentModel] = useState<
    ParentQuery["segments_by_pk"] | null | undefined
  >(props.parentModel);
  const [step, setStep] = useState<number>(props.parentModel ? 1 : 0);
  const [searchParams] = useSearchParams();
  const [name, setName] = useState(props.name ?? "");
  const [description, setDescription] = useState("");
  const {
    state: { flattenedFolders },
    refetchFolders,
  } = useFolders({ folderType: "audiences", viewType: "models" });
  const [selectedFolder, setSelectedFolder] = useState<
    string | undefined | null
  >(searchParams.get("folder"));
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  const source = parentModel?.connection;

  const modelState = useModelState({
    query_type: QueryType.Visual,
    connection: source,
    parent: parentModel,
    visual_query_filter: { conditions: props.conditions },
  });

  const { isLoading: creating, mutateAsync: createAudience } =
    useCreateAudienceMutation();

  const form = useHightouchForm({
    onSubmit: async ({ labels }) => {
      const result = await createAudience({
        input: {
          ...getModelInputFromState(modelState.state),
          visual_query_filter: modelState.state.visual_query_filter ?? {
            conditions: [],
          },
          visual_query_parent_id: parentModel?.id,
          name,
          description,
          primary_key: parentModel?.primary_key,
          folder_id: selectedFolder,
          tags: getTagsFromLabels(labels),
        },
      });
      navigate(`/audiences/${result.insert_segments_one?.id}`);
    },
    success: `${name ?? "The audience"} created.`,
    error: `${
      name ? `"${name}"` : "The audience"
    } could not be created. Please try again.`,
    defaultValues: {
      labels: [{ key: "", value: "" }],
    },
  });

  const { hasValidationErrors } = useFormErrorContext();

  const { parentModelFilter } = useAudienceCreationPermission();

  const selectParentModel = (parentModel: ParentModel) => {
    setParentModel(parentModel);
    modelState.onChange({
      connection: parentModel?.connection,
      parent: parentModel,
      visual_query_filter: { conditions: [] },
    });
    setStep(1);
  };

  const steps: Step[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          isOptionAvailable={parentModelFilter}
          onSelect={selectParentModel}
        />
      ),
    },
    {
      title: "Define audience",
      render: () => (
        <AudienceExplore
          modelState={modelState}
          parentModel={parentModel}
          source={source}
        />
      ),
      onContinue: () => {
        if (!hasValidationErrors()) {
          setStep((s) => s + 1);
        }
      },
    },
    {
      title: "Finalize audience",
      disabled: !name,
      submitting: creating,
      header: <Heading>Finalize settings for this audience</Heading>,
      render: () => (
        <Column gap={8}>
          <FormField label="Audience name">
            <TextInput
              value={name}
              width="xs"
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>
          <FormField isOptional label="Description">
            <Textarea
              placeholder="Enter a description..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormField>
          <FormField isOptional label="Move to folder">
            <Select
              isClearable
              optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
              optionValue={(folder) => folder.id}
              options={flattenedFolders || []}
              placeholder="Select a folder..."
              value={selectedFolder}
              onChange={(folder) => {
                setSelectedFolder(folder);
              }}
            />
            <Box mt={4}>
              <PermissionedButton
                permission={{
                  v2: { resource: "workspace", grant: "can_update" },
                }}
                size="sm"
                onClick={() => setAddFolderOpen(true)}
              >
                New Folder
              </PermissionedButton>
            </Box>
            {addFolderOpen && (
              <AddFolder
                toggleDisabled
                folderType="audiences"
                viewType="models"
                onSave={(folder) => {
                  setSelectedFolder(folder);
                  refetchFolders();
                }}
                onClose={() => {
                  setAddFolderOpen(false);
                }}
              />
            )}
          </FormField>
          <LabelForm
            heading="Add labels"
            hint="Example keys: team, project, region, env."
            resourceType={ResourceType.Model}
          />
        </Column>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <Helmet>
        <title>New audience</title>
      </Helmet>

      <FormProvider {...form}>
        <Wizard
          fullscreen={step === 1}
          setStep={setStep}
          step={step}
          steps={steps}
          title="New audience"
          onCancel={() => {
            if (props.referrer?.pathname) {
              navigate(`/${props.referrer?.pathname}${props.referrer?.search}`);
            } else {
              navigate("/audiences");
            }
          }}
          onSubmit={form.submit}
        />
      </FormProvider>
    </>
  );
};
