import {
  Box,
  Checkbox,
  Column,
  EmptyState,
  InformationIcon,
  Paragraph,
  Row,
  SearchInput,
  SectionHeading,
  Tooltip,
} from "@hightouchio/ui";
import { capitalize, omit, repeat } from "lodash";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useOutletContext, useParams } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  GrantObject,
  GrantableResource,
  Grants,
  RESOURCE_GRANTS,
  RESOURCE_GRANT_OPTIONS,
} from "src/components/grant-select/types";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { UserGroupGrantsInsertInput } from "src/graphql";
import { Cell, HeaderCell } from "src/ui/table/cells";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { OutletContext } from "..";
import { getCheckboxProps } from "src/components/grant-select/util";
import pluralize from "pluralize";

export type AdditionalConfigurationProps = {
  value: Record<string, any>;
  onChange: (value: Record<string, any>) => void;
};

type ResourceDefinition = {
  id: string;
  name: string;
  definition: {
    name: string;
    icon: string;
  };
  additionalConfiguration?: React.FC<AdditionalConfigurationProps>;
};

type GrantInfo<R extends GrantableResource> = {
  id: string;
  resource_id: string;
  workspace_id: string;
  additional_data?: Record<string, any>;
} & GrantObject<R>;

type Props<R extends GrantableResource> = {
  resourceType: R;
  resources: Array<ResourceDefinition>;
  onSubmit: (values: {
    objects: Array<UserGroupGrantsInsertInput>;
    additionalData: Record<string, Record<string, any>>;
  }) => Promise<void>;
  grants: GrantInfo<R>[];
};

const names = {
  source: "sources",
  destination: "destinations",
  parent_model: "parent models",
};

export function WorkspaceResourceGrantsForm<R extends GrantableResource>({
  resourceType,
  resources: unfilteredResources,
  grants,
  onSubmit,
}: Props<R>) {
  const { roleId, workspaceId } = useParams();
  const [search, setSearch] = useState("");

  const { isWorkspaceAdmin, isApprovalsRequired } =
    useOutletContext<OutletContext>();

  const resources = useMemo(() => {
    return unfilteredResources.filter((resource) =>
      resource.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, unfilteredResources]);

  const grantOptions =
    RESOURCE_GRANT_OPTIONS(isApprovalsRequired)[resourceType];
  const grantValues = RESOURCE_GRANTS[resourceType];

  const formValues: {
    values: Grants<R>;
    additionalValues: Record<string, Record<string, any>>;
  } = useMemo(() => {
    if (!resources?.length) return { values: {}, additionalValues: {} };

    const values = {};
    const additionalValues = {};
    for (const resource of resources) {
      const grant = grants.find(
        (grant) => String(grant.resource_id) === String(resource.id),
      );
      values[resource.id] = grantValues.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: grant?.[curr] ?? false,
        };
      }, {});
      additionalValues[resource.id] = grant?.additional_data ?? {};
    }

    const defaultGrant = grants.find((grant) => grant.resource_id === null);

    // Handle the default grant for a resource (e.g. all sources in a workspace)
    values["-1"] = grantValues.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: defaultGrant?.[curr] ?? false,
      };
    }, {});

    return { values, additionalValues };
  }, [grants, resources]);

  const form = useHightouchForm({
    onSubmit: async ({ grants, additional_data }) => {
      const objects: Array<UserGroupGrantsInsertInput> = Object.entries(
        grants,
      ).map(([resource_id, grants]) => ({
        resource_id: resource_id === "-1" ? null : String(resource_id),
        resource_type: resourceType,
        workspace_id: String(workspaceId),
        user_group_id: String(roleId),
        ...omit(grants, ["user_group_id"]),
      }));

      await onSubmit({ objects, additionalData: additional_data });
    },
    values: {
      // @ts-expect-error - React Hook Form doesn't support type generics properly
      grants: formValues.values as Grants<R>,
      additional_data: formValues.additionalValues,
    },
  });

  if (!resources?.length) {
    return (
      <Column p={4}>
        <EmptyState message={`No ${names[resourceType]} in this workspace`} />
      </Column>
    );
  }

  return (
    <Form form={form}>
      <Column flex={1} overflow="auto" p={4}>
        <Row align="center" justify="space-between" mb={4} gap={4}>
          <Column>
            <SectionHeading>{capitalize(names[resourceType])}</SectionHeading>
            <Paragraph color="text.secondary">
              Configure what actions users in this role can perform on each{" "}
              {pluralize(names[resourceType], 1)} and it's dependent resources.
            </Paragraph>
          </Column>
          <SearchInput
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder="Search by name..."
          />
        </Row>

        <Box
          as="table"
          display="grid"
          gridTemplateColumns={`1fr ${repeat(" 0.5fr", grantOptions.length)}`}
        >
          <Box as="thead" display="contents">
            <HeaderCell alignFirstHeader={true}>Name</HeaderCell>
            {grantOptions.map(({ label, value, description }) => {
              return (
                <HeaderCell key={value}>
                  <Row gap={1}>
                    {label}
                    {description ? (
                      <Tooltip message={description}>
                        <InformationIcon />
                      </Tooltip>
                    ) : null}
                  </Row>
                </HeaderCell>
              );
            })}
          </Box>
          <GrantRow
            id="-1"
            isWorkspaceAdmin={isWorkspaceAdmin}
            grantOptions={grantOptions}
            isDefaultGrant
          >
            <Row align="center" gap={2}>
              <TextWithTooltip fontWeight="medium">
                {`All ${names[resourceType]}`}
              </TextWithTooltip>
            </Row>
          </GrantRow>
          <Box as="tbody" display="contents">
            {resources.map((resource) => {
              return (
                <GrantGroup
                  key={resource.id}
                  isWorkspaceAdmin={isWorkspaceAdmin}
                  grantOptions={grantOptions}
                  resource={resource}
                />
              );
            })}
          </Box>
        </Box>
      </Column>

      <ActionBar fit>
        <FormActions />
      </ActionBar>
    </Form>
  );
}

type GrantGroupProps = {
  resource: ResourceDefinition;
  isWorkspaceAdmin: boolean;
  grantOptions: Array<{ value: string }>;
};

function GrantGroup({
  resource,
  isWorkspaceAdmin,
  grantOptions,
}: GrantGroupProps) {
  return (
    <GrantRow
      id={resource.id}
      isWorkspaceAdmin={isWorkspaceAdmin}
      grantOptions={grantOptions}
      additionalConfiguration={resource.additionalConfiguration}
    >
      <Row align="center" gap={2}>
        <IntegrationIcon
          src={resource.definition.icon}
          name={resource.definition.name}
        />
        <TextWithTooltip fontWeight="medium">{resource.name}</TextWithTooltip>
      </Row>
    </GrantRow>
  );
}

type GrantRowProps = {
  id: string;
  children: React.ReactNode;
  isWorkspaceAdmin: boolean;
  grantOptions: Array<{ value: string }>;
  isDefaultGrant?: boolean;
  additionalConfiguration?: React.FC<AdditionalConfigurationProps>;
};

function GrantRow({
  id,
  isWorkspaceAdmin,
  grantOptions,
  children,
  isDefaultGrant = false,
  additionalConfiguration,
}: GrantRowProps) {
  const form = useFormContext();

  const defaultGrant = !isDefaultGrant
    ? form.watch(`grants.${`-1`}`)
    : undefined;

  const cellStyle = isDefaultGrant
    ? { height: "60px", borderBottom: "2px", borderColor: "base.border" }
    : { height: "60px" };

  const AdditionalConfigurationComponent =
    additionalConfiguration ?? (() => null);

  return (
    <Box display="contents">
      <Box as="tr" display="contents">
        <Cell {...cellStyle}>
          <Row align="center" gap={2}>
            {children}
          </Row>
        </Cell>
        {grantOptions.map(({ value }) => {
          return (
            <Cell {...cellStyle} key={`${id}-${value}`}>
              <Controller
                name={`grants.${id}.${value}`}
                render={({ field }) => {
                  const { checkboxProps, tooltipProps } = getCheckboxProps({
                    value: field.value,
                    defaultValue: defaultGrant?.[value],
                    isWorkspaceAdmin,
                    isBuiltIn: false, // Built-in roles cannot access this form
                  });

                  return (
                    <Tooltip {...tooltipProps}>
                      <Checkbox {...checkboxProps} onChange={field.onChange} />
                    </Tooltip>
                  );
                }}
              />
            </Cell>
          );
        })}
      </Box>
      {Boolean(additionalConfiguration) && (
        <Controller
          name={`additional_data.${id}`}
          render={({ field }) => {
            return (
              <AdditionalConfigurationComponent
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        ></Controller>
      )}
    </Box>
  );
}
