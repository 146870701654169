export enum SyncTabSlug {
  OVERVIEW = "overview",
  RUNS = "runs",
  CONFIGURATION = "configuration",
  SCHEDULE = "schedule",
  SYNC_LOGS = "sync logs",
  ACTIVITY = "activity",
  ALERTS = "alerts",
  ALERTING = "alerting",
  SPLITS = "splits",
}

export const syncTabParam = "syncsTab";
