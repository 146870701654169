import { FC, useMemo, useState } from "react";

import {
  Avatar,
  DeleteIcon,
  EditIcon,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Outlet, useParams } from "src/router";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import { DeleteSourceWarning } from "src/components/modals/delete-source-warning";
import { OverageModal } from "src/components/modals/overage-modal";
import { PermissionProvider } from "src/components/permission/permission-context";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import {
  SourceQuery,
  useSourceQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { SourceBadges } from "src/utils/sources";
import { formatDate } from "src/utils/time";
import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { isPresent } from "src/types/utils";

export type OutletContext = {
  source: NonNullable<SourceQuery["connections_by_pk"]>;
};

export const Source: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useUser();

  const {
    data: source,
    error,
    isLoading: loadingSource,
  } = useSourceQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.connections_by_pk },
  );

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "source", grant: "can_update", id: id ?? "" },
  });

  const context: OutletContext = useMemo(() => ({ source: source! }), [source]);

  if (!id || loadingSource) {
    return <PageSpinner />;
  }

  if (error) {
    return <Warning subtitle={error.message} title="Failed to load source" />;
  }

  if (!source) {
    return (
      <Warning subtitle="It may have been deleted" title="Source not found" />
    );
  }

  return (
    <>
      <PermissionProvider
        permission={{
          v2: {
            resource: "source",
            grant: "can_update",
            id,
          },
        }}
      >
        <DetailPage
          tabs={
            source.definition?.isSampleDataSource
              ? [{ path: "configuration", title: "Overview" }]
              : [
                  { path: "configuration", title: "Configuration" },
                  hasUpdatePermission
                    ? { path: "linking", title: "Linking" }
                    : null,
                  hasUpdatePermission && source.plan_in_warehouse
                    ? {
                        path: "sync-logs",
                        title: "Sync logs",
                      }
                    : null,
                  user?.permissions_v2_enabled
                    ? {
                        path: "grants",
                        title: "Grants",
                      }
                    : null,
                ].filter(isPresent)
          }
          crumbs={[{ label: "All sources", link: "/sources" }]}
          title={`${source.name ?? "Unnamed source"} - Sources`}
          hasBottomPadding
          header={<Header source={source} />}
        >
          <Outlet context={context} />
        </DetailPage>
      </PermissionProvider>
      <OverageModal />
    </>
  );
};

const Header = ({ source }) => {
  const { toast } = useToast();
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const currentLabels = source.tags ?? {};
  const labelKeys = Object.keys(currentLabels);
  const updatedByUsername =
    source.updated_by_user?.name || source.created_by_user?.name;

  const { mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const saveName = async (name: string) => {
    try {
      await updateSource({
        id: source.id.toString(),
        source: {
          name,
        },
      });
      toast({
        id: "update-source",
        title: "Source name updated",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-source",
        title: "Failed to update source name",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <>
      <Row align="center" justify="space-between" w="100%">
        <PermissionedEditableHeading
          permission={{
            v2: { resource: "source", grant: "can_update", id: source.id },
          }}
          size="lg"
          value={source?.name ?? ""}
          onChange={saveName}
        />
        <Menu>
          <MenuActionsButton variant="secondary" />
          <MenuList>
            <PermissionedMenuItem
              icon={EditIcon}
              onClick={() => {
                setIsEditLabelModalOpen(true);
              }}
              permission={{
                v2: { resource: "source", grant: "can_update", id: source.id },
              }}
            >
              Edit labels
            </PermissionedMenuItem>
            <PermissionedMenuItem
              permission={{
                v2: { resource: "source", grant: "can_update", id: source.id },
              }}
              icon={DeleteIcon}
              variant="danger"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </PermissionedMenuItem>
          </MenuList>
        </Menu>
      </Row>
      <DetailBar>
        <Row align="center" gap={2} flexShrink={0}>
          <IntegrationIcon
            src={source.definition?.icon}
            name={source.definition?.name}
          />
          <Text fontWeight="medium">{source.definition?.name}</Text>
          <SourceBadges
            isSampleDataSource={source.definition?.isSampleDataSource}
          />
        </Row>
        <Row align="center" gap={2} flexShrink={0}>
          <Text>Last updated:</Text>
          <Row gap={1} align="center">
            {formatDate((source.updated_at || source.created_at)!)}
            {updatedByUsername && (
              <>
                <Text>by</Text>
                <Avatar size="xs" name={updatedByUsername} />
              </>
            )}
          </Row>
        </Row>
        <Row align="center" gap={2} flexShrink={0}>
          <Text>Slug:</Text>
          <DisplaySlug currentSlug={source.slug} />
        </Row>
        {labelKeys?.length > 0 && <Labels labels={currentLabels} />}
      </DetailBar>

      <EditLabelModal
        isOpen={isEditLabelModalOpen}
        labels={currentLabels ?? {}}
        resourceType={ResourceType.Source}
        onClose={() => setIsEditLabelModalOpen(false)}
        onSubmit={(labels) =>
          updateSource({
            id: source.id.toString(),
            source: {
              tags: labels,
            },
          })
        }
      />
      {isDeleteModalOpen && (
        <DeleteSourceWarning
          source={source}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </>
  );
};
