import {
  Box,
  Column,
  Drawer,
  ErrorIcon,
  Row,
  SectionHeading,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { FC } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";
import { PermissionedButton } from "src/components/permission";
import { useIsPermissionsV2Enabled } from "src/components/permission/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  AudienceSubsetGroupsOrderBy,
  OrderBy,
  useSubsetCategoriesQuery,
} from "src/graphql";
import {
  Pagination,
  SortOption,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { OutletContext } from "../layout";
import { getGrantType } from "./util";

const initialSort: SortOption<keyof AudienceSubsetGroupsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};

const sortOptions: SortOption<keyof AudienceSubsetGroupsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
  { key: "updated_at", direction: OrderBy.Desc, label: "Recently updated" },
];

export const SubsetCategories: FC = () => {
  const navigate = useNavigate();
  const { subset_category_id, "*": matchAll } = useParams();

  const isDrawerOpen = Boolean(
    subset_category_id || matchAll?.endsWith("/new"),
  );

  const permissionsV2Enabled = useIsPermissionsV2Enabled();

  const { limit, offset, page, setPage } = useTableConfig({ limit: 20 });
  const orderBy = useTableSort<AudienceSubsetGroupsOrderBy>(
    initialSort,
    sortOptions,
  );

  const { parentModel } = useOutletContext<OutletContext>();

  const onClose = () => navigate("/governance/subset-categories");

  const subsetCategoriesQuery = useSubsetCategoriesQuery(
    {
      filter: {
        parent_model_id: { _eq: parentModel.id },
      },
      limit,
      offset,
      orderBy,
      permissionsV2Enabled,
    },
    { enabled: parentModel != undefined },
  );

  const tableColumns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name }) => <Text fontWeight="medium">{name ?? "--"}</Text>,
      headerSx: { paddingLeft: "16px !important" },
      cellSx: { paddingLeft: "16px !important" },
    },
    {
      name: "Subsets",
      cell: ({ subset_values_aggregate }) => (
        <Row width="100%" justifyContent="right">
          <Text>{subset_values_aggregate.aggregate.count ?? 0}</Text>
        </Row>
      ),
      min: "min-content",
      max: "min-content",
    },
    {
      name: "Description",
      cell: ({ description }) => (
        <TextWithTooltip>{description ?? "--"}</TextWithTooltip>
      ),
    },
    {
      cell: ({ is_required, subset_values }) => {
        const hasAccessGrants = subset_values?.some(
          ({ access_grants_aggregate, user_group_subset_grants_aggregate }) =>
            permissionsV2Enabled
              ? user_group_subset_grants_aggregate.aggregate.count
              : access_grants_aggregate.aggregate.count,
        );

        if (hasAccessGrants) {
          return <></>;
        }

        return (
          <Tooltip
            message={`No ${getGrantType(
              permissionsV2Enabled,
            )} have access to any subsets in this category.${
              is_required
                ? ` This blocks all ${getGrantType(
                    permissionsV2Enabled,
                  )} from creating or editing audiences.`
                : ""
            }`}
          >
            <Box as={ErrorIcon} color="danger.base" fontSize={20} />
          </Tooltip>
        );
      },
      min: "min-content",
      max: "min-content",
    },
  ];

  return (
    <>
      <Column py={6} gap={6}>
        <Row alignItems="center" justifyContent="space-between">
          <Column>
            <SectionHeading>Subset categories</SectionHeading>
            <Text>
              Control whether users can access customer data based on subsets of
              the parent model
            </Text>
          </Column>

          <PermissionedButton
            permission={{
              v1: { resource: "audience_schema", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModel.id ?? "",
              },
            }}
            onClick={() =>
              navigate({
                pathname: "new",
                search: window.location.search,
              })
            }
            variant="primary"
          >
            Add category
          </PermissionedButton>
        </Row>

        <Table
          columns={tableColumns}
          data={subsetCategoriesQuery.data?.audience_subset_groups}
          loading={
            subsetCategoriesQuery.isLoading ||
            subsetCategoriesQuery.isRefetching
          }
          onRowClick={(row) =>
            navigate({
              pathname: row.id,
              search: window.location.search,
            })
          }
          placeholder={{
            title: `You haven't created any subset categories for parent model ${parentModel.name}`,
          }}
          sortOptions={sortOptions}
        />
        <Pagination
          count={
            subsetCategoriesQuery.data?.audience_subset_groups_aggregate
              .aggregate?.count
          }
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Column>
      <Drawer closeOnEsc isOpen={isDrawerOpen} size="lg" onClose={onClose}>
        <Outlet context={{ parentModel }} />
      </Drawer>
    </>
  );
};
