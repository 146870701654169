import { FC, useState } from "react";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  ClipboardButton,
  Column,
  FormField,
  Heading,
  Paragraph,
  Row,
  SectionHeading,
  Switch,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";

import { useOutletContext } from "src/router";
import {
  useConfigureSsoMutation,
  useUpdateOrganizationMutation,
} from "src/graphql";
import { newPylonMessage } from "src/lib/pylon";
import { FileUploader } from "src/ui/file";
import { OutletContext } from "..";

export const SSOSetup: FC = () => {
  const { organization } = useOutletContext<OutletContext>();

  const [ssoModalOpen, setSsoModalOpen] = useState(false);
  const [createNewTokenOpen, setCreateNewTokenOpen] = useState(false);
  const { toast } = useToast();

  const [allowInvites, setAllowInvites] = useState(
    organization?.can_invite_users,
  );

  const isSsoEnabled = (organization?.auth0_connections || []).length > 0;

  const loginUrl = "https://app.hightouch.com/sso/" + organization?.slug;
  const connectionName =
    organization?.auth0_connections[0]?.name || `${organization?.slug}-1`;

  const updateOrganizationMutation = useUpdateOrganizationMutation();

  const toggleAllowInvites = async (value: boolean) => {
    setAllowInvites(value);
    try {
      await updateOrganizationMutation.mutateAsync({
        id: organization?.id,
        input: {
          can_invite_users: value,
        },
      });
      toast({
        id: "update-allow-invites",
        title: "User invites are " + (value ? "enabled" : "disabled"),
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "update-allow-invites",
        title: "Failed to update user invites",
        variant: "error",
      });
      setAllowInvites(!value);
    }
  };

  return (
    <Column flex={1} minH={0}>
      <Row align="center" mb={4}>
        <Heading>Single sign-on</Heading>
      </Row>
      <Column flex={1} minH={0} overflow="auto">
        <Column align="flex-start" gap={6}>
          <Button
            variant={isSsoEnabled ? "secondary" : "primary"}
            onClick={() => setSsoModalOpen(true)}
          >
            {isSsoEnabled ? "Update SAML SSO" : "Configure SAML SSO"}
          </Button>

          {isSsoEnabled && (
            <Alert
              variant="inline"
              message="SSO cannot be disabled in the app, but our team can take care of this for you."
              title="Looking to disable single sign-on?"
              type="info"
              actions={
                <Button
                  variant="secondary"
                  onClick={() =>
                    newPylonMessage(
                      "Hi, I'd like to disable SSO for my workspace.",
                    )
                  }
                >
                  Contact us
                </Button>
              }
            />
          )}
          <SCIMTokenModal
            isOpen={createNewTokenOpen}
            isCreating={updateOrganizationMutation.isLoading}
            onClose={() => {
              setCreateNewTokenOpen(false);
            }}
            onCreate={async (key) => {
              await updateOrganizationMutation.mutateAsync({
                id: organization?.id,
                input: {
                  api_key: sha256.create().update(key).hex(),
                },
              });
            }}
          ></SCIMTokenModal>
          <Box display="flex" flexDirection="column" gap={8} mt={6}>
            {isSsoEnabled && (
              <>
                <FormField
                  label="Login URL"
                  tip="Copy and paste this somewhere safe and use it to invite teammates to this organization."
                >
                  <Box display="flex" gap={3}>
                    <TextInput isReadOnly value={loginUrl} />
                    <ClipboardButton text={loginUrl} />
                  </Box>
                </FormField>

                <FormField
                  description="By allowing users to be invited, they can still sign in with their Google or Microsoft logins in addition to SSO"
                  label="Allow inviting users"
                >
                  <Box alignItems="center" display="flex" gap={3}>
                    <Switch
                      isChecked={Boolean(allowInvites)}
                      isDisabled={updateOrganizationMutation.isLoading}
                      onChange={toggleAllowInvites}
                    />
                  </Box>
                </FormField>
              </>
            )}
          </Box>
          {isSsoEnabled && (
            <Box mt={4}>
              <FormField
                description={
                  (organization?.api_key ? "Refresh your " : "Generate a ") +
                  "SCIM API token to allow your identity provider to notify us of any changes in your users or groups."
                }
                label="SCIM provisioning"
              >
                <Button
                  onClick={() => {
                    setCreateNewTokenOpen(true);
                  }}
                >
                  {organization?.api_key ? "Refresh " : "Generate "} SCIM token
                </Button>
              </FormField>
            </Box>
          )}
        </Column>
      </Column>
      <AddSSOModal
        onClose={() => {
          setSsoModalOpen(false);
        }}
        connectionName={connectionName}
        isOpen={ssoModalOpen}
      />
    </Column>
  );
};

interface SCIMTokenModalProps {
  isOpen: boolean;
  isCreating: boolean;
  onCreate: (key: string) => Promise<void>;
  onClose: () => void;
}

const SCIMTokenModal: FC<SCIMTokenModalProps> = ({
  isOpen,
  onClose,
  onCreate,
  isCreating,
}) => {
  const [key, setKey] = useState<string | null>(null);
  if (!key) {
    setKey(uuidv4());
  }

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
      closeOnEsc
      closeOnOverlayClick
    >
      <ChakraModalOverlay />
      <ChakraModalContent p={0} my="auto">
        <ChakraModalHeader
          alignItems="center"
          padding={6}
          borderBottom="1px solid"
          borderColor="base.border"
          my="auto"
        >
          Create SCIM API key
        </ChakraModalHeader>
        <ChakraModalBody m={0} p={6}>
          <Box display="flex" flexDirection="column" gap={6}>
            <FormField
              description="This key will only be displayed once, please copy it into your secrets manager."
              label="API key"
            >
              <Box display="flex" gap={3}>
                <TextInput isReadOnly value={key ?? ""} />
                <ClipboardButton text={key ?? ""} />
              </Box>
            </FormField>
          </Box>
        </ChakraModalBody>
        <ChakraModalFooter
          p={4}
          m={0}
          borderTop="1px solid"
          borderColor="base.border"
        >
          <ButtonGroup>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              isLoading={isCreating}
              variant="primary"
              onClick={async () => {
                if (!key) {
                  return;
                }
                await onCreate(key);
                onClose();
              }}
            >
              Create
            </Button>
          </ButtonGroup>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};

interface AddSsoModalProps {
  isOpen: boolean;
  onClose: () => void;
  connectionName: string;
}

const AddSSOModal: FC<AddSsoModalProps> = ({
  isOpen,
  onClose,
  connectionName,
}) => {
  const [cert, setCert] = useState("");
  const [signInEndpoint, setSignInEndpoint] = useState("");
  const { toast } = useToast();

  const { mutateAsync: addSso, isLoading } = useConfigureSsoMutation();

  const save = async () => {
    try {
      await addSso({
        details: {
          cert,
          signInEndpoint,
        },
      });

      toast({
        id: "sso",
        title: "SSO configuration updated",
        variant: "success",
      });
      onClose();
    } catch (err) {
      toast({
        id: "sso",
        title: "Failed to configure SSO",
        variant: "error",
      });
    }
  };

  const audienceValue = `urn:auth0:hightouch:${connectionName}`;
  const samlUrl = `https://hightouch.us.auth0.com/login/callback?connection=${connectionName}`;

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
      closeOnEsc
      closeOnOverlayClick
    >
      <ChakraModalOverlay />
      <ChakraModalContent p={0} my="auto">
        <ChakraModalHeader
          alignItems="center"
          padding={6}
          borderBottom="1px solid"
          borderColor="base.border"
          my="auto"
        >
          Configure SAML SSO
        </ChakraModalHeader>
        <ChakraModalBody m={0} p={6}>
          <Box display="flex" flexDirection="column" gap={6}>
            <Box>
              <Text
                textTransform="uppercase"
                fontWeight="semibold"
                size="sm"
                color="text.tertiary"
              >
                Step 1
              </Text>
              <SectionHeading>
                Set up an SSO application in your identity provider
              </SectionHeading>
              <Paragraph>
                If you need help settings things up, view our{" "}
                <Link href="https://hightouch.com/docs/workspace-management/sso">
                  documentation
                </Link>
                .
              </Paragraph>

              <Box display="flex" flexDirection="column" gap={6} mt={4}>
                <FormField label="SAML URL">
                  <Box display="flex" gap={3}>
                    <TextInput isReadOnly value={samlUrl} />
                    <ClipboardButton text={samlUrl} />
                  </Box>
                </FormField>

                <FormField label="SAML audience URI">
                  <Box display="flex" gap={3}>
                    <TextInput isReadOnly value={audienceValue} />
                    <ClipboardButton text={audienceValue} />
                  </Box>
                </FormField>
              </Box>
            </Box>

            <Box>
              <Text
                textTransform="uppercase"
                fontWeight="semibold"
                size="sm"
                color="text.tertiary"
              >
                Step 2
              </Text>

              <SectionHeading>
                Provide the details of your SSO application
              </SectionHeading>

              <Box display="flex" flexDirection="column" gap={6} mt={4}>
                <FormField
                  description="This is the URL your identity provider (Okta, Azure AD, etc.) provides when completing the configuration of a SAML application."
                  label="Identity provider SSO URL"
                >
                  <TextInput
                    placeholder="Enter your SAML sign in endpoint"
                    value={signInEndpoint}
                    onChange={(event) => setSignInEndpoint(event.target.value)}
                  />
                </FormField>

                <FormField
                  description="This is a text file that usually starts with BEGIN CERTIFICATE. Please upload the entire file as provided by your identity provider."
                  label="x.509 certificate"
                >
                  <FileUploader
                    acceptedFileTypes={[".pem", ".crt", ".cert", ".cer"]}
                    transformation="string"
                    value={cert}
                    onChange={setCert}
                  />
                </FormField>
              </Box>
            </Box>
          </Box>
        </ChakraModalBody>
        <ChakraModalFooter
          p={4}
          m={0}
          borderTop="1px solid"
          borderColor="base.border"
        >
          <ButtonGroup>
            <Button onClick={onClose}>Close</Button>
            <Button
              isDisabled={isLoading || !cert || !signInEndpoint}
              isLoading={isLoading}
              variant="primary"
              onClick={save}
            >
              Save
            </Button>
          </ButtonGroup>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
