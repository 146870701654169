import { FC } from "react";

import { Row, Column, Text, Paragraph, SectionHeading } from "@hightouchio/ui";

import { DestinationAccessCard } from "src/components/destinations/catalog/destination-access-card";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import { CheckList, CheckListItem } from "src/ui/checklist";

interface Props {
  category: string;
  definition: DestinationDefinition | undefined;
  onClear: () => void;
}

export const DestinationDetails: FC<Readonly<Props>> = ({
  category,
  definition,
  onClear,
}) => {
  return (
    <>
      <Row height="24px" align="center" gap={2} mb={6}>
        <IntegrationIcon
          src={`${definition?.icon}?h=48`}
          name={definition?.name}
        />
        <SectionHeading>{definition?.name}</SectionHeading>
      </Row>
      <Column gap={6}>
        {(definition?.status === "alpha" ||
          definition?.status === "coming_soon") && (
          <DestinationAccessCard
            category={category}
            definition={definition}
            onClear={onClear}
          />
        )}
        {definition?.shortPitch && (
          <Column gap={2}>
            <Text
              fontWeight="semibold"
              textTransform="uppercase"
              color="text.secondary"
              size="sm"
            >
              About
            </Text>
            <Paragraph>{definition?.shortPitch}</Paragraph>
          </Column>
        )}
        {definition?.features && definition?.features?.length > 0 && (
          <Column>
            <Text
              fontWeight="semibold"
              textTransform="uppercase"
              color="text.secondary"
              size="sm"
              mb={-2}
            >
              Features
            </Text>
            <CheckList>
              {definition?.features.map((value) => (
                <CheckListItem key={value}>
                  <Text>{value}</Text>
                </CheckListItem>
              ))}
            </CheckList>
          </Column>
        )}
      </Column>
    </>
  );
};
