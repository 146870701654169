export const SUPPORTED_DESTINATIONS = [
  "s3",
  "iterable",
  "sfmc",
  "braze",
] as const;

type SupportedDestination = (typeof SUPPORTED_DESTINATIONS)[number];

type ChannelDefinition = {
  campaignSetup: (
    channelConfig: Record<string, unknown>,
  ) => "freeform" | "template";
  getChannelConfig: (
    channelConfig: Record<string, unknown>,
  ) => Record<string, unknown>;
  getCampaignUrl: (
    campaign: {
      id: string;
      name: string;
      status: string | null;
    },
    destinationConfig: Record<string, unknown>,
  ) => string;
  isCampaignInactive: (campaign: Record<string, unknown>) => boolean;
  isPreviewSupported: (channelConfig: Record<string, unknown>) => boolean;
  getReferenceString: (reference: string) => string;
};

export const CHANNEL_DEFINITION: {
  [key in SupportedDestination]: ChannelDefinition;
} = {
  s3: {
    campaignSetup: () => "freeform",
    getChannelConfig: (config) => config,
    getCampaignUrl: () => "",
    isCampaignInactive: () => false,
    isPreviewSupported: () => false,
    getReferenceString: (s) => s,
  },
  iterable: {
    campaignSetup: () => "template",
    getChannelConfig: (config) => ({
      ...config,
      recipientType: config?.recipientType ?? "email",
    }),
    getCampaignUrl: (campaign) =>
      `https://app.iterable.com/campaigns/${campaign.id}`,
    isCampaignInactive: (campaign) => campaign.status === "Ready",
    isPreviewSupported: () => true,
    getReferenceString: (s) => s,
  },
  sfmc: {
    campaignSetup: (channelConfig) =>
      channelConfig.type === "dataExtensions" ? "freeform" : "template",
    getChannelConfig: (config) => ({
      ...config,
      type: config?.type ?? "trigger",
    }),
    getCampaignUrl: () => "",
    isCampaignInactive: () => false,
    isPreviewSupported: (channelConfig) =>
      channelConfig.type !== "dataExtensions",
    getReferenceString: (s) => s,
  },
  braze: {
    campaignSetup: () => "template",
    getChannelConfig: (config) => config,
    getCampaignUrl: (campaign, destinationConfig) => {
      // https://www.braze.com/docs/api/basics/#endpoints
      const restRegionToDashboard = {
        "iad-01.braze.com": "dashboard-01.braze.com",
        "iad-02.braze.com": "dashboard-02.braze.com",
        "iad-03.braze.com": "dashboard-03.braze.com",
        "iad-04.braze.com": "dashboard-04.braze.com",
        "iad-05.braze.com": "dashboard-05.braze.com",
        "iad-06.braze.com": "dashboard-06.braze.com",
        "iad-07.braze.com": "dashboard-07.braze.com",
        "iad-08.braze.com": "dashboard-08.braze.com",
        "fra-01.braze.eu": "dashboard-01.braze.eu",
        "fra-02.braze.eu": "dashboard-02.braze.eu",
      };
      return `https://${
        restRegionToDashboard[destinationConfig.region as string]
      }/engagement/campaigns?start=0&limit=10&globalFilter="${encodeURIComponent(
        campaign.name,
      )}"&columnFilters%5Bstatus%5D=all&sortby=last_edited&sortdir=-1`;
    },
    isCampaignInactive: (campaign) => campaign.status === "Ready",
    // Braze does not support sending emails to arbitrary emails
    // We will need to create a user beforehand to preview the email
    isPreviewSupported: () => false,
    getReferenceString: (s) => "api_trigger_properties.${" + s + "}",
  },
};

export const getChannelDefinition = (
  destinationType: string,
): ChannelDefinition => {
  if (
    SUPPORTED_DESTINATIONS.includes(destinationType as SupportedDestination)
  ) {
    return CHANNEL_DEFINITION[destinationType];
  }

  return {
    campaignSetup: () => "freeform",
    getChannelConfig: (config) => config,
    getCampaignUrl: () => "",
    isCampaignInactive: () => false,
    isPreviewSupported: () => false,
    getReferenceString: (s) => s,
  };
};
