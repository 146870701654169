import {
  DecisionEngineChannelConfig,
  DecisionEngineChannelType,
  DecisionEngineOutcomeReward,
  Variable,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { generateDecisionEngineSyncConfig } from "@hightouch/core/server/graphql/schema/decision-engines/util";
import {
  FolderIcon,
  MailIcon,
  MobilePhoneIcon,
  PublishIcon,
} from "@hightouchio/ui";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { v4 as uuid } from "uuid";

import { SyncOp, useTestSyncConfigurationQuery } from "src/graphql";
import { getRawModelRow } from "src/utils/models";
import { lowercase, snakecaseObject } from "src/utils/object";
import { getChannelDefinition } from "./definitions";

export const ChannelOptions: Array<{
  label: string;
  value: DecisionEngineChannelType;
  icon: React.ComponentType;
}> = [
  {
    label: "Email",
    value: "email",
    icon: MailIcon,
  },
  {
    label: "SMS",
    value: "sms",
    icon: MobilePhoneIcon,
  },
  {
    label: "Push",
    value: "push",
    icon: PublishIcon,
  },
  {
    label: "File",
    value: "raw",
    icon: FolderIcon,
  },
] as const;

export const ChannelOptionsMap = ChannelOptions.reduce(
  (acc, option) => ({ ...acc, [option.value]: option }),
  {} as Record<DecisionEngineChannelType, (typeof ChannelOptions)[number]>,
);

export const parseMessageVariables = (variables: Array<Variable>) => {
  const subjects = variables?.find((variable) => variable.name === "subject")
    ?.values;
  return {
    subjects,
    variables: variables?.filter(({ name }) => name !== "subject"),
  };
};

export const useEmailPreview = ({
  channel,
}: {
  channel: {
    type: string;
    destination: { id: string; type: string; definition: { name: string } };
    config: Record<string, any>;
  };
}) => {
  const client = useQueryClient();
  const [previewId, setPreviewId] = useState<string | undefined>();
  const [error, setError] = useState("");

  const run = async ({
    email,
    segment,
    workspaceId,
    campaignId,
    user,
    items,
    variables,
  }: {
    email?: string;
    segment: { id: string | number; columns: any };
    workspaceId: string;
    campaignId: string;
    user: Record<string, string> | null | undefined;
    items: Record<string, any> | null | undefined;
    variables: Record<string, any> | null | undefined;
  }) => {
    const previewId = uuid();
    const rawUser = user ? getRawModelRow(user, segment.columns) : {};

    const row = {
      hightouch_recommendation: {
        ...(items ? lowercase(snakecaseObject(items), 2) : {}),
        ...(variables ? variables : {}),
      },
      hightouch_user: lowercase(rawUser),
      campaign_id: campaignId,
      external_id:
        email || `${workspaceId}_${previewId}@decision-engine.hightouch.com`,
    };

    const response = await client.fetchQuery(previewId, {
      queryFn: useTestSyncConfigurationQuery.fetcher({
        segmentId: Number(segment.id),
        destinationId: Number(channel.destination.id),
        syncConfig: generateDecisionEngineSyncConfig({
          destinationType: channel.destination.type as any,
          channelConfig: channel.config as DecisionEngineChannelConfig,
          campaignId,
        }),
        rows: [row],
        operation: SyncOp.Added,
      }),
    });

    setPreviewId(previewId);

    const rejection =
      response.testSyncConfiguration.batches[0]?.rejectedRows?.[0];

    if (rejection) {
      try {
        setError(JSON.parse(rejection.reason.split(" - ")[1])?.msg);
      } catch (e) {
        setError("Email failed to send");
      }
      return false;
    }
    return true;
  };

  return {
    run,
    previewId,
    error,
    isPreviewable: getChannelDefinition(
      channel.destination.type,
    ).isPreviewSupported(channel.config),
  };
};

export const sortOutcomes = (
  a: DecisionEngineOutcomeReward,
  b: DecisionEngineOutcomeReward,
) => {
  // Sort by type first: "positive" comes before "negative"
  if (a.type === "positive" && b.type === "negative") {
    return -1;
  }
  if (a.type === "negative" && b.type === "positive") {
    return 1;
  }
  // If types are the same, sort by priority:
  if (a.type === b.type) {
    if (a.type === "positive") {
      // For positive, higher priority first
      return b.priority - a.priority;
    } else {
      // For negative, lower priority first
      return a.priority - b.priority;
    }
  }
  return 0;
};
