import type {
  AttributionMethod,
  EventCondition,
  IntervalUnit,
  RawColumn,
  RelatedColumn,
} from ".";

export type Goal = {
  id: string;
  name: string;
  description: string;
  parentModelId: string;
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
  config: GoalConfig;
  attributionWindow: AttributionWindow;
  enabled: boolean;
  // Optional because only goals created for campaign analytics will have attribution methods
  attributionMethods?: AttributionMethod[];
};

export enum PerUserAggregationType {
  Count = "count",
  CountDistinct = "count-distinct",
  Sum = "sum",
  Average = "avg",
  UniqueUsers = "unique-users",
}

// Describes the audience-level rollup type for a given metric. This is applied
// on top of the specified per-user aggregation type.
export enum AudienceAggregationType {
  // "none" audience aggregation means that the per-user aggregation will be
  // applied across all events in the audience.
  None = "none",
  Sum = "sum",
  Average = "avg",
  PercentOfAudience = "percent-of-audience",
}

// Map specifying which pairs of metric aggregation types are valid.
export const supportedMetricAggregationPairs: {
  [key in PerUserAggregationType]: AudienceAggregationType[];
} = {
  [PerUserAggregationType.Count]: [
    AudienceAggregationType.Average,
    AudienceAggregationType.Sum,
  ],
  [PerUserAggregationType.Sum]: [
    AudienceAggregationType.Average,
    AudienceAggregationType.Sum,
  ],
  [PerUserAggregationType.Average]: [
    AudienceAggregationType.Average,
    // Average per-user with no audience aggregation means average over all events.
    AudienceAggregationType.None,
  ],
  [PerUserAggregationType.CountDistinct]: [
    // Count distinct per-user with no audience aggregation means count distinct over all events.
    AudienceAggregationType.None,
  ],
  [PerUserAggregationType.UniqueUsers]: [
    AudienceAggregationType.Sum,
    AudienceAggregationType.PercentOfAudience,
  ],
};

export type UserDefinedMetricConfig =
  | CountMetricConfig
  | SumMetricConfig
  | UniqueUsersMetricConfig;

export type GoalConfig = UserDefinedMetricConfig | PredefinedMetricConfig;

/**
 * - `column` defines the column on which to apply the aggregation.
 *    Count aggregations without a column count all rows after any filtering.
 *
 * - `filter` applies conditions to the selected event model.
 *    Any subconditions are optional.
 *    When we allow aggregations on the parent model, the filtering will be applied on that instead.
 */
export type CountMetricConfig = {
  eventModelId: string;
  relationshipId: string;
  filter: EventFilter;
  column?: RawColumn | RelatedColumn;
};

export type SumMetricConfig = {
  eventModelId: string;
  relationshipId: string;
  column: RawColumn | RelatedColumn;
  filter: EventFilter;
};

export type AverageMetricConfig = SumMetricConfig;
export type UniqueUsersMetricConfig = CountMetricConfig;

export type EventFilter = Omit<
  EventCondition,
  "type" | "eventModelId" | "relationshipId" | "operator" | "value" | "window"
>;

export enum AttributionBasis {
  Entry = "entry",
  Exit = "exit",
}

// Defines the amount of time a user's event can be attributed back to an audience
export type AttributionWindow = {
  quantity: number;
  unit: IntervalUnit.Day | IntervalUnit.Week | IntervalUnit.Month;
  // "entry": only events within the window of an audience entry event get counted
  // "exit": events within the window of an audience exit event get counted in addition
  //         to events when the user was in the audience.
  basis?: AttributionBasis;
};

export enum PredefinedMetric {
  AudienceSize = "audience-size",

  // Emails
  EmailsDelivered = "emails_delivered",
  EmailsOpened = "emails_opened",
  UniqueEmailsOpened = "unique_emails_opened",
  EmailsClicked = "emails_clicked",
  UniqueEmailsClicked = "unique_emails_delivered",
  EmailOpenRate = "email_open_rate",
  EmailClickRate = "email_click_rate",
  EmailClickthroughRate = "email_clickthrough_rate",

  // Ads
  AdClicks = "ad_clicks",
  AdImpressions = "ad_impressions",
  AdSpend = "ad_spend",
}

export type PredefinedMetricConfig = {
  predefinedMetric: PredefinedMetric;
};

export const predefinedMetricNames: { [key in PredefinedMetric]: string } = {
  [PredefinedMetric.AudienceSize]: "Audience size",
  [PredefinedMetric.EmailsDelivered]: "Email deliveries",
  [PredefinedMetric.EmailsOpened]: "Email opens",
  [PredefinedMetric.UniqueEmailsOpened]: "Email unique opens",
  [PredefinedMetric.EmailsClicked]: "Email clicks",
  [PredefinedMetric.UniqueEmailsClicked]: "Email unique clicks",
  [PredefinedMetric.EmailOpenRate]: "Email open rate",
  [PredefinedMetric.EmailClickRate]: "Email click rate",
  [PredefinedMetric.EmailClickthroughRate]: "Email click through rate",
  [PredefinedMetric.AdClicks]: "Ad clicks",
  [PredefinedMetric.AdImpressions]: "Ad impressions",
  [PredefinedMetric.AdSpend]: "Ad spend",
};

export function isPredefinedMetric(
  goal: Goal,
): goal is Goal & { config: PredefinedMetricConfig } {
  return "predefinedMetric" in goal.config;
}

export function isUserDefinedGoal(
  goal: Goal,
): goal is Goal & { config: UserDefinedMetricConfig } {
  return !isPredefinedMetric(goal);
}

export function isUserDefinedGoalConfig(
  config: GoalConfig,
): config is UserDefinedMetricConfig {
  return !("predefinedMetric" in config);
}
