import { DepGraph } from "dependency-graph";
import { nanoid } from "nanoid";
import { Edge, Node } from "reactflow";

import { SchemaModelType } from "src/types/schema";

import {
  ClickIcon,
  FolderIcon,
  FrameStackIcon,
  MetricIcon,
  TimeIcon,
  IconProps,
} from "@hightouchio/ui";
import * as React from "react";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useEntitlements } from "src/hooks/use-entitlement";
import catalogIcon from "src/pages/schema/assets/catalog.svg";
import eventIcon from "src/pages/schema/assets/event.svg";
import parentIcon from "src/pages/schema/assets/parent.svg";
import relatedIcon from "src/pages/schema/assets/related.svg";
import { EdgeType } from "src/pages/schema/types";
import adStatsIcon from "src/pages/schema/assets/ad-stats.svg";
import assetIcon from "src/pages/schema/assets/asset.svg";
import interactionIcon from "src/pages/schema/assets/interaction.svg";
import {
  GraphModel,
  GraphRelationship,
  NodeData,
} from "src/pages/schema/types";
import { applyGroupings, groupNodes } from "./grouping";

export const getGraph = ({
  models,
  relationships,
  expandedGroupNodeId,
  selectedId,
}: {
  models: GraphModel[];
  relationships: GraphRelationship[];
  expandedGroupNodeId: string | undefined;
  selectedId?: string;
}) => {
  const graph = new DepGraph<GraphModel>({ circular: true });
  const nodes: Node<NodeData>[] = [];
  const edges: Edge[] = [];

  if (!models || !relationships) {
    return { nodes, edges };
  }

  for (const model of models) {
    graph.addNode(String(model.id), model);
    nodes.push({
      id: String(model.id),
      type: model.type,
      style: { width: NODE_WIDTH, height: NODE_HEIGHT },
      data: { label: model.name, isSelected: selectedId === model.id },
      position: { x: 0, y: 0 },
    });
  }

  for (const relationship of relationships) {
    const edge = {
      id: nanoid(),
      type: "custom",
      source: relationship.from,
      target: relationship.to,
      markerEnd: "",
      markerStart: "",
      data: {
        from: relationship.from,
        edgeId: relationship.edgeId,
        reverseEdgeId: relationship.reverseEdgeId,
        cardinality: relationship.cardinality,
      },
    };

    graph.addDependency(relationship.from, relationship.to);
    edges.push(edge);
  }

  const groups = groupNodes({ nodes, edges, graph });
  const grouped = applyGroupings(nodes, edges, groups, expandedGroupNodeId);
  return {
    nodes: hydrateDerivedData(grouped.nodes, graph),
    edges: grouped.edges,
  };
};

function hydrateDerivedData(
  nodes: Node<NodeData>[],
  graph: DepGraph<GraphModel>,
): Node<NodeData>[] {
  return nodes.map((node) => {
    if (isMissingAssetDependency(node, graph)) {
      node.data.isMissingAsset = true;
    }
    return node;
  });
}

function isMissingAssetDependency(
  node: Node<NodeData>,
  graph: DepGraph<GraphModel>,
) {
  if (node.type !== SchemaModelType.Interaction) {
    return false;
  }
  const dependents = graph.dependenciesOf(node.id);
  for (const dependent of dependents) {
    const data = graph.getNodeData(dependent);
    if (data.type === SchemaModelType.Asset) {
      return false;
    }
  }

  return true;
}

export const getParams = () => {
  const params = new URL(window.location.toString()).searchParams;
  const source = params.get("source");
  const parent = params.get("parent");
  return {
    source,
    parent,
    queryString: `?source=${source}${parent ? `&parent=${parent}` : ""}`,
  };
};

export type MenuOption = {
  type: SchemaModelType;
  label: string;
  description: string;
  icon: React.FC<IconProps>;
};

const RELATED_MENU_OPTION: MenuOption = {
  icon: FrameStackIcon,
  type: SchemaModelType.Related,
  label: "Create a related model",
  description: "Filter based on a relationship with another model",
};

const EVENT_MENU_OPTION: MenuOption = {
  icon: TimeIcon,
  type: SchemaModelType.Event,
  label: "Create a related event",
  description: "Filter on actions that this user has taken",
};

const INTERACTION_MENU_OPTION: MenuOption = {
  icon: ClickIcon,
  type: SchemaModelType.Interaction,
  label: "Create a related interaction",
  description: "Filter based on marketing interactions a user has had",
};

const RELATED_ASSET_MENU_OPTION: MenuOption = {
  icon: FolderIcon,
  type: SchemaModelType.Asset,
  label: "Create a related asset",
  description: "",
};

const AD_STATS_MENU_OPTION: MenuOption = {
  icon: MetricIcon,
  type: SchemaModelType.AdStats,
  label: "Create a related stat model",
  description: "View ad stats in campaign analytics",
};

export const useMenuOptions = (type: SchemaModelType): MenuOption[] => {
  const { data: entitlementsData, isLoading } = useEntitlements(true);

  const campaignsEnabled = entitlementsData.entitlements.campaigns;

  if (type === SchemaModelType.Interaction && campaignsEnabled) {
    return [RELATED_ASSET_MENU_OPTION];
  }

  if (type === SchemaModelType.Asset && campaignsEnabled) {
    // TODO: restrict only AD asset models
    return [AD_STATS_MENU_OPTION];
  }

  const showInteractionOption =
    !isLoading && campaignsEnabled && type === SchemaModelType.Parent;

  return [
    RELATED_MENU_OPTION,
    EVENT_MENU_OPTION,
    ...(showInteractionOption ? [INTERACTION_MENU_OPTION] : []),
  ];
};

export const schemaIcons: Record<SchemaModelType, string> = {
  [SchemaModelType.Parent]: parentIcon,
  [SchemaModelType.Event]: eventIcon,
  [SchemaModelType.Related]: relatedIcon,
  [SchemaModelType.Catalog]: catalogIcon,
  [SchemaModelType.Group]: relatedIcon,
  [SchemaModelType.Interaction]: interactionIcon,
  [SchemaModelType.Asset]: assetIcon,
  [SchemaModelType.AdStats]: adStatsIcon,
};

export const getConnectedNodes = (
  startingNodeId: string,
  nodes: Node[],
  edges: Edge[],
): string[] => {
  const leftNodes = findLeftNodes(startingNodeId, nodes, edges);
  const rightNodes = findRightNodes(startingNodeId, nodes, edges);

  return Array.from(new Set([...leftNodes, ...rightNodes]));
};

const findLeftNodes = (
  startingNodeId: string,
  nodes: Node[],
  edges: Edge[],
): string[] => {
  const visitedNodeIds: Set<string> = new Set();

  function traverse(nodeId: string) {
    if (visitedNodeIds.has(nodeId)) {
      return;
    }

    const currentNode = nodes.find((node) => node.id === nodeId);
    if (currentNode) {
      visitedNodeIds.add(currentNode.id);
      if (currentNode.data.type !== SchemaModelType.Parent) {
        // Find edges with the current node as the target
        const targetEdges = edges.filter((edge) => edge.target === nodeId);

        for (const edge of targetEdges) {
          traverse(edge.source);
        }
      }
    }
  }

  traverse(startingNodeId);
  return Array.from(visitedNodeIds);
};

const findRightNodes = (
  startingNodeId: string,
  nodes: Node[],
  edges: Edge[],
): string[] => {
  const visitedNodeIds: Set<string> = new Set();

  function traverse(nodeId: string) {
    if (visitedNodeIds.has(nodeId)) {
      return;
    }

    const currentNode = nodes.find((node) => node.id === nodeId);

    if (currentNode) {
      visitedNodeIds.add(currentNode.id);

      const targetEdges = edges.filter((edge) => edge.source === nodeId);

      for (const edge of targetEdges) {
        traverse(edge.target);
      }
    }
  }

  traverse(startingNodeId);
  return Array.from(visitedNodeIds);
};

export const useCanUpdateSchema = () => {
  const { source } = getParams();

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: {
      resource: "model",
      grant: "can_create",
      creationOptions: { type: "schema", sourceId: source?.toString() ?? "" },
    },
    v1: { resource: "audience_schema", grant: "update" },
  });
  return hasUpdatePermission;
};

export function getSegmentTypeColumn(type: SchemaModelType) {
  if (type === SchemaModelType.Catalog) {
    return "catalog";
  } else if (type === SchemaModelType.Interaction) {
    return "interaction";
  } else if (type === SchemaModelType.Asset) {
    return "asset";
  } else if (type === SchemaModelType.AdStats) {
    return "ad-stats";
  }

  return undefined;
}

export const NODE_WIDTH = 232;
export const NODE_HEIGHT = 72;
export const NODE_SPACING = 150;
export const CHILD_NODE_HEIGHT = 32;

export const EventTimestampModelTypes = [
  SchemaModelType.Event,
  SchemaModelType.Interaction,
  SchemaModelType.AdStats,
];

export const newEphemeralNode = (nodeId: string, type: SchemaModelType) => ({
  id: nodeId,
  style: { width: NODE_WIDTH, height: NODE_HEIGHT },
  type,
  data: {
    isEphemeral: true,
  },
  position: { x: 0, y: 0 },
});

export const newEphemeralEdge = (
  edgeId: string,
  sourceId: string,
  targetId: string,
) => ({
  id: edgeId,
  type: EdgeType.Custom,
  source: String(sourceId),
  target: targetId,
  animated: true,
  data: {
    isEphemeral: true,
  },
});

export function findNodeParentId(nodeId: string, nodes: Node<NodeData>[]) {
  const result = nodes.find((node) => {
    const children = node.data.children;
    if (!children) return false;

    return !!children.find((child) => child.id === nodeId);
  });

  return result ? result.id : nodeId;
}
