import { FC, ReactNode, useState } from "react";

import {
  Row,
  Column,
  Button,
  Box,
  useToast,
  Heading,
  SectionHeading,
  Text,
  EmptyState,
  ArrowRightIcon,
  ChevronRightIcon,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { Navigate, useLocation, useNavigate } from "src/router";

import placeholder from "src/assets/placeholders/generic.svg";
import { PreworkspacePage } from "src/components/layout/preworkspace-page";
import { useUser } from "src/contexts/user-context";
import {
  useAccessibleOrganizationsQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  useMembershipRequestsByUserQuery,
  usePartnerConnectLinkWorkspaceMutation,
  useRequestMembershipMutation,
} from "src/graphql";
import { switchWorkspace } from "src/utils/workspaces";

import { PartnerConnectLogo } from "../partner-connect";

export const WorkspacesPage: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { toast } = useToast();
  const partnerConnection = (state as any)?.partnerConnection;

  const createWorkspace = () => {
    navigate("/workspaces/new", { state: { partnerConnection } });
  };

  const { data: organizationsData, isFetched } =
    useAccessibleOrganizationsQuery(
      {},
      { select: (data) => data.getAccessibleOrganizations.organizations },
    );

  const organizations = organizationsData ?? [];
  const hasOrganizations = organizations.length > 0;

  const { mutateAsync: linkWorkspace } =
    usePartnerConnectLinkWorkspaceMutation();

  const handleSelect = async (id, slug) => {
    if (partnerConnection) {
      try {
        await linkWorkspace({
          uuid: partnerConnection.uuid,
          workspaceId: Number(id),
        });
        navigate(`/partner-connect/${partnerConnection.uuid}`);
      } catch (err) {
        const message =
          err.message ==
          "This connection doesn't have access to the selected workspace"
            ? "There was an error creating your resource. Your user may not have the correct permissions to create the resource in the selected workspace."
            : "There was an error creating your resource. Please try again. If the error persists, please contact Hightouch support.";
        toast({
          id: "partner-connect-resource-create-error",
          title: "Error creating resource",
          message: message,
          variant: "error",
        });
      }
    } else {
      switchWorkspace(id, `/${slug}`);
    }
  };

  if (isFetched && !hasOrganizations) {
    return <Navigate to="/welcome" />;
  }

  return (
    <PreworkspacePage title="Workspaces">
      <Column gap={10} maxWidth="800px" mx="auto" width="100%">
        {partnerConnection && (
          <PartnerConnectLogo
            logo={partnerConnection.partnerLogo}
            name={partnerConnection.partnerName}
          />
        )}

        <Row align="center" justify="space-between">
          <Heading size="xl">
            Select a workspace{partnerConnection ? " to connect" : ""}
          </Heading>
          <Button onClick={createWorkspace}>Create a workspace</Button>
        </Row>

        {organizations.map((o) => (
          <Column key={o.slug} gap={2}>
            <Column>
              <Box
                color="gray.500"
                fontSize="sm"
                fontWeight="semibold"
                textTransform="uppercase"
              >
                Organization
              </Box>
              <SectionHeading mb={4}>{o.name ?? ""}</SectionHeading>
            </Column>
            {o.workspaces.length ? (
              <>
                <Workspaces
                  workspaces={o.workspaces.filter((w) => w.is_member)}
                  onSelect={handleSelect}
                />
                <AvailableWorkspaces
                  joinableWorkspaces={o.workspaces.filter((w) => w.is_joinable)}
                  visibleWorkspaces={o.workspaces.filter((w) => w.is_visible)}
                  onSelect={handleSelect}
                />
              </>
            ) : (
              <EmptyState message="You have not been granted access to any workspaces in this organization. Please contact an organization admin." />
            )}
          </Column>
        ))}
      </Column>
    </PreworkspacePage>
  );
};

export const AvailableWorkspaces: FC<
  Readonly<{
    onSelect: (id: number, slug: string) => Promise<void>;
    visibleWorkspaces: {
      name: string;
      id: number;
      slug: string;
    }[];
    joinableWorkspaces: {
      name: string;
      id: number;
      slug: string;
    }[];
  }>
> = ({ visibleWorkspaces, joinableWorkspaces, onSelect }) => {
  const { user } = useUser();

  const membershipRequests = useMembershipRequestsByUserQuery(
    {
      userId: String(user?.id),
    },
    { enabled: Boolean(user), select: (data) => data.membership_requests },
  );

  return (
    <>
      {joinableWorkspaces?.map(({ name, id, slug }) => (
        <JoinableWorkspace
          key={id}
          id={id}
          name={name}
          slug={slug}
          onSelect={() => onSelect(id, slug)}
        />
      ))}
      {visibleWorkspaces?.map(({ name, id, slug }) => (
        <RequestableWorkspace
          key={id}
          id={id}
          name={name}
          requests={membershipRequests?.data}
          slug={slug}
        />
      ))}
    </>
  );
};

export const Workspaces: FC<{
  onSelect: (id: number, slug: string) => Promise<unknown>;
  workspaces: {
    name: string;
    id: number;
    slug: string;
    member_count: number | null;
  }[];
}> = ({ onSelect, workspaces }) => {
  return (
    <>
      {workspaces.map(({ slug, name, id, member_count }) => (
        <UserWorkspace
          key={id}
          id={id}
          name={name}
          // TODO: Get the actual number of members
          size={member_count ?? 0}
          slug={String(slug)}
          onSelect={() => {
            onSelect(id, slug || "");
          }}
        />
      ))}
    </>
  );
};

const Workspace: FC<
  Readonly<{
    slug: string;
    id: number;
    name: string;
    organization?: string;
    onClick?: () => void;
    children: ReactNode;
    bg?: string;
  }>
> = ({ name, onClick, children, bg = "white", slug }) => {
  const isButton = Boolean(onClick);

  return (
    <Row
      _focus={{ outline: "none" }}
      _hover={isButton ? { bg: "gray.100" } : {}}
      align="center"
      as={isButton ? "button" : "div"}
      bg={bg}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      height="56px"
      id={slug}
      px={4}
      transition="background-color 200ms"
      width="100%"
      onClick={onClick}
    >
      <Row align="center" flex={1}>
        <Text fontWeight="semibold" size="md">
          {name}
        </Text>
      </Row>

      <Row gap={4}>{children}</Row>
    </Row>
  );
};

export const UserWorkspace: FC<
  Readonly<{
    size?: number;
    slug: string;
    name: string;
    onSelect: () => void;
    id: number;
  }>
> = ({ size, onSelect, ...props }) => {
  return (
    <Workspace {...props} onClick={onSelect}>
      <Row align="center" mr={3}>
        {size} {pluralize("members", size)}
      </Row>
      <Box fontSize="18px">
        <ChevronRightIcon color="text.secondary" />
      </Box>
    </Workspace>
  );
};

export const JoinableWorkspace: FC<
  Readonly<{
    name: string;
    slug: string;
    organization?: string;
    onSelect: () => void;
    id: number;
  }>
> = ({ onSelect, ...props }) => {
  const { toast } = useToast();
  const joinWorkspaceMutation = useJoinWorkspaceWithAutoJoinMutation();

  const joinWorkspace = async () => {
    try {
      await joinWorkspaceMutation.mutateAsync({
        workspaceId: String(props.id),
      });

      onSelect();
    } catch (error) {
      toast({
        id: "join-workspace",
        title: "Failed to join workspace",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return (
    <Workspace {...props}>
      <Button
        size="sm"
        directionIcon={ArrowRightIcon}
        id={props.slug}
        isLoading={joinWorkspaceMutation.isLoading}
        variant="primary"
        onClick={joinWorkspace}
      >
        Join
      </Button>
    </Workspace>
  );
};

export const RequestableWorkspace: FC<
  Readonly<{
    name: string;
    id: number;
    slug: string;
    organization?: string;
    requests: { workspace_id: string }[] | undefined;
  }>
> = ({ requests, ...props }) => {
  const { toast } = useToast();
  const [requested, setRequested] = useState(false);

  const requestMembershipMutation = useRequestMembershipMutation();

  const requestMembership = async () => {
    try {
      await requestMembershipMutation.mutateAsync({
        workspaceId: String(props.id),
      });
      setRequested(true);
      toast({
        id: "access-requested",
        title: "Access requested",
        message: `Access to workspace ${props.name} sent! Ask a member of the workspace to approve your request in the manage members page.`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "access-requested",
        title: "Failed to request access",
        message: "Please try again.",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const isDisabled =
    requested ?? requests?.some((r) => r?.workspace_id === String(props.id));

  return (
    <Workspace {...props} bg="transparent">
      <Button
        size="sm"
        isDisabled={isDisabled}
        isLoading={requestMembershipMutation.isLoading}
        onClick={requestMembership}
      >
        {isDisabled ? "Awaiting approval" : "Request access"}
      </Button>
    </Workspace>
  );
};

export const Placeholder: FC<{ onCreate: () => void }> = ({ onCreate }) => (
  <Column maxWidth="800px" mx="auto" p={10} width="100%">
    <EmptyState
      actions={
        <Button variant="primary" onClick={onCreate}>
          Create a workspace
        </Button>
      }
      imageUrl={placeholder}
      message="Get started by creating your first workspace. Invite people to different workspaces to keep your team’s workflows secure and organized."
      title="You have no workspaces yet"
    />
  </Column>
);
