import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import noop from "lodash/noop";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";

import { Form, useHightouchForm } from "src/components/form";
import { SaveNameAndDescriptionModal } from "src/components/modals/save-name-and-description-modal";
import {
  AssetModelColumn,
  CampaignAnalyticsDashboardData,
  useCreateSavedCampaignViewMutation,
  useEvaluateCampaignAnalyticsDashboardQuery,
  useGetCampaignAnalyticsDashboardResultBackgroundQuery,
  useUpdateSavedCampaignViewMutation,
} from "src/graphql";
import { CampaignResultsLimit } from "src/pages/campaigns/constants";
import { SavedCampaignViewsDrawer } from "src/pages/campaigns/saved-views";
import {
  ConversionMetric,
  FilterState,
  PredefinedMetric,
  RequiredAssetModelFields,
  SavedCampaignView,
} from "src/pages/campaigns/types";
import { convertColumnReferenceModelIdToString } from "src/pages/campaigns/utils";
import { useNavigate, useParams } from "src/router";
import {
  AttributionTimeWindow,
  ConditionType,
  FilterableColumn,
} from "src/types/visual";
import { useTableConfig } from "src/ui/table";

import {
  getMetricsToMeasure,
  transformSavedCampaignViewToFilters,
} from "./utils";

type InputProps = {
  parentModelId?: string;
  assetModel: RequiredAssetModelFields;
  savedView: SavedCampaignView;
  isSavedViewLoading?: boolean;
  predefinedMetrics: PredefinedMetric[];
  conversionMetrics: ConversionMetric[];
};

type Result = {
  isArchived: boolean;
  isCampaignsPageLoading: boolean;
  isPolling: boolean;
  isSavedView: boolean;
  isSavedViewLoading: boolean;
  assetModelColumns: FilterableColumn[];
  assetModel: RequiredAssetModelFields;
  data: null | CampaignAnalyticsDashboardData;
  error: null | undefined | string;
  onPageChange: (page: number) => void;
  onSaveCampaignView: (() => Promise<void | undefined>) | (() => void);
  onOpenSavedViewsDrawer: () => void;
};

export const CampaignsFilterContext = createContext<Result>({
  assetModelColumns: [],
  assetModel: {} as RequiredAssetModelFields,
  data: null,
  error: null,
  isArchived: false,
  isCampaignsPageLoading: false,
  isPolling: false,
  isSavedView: false,
  isSavedViewLoading: false,
  onPageChange: noop,
  onSaveCampaignView: noop,
  onOpenSavedViewsDrawer: noop,
});

export const CampaignFiltersContextProvider: FC<
  PropsWithChildren & InputProps
> = ({
  assetModel,
  children,
  savedView,
  isSavedViewLoading,
  conversionMetrics,
  parentModelId,
  predefinedMetrics,
}) => {
  const navigate = useNavigate();
  const { assetType, savedViewId } = useParams<{
    assetType: string;
    savedViewId?: string;
  }>();
  const { toast } = useToast();

  const { page, setPage } = useTableConfig({ limit: CampaignResultsLimit });
  const [isPolling, setIsPolling] = useState(false);
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);
  const [showSavedViews, setShowSavedViews] = useState(false);

  const createSavedCampaignViewMutation = useCreateSavedCampaignViewMutation();
  const updateSavedCampaignViewMutation = useUpdateSavedCampaignViewMutation();

  const [_primaryColumns, columns] = useMemo(() => {
    const standardColumns = assetModel?.model.filterable_audience_columns ?? [];

    return partition(
      standardColumns,
      (column) =>
        column.column_reference.name === assetModel?.primary_label_column ||
        column.column_reference.name === assetModel?.primary_key_column,
    );
  }, [assetModel]);

  const initialValues: FilterState = useMemo(() => {
    const initialSelections = transformSavedCampaignViewToFilters(
      savedView,
      columns,
    );

    // Back compat: old saved views have {} in their .sort field
    const orderBy = initialSelections?.sortBy.type
      ? initialSelections?.sortBy
      : undefined;

    return {
      name: savedView?.name ?? "",
      description: savedView?.description || null,
      filters: initialSelections?.filters ?? [],
      selectedColumns: [],
      selectedConversionMetrics:
        initialSelections?.selectedConversionMetrics ?? conversionMetrics,
      selectedPredefinedMetrics:
        initialSelections?.selectedPredefinedMetrics ?? predefinedMetrics,
      timeWindow:
        initialSelections?.timeWindow ?? AttributionTimeWindow.SevenDays,
      orderBy,
    };
  }, [savedView, columns]);

  const saveCampaignView = async ({
    name,
    description,
  }: {
    name: string;
    description: string | null;
  }) => {
    const data = form.getValues();
    const conversionMetricInput = data.selectedConversionMetrics.map(
      (metric) => ({
        goal_id: metric.id,
        attribution_method_id: metric.attributionMethod.id,
      }),
    );
    const predefinedMetricInput = data.selectedPredefinedMetrics.map(
      (metric) => ({
        goal_id: metric.id,
        attribution_method_id: null,
      }),
    );

    try {
      const result = await createSavedCampaignViewMutation.mutateAsync({
        input: {
          name,
          description,
          asset_model_id: assetModel.model_id,
          parent_model_id: parentModelId,
          column_references: data.selectedColumns.map(
            (column) => column.column_reference,
          ),
          filters: { type: ConditionType.And, conditions: data.filters },
          goals: {
            data: [...conversionMetricInput, ...predefinedMetricInput],
          },
          sort: data.orderBy || {},
          time_window: data.timeWindow,
        },
      });

      setShowSaveViewModal(false);
      navigate(
        `/campaigns/${assetType}/${result.insert_saved_campaign_views_one?.id}?id=${parentModelId}`,
      );
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "save-campaign-view-error",
        title: "Error saving view",
        message: "An error occurred while saving the view",
        variant: "error",
      });

      throw error;
    }
  };

  const updateCampaignView = async (data: FilterState) => {
    if (!savedView?.id) {
      Sentry.captureException(
        new Error("[Saved campaign views] No view ID provided for update"),
      );
      return;
    }

    const campaignViewIdToUpdate = savedView.id;

    try {
      const goalsToRemove = savedView?.goals.map(({ id }) => id) ?? [];

      const conversionMetricInput = data.selectedConversionMetrics.map(
        (metric) => ({
          goal_id: metric.id,
          attribution_method_id: metric.attributionMethod.id,
          saved_campaign_view_id: campaignViewIdToUpdate,
        }),
      );
      const predefinedMetricInput = data.selectedPredefinedMetrics.map(
        (metric) => ({
          goal_id: metric.id,
          attribution_method_id: null,
          saved_campaign_view_id: campaignViewIdToUpdate,
        }),
      );

      await updateSavedCampaignViewMutation.mutateAsync({
        id: campaignViewIdToUpdate,
        input: {
          name: data.name,
          description: data.description,
          asset_model_id: assetModel.model_id,
          parent_model_id: parentModelId,
          column_references: data.selectedColumns.map(
            (column) => column.column_reference,
          ),
          filters: { type: ConditionType.And, conditions: data.filters },
          sort: data.orderBy || {},
          time_window: data.timeWindow,
        },
        bridge_goal_row_ids_to_remove: goalsToRemove,
        goals_to_add: [...conversionMetricInput, ...predefinedMetricInput],
      });
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  const form = useHightouchForm({
    onSubmit: savedViewId ? updateCampaignView : () => Promise.resolve(),
    values: initialValues,
  });

  const onSaveCampaignView = () => {
    if (savedViewId) {
      return form.submit();
    }

    return setShowSaveViewModal(true);
  };

  const assetModelId = assetModel?.model_id;

  // Always fetch all columns + all metrics
  const formattedMetrics = useMemo(
    () => getMetricsToMeasure(predefinedMetrics, conversionMetrics),
    [conversionMetrics, predefinedMetrics],
  );
  const sortedColumns = sortBy(
    assetModel?.model.filterable_audience_columns ?? [],
    (column) => column.alias?.toLowerCase() ?? column.name.toLowerCase(),
  );
  const assetModelColumns: AssetModelColumn[] = useMemo(() => {
    return columns.map((column) =>
      convertColumnReferenceModelIdToString(column.column_reference),
    );
  }, [columns]);

  const queryEnabled =
    Boolean(parentModelId && assetModelId) && formattedMetrics.length > 0;

  const timeWindow = form.watch("timeWindow");
  const filters = form.watch("filters");
  const orderBy = form.watch("orderBy");

  const evaluateCampaignAnalyticsDashboardQuery =
    useEvaluateCampaignAnalyticsDashboardQuery(
      {
        parentModelId: parentModelId?.toString() ?? "",
        assetModelId: assetModelId?.toString() ?? "",
        timeWindow: timeWindow ?? AttributionTimeWindow.SevenDays,
        assetModelFilter:
          filters.length > 0
            ? {
                type: ConditionType.And,
                conditions: filters,
              }
            : undefined,
        assetModelColumns,
        // always has to be at least one metric
        metrics: formattedMetrics,
        orderBy,
        // Starts at page 1
        paginationOptions: { page: page + 1, pageSize: CampaignResultsLimit },
      },
      {
        enabled: queryEnabled,
        select: (data) =>
          data.evaluateCampaignAnalyticsDashboardQuery?.backgroundJob?.jobId,
      },
    );

  const backgroundJobId = evaluateCampaignAnalyticsDashboardQuery.data;

  const getCampaignAnalyticsDashboardResultBackgroundQuery =
    useGetCampaignAnalyticsDashboardResultBackgroundQuery(
      { jobId: backgroundJobId ?? "" },
      {
        enabled: Boolean(isPolling && backgroundJobId),
        refetchInterval: 3000,
        select: (data) => data.getCampaignAnalyticsDashboardResultBackground,
      },
    );

  let result: CampaignAnalyticsDashboardData | null = null;
  let error: string | null = null;

  if (getCampaignAnalyticsDashboardResultBackgroundQuery.data?.result) {
    if (
      "assets" in getCampaignAnalyticsDashboardResultBackgroundQuery.data.result
    ) {
      result = getCampaignAnalyticsDashboardResultBackgroundQuery.data.result;
    } else if (
      "error" in getCampaignAnalyticsDashboardResultBackgroundQuery.data.result
    ) {
      error =
        getCampaignAnalyticsDashboardResultBackgroundQuery.data?.result.error;
    }
  }

  useEffect(() => {
    const shouldPoll = Boolean(backgroundJobId && !result && !error);

    setIsPolling(shouldPoll);
  }, [backgroundJobId, result, error]);

  return (
    <CampaignsFilterContext.Provider
      value={{
        assetModel,
        assetModelColumns: sortedColumns,
        isArchived: Boolean(savedView?.archived_at),
        isPolling:
          evaluateCampaignAnalyticsDashboardQuery.isLoading ||
          getCampaignAnalyticsDashboardResultBackgroundQuery.isLoading ||
          isPolling,
        isCampaignsPageLoading:
          evaluateCampaignAnalyticsDashboardQuery.isLoading,
        isSavedViewLoading: Boolean(isSavedViewLoading),
        data: result,
        error: error ?? evaluateCampaignAnalyticsDashboardQuery.error?.message,
        isSavedView: Boolean(savedViewId),
        onSaveCampaignView,
        onOpenSavedViewsDrawer: () => setShowSavedViews(true),
        onPageChange: setPage,
      }}
    >
      <Form form={form}>{children}</Form>

      <SaveNameAndDescriptionModal
        type="view"
        isOpen={showSaveViewModal}
        onClose={() => setShowSaveViewModal(false)}
        onSubmit={saveCampaignView}
      />

      <SavedCampaignViewsDrawer
        isOpen={showSavedViews}
        onClose={() => setShowSavedViews(false)}
      />
    </CampaignsFilterContext.Provider>
  );
};
