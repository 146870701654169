import { FC, useState } from "react";

import { Row, Text } from "@hightouchio/ui";

import { useOrganizationMqrsQuery } from "src/graphql";
import { CaretDownIcon, CaretUpIcon } from "src/ui/icons";
import { BasicPagination, Table } from "src/ui/table";
import { commaNumber } from "src/utils/numbers";

export interface OrganizationMqrMetrics {
  usage_period: string;
  workspaces: { name: string; monthly_queried_records: number }[];
  total_monthly_queried_records: number;
  percentage_change: number;
}

type Props = {
  organizationWorkspaces?: {
    name: string;
    id: number;
    slug: string;
  }[];
};

const placeholder = {
  title: "No MQR data",
  error: "MQR data failed to load, please try again.",
};

const PAGE_SIZE = 6;

export const OrganizationMqrTable: FC<Props> = ({ organizationWorkspaces }) => {
  const { data: mqrData, isLoading } = useOrganizationMqrsQuery();
  const mqrMetrics = mqrData?.getOrganizationMqrs || [];
  const [page, setPage] = useState(0);

  const mqrColumns = [
    {
      name: "Month",
      cell: ({ usage_period }) => {
        return <Text>{usage_period}</Text>;
      },
    },
    ...getWorkspaceMqrColumns(organizationWorkspaces || []),
    {
      name: "Total MQR",
      cell: ({ total_monthly_queried_records }) => {
        return (
          <Text>
            {total_monthly_queried_records
              ? commaNumber(total_monthly_queried_records)
              : "--"}
          </Text>
        );
      },
    },
    {
      name: "% Change",
      cell: ({ percentage_change }) => {
        return (
          <Row align="center" gap={2}>
            {percentage_change !== 0 ? (
              <>
                {percentage_change > 0 ? (
                  <CaretUpIcon
                    color="primaries.7"
                    size={18}
                    sx={{ mb: 1 }}
                  ></CaretUpIcon>
                ) : (
                  <CaretDownIcon
                    color="red"
                    size={18}
                    sx={{ mb: 1 }}
                  ></CaretDownIcon>
                )}
                <Text>{`${percentage_change.toFixed(2)} %`}</Text>
              </>
            ) : (
              <Text>--</Text>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={mqrColumns}
        data={mqrMetrics.slice(page, (page + 1) * PAGE_SIZE)}
        placeholder={placeholder}
        loading={isLoading}
        scrollable
      />
      <BasicPagination
        page={page}
        setPage={setPage}
        disableNextPage={(page + 1) * PAGE_SIZE > (mqrMetrics?.length || 0)}
      />
    </>
  );
};

// Not all workspaces have MQR data, but we want to give a big picture view of the billing for the organziation.
function getWorkspaceMqrColumns(
  allWorkspaces: { id: number; name: string; slug: string }[],
) {
  return allWorkspaces.map(({ id, name }) => ({
    name: name,
    cell: ({ workspaces: mqrWorkspaces }) => {
      const workspace = mqrWorkspaces.find((workspace) => workspace.id === id);
      const monthlyQueriedRecords = workspace?.monthly_queried_records;
      return (
        <Text>
          {monthlyQueriedRecords ? commaNumber(monthlyQueriedRecords) : "--"}
        </Text>
      );
    },
  }));
}
