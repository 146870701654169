import { useUser } from "src/contexts/user-context";
import { useDestinationDefinitionsQuery } from "src/graphql";

export const useDestinationDefinitions = () => {
  const { workspace } = useUser();
  const entitlements = workspace?.organization?.entitlements || {};
  const privateDestinations = entitlements.privateDestinations || [];

  const { data, isLoading } = useDestinationDefinitionsQuery(undefined, {
    select: (data) =>
      data.getDestinationDefinitions.map((definition) => {
        if (privateDestinations.includes(definition.type)) {
          return {
            ...definition,
            status: "released",
          };
        }
        return definition;
      }),
  });

  return { data, isLoading };
};
