import { FC } from "react";

import {
  Alert,
  Checkbox,
  CheckboxGroup,
  Column,
  FormField,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Controller } from "react-hook-form";
import { ActionBar } from "src/components/action-bar";
import { FeatureFull } from "src/components/feature-gates";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { SidebarForm } from "src/components/page";
import { usePermissionContext } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import { useUpdateSyncMutation } from "src/graphql";
import { SourceWarehouseHistoryConfig } from "src/pages/sources/source/sync-log";

export interface SyncWarehouseHistoryConfig {
  tablesToWrite: {
    changelog: boolean | undefined;
    snapshot: boolean | undefined;
    syncRuns: boolean | undefined;
  };
}

type Source = {
  id: string;
  plan_in_warehouse: boolean;
  plan_in_warehouse_config: Record<string, any>;
  warehouse_history_config: SourceWarehouseHistoryConfig;
};

type Props = {
  config: SyncWarehouseHistoryConfig;
  id: string | undefined;
  source: Source | undefined;
  isAudience: boolean;
};

export const WarehouseSyncLogs: FC<Readonly<Props>> = ({
  id,
  config,
  source,
  isAudience,
}) => {
  const { workspace } = useUser();

  const sourceConfig = {
    snapshot: source?.warehouse_history_config?.snapshot?.audience
      ? isAudience
      : Boolean(source?.warehouse_history_config?.snapshot),
    changelog: source?.warehouse_history_config?.changelog?.audience
      ? isAudience
      : Boolean(source?.warehouse_history_config?.changelog),
    syncRuns: source?.warehouse_history_config?.syncRuns?.audience
      ? isAudience
      : Boolean(source?.warehouse_history_config?.syncRuns),
  };

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateSync({
        id: id ?? "",
        object: {
          warehouse_history_config: data,
        },
      });
    },
    values: {
      tablesToWrite: {
        snapshot: config?.tablesToWrite?.snapshot ?? sourceConfig.snapshot,
        changelog: config?.tablesToWrite?.changelog ?? sourceConfig.changelog,
        syncRuns: config?.tablesToWrite?.syncRuns ?? sourceConfig.syncRuns,
      },
    } as SyncWarehouseHistoryConfig,
  });

  const { control } = form;

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  const updatePermission = usePermissionContext();
  const { appWarehouseSyncLogsEnabled } = useFlags();

  return (
    <Form form={form}>
      <FeatureFull
        enabled={
          workspace?.organization?.plan?.sku === "business_tier" ||
          appWarehouseSyncLogsEnabled
        }
        featureDetails={{
          pitch:
            "Detailed log of sync results directly available in your warehouse",
          description:
            "With warehouse sync logs, Hightouch will write information on sync results back into your data warehouse provider. You can use your own BI tool or SQL editor to run custom analysis or monitor the health of your pipelines.",
          bullets: [
            "Run any SQL query to analyze the results of your syncs",
            "Monitor for common sync errors, create custom alerts, and integrate into your existing dashboards",
            "Visualize the history of specific rows and track sync status",
            "Recommended for businesses that want to bring their own observability tooling",
          ],
          video: {
            src: "https://cdn.sanity.io/files/pwmfmi47/production/c81b1d3e32df55f57ca5cdec94543144baaebad4.mp4",
          },
        }}
        featureName="warehouse sync logs"
      >
        <Row alignItems="flex-start" pb={10}>
          <Column gap={6} mr={8}>
            <SectionHeading>Warehouse sync logs</SectionHeading>
            <Text>
              <Link
                href={`${
                  import.meta.env.VITE_DOCS_URL
                }/syncs/warehouse-sync-logs`}
              >
                Warehouse sync logs
              </Link>{" "}
              writes information on the status of each row of your sync back
              into your warehouse. This is useful for analyzing sync failures
              and changes in your data over time.
              <br />
              <br />
              Specific use cases include{" "}
              <Link
                href={`${
                  import.meta.env.VITE_DOCS_URL
                }/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}
              >
                analyzing common syncs errors
              </Link>
              ,{" "}
              <Link
                href={`${
                  import.meta.env.VITE_DOCS_URL
                }/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}
              >
                visualizing when users enter and exit audiences
              </Link>
              , and{" "}
              <Link
                href={`${
                  import.meta.env.VITE_DOCS_URL
                }/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}
              >
                tracking the status of rows across syncs
              </Link>
              .
            </Text>
            {!source?.plan_in_warehouse && (
              <Alert
                variant="inline"
                type="info"
                title="This feature can only be enabled if the source is using Lightning Sync Engine"
                message={
                  <Text>
                    You can enable Lightning Sync Engine for this source{" "}
                    <Link href={`/sources/${source?.id}`}>here</Link>.
                  </Text>
                }
              />
            )}
            {source?.plan_in_warehouse && (
              <FormField
                description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
                label="Tables"
              >
                <CheckboxGroup>
                  <Controller
                    name="tablesToWrite.snapshot"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
                        isChecked={Boolean(field.value)}
                        isDisabled={updatePermission.unauthorized}
                        label={`Snapshot${
                          field.value !== sourceConfig.snapshot
                            ? " (overriding default)"
                            : ""
                        }`}
                        onChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    name="tablesToWrite.changelog"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
                        isChecked={Boolean(field.value)}
                        isDisabled={updatePermission.unauthorized}
                        label={`Changelog${
                          field.value !== sourceConfig.changelog
                            ? " (overriding default)"
                            : ""
                        }`}
                        onChange={field.onChange}
                      />
                    )}
                  />

                  <Controller
                    name="tablesToWrite.syncRuns"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        description="Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
                        isChecked={Boolean(field.value)}
                        isDisabled={updatePermission.unauthorized}
                        label={`Sync runs${
                          field.value !== sourceConfig.syncRuns
                            ? " (overriding default)"
                            : ""
                        }`}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </CheckboxGroup>
              </FormField>
            )}
          </Column>
          <SidebarForm
            hideInviteTeammate
            hideSendMessage
            docsUrl={`${
              import.meta.env.VITE_DOCS_URL
            }/syncs/warehouse-sync-logs/`}
            invite="If you need help setting up this sync"
            name="warehouse sync logs"
          />
        </Row>
        <ActionBar>
          <FormActions />
        </ActionBar>
      </FeatureFull>
    </Form>
  );
};
