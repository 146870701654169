import { FC, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Column,
  FormField,
  NumberInput,
  Paragraph,
  Radio,
  RadioGroup,
  Row,
  Select,
  Switch,
  Text,
  TextInput,
  UpgradeIcon,
  IconProps,
} from "@hightouchio/ui";
import CronGenerator from "cron-time-generator2";
import { Cron } from "croner";
import { formatInTimeZone } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";

import { usePermissionContext } from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import { DateTimeSelect } from "src/ui/datetime-select";
import { Section } from "src/ui/section";
import { validCronExpression } from "src/utils/schedule";

import { FeaturePreview } from "src/components/feature-gates";
import { ConfigureDbtCloudSchedule } from "./dbt-cloud-schedule";
import { ConfigureFivetranSchedule } from "./fivetran-schedule";
import { Schedule, ScheduleIntervalUnit, ScheduleType } from "./types";
import { VisualCronExpression } from "./visual-cron-expression";

type LaunchdarklyFlags = ReturnType<typeof useFlags>;

interface ScheduleOption {
  label: string;
  value: ScheduleType;
  description: string;
}

type ScheduleResource =
  | "sync"
  | "sequence"
  | "identity graph"
  | "event_sync"
  | "match_booster"
  | "journey";

const getScheduleOptions = ({
  resource,
  flags,
  wasAlreadyStreaming,
  types,
  matchboosterEnabledOnModel,
}: {
  resource: ScheduleResource;
  flags: LaunchdarklyFlags;
  wasAlreadyStreaming: boolean;
  types?: ScheduleType[];
  matchboosterEnabledOnModel?: boolean | null;
}): ScheduleOption[] => {
  // before changing the order of these, please check it still makes sense. Later in this function we
  // do some splices to insert options in the right places.
  const options: ScheduleOption[] = [
    {
      label: "Manual",
      value: ScheduleType.MANUAL,
      description: `Trigger your ${resource} manually in-app${
        resource === "sync" ? " or via API" : ""
      }`,
    },
    {
      label: "Interval",
      value: ScheduleType.INTERVAL,
      description: `Schedule your ${resource} to run on a set interval (e.g., once per hour)`,
    },
    {
      label: "Custom recurrence",
      value: ScheduleType.CUSTOM,
      description: `Schedule your ${resource} to run on specific days (e.g., Mondays at 9am)`,
    },
    {
      label: "Cron expression",
      value: ScheduleType.CRON,
      description: `Schedule your ${resource} using a cron expression`,
    },
    {
      label: "dbt Cloud",
      value: ScheduleType.DBT_CLOUD,
      description: `Automatically trigger your ${resource} upon completion of a dbt Cloud job`,
    },
  ];

  if (flags.fivetranExtension) {
    options.push({
      label: "Fivetran",
      value: ScheduleType.FIVETRAN,
      description: `Automatically trigger your ${resource} upon completion of a Fivetran job`,
    });
  }

  if (resource === "sync" && wasAlreadyStreaming) {
    const streamingOption = {
      label: "Continuous",
      value: ScheduleType.STREAMING,
      description: `Schedule your sync to always be running (i.e., back-to-back sync runs)`,
    };
    options.splice(1, 0, streamingOption); // Inserting after the "Manual" option
  }

  if (resource === "sync" && matchboosterEnabledOnModel) {
    options.push({
      label: "Match Booster enrichment",
      value: ScheduleType.MATCH_BOOSTER,
      description: `Automatically trigger your ${resource} upon completion of a Match Booster enrichment run`,
    });
  }

  if (types) {
    if (types.includes(ScheduleType.JOURNEY_TRIGGERED)) {
      options.push({
        label: "Journey-triggered",
        value: ScheduleType.JOURNEY_TRIGGERED,
        description: "Sync is triggered by a Hightouch Journey",
      });
    }

    return options.filter((option) => types.includes(option.value));
  }
  return options;
};

interface IntervalUnitOption {
  label: string;
  value: ScheduleIntervalUnit;
  icon?: FC<Readonly<IconProps>>;
}

export interface ScheduleManagerProps {
  schedule: Schedule | null;
  setSchedule: (schedule: Schedule) => void;
  resource?: ScheduleResource;
  types?: ScheduleType[];
  includeStartAndEnd?: boolean;
  showTitle?: boolean;
  isStreamable?: boolean | null | undefined;
  matchboosterEnabledOnModel?: boolean | null | undefined;
  intervalOptions?: IntervalUnitOption[];
  isStreaming?: boolean;
  isDisabled?: boolean;
  setIsStreaming?: (value: boolean) => void;
}

/**
 * Return a Date for the next whole [unit], e.g. the next hour
 * @param unit
 */
function nextInterval(
  unit: "second" | "minute" | "hour" | "day" | "week",
): Date {
  const date = moment().add(1, unit);

  if (unit === "week") {
    date.days(0).hours(0).minutes(0).seconds(0).milliseconds(0);
  } else if (unit === "day") {
    date.hours(0).minutes(0).seconds(0).milliseconds(0);
  } else if (unit === "hour") {
    date.minutes(0).seconds(0).milliseconds(0);
  } else if (unit === "minute") {
    date.seconds(0).milliseconds(0);
  } else if (unit === "second") {
    date.milliseconds(0);
  } else {
    throw new Error("Unexpected time unit");
  }

  return date.toDate();
}

const CronExpressionTable = ({
  expression,
  resource,
}: {
  expression: string;
  resource?: ScheduleResource;
}) => {
  // We copy the passed cron expression to avoid mutating it
  const nextRuns = Cron(expression.trim(), { timezone: "GMT" }).nextRuns(5);

  return (
    <Box pt={4}>
      <Paragraph>
        <Text fontWeight="medium">
          {resource === "journey" ? "Journeys" : "Syncs"} are scheduled in UTC.
          The next five {resource === "journey" ? "journey" : "sync"} runs will
          trigger at:
        </Text>
        <ul style={{ listStyleType: "none" }}>
          {nextRuns.map((date) => (
            <li key={date.toString()} style={{ margin: 1 }}>
              <Text size="sm">{formatInTimeZone(date, "GMT", "PPpp")} UTC</Text>
            </li>
          ))}
        </ul>
      </Paragraph>
    </Box>
  );
};

export const ScheduleManager: FC<ScheduleManagerProps> = ({
  schedule,
  setSchedule,
  resource = "sync",
  types,
  intervalOptions,
  includeStartAndEnd = true,
  showTitle = true,
  isStreamable = false,
  isStreaming = false,
  isDisabled = false,
  matchboosterEnabledOnModel = false,
  setIsStreaming,
}) => {
  const [wasAlreadyStreaming, _] = useState(
    schedule?.type === ScheduleType.STREAMING, // See CP-430. Save this value so that the streaming option doesn't just disappear instantly when you select something else.
  );

  const permission = usePermissionContext();
  const { isSubHourlyScheduleEnabled } = useScheduleState(resource);
  const { workspace } = useUser();
  const [cronExpressionErrorMessage, setCronExpressionErrorMessage] = useState<
    undefined | string
  >();
  const [cronExpression, setCronExpression] = useState<string>();
  const isEventSync = resource === "event_sync";

  const isJourneyTriggered = schedule?.type === ScheduleType.JOURNEY_TRIGGERED;

  const defaultTime = useMemo(
    () => moment().startOf("hour").utc().format("HH:mm"),
    [],
  );

  const intervalUnitOptions: IntervalUnitOption[] = useMemo(
    () =>
      intervalOptions ?? [
        {
          label: "Minute(s)",
          value: ScheduleIntervalUnit.MINUTE,
          icon: isSubHourlyScheduleEnabled ? undefined : UpgradeIcon,
        },
        { label: "Hour(s)", value: ScheduleIntervalUnit.HOUR },
        { label: "Day(s)", value: ScheduleIntervalUnit.DAY },
        { label: "Week(s)", value: ScheduleIntervalUnit.WEEK },
      ],
    [isSubHourlyScheduleEnabled, intervalOptions],
  );

  // Resetting default states
  useEffect(() => {
    if (schedule?.type === ScheduleType.CRON) {
      if (!schedule?.schedule?.expression) {
        const now = new Date();

        const expression = CronGenerator.onSpecificDaysAt(
          [now.getDay()],
          now.getHours(),
        );

        setSchedule({
          ...schedule,
          schedule: {
            expression,
          },
        });
        return;
      }
    }
    if (
      schedule?.type === ScheduleType.CUSTOM &&
      !schedule?.schedule?.expressions
    ) {
      setSchedule({
        ...schedule,
        schedule: {
          expressions: [
            {
              days: {},
              time: defaultTime,
            },
          ],
        },
      });
    }
  }, [schedule?.type]);

  // Check for cron expression validity
  useEffect(() => {
    if (
      schedule?.type === ScheduleType.CRON &&
      schedule?.schedule?.expression
    ) {
      const exp = validCronExpression(schedule?.schedule?.expression);
      if (exp) {
        setCronExpression(schedule?.schedule?.expression);
        setCronExpressionErrorMessage(undefined);
      } else {
        setCronExpression(undefined);
        setCronExpressionErrorMessage("Invalid cron expression.");
      }
    }
  }, [schedule?.type === ScheduleType.CRON && schedule?.schedule?.expression]);

  const flags = useFlags();
  const scheduleOptions = getScheduleOptions({
    resource: isEventSync ? "sync" : resource,
    flags,
    wasAlreadyStreaming,
    types,
    matchboosterEnabledOnModel,
  });

  return (
    <Box
      display="grid"
      w="100%"
      gap={4}
      sx={{ "& > div:not(:last-child)": { pb: 8, borderBottom: "small" } }}
    >
      {!isJourneyTriggered && isStreamable && flags.appStreamingRetl ? (
        //   TODO: (calum) add the real copy here...
        <Section title="Streaming rETL">
          <Row
            alignItems="center"
            textAlign="center"
            gap={4}
            sx={{ textAlign: "left" }}
          >
            <Switch
              isChecked={isStreaming}
              onChange={(v) => (setIsStreaming ? setIsStreaming(v) : null)}
            />
            <Text>
              Enable Streaming rETL. This will sync data incrementally from the
              warehouse rather than doing full diff operations on the entire
              data set.
            </Text>
          </Row>
        </Section>
      ) : null}
      {scheduleOptions.length >= 1 && (
        <Section title="Schedule type">
          <RadioGroup
            isDisabled={permission.unauthorized || isDisabled}
            value={schedule?.type}
            onChange={(type) =>
              type === schedule?.type
                ? undefined
                : setSchedule({
                    schedule: undefined,
                    type: type as ScheduleType,
                  })
            }
          >
            {scheduleOptions.map((option) => (
              <Radio key={option.label} {...option} />
            ))}
          </RadioGroup>
        </Section>
      )}
      {schedule?.type &&
        schedule?.type !== ScheduleType.MANUAL &&
        !isJourneyTriggered && (
          <Section title={showTitle ? "Schedule configuration" : ""}>
            <Box display="grid" gap={8}>
              {schedule?.type === ScheduleType.STREAMING && (
                <FeaturePreview
                  enabled={
                    workspace?.organization?.plan?.sku === "business_tier"
                  }
                  featureDetails={{
                    pitch: "Data syncing continuously",
                    description:
                      "Realtime syncing enables you to schedule syncs to be run when the previous sync finishes. This ensures your business systems are updated with the freshest data.",
                    bullets: [
                      "Schedule Hightouch syncs to run continuously",
                      "Great for operational use cases that require quick response times",
                      "Recommended when connecting Hightouch to a streaming transactional source, like a database",
                    ],
                    image: {
                      src: "https://cdn.sanity.io/images/pwmfmi47/production/848482d36fb62b7df5542d8e8996031a77c2db9e-996x708.png",
                    },
                  }}
                  featureName="continuous syncing"
                  variant="hidden"
                />
              )}
              {schedule?.type === ScheduleType.INTERVAL && (
                <>
                  <Box
                    display="grid"
                    gridAutoFlow="column"
                    gridAutoColumns="max-content"
                    alignItems="center"
                    gap={2}
                  >
                    <Text fontWeight="medium">Every</Text>
                    {isEventSync &&
                    schedule?.schedule?.interval?.unit ===
                      ScheduleIntervalUnit.MINUTE ? (
                      <Select
                        isDisabled={isDisabled}
                        width="auto"
                        options={[15, 30, 60].map((quantity) => ({
                          label: String(quantity),
                          value: quantity,
                        }))}
                        value={
                          schedule?.schedule?.interval?.quantity ?? undefined
                        }
                        onChange={(value) =>
                          setSchedule({
                            ...schedule,
                            schedule: {
                              interval: {
                                ...schedule?.schedule?.interval,
                                quantity: value ? Number(value) : null,
                              },
                            },
                          })
                        }
                      />
                    ) : (
                      <NumberInput
                        isDisabled={isDisabled}
                        min={1}
                        width="auto"
                        value={
                          schedule?.schedule?.interval?.quantity
                            ? schedule?.schedule?.interval?.quantity
                            : undefined
                        }
                        onChange={(value) =>
                          setSchedule({
                            ...schedule,
                            schedule: {
                              interval: {
                                ...schedule?.schedule?.interval,
                                quantity: value ? Number(value) : null,
                              },
                            },
                          })
                        }
                      />
                    )}
                    <Select
                      isDisabled={isDisabled}
                      width="auto"
                      placeholder="Interval..."
                      options={intervalUnitOptions}
                      optionLabel={(option) => option.label}
                      optionAccessory={(option) =>
                        option.icon
                          ? {
                              type: "icon",
                              icon: option.icon,
                            }
                          : undefined
                      }
                      optionValue={(option) => option.value}
                      value={
                        schedule?.schedule?.interval?.unit
                          ? intervalUnitOptions.find(
                              (s) =>
                                schedule?.schedule?.interval?.unit === s.value,
                            )?.value
                          : undefined
                      }
                      onChange={(selected) => {
                        setSchedule({
                          ...schedule,
                          schedule: {
                            interval: {
                              ...schedule?.schedule?.interval,
                              unit:
                                (selected as ScheduleIntervalUnit) ?? undefined,
                            },
                          },
                        });
                      }}
                    />
                  </Box>
                  {schedule?.schedule?.interval?.unit ===
                    ScheduleIntervalUnit.MINUTE && (
                    <FeaturePreview
                      enabled={isSubHourlyScheduleEnabled}
                      featureDetails={{
                        pitch: "Data syncing at sub-hourly frequencies",
                        description:
                          "Realtime syncing enables you to schedule syncs to be run at sub-hourly frequencies. This ensures your business systems are updated with the freshest data.",
                        bullets: [
                          "Schedule Hightouch syncs to run every few minutes",
                          "Great for operational use cases that require sub-hourly response times",
                          "Recommended when connecting Hightouch to a transactional source, like a database",
                        ],
                        image: {
                          src: "https://cdn.sanity.io/images/pwmfmi47/production/848482d36fb62b7df5542d8e8996031a77c2db9e-996x708.png",
                        },
                      }}
                      featureName="continuous syncing"
                      variant="hidden"
                    />
                  )}
                </>
              )}

              {schedule?.type === ScheduleType.DBT_CLOUD && (
                <ConfigureDbtCloudSchedule
                  schedule={schedule}
                  setSchedule={setSchedule}
                />
              )}

              {schedule?.type === ScheduleType.FIVETRAN && (
                <ConfigureFivetranSchedule
                  schedule={schedule}
                  setSchedule={setSchedule}
                />
              )}

              {schedule?.type === ScheduleType.CUSTOM && (
                <Column align="start" gap={8}>
                  <Box display="grid" gap={8}>
                    {schedule?.schedule?.expressions?.length &&
                      schedule.schedule.expressions.map(
                        (_expression, index) => (
                          <VisualCronExpression
                            key={index}
                            index={index}
                            isDisabled={isDisabled}
                            schedule={schedule}
                            setSchedule={setSchedule}
                          />
                        ),
                      )}
                  </Box>
                  <Button
                    isDisabled={isDisabled}
                    onClick={() => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          ...schedule?.schedule,
                          expressions: [
                            ...(schedule?.schedule?.expressions ?? []),
                            { days: {}, time: defaultTime },
                          ],
                        },
                      });
                    }}
                  >
                    Add recurrence
                  </Button>
                </Column>
              )}

              {schedule?.type === ScheduleType.CRON && (
                <FormField
                  error={cronExpressionErrorMessage}
                  label="Cron expression"
                >
                  <TextInput
                    isDisabled={isDisabled}
                    width="xs"
                    value={schedule?.schedule?.expression ?? ""}
                    onChange={(e) => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          expression: e.target.value,
                        },
                      });
                    }}
                  />
                  {!cronExpressionErrorMessage && cronExpression && (
                    <CronExpressionTable
                      resource={resource}
                      expression={cronExpression}
                    />
                  )}
                </FormField>
              )}

              {includeStartAndEnd &&
                workspace?.organization?.plan?.sku === "business_tier" && (
                  <Box
                    display={{ base: "flex", md: "grid" }}
                    flexWrap={{ base: "wrap", md: "nowrap" }}
                    gridTemplateColumns={
                      schedule?.startDate || schedule?.endDate
                        ? {
                            base: "repeat(1, max-content)",
                            xl: "repeat(2, max-content)",
                          }
                        : {
                            base: "repeat(2, max-content)",
                            xl: "repeat(4, max-content)",
                          }
                    }
                    gridAutoFlow="row"
                    alignItems="center"
                    gap={2}
                  >
                    <Row justify="end">
                      <Text>This schedule will be applied effective</Text>
                    </Row>
                    <Row flex="0 1 0" justify="start">
                      <Select
                        isDisabled={isDisabled}
                        width="auto"
                        options={[
                          {
                            key: "immediate",
                            value: "immediate",
                            label: "immediately",
                          },
                          { key: "deferred", value: "deferred", label: "from" },
                        ]}
                        value={schedule?.startDate ? "deferred" : "immediate"}
                        onChange={(value) =>
                          setSchedule({
                            ...schedule,
                            startDate:
                              value === "immediate"
                                ? null
                                : schedule?.startDate ??
                                  nextInterval("day").toISOString(),
                          })
                        }
                      />
                    </Row>
                    {schedule?.startDate && (
                      <DateTimeSelect
                        gridColumn={2}
                        mb={4}
                        flexWrap="wrap"
                        gap={{ base: 2, xl: 0 }}
                        maxWidth="100%"
                        value={
                          schedule?.startDate
                            ? new Date(schedule?.startDate)
                            : nextInterval("day")
                        }
                        onChange={(value) => {
                          setSchedule({
                            ...schedule,
                            startDate: value.toISOString(),
                          });
                        }}
                      />
                    )}
                    <Row justify="end">
                      <Text>and will remain effective</Text>
                    </Row>
                    <Row width="min-content">
                      <Select
                        isDisabled={isDisabled}
                        width="auto"
                        options={[
                          {
                            key: "indefinite",
                            value: "indefinite",
                            label: "indefinitely",
                          },
                          { key: "finite", value: "finite", label: "until" },
                        ]}
                        value={schedule?.endDate ? "finite" : "indefinite"}
                        onChange={(value) =>
                          setSchedule({
                            ...schedule,
                            endDate:
                              value === "indefinite"
                                ? null
                                : schedule?.endDate ??
                                  nextInterval("week").toISOString(),
                          })
                        }
                      />
                    </Row>
                    {schedule?.endDate && (
                      <DateTimeSelect
                        gridColumn={2}
                        flexWrap="wrap"
                        gap={{ base: 2, xl: 0 }}
                        maxWidth="100%"
                        value={
                          schedule?.endDate
                            ? new Date(schedule?.endDate)
                            : nextInterval("week")
                        }
                        onChange={(value) => {
                          setSchedule({
                            ...schedule,
                            endDate: value.toISOString(),
                          });
                        }}
                      />
                    )}
                  </Box>
                )}
            </Box>
          </Section>
        )}
    </Box>
  );
};

export const useScheduleState = (resource: ScheduleResource) => {
  const { isEmbedded, workspace } = useUser();
  const { appSubHourlySyncsEnabled } = useFlags();

  const isEventSync = resource === "event_sync";
  const isBusinessTier = workspace?.organization?.plan?.sku === "business_tier";
  const isSubHourlyScheduleEnabled =
    isEventSync || appSubHourlySyncsEnabled || isBusinessTier || isEmbedded;

  const validateSchedule = (schedule: Schedule | null): boolean => {
    if (!schedule) {
      return false;
    }
    if (
      schedule.type === ScheduleType.MANUAL ||
      schedule.type === ScheduleType.JOURNEY_TRIGGERED
    )
      return true;
    if (schedule.type === ScheduleType.STREAMING) return isBusinessTier;
    if (schedule.type === ScheduleType.MATCH_BOOSTER) {
      return true;
    }
    if (schedule.type === ScheduleType.INTERVAL) {
      if (
        schedule?.schedule?.interval?.unit === "minute" &&
        !isSubHourlyScheduleEnabled
      ) {
        return false;
      }
      return Boolean(
        schedule.schedule?.interval?.unit &&
          schedule.schedule?.interval?.quantity &&
          Number.isInteger(schedule.schedule.interval.quantity) &&
          schedule.schedule.interval.quantity >= 1,
      );
    }
    if (schedule.type === ScheduleType.CRON) {
      return Boolean(validCronExpression(schedule.schedule?.expression));
    }
    if (schedule.type === ScheduleType.CUSTOM) {
      return Boolean(
        Array.isArray(schedule.schedule?.expressions) &&
          schedule.schedule?.expressions?.every(
            (e) => Object.keys(e.days ?? {}).length > 0 && e.time,
          ),
      );
    }
    if (schedule.type === ScheduleType.DBT_CLOUD) {
      return Boolean(
        schedule.schedule?.account?.id && schedule.schedule?.job?.id,
      );
    }
    if (schedule.type === ScheduleType.FIVETRAN) {
      return Boolean(
        schedule.schedule?.triggers &&
          schedule.schedule.triggers.length > 0 &&
          schedule?.schedule?.groupId,
      );
    }
    return false;
  };

  return { validateSchedule, isSubHourlyScheduleEnabled };
};
