import { FC } from "react";

import { Box, Column, Heading, Text, WarningIcon } from "@hightouchio/ui";
import grain from "src/assets/backgrounds/grain.png";
import { Card } from "src/components/card";

export const Maintenance: FC = () => {
  return (
    <Box
      bg={`url(${grain}) repeat, linear-gradient(107.85deg, #002124 0%, #02373B 17.58%, #033E40 31.02%, #085954 64.76%)`}
      width="100vw"
      height="100vh"
      flex={1}
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <Card>
        <Box as={WarningIcon} boxSize={12} color="warning.base" mb={8} />
        <Column align="center" color="gray.900" gap={4}>
          <Heading size="xl">Scheduled maintenance</Heading>
          <Text>We apologize for any inconvience, we will be back soon!</Text>
        </Column>
      </Card>
    </Box>
  );
};
