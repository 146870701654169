import { FC, useEffect, useState } from "react";

import {
  Box,
  Column,
  FormField,
  SectionHeading,
  Row,
  Text,
  TextInput,
  useToast,
  RadioGroup,
  Radio,
  StatusIndicator,
  ButtonGroup,
  Dialog,
  Button,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Outlet, useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import sigmaExtensionImage from "src/components/extensions/assets/sigma-extension.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import {
  GetSigmaExtensionQuery,
  useUpsertSigmaExtensionMutation,
  useDeleteSigmaExtensionMutation,
  useGetSigmaExtensionQuery,
  useTestSigmaExtensionQuery,
  useModelsQuery,
} from "src/graphql";
import { SigmaIcon } from "src/ui/icons";
import { PageSpinner } from "src/components/loading";
import { Table } from "src/ui/table";

import { ExtensionTestStatus } from "./common";
import { PermissionedButton } from "src/components/permission";
import { RouteTabs } from "src/components/route-tabs";

export const Sigma: FC = () => {
  const { data: credentials, isLoading: loading } = useGetSigmaExtensionQuery(
    undefined,
    {
      select: (data) => data.sigma_credentials?.[0],
    },
  );

  return (
    <Page
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Sigma - Extensions"
    >
      <RouteTabs
        depth={3}
        tabs={[
          {
            path: "",
            title: "Overview",
          },
          {
            path: "configuration",
            title: "Configuration",
          },
        ]}
      />
      <Box mt={8}>
        <Outlet context={{ credentials, loading }} />
      </Box>
    </Page>
  );
};

export const SigmaOverview = () => {
  return (
    <Overview
      description="When you create a Hightouch model backed by a Sigma workbook, Hightouch converts that workbook into SQL and runs it against your data source."
      icon={SigmaIcon}
      image={sigmaExtensionImage}
      subtitle="Import models from Sigma"
      title="Sigma"
    />
  );
};

interface OutletContext {
  credentials: GetSigmaExtensionQuery["sigma_credentials"][0] | undefined;
  loading: boolean;
}

export const SigmaConfiguration: FC = () => {
  const { credentials, loading } = useOutletContext<OutletContext>();
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [cloud, setCloud] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const { sigmaStaging } = useFlags();

  const [problemModelIds, setProblemModelIds] = useState<string[]>([]);
  const { data: problemModels, isLoading: problemModelsLoading } =
    useModelsQuery(
      { filters: { id: { _in: problemModelIds } }, limit: 100, offset: 0 },
      { enabled: problemModelIds.length != 0, select: (data) => data.segments },
    );

  const [cloudError, setCloudError] = useState<string | null>(null);
  const [clientIdError, setClientIdError] = useState<string | null>(null);
  const [clientSecretError, setClientSecretError] = useState<string | null>(
    null,
  );

  function resetErrors() {
    setCloudError(null);
    setClientIdError(null);
    setClientSecretError(null);
  }

  const [testStatus, setTestStatus] = useState<ExtensionTestStatus>("loading");
  const { toast } = useToast();
  const { mutateAsync: upsert, isLoading: isCreating } =
    useUpsertSigmaExtensionMutation();
  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestSigmaExtensionQuery(
    {},
    { select: (data) => data.testSigmaExtension, enabled: !!credentials },
  );
  const { mutateAsync: deleteExtension } = useDeleteSigmaExtensionMutation();

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (testResult?.__typename === "SigmaExtensionError") {
        setTestStatus("failed");
        toast({
          id: "failed-sigma-test",
          variant: "error",
          title: "Sigma extension test failed.",
          message: testResult?.message ?? "Please check your API credentials.",
        });
      } else {
        setClientId(credentials.clientId);
        setCloud(credentials.cloud);
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting, isDeleting]);

  const submit = async () => {
    try {
      resetErrors();
      const resp = await upsert({
        id: credentials?.id.toString(),
        clientId,
        clientSecret,
        cloud,
      });
      if (resp.upsertSigmaExtension.__typename === "SigmaExtensionError") {
        const sigmaError = resp.upsertSigmaExtension?.sigmaError ?? "";
        if (sigmaError.includes("client secret")) {
          setClientSecretError(
            "Invalid client secret. Please check your credentials.",
          );
        } else if (sigmaError.includes("token")) {
          setClientIdError(
            "Possibly invalid client ID. Please check your credentials.",
          );
          setCloudError(
            "Possibly invalid cloud provider. Please check your credentials.",
          );
        } else if (sigmaError.includes("cloud")) {
          setCloudError("Invalid cloud. Please check your credentials.");
        }
        throw Error(
          resp.upsertSigmaExtension.sigmaError ??
            resp.upsertSigmaExtension.message ??
            "",
        );
      }
      toast({
        id: "sigma-credentials",
        variant: "success",
        title: "Sigma extension connected",
        message: "You can now import Sigma workbooks as models.",
      });
      setClientSecret("");
    } catch (e) {
      toast({
        id: "sigma-credentials",
        variant: "error",
        title: "Sigma connection failed",
        message: "Check your Sigma configuration and try again.",
      });
      Sentry.captureException(e);
    }
  };

  const deleteSigma = async () => {
    try {
      resetErrors();
      setIsDeleting(true);
      const r = await deleteExtension({});
      setIsDeleting(false);
      if (r.deleteSigmaExtension.__typename === "SigmaExtension") {
        setCloud("");
        setClientId("");
        toast({
          id: "extension-disconnect",
          title: "Sigma disconnected",
          message:
            "This extension has been removed from your Hightouch workspace.",
          variant: "success",
        });
      }
      if (r.deleteSigmaExtension.__typename === "SigmaExtensionError") {
        const serverMessage = r.deleteSigmaExtension.message;
        if (serverMessage?.includes("Sigma credentials still in use")) {
          const modelIds = serverMessage.match(/\d+/g) ?? [];
          setProblemModelIds(modelIds);
        } else {
          throw Error(serverMessage ?? "");
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "extension-disconnect",
        title: "Failed to disconnect",
        message:
          "Sigma could not be disconnected from your Hightouch workspace. Please try again.",
        variant: "error",
      });
    }
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Row justifyContent="space-between">
        <Column flex={1} gap={5}>
          <Row gap={5}>
            <SectionHeading>Sigma configuration</SectionHeading>
            {credentials?.id && (
              <StatusIndicator
                variant={
                  testStatus === "loading"
                    ? "processing"
                    : testStatus === "failed"
                      ? "error"
                      : "success"
                }
              >
                {testStatus === "loading"
                  ? "Testing..."
                  : testStatus === "failed"
                    ? "Connection Failed"
                    : "Connected"}
              </StatusIndicator>
            )}
          </Row>
          <Column gap={6}>
            <FormField
              description={
                <Text>
                  You can find this by visiting Sigma and navigating to{" "}
                  <Text fontWeight="semibold">Administration &gt; Account</Text>
                  .
                </Text>
              }
              error={cloudError ?? undefined}
              label="Cloud provider"
            >
              <RadioGroup
                isDisabled={isCreating}
                value={cloud}
                onChange={(value) => {
                  setCloud(value);
                  setCloudError(null);
                }}
              >
                <Radio label="AWS" value="aws" />
                <Radio label="Azure" value="azure" />
                <Radio label="GCP" value="gcp" />
                {sigmaStaging && <Radio label="Staging" value="staging" />}
              </RadioGroup>
            </FormField>

            <FormField
              description={
                <Text>
                  You can generate a new API key by visiting Sigma and
                  navigating to{" "}
                  <Text fontWeight="semibold">
                    Administration &gt; APIs & Embed Secrets
                  </Text>
                  , then clicking <Text fontWeight="semibold">Create New</Text>.
                </Text>
              }
              error={clientIdError ?? undefined}
              label="Client ID"
            >
              <TextInput
                isDisabled={isCreating}
                placeholder="Enter your client ID..."
                value={clientId}
                onChange={(event) => {
                  setClientId(event.target.value);
                  setClientIdError(null);
                }}
              />
            </FormField>

            <FormField
              description="This secret is generated at the end of the API key creation flow."
              error={clientSecretError ?? undefined}
              label="Client secret"
            >
              <TextInput
                isDisabled={isCreating}
                // We don't return credentials.clientSecret to the client.
                // When credentials are present, use different placeholder text to inform the user that the extension is still connected.
                placeholder={
                  credentials?.id
                    ? "To update, enter your client secret..."
                    : "Enter your client secret..."
                }
                type="password"
                value={clientSecret}
                onChange={(event) => {
                  setClientSecret(event.target.value);
                  setClientSecretError(null);
                }}
              />
            </FormField>
          </Column>
          <ActionBar>
            {!credentials?.id ? (
              <PermissionedButton
                permission={{
                  v2: { resource: "workspace", grant: "can_update" },
                }}
                size="lg"
                isDisabled={!clientId || !clientSecret || !cloud}
                isLoading={isCreating}
                variant="primary"
                onClick={() => submit()}
              >
                Connect
              </PermissionedButton>
            ) : (
              <>
                <ButtonGroup>
                  <PermissionedButton
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    size="lg"
                    isDisabled={!clientId || !clientSecret || !cloud}
                    isLoading={isCreating}
                    variant="primary"
                    onClick={() => submit()}
                  >
                    Save changes
                  </PermissionedButton>
                  <PermissionedButton
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    size="lg"
                    isLoading={isTesting || isReTesting || isDeleting}
                    onClick={() => {
                      setTestStatus("loading");
                      testExtension({});
                    }}
                  >
                    {isTesting || isReTesting
                      ? "Testing..."
                      : "Test connection"}
                  </PermissionedButton>
                </ButtonGroup>
                <PermissionedButton
                  permission={{
                    v2: { resource: "workspace", grant: "can_update" },
                  }}
                  size="lg"
                  isDisabled={isTesting}
                  isLoading={isDeleting}
                  variant="danger"
                  onClick={() => deleteSigma()}
                >
                  Disconnect
                </PermissionedButton>
              </>
            )}
          </ActionBar>
        </Column>
        <SidebarForm docsUrl="extensions/sigma" name="Sigma" />
      </Row>
      <Dialog
        isOpen={problemModelIds.length > 0}
        variant="info"
        width="xl"
        title="A problem occurred while disconnecting Sigma"
        actions={<Button onClick={() => setProblemModelIds([])}>Close</Button>}
        onClose={() => setProblemModelIds([])}
      >
        <Row mb={8}>
          <Text>
            Disconnecting Sigma from your Hightouch workspace will affect the
            following models. Please update or delete them before deleting your
            Sigma extension.
          </Text>
        </Row>
        <Column>
          <Table
            columns={[
              {
                name: "Model",
                cell: ({ model }) => (
                  <Link href={`/models/${model.id}`}>{model.name}</Link>
                ),
              },
              {
                name: "Model source",
                cell: ({ source }) => (
                  <Link href={`/sources/${source.id}`}>{source.name}</Link>
                ),
              },
              {
                name: "Syncs",
                cell: ({ syncs }) => (
                  <Box>
                    {syncs.ids.sort().map((id, index) => (
                      <Link key={id} href={`/syncs/${id}`}>
                        {id}
                        {index + 1 !== syncs.ids.length ? ", " : ""}
                      </Link>
                    ))}
                  </Box>
                ),
              },
            ]}
            data={problemModels?.map((segment) => {
              return {
                model: {
                  id: segment.id,
                  name: segment.name,
                },
                source: {
                  id: segment?.connection?.id ?? "",
                  name: segment?.connection?.name ?? "",
                },
                syncs: {
                  ids: segment.syncs.map((sync) => sync?.id ?? ""),
                },
              };
            })}
            placeholder={{
              error:
                "Dependent models failed to load, please refresh this page.",
            }}
            loading={problemModelsLoading}
          />
        </Column>
      </Dialog>
    </>
  );
};
