import { BoxProps, Row, Spinner } from "@hightouchio/ui";
import { default as LoadingSkeleton } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Skeleton = LoadingSkeleton;

export const PageSpinner = () => (
  <Row
    sx={{
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner size="lg" />
  </Row>
);

export const OverlaySpinner = ({ sx = {} }: { sx?: BoxProps["sx"] }) => (
  <Row
    sx={{
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      minHeight: 0,
      height: "100%",
      width: "100%",
      bg: "rgba(250, 251, 252, .75)",
      ...sx,
    }}
  >
    <Spinner size="lg" />
  </Row>
);

export { LoadingDots } from "./loading-dots";
export { LoadingCircles } from "./loading-circles";
