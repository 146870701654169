import { useEffect, useState } from "react";
import { ButtonGroup, Column, Row, useToast } from "@hightouchio/ui";
import { isEqual, omit } from "lodash";

import { useOutletContext } from "src/router";
import {
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import {
  TestDestinationStepStatus,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useQueryString } from "src/utils/use-query-string";
import { SetupForm } from "src/components/destinations/destination-form";
import { TestConnectionTable } from "src/components/destinations/test-connection-status";
import { ActionBar } from "src/components/action-bar";
import { PermissionedButton } from "src/components/permission";
import { SidebarForm } from "src/components/page";
import { OutletContext } from ".";

type Config = Record<string, unknown>;

export const DestinationConfiguration = () => {
  const { destination } = useOutletContext<OutletContext>();
  const {
    data: { reauthorized },
  } = useQueryString();

  const { toast } = useToast();
  const [config, setConfig] = useState<Config | undefined>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testConnectionResult, setTestConnectionResult] = useState<
    TestDestinationStepStatus[] | null
  >(null);
  const [testError, setTestError] = useState<Error | null>(null);

  const { isLoading: updating, mutateAsync: updateDestination } =
    useUpdateDestinationV2Mutation();

  const isOAuth =
    config &&
    (config.methodKey === "googleOauth" || config.methodKey === "oauth");

  const definition = destination?.definition;

  const reset = () => {
    setName(destination?.name ?? undefined);
    setConfig(destination?.config ?? undefined);
    setCredentialId(destination?.credential_id ?? undefined);
    setTestResult(TestResult.Unknown);
    setTestConnectionResult(null);
  };

  const update = async () => {
    await updateDestination({
      id: destination.id.toString(),
      destination: {
        credential_id: credentialId,
        config: {
          ...destination?.config,
          ...config,
        },
      },
    });
    toast({
      id: "update-destination",
      title: "Destination was updated",
      variant: "success",
    });
  };

  const dirty =
    name !== destination?.name ||
    !isEqual(
      omit(config, ["methodKey"]),
      omit(destination?.config, ["methodKey"]),
    ) ||
    !isEqual(credentialId || false, destination?.credential_id || false);

  const setConfigDirty = (config: Config) => {
    setConfig(config);
    setTestResult(TestResult.Unknown);
    setTestConnectionResult(null);
  };

  useEffect(() => {
    reset();
  }, [destination]);

  useEffect(() => {
    if (reauthorized) {
      toast({
        id: "reauthorized-destination",
        title: "Destination reauthorized",
        variant: "success",
      });
    }
  }, [reauthorized]);

  return (
    <Row align="flex-start">
      <Column gap={8} flexGrow={1} minWidth={0} pb={20}>
        {definition && (
          <SetupForm
            config={config}
            credentialId={credentialId}
            definition={definition}
            destination={destination}
            error={testError}
            isSetup={false}
            setConfig={setConfigDirty}
            setCredentialId={setCredentialId}
            connectionHandlers={{
              onResult: setTestResult,
              onTestResult: setTestConnectionResult,
              onError: setTestError,
              onLoading: setTesting,
              testResult,
              testConnectionResult,
              testError,
            }}
          />
        )}
        {(testConnectionResult || testing) && config && !isOAuth && (
          <TestConnectionTable
            result={testConnectionResult}
            testing={testing}
          />
        )}
        <ActionBar>
          <ButtonGroup>
            <PermissionedButton
              permission={{
                v2: {
                  resource: "destination",
                  grant: "can_update",
                  id: destination.id,
                },
              }}
              size="lg"
              variant="primary"
              isDisabled={!dirty}
              isLoading={updating}
              onClick={update}
            >
              Save changes
            </PermissionedButton>
            {config && !isOAuth && definition?.testConnection ? (
              <TestUpdatedDestinationButton
                size="lg"
                credentialId={credentialId}
                destinationId={destination.id?.toString()}
                newConfiguration={config}
                onError={setTestError}
                onLoading={setTesting}
                onResult={setTestResult}
                onTestResult={setTestConnectionResult}
              />
            ) : null}
          </ButtonGroup>
        </ActionBar>
      </Column>
      <SidebarForm
        docsUrl={definition?.docs ?? ""}
        name={definition?.name ?? ""}
      />
    </Row>
  );
};
