import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";

import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Column,
  ConfirmationDialog,
  Dialog,
  ExternalLinkIcon,
  GroupedCombobox,
  Menu,
  MenuButton,
  MenuList,
  Paragraph,
  Row,
  SectionHeading,
  Select,
  StatusBadge,
  StatusBadgeProps,
  Text,
  UpsellButton,
  useToast,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Link } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { array, object, string } from "yup";
import { groupBy } from "lodash";

import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import { ActionBar } from "src/components/action-bar";
import {
  PermissionedButton,
  PermissionedIconButton,
  PermissionedMenuItem,
  PermissionedSwitch,
} from "src/components/permission";
import { ScheduleManager } from "src/components/schedule/schedule-manager";
import {
  IntervalSchedule,
  Schedule,
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";
import { SplashComponent } from "src/components/splash-page";
import { useUser } from "src/contexts/user-context";
import {
  EnrichmentStatus,
  EnrichmentStatusQuery,
  MatchboostingConfigQuery,
  MatchboostingStatsQuery,
  ModelWithoutColumnsQuery,
  ObjectQuery,
  useAllMatchboostingStatsQuery,
  useCancelEnrichmentMutation,
  useEnrichmentStatusQuery,
  useMatchboostingConfigQuery,
  useMatchboostingIdentifiersQuery,
  useMatchboostingStatsQuery,
  useStartEnrichmentMutation,
  useUpdateMatchboostingSettingsMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import Status from "src/pages/audiences/setup/assets/status.svg";
import {
  IdentifierField,
  containsModelColumn,
  doesModelContainBoostedSyncs,
  formatDurationDisplay,
  getEnrichmentSchedule,
  getNextScheduledEnrichment,
} from "src/utils/match-boosting";
import { formatDatetime } from "src/utils/time";
import { useResourcePermission } from "src/components/permission/use-resource-permission";

import MatchRate from "src/pages/schema/mr.svg";
import { MatchBoosterInitializing } from "./initializing";
import { RunsTable } from "./runs-table";

type Props = {
  sidebar: boolean;
  segmentType: "model" | "schema";
  model:
    | ModelWithoutColumnsQuery["segments_by_pk"]
    | ObjectQuery["segments_by_pk"]
    | null
    | undefined;
  columns: { name: string; semantic_type: string | null }[] | undefined;
};

const validationSchema = object()
  .shape({
    columns: array().of(
      object().shape({
        columnName: string().required("Column name is required"),
        identifier: string().required("Identifier is required"),
      }),
    ),
  })
  .optional();

export const MatchBoostingSettingsWrapper: FC<Readonly<Props>> = ({
  sidebar,
  segmentType,
  model,
  columns,
}) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      <Matchboosting
        sidebar={sidebar}
        segmentType={segmentType}
        model={model}
        columns={columns}
      />
    </FormProvider>
  );
};

const Matchboosting = ({ sidebar, columns, model, segmentType }: Props) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const [enabled, setEnabled] = useState(Boolean(model?.matchboosting_enabled));
  const [cancelling, setCancelling] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const { appMatchBoosting } = useFlags();
  const [sendEmail, setSendEmail] = useState(true);
  const [schedule, setSchedule] = useState<Schedule | null>(
    model?.enrichment_schedule ?? {
      type: ScheduleType.INTERVAL,
      schedule: { interval: { unit: ScheduleIntervalUnit.DAY, quantity: 1 } },
    },
  );
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);
  const [isConfirmInitializingOpen, setIsConfirmInitializingOpen] =
    useState(false);
  const [showMatchBoosterSyncBanner, setShowMatchBoosterSyncBanner] =
    useState(false);

  const { isPermitted: hasUpdateColumnPermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: model?.id },
    v1: { resource: "audience_schema", grant: "update" },
  });

  const {
    data: matchboostingConfigs,
    isLoading: isMatchboostingConfigsLoading,
  } = useMatchboostingConfigQuery(
    {
      modelId: model?.id,
      workspaceId: workspace?.id,
    },
    {
      enabled: Boolean(model?.id),
      refetchInterval: 5000,
    },
  );

  const { isPermitted: hasCreateSyncPermission } = useResourcePermission({
    v1: { resource: "sync", grant: "create" },
  });

  const { data: lastEnrichedStats } = useMatchboostingStatsQuery(
    {
      modelId: model?.id,
    },
    {
      enabled: Boolean(model?.id),
      refetchInterval: 5000, // 5 seconds is often enough.
      select: (data) => data.matchboosting_stats[0],
    },
  );

  const { data: allMatchboostingStats } = useAllMatchboostingStatsQuery(
    {
      modelId: model?.id,
    },
    {
      enabled: Boolean(model?.id),
      refetchInterval: 5000, // 5 seconds is often enough.
      select: (data) => data.matchboosting_stats,
    },
  );

  const { data: enrichmentStatus, refetch } = useEnrichmentStatusQuery(
    {
      modelId: model?.id?.toString() ?? "",
    },
    {
      refetchInterval: 5000, // 5 seconds is often enough.
      enabled: Boolean(model?.id),
      select: (data) => data.enrichmentStatus,
    },
  );

  // Initializing = calibrating MB with our third party graph
  // We need to initialize the model if we don't detect any MB configs for the
  // workspace OR the model. We also need to check if the model has MB
  // enabled as it's the only way to start or schedule a matchbooster job.
  // Note that we can still be calibrating beyond the initializing job duration
  const needToInitializeMB = Boolean(
    !matchboostingConfigs?.matchboosting_config?.length &&
      !matchboostingConfigs?.matchboosting_workspace_config?.length,
  );
  const isInitializing = Boolean(
    model?.matchboosting_enabled &&
      !isMatchboostingConfigsLoading &&
      needToInitializeMB,
  );

  const { data: initializingStatus } = useEnrichmentStatusQuery(
    {
      modelId: model?.id?.toString() ?? "",
      isInitializing: true,
    },
    {
      refetchInterval: 60_000, // spinner text only needs to update every min
      enabled: isInitializing,
      select: (data) => data.enrichmentStatus,
    },
  );

  const { data } = useMatchboostingIdentifiersQuery();

  const { mutateAsync: updateMatchboostingSettings } =
    useUpdateMatchboostingSettingsMutation();

  const { mutateAsync: runEnrichment } = useStartEnrichmentMutation();

  const { mutateAsync: cancelEnrichment } = useCancelEnrichmentMutation();

  const objectColumns = columns?.map((col) => {
    return {
      identifier: col.semantic_type,
      columnName: col.name,
    };
  });

  useEffect(() => {
    model && setEnabled(model.matchboosting_enabled);
  }, [model?.matchboosting_enabled]);

  useEffect(() => {
    setCancelling(false);
  }, [enrichmentStatus?.status]);

  const mbBannerClearedKey = `mb-sync-banner-cleared-${model?.id}`;

  useEffect(() => {
    // Don't check parent models bc syncs only exist on their audiences
    if (segmentType === "schema" || !model?.matchboosting_enabled) return;

    // We want to show this banner to encourage users to create a sync with MB
    // when an enrichment is done and they don't have any MB syncs. However,
    // if a user already seen and cleared this banner, we shouldn't show it again.
    const userClearedBanner = localStorage.getItem(mbBannerClearedKey);

    const modelContainBoostedSyncs = doesModelContainBoostedSyncs(model);

    if (modelContainBoostedSyncs && userClearedBanner != null) {
      // If model contain MB syncs, remove item from localStorage so it doesn't
      // take up space
      localStorage.removeItem(mbBannerClearedKey);
      return;
    }

    const successfulEnrichmentRan = allMatchboostingStats?.some(
      (stats) => stats.status === "success",
    );

    setShowMatchBoosterSyncBanner(
      Boolean(
        matchboostingTabEnabled &&
          // Make sure at least one enrichment ran for the model before showing banner
          successfulEnrichmentRan &&
          !userClearedBanner &&
          !modelContainBoostedSyncs,
      ),
    );
  }, [allMatchboostingStats]);

  const identifiers = data?.matchboostingIdentifiers || [];
  const identifiersGroupByCategories = groupBy(
    identifiers,
    ({ category }) => category,
  );

  const categories = Object.keys(identifiersGroupByCategories);
  const identifiersGroupedOptions = categories.map((c) => ({
    label: c,
    options:
      identifiersGroupByCategories[c]?.map((i) => ({
        label: i.alias,
        value: i.identifier,
      })) ?? [],
  }));

  const formName = "columns";
  const { watch, reset, handleSubmit } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: formName,
  });

  const watchFieldArray = watch(formName);

  const controlledFields: IdentifierField[] =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    const existingMappings = columns
      ?.filter((column) => column.semantic_type)
      ?.map((column) => {
        return {
          id: column.name,
          columnName: column.name,
          identifier: column.semantic_type,
        };
      });

    reset({
      [formName]: existingMappings,
    });
  }, [columns]);

  const columnOptions =
    columns?.map((column) => ({
      value: column.name,
      label: column.name,
      semantic_type: column.semantic_type,
    })) || [];

  const onSubmit = async (forceFullEnrichment: boolean) => {
    if (controlledFields.length === 0) {
      toast({
        id: "update-column",
        title: "You must map at least 1 identifier",
        variant: "error",
      });
      return;
    }

    const columnsChanged: { name: string; semantic_type: string | null }[] = [];
    for (const originalColumn of objectColumns || []) {
      const newColumn = controlledFields.find(
        (col) => col.columnName === originalColumn.columnName,
      );
      if ((newColumn?.identifier || null) !== originalColumn.identifier) {
        columnsChanged.push({
          name: originalColumn.columnName,
          semantic_type: newColumn?.identifier || null, // set to null to clear the semantic type.
        });
      }
    }

    try {
      await updateMatchboostingSettings({
        segmentId: model?.id,
        updates: columnsChanged.map((col) => {
          return {
            where: {
              name: { _eq: col.name },
              model_id: { _eq: model?.id },
            },
            _set: {
              semantic_type: col.semantic_type,
            },
          };
        }),
        enabled,
        enrichment_schedule: schedule,
      });
    } catch (err) {
      Sentry.captureException(err);
      toast({
        id: "update-column",
        title: `Failed to updated Match Booster settings`,
        variant: "error",
      });
    }

    if (isConfirmInitializingOpen) {
      setIsConfirmInitializingOpen(false);
    }

    await startEnrichment(forceFullEnrichment);
  };

  const startEnrichment = async (forceFullEnrichment: boolean) => {
    try {
      await runEnrichment({
        modelId: model?.id.toString(),
        sendEmail,
        forceFullEnrichment,
      });
      refetch();
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "run-enrichment",
        title: `Failed to start enrichment`,
        variant: "error",
      });
    }
  };

  const stopEnrichment = async () => {
    try {
      if (enrichmentStatus?.status === EnrichmentStatus.Processing) {
        setCancelling(true);
      }
      setIsConfirmCancelOpen(false);

      await cancelEnrichment({
        modelId: model?.id?.toString(),
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "cancel-enrichment",
        title: `Failed to cancel enrichment`,
        variant: "error",
      });
    }
  };

  const cancelInitializingMatchBooster = async () => {
    try {
      await disableMatchbooster();
      await cancelEnrichment({
        modelId: model?.id.toString(),
      });

      setEnabled(false);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "cancel-mb-initializing",
        title: `Failed to cancel initialization process`,
        variant: "error",
      });
    }
  };

  const disableMatchbooster = async () => {
    await updateMatchboostingSettings({
      enabled: false,
      segmentId: model?.id,
      updates: [],
    });
  };

  const actionContent = () => {
    const enrichmentIsProcessing =
      enrichmentStatus?.status === EnrichmentStatus.Processing ||
      initializingStatus?.status === EnrichmentStatus.Processing;
    const enrichmentIsCancelling =
      enrichmentStatus?.status === EnrichmentStatus.Cancelling ||
      initializingStatus?.status === EnrichmentStatus.Cancelling ||
      cancelling;

    return (
      <>
        <Row gap={4}>
          {needToInitializeMB ? (
            <PermissionedButton
              permission={{
                v1: {
                  resource: "audience_schema",
                  grant: "update",
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: model?.id,
                },
              }}
              isDisabled={
                enrichmentIsCancelling ||
                enrichmentIsProcessing ||
                !containsModelColumn(controlledFields)
              }
              size="lg"
              variant="primary"
              onClick={() => setIsConfirmInitializingOpen(true)}
            >
              Initialize Match Booster
            </PermissionedButton>
          ) : (
            <Menu>
              <MenuButton
                size="lg"
                variant="primary"
                isDisabled={enrichmentIsProcessing}
              >
                Run Match Booster
              </MenuButton>
              <MenuList>
                <PermissionedMenuItem
                  permission={{
                    v1: {
                      resource: "audience_schema",
                      grant: "update",
                    },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: model?.id,
                    },
                  }}
                  onClick={handleSubmit(async () => await onSubmit(false))}
                >
                  Incremental enrichment run
                </PermissionedMenuItem>
                <PermissionedMenuItem
                  permission={{
                    v1: {
                      resource: "audience_schema",
                      grant: "update",
                    },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: model?.id,
                    },
                  }}
                  onClick={handleSubmit(async () => await onSubmit(true))}
                >
                  Full enrichment run
                </PermissionedMenuItem>
              </MenuList>
            </Menu>
          )}
          {enrichmentIsProcessing && (
            <PermissionedButton
              permission={{
                v1: {
                  resource: "audience_schema",
                  grant: "update",
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: model?.id,
                },
              }}
              size="lg"
              variant="secondary"
              onClick={() => setIsConfirmCancelOpen(true)}
            >
              Cancel
            </PermissionedButton>
          )}
          <Row alignItems="center" gap={2}>
            <Checkbox
              isChecked={sendEmail}
              onChange={(checked: ChangeEvent<HTMLInputElement>) => {
                setSendEmail(checked.target.checked);
              }}
            />
            <Text>Email me when enrichment is complete</Text>
          </Row>
        </Row>

        <Dialog
          isOpen={isConfirmInitializingOpen}
          variant="form"
          title="Initialize Match Booster"
          actions={
            <>
              <Button
                variant="secondary"
                onClick={() => setIsConfirmInitializingOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(async () => await onSubmit(true))}
              >
                Confirm
              </Button>
            </>
          }
          onClose={() => setIsConfirmInitializingOpen(false)}
        >
          <Paragraph>
            To initialize Match Booster, our system will automatically analyze
            your model to find the optimal configuration for your identity
            graph. This can take up to 72 hours once you confirm.
          </Paragraph>
        </Dialog>
      </>
    );
  };

  const unlockMatchBooster = () => {
    newPylonMessage("Hi, I'd like to unlock Match Booster!");
  };

  const trackLearnMoreClick = () => {
    analytics.track("Match Booster Learn More Clicked");
  };

  const matchboostingTabEnabled =
    appMatchBoosting || workspace?.organization?.entitlements?.matchbooster;

  if (!matchboostingTabEnabled) {
    return (
      <Column>
        <Column py={4} px={6}>
          <SplashComponent
            icon={null}
            eyebrow=""
            heading="Better match rates. Bigger audiences. Lower cost."
            description="You bring the target customer list. We'll bring the data to easily and affordably boost match rates across ad networks."
            actions={
              <>
                <UpsellButton onClick={unlockMatchBooster}>
                  Unlock Match Booster
                </UpsellButton>
                <LinkButton
                  href="https://hightouch.com/docs/match-booster/overview"
                  onClick={trackLearnMoreClick}
                >
                  Learn more
                </LinkButton>
              </>
            }
            visual={
              <Column
                sx={{
                  minWidth: "244",
                  maxWidth: "244",
                }}
              >
                <Box
                  as="img"
                  src={MatchRate}
                  pointerEvents="none" // to allow foreground content scroll
                />
              </Column>
            }
          />
        </Column>
        <Box
          as="img"
          pointerEvents="none" // to allow foreground content scroll
          position="fixed"
          bottom={0}
          right={0}
          src={bottomRightGradient}
        />
      </Column>
    );
  }

  if (isInitializing && enabled) {
    return (
      <MatchBoosterInitializing
        status={initializingStatus}
        sidebar={sidebar}
        modelId={model?.id}
        cancelInitializing={cancelInitializingMatchBooster}
      />
    );
  }

  return (
    <>
      <Row overflow="auto" height="100%" px={sidebar ? 6 : 0} mb={8}>
        <Column flex={1} minHeight={0} maxWidth="960px" gap={6}>
          {showMatchBoosterSyncBanner && (
            <Row>
              <Column width="100%" mb={2}>
                <Alert
                  variant="inline"
                  type="success"
                  title="Match Booster has finished enriching!"
                  message={
                    <Text>
                      {hasCreateSyncPermission ? (
                        <Link href={`/syncs/new?model=${model?.id}`}>
                          Add a sync
                        </Link>
                      ) : (
                        "Add a sync"
                      )}

                      {(model as ModelWithoutColumnsQuery["segments_by_pk"])
                        ?.syncs?.length
                        ? " with Match Booster or enable it for an existing sync to start increasing your match rates!"
                        : " with Match Booster to start increasing your match rates!"}
                    </Text>
                  }
                  onDismiss={() => {
                    setShowMatchBoosterSyncBanner(false);
                    try {
                      localStorage.setItem(mbBannerClearedKey, "true");
                    } catch (err) {
                      Sentry.captureException(err);
                    }
                  }}
                />
              </Column>
            </Row>
          )}

          {/* so we have some scroll before the sticky footer */}
          <Row flex={1} justifyContent="space-between">
            <Column gap={6} maxWidth="570px" pb={sidebar ? undefined : 12}>
              <Column gap={2}>
                <Row alignItems="center" gap={4} pt={sidebar ? "4" : undefined}>
                  <SectionHeading>Enable Match Booster</SectionHeading>
                  <PermissionedSwitch
                    permission={{
                      v1: {
                        resource: "audience_schema",
                        grant: "update",
                      },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: model?.id,
                      },
                    }}
                    isChecked={enabled}
                    onChange={async (value) => {
                      if (!value) {
                        if (model?.matchboosting_enabled) {
                          setShowConfirmDisable(true);
                        } else {
                          setEnabled(false);
                        }
                        return;
                      }

                      setEnabled(true);
                      if (controlledFields.length === 0) {
                        append([
                          { identifier: "email" },
                          { identifier: "phone" },
                          { identifier: "MAID" },
                        ]);
                      }
                    }}
                  />
                </Row>
                <Paragraph color="text.secondary" size="md">
                  Enhance this {segmentType} with additional identifiers from
                  Hightouch's cookieless identity graph to increase match rates
                  when syncing to supported destinations.{" "}
                  <Link href="https://hightouch.com/docs/customer-studio/match-booster#data-accuracy-and-security">
                    Read more about Match Booster here
                    <Box as="span" pl={1}>
                      <ExternalLinkIcon />
                    </Box>
                  </Link>
                </Paragraph>
              </Column>

              {enabled && (
                <>
                  <Column gap={2}>
                    <SectionHeading>
                      Which identifiers should be used to link your{" "}
                      {segmentType} to Hightouch's identity graph?
                    </SectionHeading>
                    <Paragraph color="text.secondary">
                      Select as many identifiers as possible to increase the
                      number of records that Hightouch is able to boost.{" "}
                    </Paragraph>
                  </Column>

                  <Box
                    display="grid"
                    gridTemplateColumns="1fr max-content 1fr max-content"
                    rowGap={4}
                    columnGap={2}
                    alignItems="flex-start"
                  >
                    <>
                      <Text
                        color="text.secondary"
                        fontWeight="semibold"
                        isTruncated
                        size="sm"
                        textTransform="uppercase"
                      >
                        Column Name
                      </Text>
                      <Box />
                      <Text
                        color="text.secondary"
                        fontWeight="semibold"
                        isTruncated
                        size="sm"
                        textTransform="uppercase"
                      >
                        Identifier type
                      </Text>
                      <Box />
                    </>

                    {controlledFields.map(({ id }, index) => (
                      <Fragment key={id}>
                        <Controller
                          name={`${formName}.${index}.columnName`}
                          render={({ field, fieldState: { error } }) => (
                            <Column>
                              <Select
                                width="auto"
                                isDisabled={!hasUpdateColumnPermission}
                                isOptionDisabled={(option) => {
                                  return Boolean(
                                    controlledFields.find((f) => {
                                      return (
                                        f.columnName === option.value &&
                                        f.id !== id
                                      );
                                    }),
                                  );
                                }}
                                options={columnOptions || []}
                                placeholder="Select a column..."
                                isInvalid={Boolean(error)}
                                onChange={async (columnName) => {
                                  field.onChange(columnName);
                                }}
                                value={field.value}
                              />

                              {error && (
                                <Text mt={1} color="text.danger">
                                  {error.message}
                                </Text>
                              )}
                            </Column>
                          )}
                        />

                        <Row height="32px" fontSize="20px" alignItems="center">
                          <ArrowRightIcon color="text.tertiary" />
                        </Row>

                        <Controller
                          name={`${formName}.${index}.identifier`}
                          render={({ field, fieldState: { error } }) => (
                            <Column>
                              <GroupedCombobox
                                optionGroups={identifiersGroupedOptions}
                                placeholder="Select an identifier..."
                                width="100%"
                                isDisabled={!hasUpdateColumnPermission}
                                value={field.value}
                                onChange={async (identifier) => {
                                  field.onChange(identifier);
                                }}
                                isInvalid={Boolean(error)}
                                isOptionDisabled={(option) => {
                                  return Boolean(
                                    controlledFields.find((f) => {
                                      return (
                                        f.identifier === option.value &&
                                        f.id !== id
                                      );
                                    }),
                                  );
                                }}
                              />
                              {error && (
                                <Text mt={1} color="text.danger">
                                  {error.message}
                                </Text>
                              )}
                            </Column>
                          )}
                        />

                        <PermissionedIconButton
                          permission={{
                            v1: {
                              resource: "audience_schema",
                              grant: "update",
                            },
                            v2: {
                              resource: "model",
                              grant: "can_update",
                              id: model?.id,
                            },
                          }}
                          aria-label="Remove mapping"
                          icon={CloseIcon}
                          onClick={() => {
                            remove(index);
                          }}
                        />
                      </Fragment>
                    ))}

                    <Select
                      width="auto"
                      isDisabled={!hasUpdateColumnPermission}
                      isOptionDisabled={(option) => {
                        return Boolean(
                          controlledFields.find((f) => {
                            return f.columnName === option.value;
                          }),
                        );
                      }}
                      options={columnOptions || []}
                      placeholder="Select a column..."
                      onChange={async (columnName) => {
                        append({
                          columnName,
                        });
                      }}
                      value={undefined}
                    />
                  </Box>

                  <Column gap={2}>
                    <SectionHeading>Enrichment schedule</SectionHeading>
                    <Column gap={4}>
                      <Paragraph color="text.secondary">
                        Configure your enrichment to run a schedule.
                      </Paragraph>

                      <ScheduleManager
                        resource="match_booster"
                        schedule={schedule}
                        setSchedule={setSchedule}
                        types={[ScheduleType.INTERVAL]}
                        includeStartAndEnd={false}
                        showTitle={false}
                        intervalOptions={[
                          {
                            label: "Hour(s)",
                            value: ScheduleIntervalUnit.HOUR,
                          },
                          { label: "Day(s)", value: ScheduleIntervalUnit.DAY },
                          {
                            label: "Week(s)",
                            value: ScheduleIntervalUnit.WEEK,
                          },
                        ]}
                      />
                    </Column>
                  </Column>
                </>
              )}
            </Column>
            {enabled && !sidebar && (
              <Column>
                <EnrichmentBadge
                  lastEnrichedStats={lastEnrichedStats}
                  segmentType={segmentType}
                  latestEnrichment={enrichmentStatus}
                  cancelling={cancelling}
                  enrichmentSchedule={model?.enrichment_schedule}
                  configs={matchboostingConfigs}
                  needToInitializeMB={needToInitializeMB}
                />
              </Column>
            )}
          </Row>
          {enabled && sidebar && (
            <Column pb={4}>
              <EnrichmentBadge
                lastEnrichedStats={lastEnrichedStats}
                segmentType={segmentType}
                latestEnrichment={enrichmentStatus}
                cancelling={cancelling}
                enrichmentSchedule={model?.enrichment_schedule}
                configs={matchboostingConfigs}
                needToInitializeMB={needToInitializeMB}
              />
            </Column>
          )}

          <ConfirmationDialog
            confirmButtonText="Cancel"
            isOpen={isConfirmCancelOpen}
            title="Cancel Match Booster enrichment?"
            variant="danger"
            onClose={() => setIsConfirmCancelOpen(false)}
            onConfirm={stopEnrichment}
            cancelButtonText="Never mind"
          >
            <Paragraph>
              Stopping the enrichment process will require the process to start
              again from the beginning.
            </Paragraph>
          </ConfirmationDialog>

          <ConfirmationDialog
            confirmButtonText="Disable"
            isOpen={showConfirmDisable}
            title="Disable Match Booster"
            variant="danger"
            onClose={() => setShowConfirmDisable(false)}
            onConfirm={disableMatchbooster}
          >
            <Paragraph>
              Do you want to disable Match Booster on this {segmentType}?
            </Paragraph>
          </ConfirmationDialog>
          {enabled &&
            !needToInitializeMB &&
            allMatchboostingStats &&
            Boolean(allMatchboostingStats.length) && (
              <RunsTable allStats={allMatchboostingStats} sidebar={sidebar} />
            )}
        </Column>
      </Row>

      {enabled && !sidebar && <ActionBar>{actionContent()}</ActionBar>}
      {enabled && sidebar && (
        <Row bg="white" borderTop="1px" borderColor="base.border" p={6}>
          {actionContent()}
        </Row>
      )}
    </>
  );
};

export const EnrichmentBadge = ({
  lastEnrichedStats,
  cancelling,
  latestEnrichment,
  segmentType,
  enrichmentSchedule,
  configs,
  needToInitializeMB,
}: Omit<EnrichmentBadgeProps, "hasEnrichedBefore"> & {
  needToInitializeMB?: boolean;
}) => {
  const enrichedStats = lastEnrichedStats;
  const hasEnrichedBefore = Boolean(enrichedStats);

  let text: string | undefined,
    variant: StatusBadgeProps["variant"] | undefined;

  // Pending = not running now and hasn't finishing enriching before
  const isPending =
    needToInitializeMB ||
    (!hasEnrichedBefore &&
      latestEnrichment?.status !== EnrichmentStatus.Processing &&
      latestEnrichment?.status !== EnrichmentStatus.Failed);

  if (isPending) {
    text = "Pending";
    variant = "inactive";
  }
  // Manually show canceling when the backend request is running.
  else if (
    cancelling ||
    latestEnrichment?.status === EnrichmentStatus.Cancelling
  ) {
    text = "Cancelling";
    variant = "inactive";
  } else if (latestEnrichment?.status === EnrichmentStatus.Cancelled) {
    text = "Cancelled";
    variant = "inactive";
  } else if (latestEnrichment?.status === EnrichmentStatus.Failed) {
    text = "Failed";
    variant = "error";
  } else if (
    latestEnrichment?.status === EnrichmentStatus.Success &&
    lastEnrichedStats
  ) {
    // leave variant as undefined - this indicates we are using the boosted badge.
    text = "Boosted!";
  } else if (latestEnrichment?.status === EnrichmentStatus.Processing) {
    text = "Processing";
    variant = "processing";
  }

  return (
    <Row
      bg="white"
      gap={4}
      align="flex-start"
      flexDir="column"
      minWidth="280px"
      maxWidth="280px"
    >
      <Column gap={1}>
        <Row>
          <Text fontWeight="semibold" color="text.secondary" size="sm">
            STATUS:
          </Text>
        </Row>
        <Row>
          {variant ? (
            <StatusBadge variant={variant}>{text}</StatusBadge>
          ) : (
            <Box as="img" src={Status} />
          )}
        </Row>
        {isPending && (
          <Paragraph color="text.secondary" size="sm">
            Save enrichment configurations to{" "}
            {needToInitializeMB
              ? "initialize Match Booster. The one-time calibration can take up to 72 hours."
              : "run Match Booster. This can take up to 24 hours to process."}
          </Paragraph>
        )}
      </Column>

      {!needToInitializeMB && (
        <LastEnrichedDetails
          hasEnrichedBefore={hasEnrichedBefore}
          lastEnrichedStats={lastEnrichedStats}
          latestEnrichment={latestEnrichment}
          segmentType={segmentType}
          enrichmentSchedule={enrichmentSchedule}
          configs={configs}
        />
      )}
    </Row>
  );
};

interface EnrichmentBadgeProps {
  hasEnrichedBefore: boolean;
  lastEnrichedStats:
    | MatchboostingStatsQuery["matchboosting_stats"][0]
    | undefined
    | null;
  latestEnrichment:
    | EnrichmentStatusQuery["enrichmentStatus"]
    | undefined
    | null;
  cancelling: boolean;
  segmentType: string;
  enrichmentSchedule: IntervalSchedule | undefined;
  configs: MatchboostingConfigQuery | undefined;
}

const LastEnrichedDetails = ({
  segmentType,
  hasEnrichedBefore,
  lastEnrichedStats,
  latestEnrichment,
  enrichmentSchedule,
}: Omit<EnrichmentBadgeProps, "cancelling">) => {
  const latestEnrichmentStatus = latestEnrichment?.status;

  if (!hasEnrichedBefore) {
    if (!latestEnrichment) {
      return null;
    }

    if (latestEnrichmentStatus === EnrichmentStatus.Processing) {
      return (
        <Text size="sm" color="text.secondary">
          Currently enriching this {segmentType}. Processing time can take up to
          a few hours. Please check back to see if enrichment is complete.
        </Text>
      );
    } else if (latestEnrichmentStatus === EnrichmentStatus.Failed) {
      return (
        <Text size="sm" color="text.secondary">
          Something went wrong during enrichment. Try again.
        </Text>
      );
    }
  }

  const enrichedDurationMs = lastEnrichedStats?.enriched_duration_ms;
  const lastEnrichedAt = lastEnrichedStats?.last_enriched_at;

  const nextScheduled = getNextScheduledEnrichment(
    enrichmentSchedule,
    lastEnrichedAt,
  );

  return (
    <Column gap={4}>
      <Column>
        <Text
          fontWeight="semibold"
          size="sm"
          color="text.secondary"
          textTransform="uppercase"
        >
          Enrichment Schedule
        </Text>
        <Text>{getEnrichmentSchedule(enrichmentSchedule)}</Text>
      </Column>
      {latestEnrichmentStatus !== EnrichmentStatus.Processing &&
        latestEnrichmentStatus !== EnrichmentStatus.Cancelling && (
          <>
            <Column>
              <Text
                fontWeight="semibold"
                size="sm"
                color="text.secondary"
                textTransform="uppercase"
              >
                Last Run At
              </Text>
              <Text>
                {lastEnrichedAt &&
                  formatDatetime(lastEnrichedAt)?.replace("at", "@")}
              </Text>
              <Text>
                {enrichedDurationMs &&
                  (formatDurationDisplay({
                    start: 0,
                    end: enrichedDurationMs,
                  }) ||
                    "1 min")}
              </Text>
            </Column>
            <Column>
              <Text
                fontWeight="semibold"
                size="sm"
                color="text.secondary"
                textTransform="uppercase"
              >
                Next Scheduled
              </Text>
              <Text>
                {nextScheduled &&
                  formatDatetime(nextScheduled.toISOString())?.replace(
                    "at",
                    "@",
                  )}
              </Text>
            </Column>
          </>
        )}
      {/** Show the started at during currently processing/cancelling enrichments to give better feedback */}
      {(latestEnrichment?.status === EnrichmentStatus.Processing ||
        latestEnrichment?.status === EnrichmentStatus.Cancelling) && (
        <Column>
          <Text
            fontWeight="semibold"
            size="sm"
            color="text.secondary"
            textTransform="uppercase"
          >
            Started At
          </Text>
          <Text>
            {latestEnrichment?.created_at &&
              formatDatetime(latestEnrichment?.created_at)?.replace("at", "@")}
          </Text>
        </Column>
      )}
    </Column>
  );
};
