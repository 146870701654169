import { FC, useMemo } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Box,
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  Combobox,
  FormField,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  TextInput,
  useToast,
  WizardDrawerStep,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import orderBy from "lodash/orderBy";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "src/router";
import * as Yup from "yup";

import { WizardDrawer } from "src/components/drawer";
import {
  SyncableColumn,
  TraitDefinitionsConstraint,
  TraitDefinitionsInsertInput,
  useCreateTraitMutation,
  useParentModelsQuery,
} from "src/graphql";
import { ParentModel } from "src/pages/audiences/types";

import { CalculationSummary } from "./calculation-summary";
import { TraitCalculationForm } from "./trait-calculation-form";
import {
  CALCULATION_METHODS,
  CalculationMethod,
  defaultTypeByCalculationMethod,
  formatTraitConfig,
  validateConfig,
} from "./utils";

type Props = {
  isTemplate: boolean;
};

export type FormProps = {
  name: string;
  description: string;
  parent_model_id: string;
  relationship_id: string;
  calculation_method: CalculationMethod;
  type: TraitType;
  config: TraitConfig;
  is_template: boolean;
};

export const validationSchema = Yup.lazy<TraitDefinitionsInsertInput>(
  (trait) => {
    return Yup.object()
      .shape({
        name: Yup.string().required("Name is required"),
        description: Yup.string().nullable(),
        parent_model_id: Yup.string().required("Parent model is required"),
        relationship_id:
          trait.type === TraitType.Formula
            ? Yup.string().nullable()
            : Yup.string().required("Related model or event is required"),
        type: Yup.string().required("Related model or event is required"),
        config: Yup.object().required(),
        trait_template_id: Yup.string().nullable(),
        is_template: Yup.boolean().required(),
      })
      .required();
  },
);

export const CreateTrait: FC<Readonly<Props>> = ({ isTemplate }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { appEnableFormulaTraits } = useFlags();

  const createTrait = useCreateTraitMutation();

  const form = useForm<FormProps>({
    defaultValues: { is_template: isTemplate },
    resolver: yupResolver(validationSchema),
  });
  const { control, handleSubmit, getValues, reset, watch } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const parentModelId = watch("parent_model_id");
  const calculationMethod = watch("calculation_method");
  const relationshipId = watch("relationship_id");
  const type = watch("type");
  const config = watch("config");
  const name = watch("name");

  const { data: parentModelsData, isLoading: isParentModelsLoading } =
    useParentModelsQuery({
      filters: {
        is_schema: { _eq: true },
        _not: { event: {} },
        primary_key: { _is_null: false },
      },
    });

  const parentModel:
    | (ParentModel & { syncable_columns: SyncableColumn[] })
    | undefined = useMemo(() => {
    return parentModelsData?.segments?.find(({ id }) => id === parentModelId);
  }, [parentModelsData?.segments, parentModelId]);

  const relationship = useMemo(() => {
    return parentModel?.relationships.find(({ id }) => id === relationshipId);
  }, [parentModel, relationshipId]);

  const onClose = () => {
    isTemplate ? navigate("/traits/templates") : navigate("/traits/active");
  };

  const onSubmit = async (formData: FormProps) => {
    try {
      const newTrait = await createTrait.mutateAsync({
        input: {
          name: formData.name,
          description: formData.description,
          parent_model_id: formData.parent_model_id,
          relationship_id: formData.relationship_id,
          type: formData.type,
          config: formatTraitConfig(
            formData.type,
            formData.config,
            parentModel,
          ),
          is_template: isTemplate,
        },
      });

      toast({
        id: "create-trait",
        title: "Trait was created successfully",
        variant: "success",
      });

      const newTraitId = newTrait.insert_trait_definitions_one?.id;
      navigate(`/traits/${isTemplate ? "templates" : "active"}/${newTraitId}`);
    } catch (error) {
      toast({
        id: "create-trait",
        title: "Trait failed to be created",
        message: error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? `There is an existing ${isTemplate ? "template" : "trait"} named "${
              formData.name
            }" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const parentModelOptions =
    orderBy(
      parentModelsData?.segments.map((segment) => {
        const relationshipCount = segment.relationships.map(
          (relationship) => relationship.to_model,
        ).length;

        return {
          label: segment.name,
          description:
            relationshipCount === 0 ? "No related or event models" : undefined,
          value: segment.id,
          logo: segment.connection?.definition.icon || "",
          isDisabled: relationshipCount === 0,
        };
      }),
      ["isDisabled", "label"],
      ["asc", "asc"],
    ) ?? [];

  const handleChangeCalculationMethod = (
    calculationMethod: CalculationMethod,
  ) => {
    const values = getValues();

    reset({
      ...values,
      calculation_method: calculationMethod,
      type: defaultTypeByCalculationMethod[calculationMethod],
      config: {},
    });
  };

  const ParentModelSelect = parentModelOptions.length > 10 ? Combobox : Select;

  const showParentModelDisabledWarning =
    parentModel &&
    calculationMethod !== CalculationMethod.Formula &&
    parentModel.relationships.length === 0;

  const steps: WizardDrawerStep[] = [
    {
      label: "Method",
      continueLabel: "Continue to calculation",
      isDisabled:
        !parentModel || !calculationMethod || showParentModelDisabledWarning,
      tooltip: showParentModelDisabledWarning
        ? "Please select a parent model with at least one relationship"
        : undefined,
      render: () => (
        <Column gap={6} width="100%">
          <Column gap={2}>
            <Text fontWeight="medium">Create a new trait</Text>
            <Text>
              A trait lets you perform a calculation on a column of a
              related/event model. It acts as a new column on a parent model and
              can be utilized in associated Audiences.{" "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/traits`}
                isExternal
              >
                Learn more.
              </Link>
            </Text>
          </Column>
          <FormField
            label="Parent model"
            tip="This trait will be available in the audiences of this parent model."
          >
            <Controller
              control={control}
              name="parent_model_id"
              render={({ field }) => (
                <ParentModelSelect
                  isDisabled={isParentModelsLoading}
                  isLoading={isParentModelsLoading}
                  options={parentModelOptions}
                  optionAccessory={(option: any) => ({
                    type: "image",
                    url: option.logo,
                  })}
                  placeholder="Select a parent model"
                  value={field.value}
                  width="100%"
                  onChange={field.onChange}
                />
              )}
            />
          </FormField>
          <FormField label="Calculation method">
            <Controller
              control={control}
              name="calculation_method"
              render={({ field }) => (
                <Box
                  display="grid"
                  gridTemplateColumns="60% 40%"
                  gap={1}
                  width="100%"
                >
                  <RadioGroup
                    value={field.value}
                    onChange={(value) =>
                      handleChangeCalculationMethod(value as CalculationMethod)
                    }
                  >
                    {Object.values(CALCULATION_METHODS)
                      .filter(
                        (calculationMethod) =>
                          appEnableFormulaTraits ||
                          calculationMethod.value !== CalculationMethod.Formula,
                      )
                      .map(({ label, value, description }) => (
                        <Radio
                          key={label}
                          label={label}
                          description={description}
                          value={value}
                        />
                      ))}
                  </RadioGroup>
                  {field.value && (
                    <Column bg="base.lightBackground" p={4}>
                      <Text color="text.secondary" fontWeight="medium">
                        Examples:
                      </Text>

                      <ChakraUnorderedList>
                        {CALCULATION_METHODS[field.value]?.examples.map(
                          (tip: string) => (
                            <ChakraListItem key={tip}>{tip}</ChakraListItem>
                          ),
                        )}
                      </ChakraUnorderedList>
                    </Column>
                  )}
                </Box>
              )}
            />
          </FormField>
        </Column>
      ),
    },
    {
      label: "Calculation",
      continueLabel: "Continue to finalize",
      isDisabled:
        (type !== TraitType.Formula && !relationshipId) ||
        !validateConfig(type, config),
      render: () => (
        <>
          {parentModel && (
            <FormProvider {...form}>
              <TraitCalculationForm
                parentModel={parentModel}
                hideCalculationMethod
              />
            </FormProvider>
          )}
        </>
      ),
    },
    {
      label: "Finalize",
      continueLabel: isTemplate ? "Save template" : "Create trait",
      isDisabled: !name,
      render: () => (
        <Column gap={6}>
          <Column gap={6}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <FormField
                  label="Name"
                  tip="Shows up in the audience query builder when selecting from a list of traits."
                >
                  <TextInput
                    placeholder="Enter a name"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <FormField isOptional label="Description">
                  <Textarea
                    placeholder="Enter a description"
                    width="100%"
                    {...field}
                  />
                </FormField>
              )}
            />
          </Column>

          <Column>
            <Text fontWeight="medium" mb={1}>
              Calculation summary
            </Text>
            <Box
              borderRadius="6px"
              border="1px solid"
              borderColor="base.border"
              p={4}
            >
              <CalculationSummary
                type={type}
                config={config}
                model={relationship?.to_model}
              />
            </Box>
          </Column>
        </Column>
      ),
    },
  ];

  return (
    <WizardDrawer
      isOpen
      steps={steps}
      title={isTemplate ? "New template" : "New trait"}
      size="xl"
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};
