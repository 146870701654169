import { Navigate, Route, Routes, useOutletContext } from "src/router";

import { SourceLinking } from "./source/linking";
import { SourceGrants } from "./source/grants";
import { Sources } from "./sources";
import { SourceSyncLog } from "./source/sync-log";
import { SourceConfiguration } from "./source/configuration";
import { OutletContext, Source } from "./source";
import { CreateSource } from "./create-source";
import { DemoSourceConfiguration } from "./source/demo-configuration";
import { PermissionedRoute } from "src/components/router/permissioned-route";

export const SourcesRouter = () => {
  return (
    <Routes>
      <Route index element={<Sources />} />
      <Route
        path="new"
        element={
          <PermissionedRoute
            permission={{ v2: { resource: "source", grant: "can_create" } }}
            redirect="/sources"
          >
            <CreateSource />
          </PermissionedRoute>
        }
      />
      <Route path=":id/*" element={<Source />}>
        <Route index element={<Navigate to="configuration" replace />} />
        <Route path="configuration" element={<ConfigurationRouter />} />
        <Route path="linking" element={<SourceLinking />} />
        <Route path="grants" element={<SourceGrants />} />
        <Route path="sync-logs" element={<SourceSyncLog />} />
      </Route>
    </Routes>
  );
};

const ConfigurationRouter = () => {
  const { source } = useOutletContext<OutletContext>();
  if (source.definition.isSampleDataSource) {
    return <DemoSourceConfiguration />;
  }
  return <SourceConfiguration />;
};
