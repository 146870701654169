import {
  OrganizationParentModelsQuery,
  useOrganizationParentModelsQuery,
  useUpdateUserGroupGrantsMutation,
  useUpdateUserGroupSubsetGrantsMutation,
  useUserGroupGrantsByTypeQuery,
  useUserGroupSubsetGrantsQuery,
} from "src/graphql";
import {
  AdditionalConfigurationProps,
  WorkspaceResourceGrantsForm,
} from "./components/form";
import { useParams } from "src/router";
import { useMemo } from "react";
import { Box, Column, Text, FormField, MultiSelect } from "@hightouchio/ui";
import { Cell } from "src/ui/table/cells";

export const ParentModelGrants = () => {
  const { workspaceId, roleId } = useParams();

  const { data: definitions } = useOrganizationParentModelsQuery(
    { workspaceId: workspaceId ?? "" },
    {
      enabled: Boolean(workspaceId),
      select: (data) => data.getOrganizationParentModels.parentModels,
      suspense: true,
    },
  );

  const { data: parentModelGrants } = useUserGroupGrantsByTypeQuery(
    {
      workspaceId: workspaceId ?? "",
      userGroupId: roleId ?? "",
      resourceType: "parent_model",
    },
    {
      enabled: Boolean(workspaceId && roleId),
      select: (data) => data.user_group_grants,
      suspense: true,
    },
  );

  const { data: subsetGrants } = useUserGroupSubsetGrantsQuery(
    {
      workspaceId: workspaceId ?? "",
      userGroupId: roleId ?? "",
    },
    {
      enabled: Boolean(workspaceId && roleId),
      select: (data) => data.user_group_subset_grants,
      suspense: true,
    },
  );

  const { mutateAsync: updateGrants } = useUpdateUserGroupGrantsMutation({
    onSuccess: () => {},
  });
  const { mutateAsync: updateSubsetGrants } =
    useUpdateUserGroupSubsetGrantsMutation();

  const grants = useMemo(() => {
    if (!parentModelGrants || !subsetGrants) {
      return null;
    }

    // Create a map of subsets to their parent model
    const subsetsToParentModel = new Map<string, string>();
    for (const definition of definitions ?? []) {
      for (const subset of definition.subsets) {
        subsetsToParentModel.set(subset.id, definition.id);
      }
    }

    return parentModelGrants.map((grant) => {
      const relatedSubsetGrants = subsetGrants.filter(
        (g) =>
          subsetsToParentModel.get(g.resource_id) ===
            String(grant.resource_id) && g.can_sync,
      );

      return {
        ...grant,
        additional_data: {
          subsets: relatedSubsetGrants.map((g) => g.resource_id),
        },
      };
    });
  }, [parentModelGrants, subsetGrants, definitions]);

  return (
    <WorkspaceResourceGrantsForm
      grants={grants?.map((g) => ({ ...g })) ?? []}
      resourceType="parent_model"
      resources={
        definitions?.map((d) => {
          return {
            id: d.id,
            name: d.name,
            definition: d.source.definition,
            additionalConfiguration: d.subsets.length
              ? ({ value, onChange }: AdditionalConfigurationProps) => {
                  return (
                    <SubsetAdditionalConfiguration
                      definition={d}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }
              : undefined,
          };
        }) ?? []
      }
      onSubmit={async ({ objects, additionalData }) => {
        if (workspaceId === undefined || roleId === undefined) {
          return;
        }

        const subsetObjects: {
          subset_id: string;
          workspace_id: string;
          user_group_id: string;
          can_sync: boolean;
          can_draft: boolean;
        }[] = [];

        for (const definition of definitions ?? []) {
          const selectedSubsets = additionalData[definition.id]?.subsets ?? [];
          for (const subset of definition.subsets) {
            const hasAccess = selectedSubsets?.includes(subset.id);
            subsetObjects.push({
              subset_id: subset.id,
              workspace_id: workspaceId,
              user_group_id: roleId,
              can_sync: hasAccess,
              can_draft: hasAccess,
            });
          }
        }

        await updateGrants({
          objects: objects.map((o) => ({ ...o, can_draft: o.can_sync })),
        });
        if (subsetObjects.length) {
          await updateSubsetGrants({
            objects: subsetObjects,
          });
        }
      }}
    />
  );
};

export const SubsetAdditionalConfiguration = ({
  definition,
  value,
  onChange,
}: {
  definition: OrganizationParentModelsQuery["getOrganizationParentModels"]["parentModels"][0];
} & AdditionalConfigurationProps) => {
  const groupedOptions = useMemo(() => {
    const groupedOptionsMap: {
      [key: string]: { label: string; value: string }[];
    } = {};

    for (const subset of definition.subsets) {
      const groupName = subset.group.name;
      if (!groupedOptionsMap[groupName]) {
        groupedOptionsMap[groupName] = [];
      }
      groupedOptionsMap[groupName]?.push({
        label: subset.name,
        value: subset.id,
      });
    }

    return Object.keys(groupedOptionsMap).map((groupName) => ({
      label: groupName,
      options: groupedOptionsMap[groupName] ?? [],
    }));
  }, [definition]);

  return (
    <>
      {groupedOptions.map((group) => {
        const values =
          value?.subsets?.filter((v: string) => {
            return group.options.some((option) => option.value === v);
          }) ?? [];

        const otherValues =
          value?.subsets?.filter((v: string) => !values?.includes(v)) ?? [];

        const onSelectChange = (values: string[]) => {
          onChange({
            ...value,
            subsets: [...otherValues, ...values],
          });
        };

        return (
          <Box key={group.label} display="contents">
            <Cell py={4} pl={16} bg="base.lightBackground">
              <Text fontWeight="medium">{group.label}</Text>
            </Cell>
            <Cell
              gridColumnStart={2}
              gridColumnEnd={-1}
              py={4}
              bg="base.lightBackground"
            >
              <Column gap={2}>
                <FormField
                  label="Apply permissions to the following subsets"
                  description="If left blank, the subset category will not be available to this role."
                >
                  <MultiSelect
                    options={group.options}
                    placeholder="Select one or more subsets..."
                    value={values}
                    onChange={onSelectChange}
                  />
                </FormField>
              </Column>
            </Cell>
          </Box>
        );
      })}
    </>
  );
};
