import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";

import { useUser } from "./user-context";

const clientSideID =
  import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID?.toString() ?? "";

interface ContainerProps {
  children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => <>{children}</>;

interface FeatureFlagProviderProps {
  children: ReactNode;
}

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({
  children,
}) => {
  const { user, workspace } = useUser();

  const config = useMemo(() => {
    if (user) {
      return {
        key: `user-${user.id.toString()}`,
        name: user.name,
        email: user.email,
        custom: {
          workspaceId: workspace?.id,
          organizationId: user.organization?.id ?? -1,
        },
      };
    }
    return { anonymous: true };
  }, [user?.name, user?.email, user?.id, workspace?.id]);

  const Provider = useMemo(
    () =>
      withLDProvider<ContainerProps>({
        clientSideID,
        user: config,
      })(Container),
    [config],
  );

  return (
    <Provider>
      <FeatureFlagLoader>{children}</FeatureFlagLoader>
    </Provider>
  );
};

interface FeatureFlagLoaderProps {
  children: ReactNode;
}

const FeatureFlagLoader: FC<FeatureFlagLoaderProps> = ({ children }) => {
  const launchDarkly = useLDClient();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (launchDarkly) {
      launchDarkly.waitUntilReady().then(() => setReady(true));
    }
  }, [launchDarkly]);

  if (ready) {
    return <>{children}</>;
  }

  return null;
};
