import {
  ConditionFriendlyNames,
  MonitorConditionTypes,
  MonitoredResourceType,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  ExternalLinkIcon,
  Alert,
  AlertProps,
  CodeSnippet,
} from "@hightouchio/ui";
import * as time from "src/utils/time";
import {
  useMonitoredResourceStatusQuery,
  useRenderedConditionChangesQuery,
} from "src/graphql";
import {
  syncHealthOrUnknown,
  SyncHealthStatus,
  UnknownSyncHealth,
} from "src/utils/syncs";
import { enumOrFallback } from "src/types/utils";

const monitorStatusToAlertType: {
  [key in MonitorStatus]: AlertProps["type"];
} = {
  [MonitorStatus.Healthy]: "success",
  [MonitorStatus.Warning]: "warning",
  [MonitorStatus.Unhealthy]: "error",
  [MonitorStatus.Disabled]: "subtle",
} as const;

export const SyncHealthAlertsForSync = ({
  syncId,
  resourceType,
  onEdit,
}: {
  resourceType: MonitoredResourceType.EventSync | MonitoredResourceType.Sync;
  syncId: string;
  onEdit: () => void;
}) => {
  const statuses = useMonitoredResourceStatusQuery(
    {
      resourceId: syncId.toString(),
      resourceType,
      limit: 1,
    },
    { select: (data) => data.resource_monitor_statuses },
  );

  const latestStatus = statuses.data?.[0];

  const conditionChangeIds = latestStatus?.condition_changes;

  const { data: renderedConditionChanges } = useRenderedConditionChangesQuery(
    {
      resourceId: syncId,
      resourceType,
      conditionChangeIds: conditionChangeIds,
    },
    {
      enabled: !!conditionChangeIds,
      select: (data) => data.renderedMonitorConditionStatuses,
    },
  );
  const health = syncHealthOrUnknown(latestStatus?.new_status);

  if (
    !latestStatus ||
    !renderedConditionChanges ||
    !renderedConditionChanges.length ||
    health === UnknownSyncHealth
  ) {
    return null;
  }

  return (
    <SyncHealthAlerts
      health={health}
      healthChangedAt={latestStatus.transitioned_at}
      statuses={renderedConditionChanges.map((change) => ({
        ...change,
        conditionType: enumOrFallback(
          MonitorConditionTypes,
          MonitorConditionTypes.SyncThroughput,
          false,
        )(change.conditionType),
        status: enumOrFallback(
          MonitorStatus,
          MonitorStatus.Unhealthy,
          false,
        )(change.status),
      }))}
      onEdit={onEdit}
    />
  );
};

export const SyncHealthAlerts = ({
  health,
  healthChangedAt,
  statuses: renderedStatuses,
  onEdit,
}: {
  health: SyncHealthStatus;
  healthChangedAt: string;
  statuses: {
    id: string;
    text: string;
    metadata?: { error?: string | null } | null;
    conditionType: MonitorConditionTypes;
    status: MonitorStatus;
    eventTime: string;
  }[];
  onEdit?: () => void;
}) => (
  <Box display="flex" flexDirection="column" rowGap={6}>
    {health === MonitorStatus.Healthy ? (
      <Alert
        type={monitorStatusToAlertType[health]}
        variant="inline"
        title="No active alerts"
        contextMessage={`Since ${time.formatDate(healthChangedAt)}`}
      />
    ) : (
      renderedStatuses.map((status) => (
        <Alert
          key={status.id}
          type={monitorStatusToAlertType[status.status]}
          variant="inline"
          message={
            <Box>
              {status.text}
              {status.metadata?.error && (
                <CodeSnippet
                  code={status.metadata?.error}
                  mt={2}
                  hasCopyButton={false}
                />
              )}
            </Box>
          }
          title={ConditionFriendlyNames[status.conditionType]}
          contextMessage={
            <Box display="flex" alignItems="center">
              {time.formatFriendlyDistanceToNow(status.eventTime)}{" "}
              {onEdit && (
                <>
                  <Box
                    borderLeft="1px solid"
                    borderColor="base.borderColor"
                    h={4}
                    mx={2}
                  ></Box>
                  <Box cursor="pointer" onClick={onEdit}>
                    Edit alert <ExternalLinkIcon />
                  </Box>
                </>
              )}
            </Box>
          }
        />
      ))
    )}
  </Box>
);
