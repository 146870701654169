import {
  Column,
  Row,
  Text,
  MonitorIcon,
  ClickIcon,
  IdentityIcon,
  MobilePhoneIcon,
  AudienceIcon,
  BidirectionalArrowIcon,
} from "@hightouchio/ui";
import { capitalize } from "lodash";

import { EventPayload } from "src/events/types";
import { EventSchemaEventType } from "src/graphql";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export const EventDisplay = ({
  eventType,
  title,
}: {
  eventType: EventSchemaEventType;
  title?: string;
}) => {
  const Icon = eventTypeIconMap[eventType];

  return (
    <Row align="center" gap={4} flex={1} minW={0}>
      <Column sx={{ svg: { h: 6, w: 6 } }}>
        <Icon color="text.secondary" />
      </Column>
      <Column overflow="auto">
        {title ? (
          <>
            <TextWithTooltip fontWeight="medium">{title}</TextWithTooltip>
            <Text color="text.secondary">{capitalize(eventType)}</Text>
          </>
        ) : (
          <TextWithTooltip fontWeight="medium">
            {capitalize(eventType)}
          </TextWithTooltip>
        )}
      </Column>
    </Row>
  );
};

export const eventTypeIconMap = {
  [EventSchemaEventType.Track]: ClickIcon,
  [EventSchemaEventType.Page]: MonitorIcon,
  [EventSchemaEventType.Screen]: MobilePhoneIcon,
  [EventSchemaEventType.Identify]: IdentityIcon,
  [EventSchemaEventType.Group]: AudienceIcon,
  [EventSchemaEventType.Alias]: BidirectionalArrowIcon,
} as const;

export const getEventTitle = (payload: EventPayload): string => {
  switch (payload.type) {
    case "track": {
      return payload.event;
    }
    case "page":
    case "screen": {
      return payload.name || payload.category || "";
    }
    case "identify": {
      return (
        payload.traits?.name ||
        payload.traits?.email ||
        payload.traits?.phone ||
        payload.userId
      );
    }
    case "group": {
      return payload.traits?.company_name || payload.groupId;
    }
    case "alias": {
      return "";
    }
  }
};
