import { Text, Row, Column } from "@hightouchio/ui";
import { useOutletContext } from "src/router";
import { SidebarForm } from "src/components/page";
import { OutletContext } from ".";

export const DemoSourceConfiguration = () => {
  const { source } = useOutletContext<OutletContext>();

  return (
    <Row align="flex-start" gap={8}>
      <Column gap={4}>
        <Text size="lg" fontWeight="semibold">
          {source.definition?.name}
        </Text>
        <Text color="text.secondary">{source.definition?.longPitch}</Text>
      </Column>
      <SidebarForm
        docsUrl={source.definition?.docs ?? ""}
        name={source.definition?.name ?? ""}
      />
    </Row>
  );
};
