import { FC, useCallback, useMemo, useState } from "react";

import {
  ButtonGroup,
  DeleteIcon,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Row,
  SearchInput,
  TagIcon,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import destinationPlaceholder from "src/assets/placeholders/destination.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { labelFilter } from "src/components/folders/filter-helpers";
import {
  createdByFilterConfig,
  destinationTypeFilterConfig,
  Filters,
  labelFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { PageSidebar } from "src/components/layout/page-sidebar";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationsBoolExp,
  DestinationsOrderBy,
  useDeleteDestinationsMutation,
  useDestinationFiltersQuery,
  useDestinationsQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import {
  OrderBy,
  PageTable,
  SortOption,
  TableColumn,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof DestinationsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof DestinationsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "type", direction: OrderBy.Asc, label: "Type" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const columns: TableColumn[] = [
  {
    name: "Name",
    cell: ({ name, definition }) => {
      return (
        <Text isTruncated fontWeight="medium">
          {name ?? definition?.name ?? "Private destination"}
        </Text>
      );
    },
  },
  {
    name: "Type",
    cell: ({ definition }) => {
      return (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon name={definition?.name} src={definition?.icon} />
          <Text isTruncated fontWeight="medium">
            {definition?.name ?? "Private destination"}
          </Text>
        </Row>
      );
    },
  },
  {
    ...LastUpdatedColumn,
    breakpoint: "md",
  },
  {
    name: "Labels",
    cell: ({ tags }) => {
      if (isEmpty(tags)) {
        return "--";
      }
      return <Labels labels={tags} />;
    },
    breakpoint: "md",
  },
];

export const Destinations: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [addingLabels, setAddingLabels] = useState(false);

  const { mutateAsync: updateDestination } = useUpdateDestinationV2Mutation();
  const { mutateAsync: bulkDelete } = useDeleteDestinationsMutation();

  const orderBy = useTableSort<DestinationsOrderBy>(initialSort, sortOptions);

  const buildHasuraFilters = () => {
    const initial: DestinationsBoolExp = {
      _and: [
        {
          type: !filters.destination?.isAllSelected
            ? { _in: filters.destination?.selected.map((filter) => filter.id) }
            : {},
        },
        labelFilter(filters.label),
        !filters.created?.isAllSelected
          ? {
              _or: [
                {
                  created_by: {
                    _in: filters.created?.selected.map((f) => f.id),
                  },
                },
                {
                  created_by: { _is_null: true },
                },
              ],
            }
          : {},
      ],
    };
    if (search) {
      initial._and!.push({ name: { _ilike: `%${search}%` } });
    }
    return initial;
  };

  const { data: allDestinations, isLoading: filtersLoading } =
    useDestinationFiltersQuery(undefined, {
      select: (data) => data.destinations,
    });

  const filterDefinitions = useMemo(() => {
    return {
      viewKey: "destination",
      loading: filtersLoading,
      filters: {
        destination: {
          options: destinationTypeFilterConfig(allDestinations || []),
          title: "Type",
        },
        created: {
          options: createdByFilterConfig(allDestinations || []),
          title: "Created by",
        },
        label: {
          options: labelFilterConfig(allDestinations || []),
          title: "Labels",
        },
      },
    };
  }, [allDestinations]);

  const {
    result: { state: filters, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    return buildHasuraFilters();
  }, [search, filters]);

  const {
    data: destinations,
    error: destinationsError,
    isLoading,
  } = useDestinationsQuery(
    {
      filters: hasuraFilters,
      orderBy,
    },
    { select: (data) => data.destinations },
  );

  const bulkDeleteDestinations = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("destination", count);

    try {
      await bulkDelete({
        ids: selectedRows.map(String),
        stringIds: selectedRows.map(String),
      });

      toast({
        id: "bulk-delete-destinations",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-destinations",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No destinations found",
      error: "Destinations failed to load, please try again.",
    }),
    [],
  );

  const { data: entitlementsData } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a destination, upgrade your plan.";

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/destinations/${id}`, navigate, event),
    [navigate],
  );

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "destination",
          grant: "can_create",
        },
      }}
    >
      <Page
        sidebar={
          <PageSidebar
            header={
              <SearchInput
                placeholder="Search all destinations..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            }
          >
            <Filters
              clearFilters={clearFilters}
              createView={createView}
              creatingView={creatingView}
              deleteView={deleteView}
              filters={filterData}
              resetFilters={resetViewFilters}
              resource="destination"
              selectView={selectView}
              selectedView={selectedView}
              updateCurrentView={updateCurrentView}
              updatingView={updatingView}
              viewNotSaved={viewNotSaved}
              views={views}
            />
          </PageSidebar>
        }
        title="Destinations"
      >
        <PageTable
          header={
            <>
              <Heading isTruncated size="xl">
                Destinations
              </Heading>
              <ButtonGroup>
                {selectedRows.length > 0 && (
                  <Row align="center" gap={2}>
                    <Text>{`${pluralize(
                      "destination",
                      selectedRows.length,
                      true,
                    )} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      <MenuList>
                        <PermissionedMenuItem
                          permission={{
                            v1: {
                              resource: "destination",
                              grant: "update",
                            },
                          }}
                          icon={TagIcon}
                          onClick={() => {
                            setAddingLabels(true);
                          }}
                        >
                          Add labels
                        </PermissionedMenuItem>
                        <MenuDivider />
                        <PermissionedMenuItem
                          permission={{
                            v1: {
                              resource: "destination",
                              grant: "delete",
                            },
                          }}
                          icon={DeleteIcon}
                          variant="danger"
                          onClick={() => {
                            setConfirmingDelete(true);
                          }}
                        >
                          Delete
                        </PermissionedMenuItem>
                      </MenuList>
                    </Menu>
                  </Row>
                )}
                <PermissionedLinkButton
                  href="/destinations/new"
                  isDisabled={overageLockout}
                  permission={{
                    v2: { resource: "destination", grant: "can_create" },
                  }}
                  tooltip={overageLockout && overageText}
                  variant="primary"
                  onClick={() => {
                    analytics.track("Add Destination Clicked");
                  }}
                >
                  Add destination
                </PermissionedLinkButton>
              </ButtonGroup>
            </>
          }
          columns={columns}
          data={destinations}
          error={Boolean(destinationsError)}
          loading={isLoading}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={onRowClick}
          onSelect={onRowSelect}
          sortOptions={sortOptions}
        />

        <BulkDeleteConfirmationModal
          count={selectedRows.length}
          isOpen={confirmingDelete}
          label="destination"
          onClose={() => setConfirmingDelete(false)}
          onDelete={bulkDeleteDestinations}
        />

        <EditLabelModal
          rows={selectedRows.length}
          isOpen={addingLabels}
          resourceType={ResourceType.Destination}
          onClose={() => setAddingLabels(false)}
          onSubmit={async (labels) => {
            const promises = selectedRows.map((id) =>
              updateDestination({ id: String(id), append: { tags: labels } }),
            );
            await Promise.all(promises);
            onRowSelect([]);
          }}
        />
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.destination) {
    return <Destinations />;
  }

  return (
    <Page fullWidth title="Destinations">
      <Heading mb={8} size="xl">
        Destinations
      </Heading>
      <Placeholder
        content={{
          image: destinationPlaceholder,
          title: "No destinations in this workspace",
          body: `A destination is where your data will be sent. Hightouch supports ${
            import.meta.env.VITE_DESTINATIONS_COUNT
          }+ popular destinations, including CRMs like Salesforce, marketing automation tools like HubSpot, and ad platforms like Google. You can also build your own destination.`,
          button: (
            <PermissionedLinkButton
              href="/destinations/new"
              permission={{
                v2: { resource: "destination", grant: "can_create" },
              }}
              variant="primary"
            >
              Add destination
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;
