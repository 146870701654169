// Allowed diff modes. This is imported by frontend code so keep it lightweight
// and avoid circular imports.

export enum DiffMode {
  /** Only syncs changes that happened to the source model to the destination since the last run (e.g adds, updates or removes) */
  Diff = "diff",

  /** Syncs every row to the destination from the entire source model on every run */
  All = "all",

  /** Removes every row from the destination on every run */
  Archive = "archive",
}

/**
 * DiffModes that cause the planner to run in all mode (either lightning or
 * standard All planner).
 */
export const ALL_MODE_DIFF_MODES = [DiffMode.All, DiffMode.Archive];

export enum DiffModeOverride {
  FullResync = "full_resync",
  ResetCdc = "reset_cdc",
}

export enum DiffModeOverrideReason {
  ChangedPrimaryKeyScheduleNow = "changed-primary-key-schedule-now",
  ChangedPrimaryKeyScheduleLater = "changed-primary-key-schedule-later",

  // existing full resync reasons
  ExplicitRequest = "explicit-request",
  AddedFields = "added-fields",
  ChangedConfig = "changed-config",
  ChangedMappings = "changed-mappings",
  ChangedSourceTypes = "changed-source-types",
  ResyncInterval = "resync-interval",
  ChangedPrimaryKey = "changed-primary-key",
  NextDiffModeOverride = "next-diff-mode-override",
  RetryFailedResync = "retry-failed-resync",
  ArchiveModeResync = "archive-mode-resync",

  // existing non-duplicate reset cdc reason
  RetryFailedResetCDC = "retry-failed-reset-cdc",
}
