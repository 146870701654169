import { FC, useState } from "react";
import { useNavigate, useOutletContext } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { ActionBar } from "src/components/action-bar";
import {
  FunctionCodeFormState,
  FunctionOutletContext,
} from "src/events/functions/types";
import {
  useCreateFunctionVersionMutation,
  useDeleteFunctionMutation,
} from "src/graphql";

import { FunctionCodeEditor } from "./code-editor";
import { codeValidator } from "src/events/functions/utils";
import {
  Button,
  ButtonGroup,
  ConfirmationDialog,
  useToast,
} from "@hightouchio/ui";
import { CodePageWrapper } from "./code-page-wrapper";
import pluralize from "pluralize";

const validationSchema = Yup.object().shape({
  code: codeValidator,
});

export const FunctionDetails: FC = () => {
  const { function: fn } = useOutletContext<FunctionOutletContext>();

  const { toast } = useToast();
  const navigate = useNavigate();

  const createFunctionVersion = useCreateFunctionVersionMutation();
  const deleteFunction = useDeleteFunctionMutation();

  const [deletingFunction, setDeletingFunction] = useState(false);

  const onDeleteFunction = async () => {
    await deleteFunction.mutateAsync({
      id: fn.id,
    });

    toast({
      id: "function-deleted",
      title: "Function deleted",
      variant: "success",
    });

    navigate("/events/functions");
  };

  const numConnectedSyncs = fn.function_resources.length;
  const hasConnectedSyncs = numConnectedSyncs > 0;

  const form = useHightouchForm<FunctionCodeFormState>({
    defaultValues: {
      code: fn.code,
    },
    resolver: yupResolver(validationSchema),
    error: "Failed to update function",
    success: hasConnectedSyncs
      ? `Changes to function “${
          fn.name
        }” were applied to ${numConnectedSyncs} connected ${pluralize(
          "sync",
          numConnectedSyncs,
        )}.`
      : `Changes to function “${fn.name}” were saved.`,
    onSubmit: async (data) => {
      await createFunctionVersion.mutateAsync({
        object: {
          id: fn.id,
          code: data.code,
        },
      });

      // We need to call this manually to reset the default values after update
      form.reset({
        code: data.code,
      });
    },
  });

  return (
    <CodePageWrapper>
      <Form form={form}>
        <FunctionCodeEditor />

        <ActionBar>
          <ButtonGroup>
            <SaveButton
              size="md"
              // If no syncs are connected, don’t show the confirmation modal.
              confirmation={
                hasConnectedSyncs
                  ? {
                      title: "Confirm changes",
                      message: `${fn.function_resources.length} connected syncs will immediately use the new function code.`,
                    }
                  : undefined
              }
            >
              Save {hasConnectedSyncs ? " & apply " : ""} changes
            </SaveButton>
            <DiscardButton size="md" />
          </ButtonGroup>
          <Button
            isDisabled={createFunctionVersion.isLoading}
            variant="warning"
            onClick={() => setDeletingFunction(true)}
          >
            Delete
          </Button>
        </ActionBar>

        <ConfirmationDialog
          isOpen={deletingFunction}
          title="Delete function"
          onConfirm={async () => {
            await onDeleteFunction();
            setDeletingFunction(false);
          }}
          onClose={() => setDeletingFunction(false)}
          confirmButtonText="Delete"
          variant="danger"
        >
          Are you sure you want to delete this function?
        </ConfirmationDialog>
      </Form>
    </CodePageWrapper>
  );
};
