import { Suspense, useEffect } from "react";

import { ThemeProvider as HightouchUiThemeProvider } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import Helmet from "react-helmet";
// eslint-disable-next-line
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { App } from "src/components/app";
import { PageSpinner } from "src/components/loading";
import { FeatureFlagProvider } from "src/contexts/feature-flags";
import { UserProvider } from "src/contexts/user-context";
import { QueryProvider } from "src/utils/query-client";
import { Maintenance } from "src/components/maintenance";
import { ErrorBoundary } from "src/components/error-boundary";
import { filterConsoleErrors } from "src/utils/logs";
import { GlobalStyles } from "src/components/global-styles";

Sentry.init({
  enabled: import.meta.env.PROD && !import.meta.env.PREVIEW,
  dsn: "https://b61c707a79854bc2902b1392410fa6ae@o1224454.ingest.sentry.io/6505512",
  ignoreErrors: [/ResizeObserver loop/, /A network error occurred./],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 0.5,
});

window.Hightouch = window.Hightouch || {};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const root = createRoot(document.getElementById("react")!);

filterConsoleErrors();

if (import.meta.env.MAINTENANCE) {
  root.render(
    <>
      <Helmet titleTemplate="Hightouch" />
      <HightouchUiThemeProvider>
        <Maintenance />
      </HightouchUiThemeProvider>
    </>,
  );
} else {
  root.render(
    <>
      <Helmet titleTemplate="%s - Hightouch" />
      <QueryProvider>
        <HightouchUiThemeProvider>
          <ErrorBoundary>
            <Suspense fallback={<PageSpinner />}>
              <UserProvider>
                <FeatureFlagProvider>
                  <App />
                </FeatureFlagProvider>
              </UserProvider>
            </Suspense>
          </ErrorBoundary>
          <GlobalStyles />
        </HightouchUiThemeProvider>
      </QueryProvider>
    </>,
  );
}
