import { FC, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Text,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  ChakraModalBody,
  ChakraModalFooter,
  Heading,
  FormField,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

type NewVersionModalProps = {
  error?: string | undefined;
  invalidNames?: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newVersionName: string) => Promise<void>;
};

type NewVersionForm = {
  newVersionName: string;
};

const invalidRegexp = /[^A-Za-z0-9\s]/g;

export const NewVersionModal: FC<NewVersionModalProps> = ({
  invalidNames = [],
  error: externalError,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { toast } = useToast();

  const form = useForm<NewVersionForm>({
    defaultValues: {
      newVersionName: "",
    },
    resolver: yupResolver(
      Yup.object()
        .shape({
          newVersionName: Yup.string()
            .test(
              "no-special-characters",
              "No special characters are allowed",
              (value) => Boolean(value && !invalidRegexp.test(value)),
            )
            .notOneOf(invalidNames, "Version name must be unique")
            .required(),
        })
        .required(),
    ),
  });

  useEffect(() => {
    if (externalError) {
      if (externalError.includes("Uniqueness violation")) {
        form.setError("newVersionName", {
          message: "Version name must be unique",
        });
      } else {
        form.setError("newVersionName", { message: externalError });
      }
    }
  }, [externalError]);

  const submit = async (data: NewVersionForm) => {
    form.clearErrors(undefined);

    try {
      await onSubmit(data.newVersionName);

      form.reset();
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "create-event-schema-version",
        title: "No special characters are allowed",
        variant: "error",
      });
    }
  };

  return (
    <ChakraModal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent p={0} my="auto">
        <ChakraModalHeader
          p={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Heading>Enter version name</Heading>
        </ChakraModalHeader>
        <ChakraModalBody mt={0} p={6}>
          <Controller
            control={form.control}
            name="newVersionName"
            render={({ field, fieldState }) => (
              <FormField
                isRequired
                error={fieldState.error?.message}
                label="Version name"
                description="Use a name that helps you assign usage logic, such as a numbering system or “website v1.” "
              >
                <Column gap={2}>
                  <TextInput
                    placeholder="Enter a placeholder..."
                    value={field.value}
                    onChange={field.onChange}
                  />
                  {!fieldState.error?.message && (
                    <Text color="text.placeholder" size="sm">
                      Special characters not allowed
                    </Text>
                  )}
                </Column>
              </FormField>
            )}
          />
        </ChakraModalBody>
        <ChakraModalFooter
          mt={0}
          px={6}
          py={4}
          borderTop="1px solid"
          borderColor="base.border"
        >
          <ButtonGroup>
            <Button isDisabled={form.formState.isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={form.formState.isSubmitting}
              variant="primary"
              onClick={form.handleSubmit(submit)}
            >
              Continue
            </Button>
          </ButtonGroup>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
