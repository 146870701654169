import { FC, useState } from "react";

import {
  Text,
  Column,
  FormField,
  Row,
  SearchInput,
  SectionHeading,
  TextInput,
} from "@hightouchio/ui";
import { useNavigate, useOutletContext } from "src/router";

import { useCreateUserGroupMutation } from "src/graphql";

import { OutletContext } from "..";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { ActionBar } from "src/components/action-bar";
import { Controller } from "react-hook-form";
import { SelectMembers } from "./role/users/components/select-members";
import { Member } from "./role/users/components/members";

export const NewRole: FC = () => {
  const navigate = useNavigate();
  const { organization } = useOutletContext<OutletContext>();
  const { mutateAsync: createUserGroup } = useCreateUserGroupMutation();
  const [search, setSearch] = useState("");

  const form = useHightouchForm({
    onSubmit: async ({ name, members }) => {
      const { insert_user_groups_one } = await createUserGroup({
        input: {
          name,
          user_group_members: {
            data: Object.values(members)
              .filter(Boolean)
              .map(({ user_id, is_group_manager }) => ({
                user_id: String(user_id),
                is_group_manager,
              })),
          },
          organization_id: organization.id,
        },
      });
      navigate(`/organization/groups/${insert_user_groups_one?.id}/members`);
    },
    defaultValues: {
      name: "",
      members: {} as Record<string, Member>,
    },
  });

  return (
    <Form form={form}>
      <Column p={4} minH={0} gap={8} flex={1}>
        <Row justifyContent="space-between" alignItems="center" width="100%">
          <SectionHeading>New group</SectionHeading>
        </Row>
        <FormField label="Group name">
          <Controller
            name="name"
            render={({ field }) => <TextInput {...field} />}
          />
        </FormField>
        <Column minH={0} flex={1}>
          <Row align="center" gap={4} justify="space-between" mb={4}>
            <Row gap={2} align="center">
              <Text size="md" fontWeight="medium">
                Select members
              </Text>
            </Row>

            <SearchInput
              value={search}
              placeholder="Search by name or email..."
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Row>
          <Column overflow="auto" flex={1}>
            <SelectMembers organizationId={organization.id} search={search} />
          </Column>
        </Column>
      </Column>
      <ActionBar fit>
        <SaveButton>Add group</SaveButton>
      </ActionBar>
    </Form>
  );
};
