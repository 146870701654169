import { FC, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  FormField,
  Heading,
  Radio,
  RadioGroup,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Controller, useForm } from "react-hook-form";

import { useUser } from "src/contexts/user-context";
import { useUpdateEventPlanMutation } from "src/graphql";

import { Contract, SchemaViolations } from "src/events/contracts/types";

type EnforcementSettingsModalProps = {
  contract: Contract;
  isOpen: boolean;
  onClose: () => void;
};

type EnforcementSettings = {
  blockUndeclaredEventTypes: SchemaViolations;
};

export const EnforcementSettingsModal: FC<EnforcementSettingsModalProps> = ({
  contract,
  isOpen,
  onClose,
}) => {
  const { toast } = useToast();
  const { workspace } = useUser();

  const { control, handleSubmit, reset } = useForm<EnforcementSettings>({
    defaultValues: {
      blockUndeclaredEventTypes:
        (contract.on_undeclared_schema as SchemaViolations) ?? "ALLOW_EVENT",
    },
  });

  const updateEventPlanMutation = useUpdateEventPlanMutation();

  useEffect(() => {
    if (isOpen && contract) {
      reset({
        blockUndeclaredEventTypes:
          (contract.on_undeclared_schema as SchemaViolations) ?? "ALLOW_EVENT",
      });
    }
  }, [isOpen, contract, reset]);

  const submit = async (data: EnforcementSettings) => {
    try {
      await updateEventPlanMutation.mutateAsync({
        id: contract.id,
        workspace_id: workspace?.id ?? "",
        object: {
          on_undeclared_schema: data.blockUndeclaredEventTypes,
        },
      });

      toast({
        id: "update-enforcement-settings",
        title: "Updated enforcement settings",
        variant: "success",
      });

      onClose();
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-enforcement-settings",
        title: "Error updating enforcement settings",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <ChakraModal closeOnEsc isOpen={isOpen} onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent p={0} my="auto">
        <ChakraModalHeader
          p={6}
          m={0}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Heading>Event enforcement settings</Heading>
        </ChakraModalHeader>
        <ChakraModalBody
          display="flex"
          flexDirection="column"
          m={0}
          p={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Controller
            name="blockUndeclaredEventTypes"
            control={control}
            render={({ field }) => (
              <FormField
                label="Undeclared event types"
                description="When Hightouch finds event types that are not part of the contract"
              >
                <RadioGroup
                  orientation="vertical"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <Radio
                    label="Allow"
                    description="Undeclared events will sync to their destination with warnings"
                    value="ALLOW_EVENT"
                  />
                  <Radio
                    label="Block"
                    description="Undeclared events will not sync to their destination"
                    value="BLOCK_EVENT"
                  />
                </RadioGroup>
              </FormField>
            )}
          />
        </ChakraModalBody>
        <ChakraModalFooter my={4}>
          <ButtonGroup>
            <Button
              isDisabled={updateEventPlanMutation.isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              isLoading={updateEventPlanMutation.isLoading}
              variant="primary"
              onClick={handleSubmit(submit)}
            >
              Save
            </Button>
          </ButtonGroup>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
