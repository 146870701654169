import { FC, useEffect, useMemo, useState } from "react";

import {
  Alert,
  Box,
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Checkbox,
  CheckIcon,
  Column,
  EmptyState,
  ErrorIcon,
  Heading,
  Paragraph,
  Pill,
  Radio,
  RadioGroup,
  Row,
  Spinner,
  Text,
  WarningIcon,
  WizardDrawer,
  WizardDrawerStep,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import * as Sentry from "@sentry/browser";

import { useUser } from "src/contexts/user-context";
import {
  CanDeployModelsQuery,
  DeploymentOperation,
  LinkableWorkspaceResourcesQuery,
  ValidatorNames,
} from "src/graphql";

import { AccordionSection } from "../accordion-section";
import { Deployment, TargetWorkspace } from "./common";
import { DeploymentComparison, DeploymentDiff } from "./diff";
import { DeploymentTargetRadio } from "./target";

interface TargetDeploymentDetails {
  test: CanDeployModelsQuery["canDeploySegments"][0];
  targetWorkspace: TargetWorkspace;
}

interface DeploymentWizardProps {
  deployment: Deployment;

  // Deployment validation shares the same types between segments and destination instances
  deploymentTests: CanDeployModelsQuery["canDeploySegments"] | undefined;
  deploymentTestsLoading: boolean;

  deployResource: (
    resourceId: string,
    targetWorkspaceId: string,
    draft: boolean,
    additionalOptions: Record<string, unknown>,
  ) => Promise<{ success: boolean; resourceId?: string }>;
  isDeploying: boolean;

  isOpen?: boolean;

  targetWorkspaces: TargetWorkspace[];

  linkableResources:
    | LinkableWorkspaceResourcesQuery["getLinkableResources"]
    | undefined;
  linkableResourcesLoading: boolean;

  getDeploymentDiff: (
    deployment: Deployment,
    sourceObj: any,
    targetObj: any,
  ) => DeploymentDiff[];

  onClose: () => void;
}

export const DeploymentWizard: FC<Readonly<DeploymentWizardProps>> = ({
  deployment,
  deployResource,
  deploymentTests,
  deploymentTestsLoading,
  isDeploying,
  isOpen,
  targetWorkspaces,
  linkableResources,
  linkableResourcesLoading,
  getDeploymentDiff,
  onClose,
}) => {
  const { workspace } = useUser();

  const [deploymentConsent, setDeploymentConsent] = useState(false);
  const [draft, setDraft] = useState(true);
  const [targetWorkspaceId, setTargetWorkspaceId] = useState<string>();
  const [deploymentResults, setDeploymentResults] = useState<{
    success: boolean;
    resourceId?: string;
  }>();
  const [additionalOptions, setAdditionalOptions] = useState<{
    [key: string]: unknown;
  }>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  let syncScheduleDeploymentDisabled;

  const targetDeploymentDetails: TargetDeploymentDetails | undefined =
    useMemo(() => {
      const test = deploymentTests?.find(
        (d) => String(d.targetWorkspace.id) === String(targetWorkspaceId),
      );
      const targetWorkspace = targetWorkspaces.find(
        (workspace) => String(workspace.id) === String(targetWorkspaceId),
      );

      if (!test || !targetWorkspace) {
        return undefined;
      }

      // Work out if we shouldn't be able to deploy syncs
      for (const result of test.results) {
        if (result?.stepName === ValidatorNames.ScheduleExtensions) {
          if (result.__typename === "FailedDeploymentValidationResult") {
            syncScheduleDeploymentDisabled = {
              message: result.message,
            };
          }
        }
      }

      return {
        test,
        targetWorkspace,
      };
    }, [deploymentTests, targetWorkspaces, targetWorkspaceId]);

  const isSuccessfulDeploymentTest =
    targetDeploymentDetails?.test.__typename ===
    "SuccessfulDeploymentTestResult";
  const resourceName = deployment.resourceName.toLowerCase();

  const isDeployingDraft =
    targetDeploymentDetails?.test.__typename ===
      "SuccessfulDeploymentTestResult" &&
    targetDeploymentDetails?.test?.workspaceApprovalsRequired &&
    draft;

  const onCancel = () => {
    onClose();
  };

  const onDeploy = async () => {
    try {
      if (!targetDeploymentDetails) {
        throw new Error("No target deployment details");
      }

      const deploymentResults = await deployResource(
        deployment.sourceResourceId,
        targetDeploymentDetails.targetWorkspace.id || "",
        isDeployingDraft, // if approvals required and user is deploying as draft
        additionalOptions,
      );

      if (deploymentResults.success) {
        setDeploymentResults(deploymentResults);
      }

      onClose();
    } catch (e) {
      Sentry.captureException(e);
      setDeploymentResults({ success: false });
    }

    setShowConfirmationModal(true);
  };

  useEffect(() => {
    if (targetDeploymentDetails && deploymentTests?.length) {
      if (
        targetDeploymentDetails?.test?.__typename !==
        "SuccessfulDeploymentTestResult"
      ) {
        setTargetWorkspaceId(undefined);
      }
    }
  }, [deploymentTests]);

  // reset state when target changes
  useEffect(() => {
    setDraft(true);
    setDeploymentConsent(false);
  }, [targetDeploymentDetails]);

  const successfulDeploymentTests =
    deploymentTests?.filter(
      (t) => t.__typename === "SuccessfulDeploymentTestResult",
    ) || [];
  const failedDeploymentTests =
    deploymentTests?.filter(
      (t) => t.__typename !== "SuccessfulDeploymentTestResult",
    ) || [];

  const deploymentDiff = useMemo(() => {
    if (
      targetDeploymentDetails?.test?.__typename ===
      "SuccessfulDeploymentTestResult"
    ) {
      return getDeploymentDiff(
        deployment,
        targetDeploymentDetails?.test?.sourceObj,
        targetDeploymentDetails?.test?.targetObj,
      );
    } else {
      return [];
    }
  }, [targetDeploymentDetails]);

  const targetWorkspace = targetWorkspaces?.find(
    (w) => String(w.id) === String(targetDeploymentDetails?.targetWorkspace.id),
  );
  const { success, resourceId } = deploymentResults || {};

  const steps: WizardDrawerStep[] = [
    {
      label: "Select target workspace",
      render: () => (
        <Column gap={4} width="100%">
          {deploymentTestsLoading ? (
            <Row align="center" justify="center">
              <Spinner size="lg" />
            </Row>
          ) : deploymentTests?.length ? (
            <RadioGroup
              orientation="vertical"
              value={targetDeploymentDetails?.targetWorkspace.id}
              onChange={setTargetWorkspaceId}
            >
              <Column mb={4} width="100%">
                <Row alignItems="center" mb={4}>
                  <Text fontWeight="medium">Available workspaces</Text>
                  <Pill ml={2}>{successfulDeploymentTests?.length}</Pill>
                </Row>

                {successfulDeploymentTests?.length ? (
                  <Column gap={2}>
                    {successfulDeploymentTests?.map((test) => {
                      const targetWorkspace = targetWorkspaces.find(
                        (workspace) => workspace.id === test.targetWorkspace.id,
                      );
                      if (!targetWorkspace) {
                        return null;
                      }
                      return (
                        <DeploymentTargetRadio
                          key={targetWorkspace.id}
                          linkableResources={linkableResources}
                          loading={linkableResourcesLoading}
                          isSelected={
                            targetDeploymentDetails?.targetWorkspace.id ===
                            targetWorkspace.id
                          }
                          getDeploymentDiff={getDeploymentDiff}
                          deploymentTest={test}
                          deployment={deployment}
                          targetWorkspace={targetWorkspace}
                        />
                      );
                    })}
                  </Column>
                ) : (
                  <EmptyState
                    title={`No available ${resourceName}s to deploy to`}
                    message="Deployments work when sources and destinations are linked across workspaces."
                  />
                )}
              </Column>
              {failedDeploymentTests?.length && (
                <AccordionSection
                  label="Other workspaces"
                  count={failedDeploymentTests.length}
                >
                  <Column gap={2}>
                    {failedDeploymentTests?.map((test) => {
                      return (
                        <DeploymentTargetRadio
                          key={test.targetWorkspace.id}
                          linkableResources={linkableResources}
                          loading={linkableResourcesLoading}
                          isSelected={
                            targetDeploymentDetails?.targetWorkspace.id ===
                            test.targetWorkspace.id
                          }
                          getDeploymentDiff={getDeploymentDiff}
                          deploymentTest={test}
                          deployment={deployment}
                          targetWorkspace={test.targetWorkspace}
                        />
                      );
                    })}
                  </Column>
                </AccordionSection>
              )}
            </RadioGroup>
          ) : (
            <EmptyState
              title={`No ${resourceName}s to deploy to`}
              message="Deployments work when sources and destinations are linked across workspaces."
            />
          )}
        </Column>
      ),
      isDisabled: !isSuccessfulDeploymentTest,
    },
    {
      label: `Review ${resourceName} changes`,
      continueLabel: "Deploy",
      isDisabled: !deploymentConsent,
      render: () => {
        if (
          targetDeploymentDetails?.test?.__typename !==
          "SuccessfulDeploymentTestResult"
        ) {
          return null;
        }
        if (isDeploying) {
          <Column justifyContent="center">
            <Spinner size="lg" />
          </Column>;
        }

        const deploymentType = targetDeploymentDetails?.test.type;
        const userCanOnlyDraft =
          targetDeploymentDetails?.test?.userCanOnlyDraft;
        const targetHasDraft = targetDeploymentDetails?.test?.results?.some(
          (result) =>
            result?.stepName === "Draft" &&
            result.__typename === "FailedDeploymentValidationResult",
        );

        return (
          <Column gap={8}>
            <Paragraph>
              You are about to deploy a {resourceName} resource from{" "}
              <Text fontWeight="semibold">{workspace?.name}</Text> to{" "}
              <Text fontWeight="semibold">
                {targetDeploymentDetails?.targetWorkspace.name}
              </Text>
              .
            </Paragraph>

            {deploymentType === DeploymentOperation.Update && (
              <Column gap={2}>
                <Text size="lg" fontWeight="medium">
                  Review {resourceName} changes
                </Text>
                <DeploymentComparison diffs={deploymentDiff} />
              </Column>
            )}

            {targetDeploymentDetails?.test?.workspaceApprovalsRequired && (
              <Column gap={2}>
                <Column gap={1}>
                  <Text size="lg" fontWeight="medium">
                    Draft or publish
                  </Text>
                  <Paragraph>
                    This deployment will create a draft by default because{" "}
                    <Text fontWeight="semibold">
                      {targetDeploymentDetails?.targetWorkspace.name}
                    </Text>{" "}
                    has approval flows enabled.
                  </Paragraph>
                </Column>

                <RadioGroup
                  value={draft ? "draft" : "publish"}
                  onChange={(value) => {
                    setDraft(value === "draft");
                  }}
                  orientation="vertical"
                >
                  <Radio
                    label={
                      deploymentType === DeploymentOperation.Create
                        ? `Submit a draft ${resourceName}`
                        : `Submit a draft ${resourceName} change`
                    }
                    value="draft"
                  />
                  <Radio
                    label={`Publish ${
                      deploymentType === DeploymentOperation.Create
                        ? `this ${resourceName}`
                        : `${resourceName} changes`
                    }  now${
                      userCanOnlyDraft ? " (you do not have permissions)" : ""
                    }`}
                    value="publish"
                    isDisabled={userCanOnlyDraft}
                  />
                </RadioGroup>
              </Column>
            )}

            <Column gap={2}>
              <Text size="lg" fontWeight="medium">
                Confirm actions
              </Text>

              {targetHasDraft && (
                <Row alignItems="center" width="100%" gap={1}>
                  <WarningIcon color="warning.base" />
                  <Text>
                    This deployment will override the existing draft on the{" "}
                    {resourceName}
                  </Text>
                </Row>
              )}

              <Checkbox
                isChecked={deploymentConsent}
                onChange={(e) =>
                  setDeploymentConsent(Boolean(e.target.checked))
                }
                label={
                  isDeployingDraft
                    ? `${
                        deploymentType === DeploymentOperation.Create
                          ? "Submit a draft of"
                          : "Submit a draft update to"
                      } this ${resourceName} in ${targetDeploymentDetails
                        ?.targetWorkspace.name}`
                    : `${
                        deploymentType === DeploymentOperation.Create
                          ? "Create"
                          : "Update"
                      } this ${resourceName} in ${targetDeploymentDetails
                        ?.targetWorkspace.name}`
                }
              />
              {deployment.resourceType === "destination_instances" && (
                <Checkbox
                  isChecked={Boolean(additionalOptions.deploySchedule)}
                  isDisabled={!!syncScheduleDeploymentDisabled}
                  onChange={(e) =>
                    setAdditionalOptions((o) => ({
                      ...o,
                      deploySchedule: Boolean(e.target.checked),
                    }))
                  }
                  label={
                    syncScheduleDeploymentDisabled
                      ? `Deploy schedule (${syncScheduleDeploymentDisabled.message})`
                      : `Set the schedule of this ${resourceName} in ${targetDeploymentDetails?.targetWorkspace.name}`
                  }
                />
              )}
            </Column>

            {deploymentType === DeploymentOperation.Create && (
              <Alert
                variant="inline"
                type="info"
                title={`Need to link to an existing ${resourceName}?`}
                message={`When a ${resourceName} is created in your target workspace using deployments, it will be permanently linked to this ${resourceName}. If you need to link this ${resourceName} to an existing ${resourceName}, please contact our team.`}
              />
            )}
          </Column>
        );
      },
    },
  ];

  if (deploymentTestsLoading) {
    return null;
  }

  return (
    <>
      <WizardDrawer
        isOpen={Boolean(isOpen)}
        title={`Deploy ${resourceName}`}
        steps={steps}
        size="lg"
        onClose={onCancel}
        onSubmit={async () => {
          await onDeploy();
        }}
      />

      <ChakraModal
        isCentered
        isOpen={showConfirmationModal}
        scrollBehavior="inside"
        onClose={() => setShowConfirmationModal(false)}
      >
        <ChakraModalOverlay />
        <ChakraModalContent p={0} my="auto">
          <ChakraModalHeader
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
          >
            <Heading>Deployment {success ? "successful" : "failed"}</Heading>
          </ChakraModalHeader>
          <ChakraModalBody m={0}>
            <Column align="center" justify="center" width="100%" p={6} gap={4}>
              {success ? (
                <>
                  <Box
                    sx={{
                      "& > svg": {
                        width: "var(--chakra-sizes-12)",
                        height: "var(--chakra-sizes-12)",
                      },
                    }}
                  >
                    <CheckIcon color="success.base" />
                  </Box>
                  <Text size="lg" fontWeight="medium">
                    Deployed {resourceName} to{" "}
                    {targetDeploymentDetails?.targetWorkspace.name}
                  </Text>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      "& > svg": {
                        width: "var(--chakra-sizes-12)",
                        height: "var(--chakra-sizes-12)",
                      },
                    }}
                  >
                    <ErrorIcon color="danger.base" />
                  </Box>
                  <Text size="lg" fontWeight="medium">
                    Failed to deploy {resourceName} to{" "}
                    {targetDeploymentDetails?.targetWorkspace.name}
                  </Text>
                </>
              )}
            </Column>
          </ChakraModalBody>
          <ChakraModalFooter
            borderTop="solid 1px"
            borderTopColor="base.border"
            bg="white"
            m={0}
            px={6}
            py={4}
            gap={3}
            borderRadius="inherit"
          >
            <Button onClick={() => setShowConfirmationModal(false)}>
              Close
            </Button>
            {success && (
              <LinkButton
                href={`${
                  import.meta.env.VITE_APP_BASE_URL
                }/${targetWorkspace?.slug}/${resourceName}s/${resourceId}`}
                variant="primary"
              >
                View {resourceName} in target workspace
              </LinkButton>
            )}
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModal>
    </>
  );
};
