import { Row } from "@hightouchio/ui";
import { useOutletContext } from "src/router";

import { FullResourceLinkForm } from "src/components/deployments/linking";
import { SidebarForm } from "src/components/page";
import { OutletContext } from ".";

export const SourceLinking = () => {
  const { source } = useOutletContext<OutletContext>();

  return (
    <Row justifyContent="space-between">
      <FullResourceLinkForm
        sourceResourceId={source.id}
        resourceType="connections"
      />
      <SidebarForm name="source linking" docsUrl="" />
    </Row>
  );
};
