import { FC, ReactNode, useRef } from "react";

import { Box, Column, Row } from "@hightouchio/ui";
import { Helmet } from "react-helmet";
import { useScroll } from "react-use";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import { SIZES } from "src/ui/box/container";

import { Props as HeaderProps, PageHeader } from "./header/page-header";
import { DefaultPageContainerPadding } from "./page-container";
import { RouteTab, RouteTabs } from "src/components/route-tabs";

export type Props = {
  bg?: string;
  children: ReactNode;
  contentFullWidth?: boolean;
  contentFullHeight?: boolean;
  header?: ReactNode;
  hasBottomPadding?: boolean;
  sidebar?: ReactNode;
  size?: keyof typeof SIZES;
  title: string;
  tabs?: Array<RouteTab>;
  tabDepth?: number;
} & HeaderProps;

const headerBarDefaultHeight = 112;
const tabBarDefaultHeight = 40;

// distance from top of window
export const cssVariableTopOffset = "--top-section-offset";
// height of header content
export const cssVariableTopSectionHeight = "--top-section-height";
// height of footer content
export const cssVariableBottomSectionHeight = "--bottom-section-height";

export const DetailPage: FC<Readonly<Props>> = ({
  bg = "white",
  children,
  contentFullWidth,
  contentFullHeight,
  header,
  hasBottomPadding,
  crumbs,
  outsideTopbar,
  size = "page",
  sync,
  tabs,
  title,
  tabDepth = 3,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const tabBarRef = useRef<HTMLDivElement>(null);

  const headerBarHeight = useMeasureHeightOnce(
    headerRef,
    headerBarDefaultHeight,
  );
  const tabBarHeight = useMeasureHeightOnce(tabBarRef, tabBarDefaultHeight);
  const { headerHeight: pageHeaderHeight } = useHeaderHeight();

  const scrollPosition = useScroll(containerRef);
  const visibleTopSectionHeight = Math.max(
    0,
    pageHeaderHeight +
      headerBarHeight +
      (tabs ? tabBarHeight : 0) -
      scrollPosition.y,
  );

  return (
    <Column
      ref={containerRef}
      height="100vh"
      position="relative"
      overflowY="auto"
      bg={bg}
      pb={hasBottomPadding ? DefaultPageContainerPadding.Bottom : 0}
      sx={{
        [cssVariableTopOffset]: "0px",
        [cssVariableTopSectionHeight]: `${visibleTopSectionHeight}px`,
        [cssVariableBottomSectionHeight]: "0px",
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Column bg="white">
        <PageHeader
          crumbs={crumbs}
          outsideTopbar={outsideTopbar}
          stickToTop={false}
          sync={sync}
        />

        <Column
          ref={headerRef}
          bg="white"
          maxWidth={SIZES[size]}
          mx="auto"
          px={DefaultPageContainerPadding.X}
          width="100%"
          mb={4}
        >
          {header}
        </Column>

        <Row
          ref={tabBarRef}
          bg="white"
          flex={1}
          maxHeight={`${tabs ? tabBarDefaultHeight : 0}px`}
          height="auto"
          minWidth={0}
        >
          <BorderLine />
          {tabs && (
            <Box
              maxWidth={`calc(${SIZES.page} - ${
                DefaultPageContainerPadding.X * 4 * 2
              }px)`}
              width="100%"
            >
              <RouteTabs tabs={tabs} depth={tabDepth} />
            </Box>
          )}
          <BorderLine />
        </Row>
      </Column>

      <Column
        justifyContent="flex-start"
        maxWidth={contentFullWidth ? undefined : SIZES[size]}
        mt={contentFullWidth ? undefined : 8}
        mx={contentFullWidth ? undefined : "auto"}
        position="relative"
        px={contentFullWidth ? undefined : DefaultPageContainerPadding.X}
        width="100%"
        minHeight={contentFullHeight ? 0 : undefined}
        // NOTE: adding height="100%" breaks scrolling of long content
        // such as for the audience builder.
        height={contentFullHeight ? "100%" : undefined}
      >
        {children}
      </Column>
    </Column>
  );
};

const BorderLine = () => (
  <Box
    borderBottom="1px solid"
    borderColor="base.border"
    flex={1}
    minWidth={DefaultPageContainerPadding.X}
  />
);
