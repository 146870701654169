import { FC, useState } from "react";

import {
  Alert,
  Badge,
  Button,
  Box,
  Column,
  Dialog,
  FormField,
  Row,
  SectionHeading,
  TextInput,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { orderBy } from "lodash";
import pluralize from "pluralize";
import { Controller } from "react-hook-form";
import { Outlet, useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { Card } from "src/components/card";
import gitSyncImage from "src/components/extensions/assets/git-sync.png";
import { Overview } from "src/components/extensions/overview";
import { ExtensionSyncStatusBadge } from "src/components/extensions/extension-sync-status-badge";
import { FeaturePreview } from "src/components/feature-gates";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { GitBranchSelector } from "src/components/git/git-branch-selector";
import { GitChecksToggle } from "src/components/git/git-checks-toggle";
import { GitCredentialsFields } from "src/components/git/git-credentials-fields";
import { GitRepositorySelector } from "src/components/git/git-repository-selector";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import {
  PermissionedButton,
  PermissionedSwitch,
} from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import {
  GitCredentials,
  GitSyncConfigsQuery,
  useCreateGitSyncConfigsMutation,
  useGitCredentialsQuery,
  useGitSyncConfigsQuery,
  useUpdateGitSyncConfigsMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Container } from "src/ui/box";
import {
  AzureIcon,
  BitbucketIcon,
  GitHubIcon,
  GitIcon,
  GitlabIcon,
} from "src/ui/icons";
import { PageSpinner } from "src/components/loading";
import { Table } from "src/ui/table";
import { formatDatetime } from "src/utils/time";
import { RouteTabs } from "src/components/route-tabs";

export const GitSync: FC = () => {
  const { data: config, isLoading: configLoading } = useGitSyncConfigsQuery(
    {},
    { select: (data) => data.git_sync_configs?.[0], refetchInterval: 3000 },
  );

  const { data: credentials, isLoading: credsLoading } = useGitCredentialsQuery(
    undefined,
    {
      select: (data) => data.git_credentials?.[0],
    },
  );

  return (
    <Page
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Git sync - Extensions"
    >
      <RouteTabs
        depth={3}
        tabs={[
          { title: "Overview", path: "" },
          { title: "Configuration", path: "configuration" },
          { title: "Runs", path: "runs" },
        ]}
      />
      <Box mt={8}>
        <Outlet
          context={{
            config,
            credentials,
            loading: configLoading || credsLoading,
          }}
        />
      </Box>
    </Page>
  );
};

export const GitSyncOverview = () => {
  return (
    <Overview
      description="Hightouch can write your sync and model configurations to YAML files in a GitHub, Bitbucket, or GitLab repository. This integration is bidirectional, so you can also edit existing resources or create new ones via Git. Use this integration for version control, rolling back changes, or building your own approval flow based on pull/merge requests."
      icon={GitIcon}
      image={gitSyncImage}
      integrations={[
        { name: "GitHub", icon: GitHubIcon },
        { name: "Bitbucket", icon: BitbucketIcon },
        { name: "GitLab", icon: GitlabIcon },
        { name: "Azure", icon: AzureIcon },
      ]}
      subtitle="Manage syncs and models in a Git repo"
      title="Version control with Git"
    />
  );
};

interface OutletContext {
  config: GitSyncConfigsQuery["git_sync_configs"][0] | undefined;
  credentials: GitCredentials;
  loading: boolean;
}

export const GitSyncConfiguration: FC = () => {
  const { config, credentials, loading } = useOutletContext<OutletContext>();
  const { featureFlags, workspace } = useUser();
  const { toast } = useToast();
  const form = useHightouchForm({
    values: {
      enabled: config?.enabled ?? false,
      checks_enabled: config?.checks_enabled ?? false,
      repository: config?.repository ?? "",
      branch: config?.branch ?? "",
      path: config?.path ?? "",
    },
    onSubmit: async (data) => {
      if (!config?.id) {
        await create({
          object: {
            ...data,
            git_credential_id: credentials?.id,
          },
        });
      } else {
        await update({
          id: String(config.id),
          object: data,
        });
      }
      if (!config?.enabled && data.enabled) {
        analytics.track("Git Sync Enabled", {
          workspace_id: workspace?.id,
        });
      }

      if (config?.enabled && !data.enabled) {
        analytics.track("Git Sync Disabled", {
          workspace_id: workspace?.id,
        });
      }
    },
  });

  const { mutateAsync: create } = useCreateGitSyncConfigsMutation();
  const { mutateAsync: update } = useUpdateGitSyncConfigsMutation();

  const unidirectionalEnabled = Boolean(featureFlags?.unidirection_git_sync);

  const { watch } = form;

  const enabled = watch("enabled");

  const fullResync = async () => {
    try {
      await update({
        id: config?.id ? String(config.id) : "",
        object: {
          full_resync: true,
        },
      });

      toast({
        id: "resync-git",
        title: "Resync will begin shortly",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "resync-git",
        title: "There was an error starting a resync",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Form form={form}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Column gap={12}>
            <FeaturePreview
              enabled={workspace?.organization?.plan?.sku === "business_tier"}
              featureDetails={{
                pitch:
                  "Workspace version control managed through your Git repo",
                description:
                  "Hightouch can connect with your Git provider (GitHub, GitLab, etc.) to manage, backup, and replicate your syncs and models using a Git repo. This lets you manage the lifecycle of your syncs via your existing Git workflows.",
                bullets: [
                  "Bi-directional sync between your Hightouch resources and your Git repo",
                  "Changes in your Git repo are automatically propagated to Hightouch",
                  "Use .yaml file format to declare the configuration of syncs and model",
                  "Run CI checks on any pull request to detect for breakages",
                ],
                image: {
                  src: "https://cdn.sanity.io/images/pwmfmi47/production/0723ac000e333d514b51ab3b847a20ff7301d4dc-1118x450.png",
                },
              }}
              featureName="git sync"
              variant="full"
            />
            <Column>
              <SectionHeading mb={4}>Status</SectionHeading>
              <Column gap={4}>
                <Card>
                  <Row justifyContent="space-between" alignItems="center">
                    <Row alignItems="center">
                      <GitIcon />
                      <Text ml={2}>Git Sync</Text>
                    </Row>
                    <Controller
                      name="enabled"
                      render={({ field }) => (
                        <Box alignItems="center" display="flex" gap={2}>
                          <Text
                            textTransform="uppercase"
                            size="sm"
                            fontWeight="semibold"
                            color="gray.400"
                          >
                            {field.value ? "Enabled" : "Disabled"}
                          </Text>

                          <PermissionedSwitch
                            permission={{
                              v2: {
                                resource: "workspace",
                                grant: "can_update",
                              },
                            }}
                            isChecked={field.value}
                            onChange={field.onChange}
                          />
                        </Box>
                      )}
                    />
                  </Row>
                </Card>

                <Card>
                  {unidirectionalEnabled ? (
                    <Column mb={4}>
                      <Row justifyContent="space-between" alignItems="center">
                        <Text>Hightouch to Git</Text>
                        <ExtensionSyncStatusBadge
                          error={null}
                          lastAttemptedAt={null}
                          statusTextOverride="Disabled"
                          setup={Boolean(config)}
                        />
                      </Row>
                      <ErrorBlock error={config?.outbound_error} />
                    </Column>
                  ) : (
                    <Column mb={4}>
                      <Row justifyContent="space-between" alignItems="center">
                        <Text>Hightouch to Git</Text>
                        <ExtensionSyncStatusBadge
                          error={config?.outbound_error}
                          lastAttemptedAt={config?.last_attempted_at}
                          setup={Boolean(config)}
                        />
                      </Row>
                      <ErrorBlock error={config?.outbound_error} />
                    </Column>
                  )}
                  <Column>
                    <Row
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Git to Hightouch</Text>
                      <ExtensionSyncStatusBadge
                        error={config?.inbound_error}
                        lastAttemptedAt={
                          !config?.outbound_error
                            ? config?.last_attempted_at
                            : undefined
                        }
                        setup={Boolean(config)}
                      />
                    </Row>
                    <ErrorBlock error={config?.inbound_error} />
                  </Column>
                </Card>
                {enabled && (
                  <Card>
                    <GitChecksToggle
                      credentials={credentials}
                      description="Check your Hightouch Git Sync config files for breaking changes before they are merged."
                    />
                  </Card>
                )}
              </Column>
            </Column>

            <Column>
              <SectionHeading mb={4}>Configuration</SectionHeading>
              <Column gap={8}>
                <GitCredentialsFields
                  credentials={credentials}
                  isSetup={Boolean(config?.git_credential?.id)}
                  page="git-sync"
                />
                {!credentials ||
                (credentials?.type === "github_app" &&
                  !credentials?.credentials) ? null : (
                  <>
                    <GitRepositorySelector credentials={credentials} />
                    <GitBranchSelector credentials={credentials} />
                    <FormField
                      isOptional
                      description="Specify a custom path to look for the sync and model folders."
                      label="Path"
                    >
                      <Controller
                        name="path"
                        render={({ field }) => (
                          <TextInput placeholder="./hightouch" {...field} />
                        )}
                      />
                    </FormField>
                  </>
                )}
              </Column>
            </Column>
          </Column>
        </Container>

        <SidebarForm docsUrl="syncs/git-sync" name="Git sync" />
      </Row>
      <ActionBar>
        <FormActions
          permission={{
            v2: { resource: "workspace", grant: "can_update" },
          }}
        />
        <PermissionedButton
          permission={{
            v2: { resource: "workspace", grant: "can_update" },
          }}
          onClick={fullResync}
          isDisabled={!config?.enabled}
          isLoading={!!config?.full_resync}
          tooltip="Push all workspace syncs and models to Git and then pull changes from Git back to your workspace. This may overwrite recent Git changes."
        >
          Full resync
        </PermissionedButton>
      </ActionBar>
    </Form>
  );
};

export const GitSyncRuns: FC = () => {
  const { config } = useOutletContext<OutletContext>();

  const [inboundChanges, setInboundChanges] = useState<
    | GitSyncConfigsQuery["git_sync_configs"][0]["git_inbound_runs"][0]["affected_resources"]
    | null
  >();
  const [outboundChanges, setOutboundChanges] = useState<
    | GitSyncConfigsQuery["git_sync_configs"][0]["git_outbound_runs"][0]["commits"]
    | null
  >();

  const outboundRuns =
    config?.git_outbound_runs?.map((o) => ({
      createdAt: o.created_at,
      type: "outbound",
      state: o.changelog_id,
      numChanges: o.commits?.length || 0,
      changes: o.commits,
    })) || [];
  const inboundRuns =
    config?.git_inbound_runs?.map((i) => ({
      createdAt: i.created_at,
      type: "inbound",
      state: i.commit,
      numChanges:
        i.affected_resources.syncs.length +
          i.affected_resources.models.length || 0,
      changes: i.affected_resources,
    })) || [];

  const rows = orderBy(
    [...outboundRuns, ...inboundRuns],
    ["createdAt"],
    ["desc"],
  );

  const getCommitUrl = (commit: string) => {
    if (config?.repository) {
      const repo = config?.repository.toString();
      const url = repo.endsWith(".git") ? repo.slice(0, -4) : repo;
      return `${url}/commit/${commit}`;
    }
    return "";
  };

  return (
    <>
      <Table
        columns={[
          {
            name: "Type",
            max: "max-content",
            cell: ({ type }) =>
              type === "inbound" ? (
                <Badge variant="success">Inbound</Badge>
              ) : (
                <Badge variant="beta">Outbound</Badge>
              ),
          },
          {
            name: "Completed",
            max: "max-content",
            cell: ({ createdAt }) => formatDatetime(createdAt),
          },
          {
            name: "State",
            cell: ({ type, state }) => (
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {type === "inbound" ? (
                  <Link href={getCommitUrl(state)}>{`Commit: ${state}`}</Link>
                ) : (
                  `Changelog ID: ${state}`
                )}
              </Box>
            ),
          },
          {
            name: "Changes",
            max: ".5fr",
            cell: ({ type, numChanges, changes }) => {
              if (numChanges) {
                return (
                  <Box
                    display="inline-block"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    onClick={() => {
                      if (type === "inbound") {
                        setInboundChanges(changes);
                      } else {
                        setOutboundChanges(changes);
                      }
                    }}
                  >
                    <Link href="">{`${numChanges} ${pluralize(
                      "resource",
                      numChanges,
                    )} changed`}</Link>
                  </Box>
                );
              }
              return "None";
            },
          },
        ]}
        data={rows}
        placeholder={{
          title: "No runs",
          body: "Enable syncing to your repository",
          error: "Runs failed to load, please try again.",
        }}
      />

      <Dialog
        isOpen={Boolean(outboundChanges)}
        variant="info"
        title="Git commits"
        width="lg"
        actions={
          <Button onClick={() => setOutboundChanges(null)}>Close</Button>
        }
        onClose={() => {
          setOutboundChanges(null);
        }}
      >
        <Column gap={2}>
          {outboundChanges?.map((c, i) => (
            <Link key={i} href={getCommitUrl(c)}>
              {getCommitUrl(c)}
            </Link>
          ))}
        </Column>
      </Dialog>

      <Dialog
        isOpen={Boolean(inboundChanges)}
        variant="info"
        title="Changed resources"
        width="lg"
        actions={<Button onClick={() => setInboundChanges(null)}>Close</Button>}
        onClose={() => {
          setInboundChanges(null);
        }}
      >
        <Column gap={6}>
          {Boolean(inboundChanges?.syncs?.length) && (
            <FormField label="Syncs">
              {inboundChanges?.syncs?.map((s) => (
                <Link key={s} href={`/syncs/${s}`}>
                  Sync {s}
                </Link>
              ))}
            </FormField>
          )}
          {Boolean(inboundChanges?.models?.length) && (
            <FormField label="Models">
              {inboundChanges?.models?.map((m) => (
                <Link key={m} href={`/models/${m}`}>
                  Model {m}
                </Link>
              ))}
            </FormField>
          )}
        </Column>
      </Dialog>
    </>
  );
};

const ErrorBlock = ({ error }: { error: any }) => {
  if (!error) return null;
  return (
    <>
      {error?.fatal && (
        <Alert
          my={2}
          variant="inline"
          type="error"
          title="Fatal error"
          message="Hightouch has detected a fatal error and temporarily disabled Git sync. Examples of fatal errors are: your credentials may be invalid, you may not have access to this repository, etc."
        />
      )}
      {error?.temp && (
        <Alert
          my={2}
          variant="inline"
          type="warning"
          title="Possible race condition"
          message="Hightouch has detected a possible race condition and will attempt to automatically resolve. No action is required."
        />
      )}
      <Box p={4} bg="base.background">
        <Text as="pre" wordBreak="break-all" whiteSpace="pre-wrap">
          {JSON.stringify(error, null, 2)}
        </Text>
      </Box>
    </>
  );
};
