import { FC } from "react";

import { Box, CloseIcon, Row, Tag } from "@hightouchio/ui";

import {
  formatValue,
  getOperatorLabel,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { PropertyCondition } from "src/types/visual";

export const FilterTag: FC<{
  condition: PropertyCondition;
  onRemove: () => void;
}> = ({ condition, onRemove }) => {
  return (
    <Tag>
      <Row as="span" gap={2} alignItems="center">
        <TextWithTooltip>
          <Row gap={1}>
            {getPropertyNameFromColumn(condition.property)}
            <TextWithTooltip color="text.secondary">
              {getOperatorLabel(condition.operator, condition.propertyType)}
            </TextWithTooltip>

            {formatValue(condition, condition.value)}
          </Row>
        </TextWithTooltip>

        <Box
          as="button"
          aria-label="remove filter"
          cursor="pointer"
          textColor="text.secondary"
          fontSize="13"
          _focus={{
            outline: "none",
          }}
          _focusVisible={{
            boxShadow: "var(--chakra-shadows-outline)",
          }}
          onClick={onRemove}
        >
          <CloseIcon />
        </Box>
      </Row>
    </Tag>
  );
};
