import {
  IconButton,
  Button,
  Column,
  Row,
  TextInput,
  PlusIcon,
  CloseIcon,
  FormField,
} from "@hightouchio/ui";
import { Controller, useFieldArray } from "react-hook-form";

export const Subjects = () => {
  const { fields, append, remove } = useFieldArray({
    name: "subject",
  });

  return (
    <Column align="flex-start" gap={4} maxW="2xl" w="100%">
      <FormField
        label="Subject lines"
        description="The subject lines that could be sent to the user."
        isOptional
      >
        <Column gap={2} width="100%">
          {fields.map(({ id }, index) => (
            <Controller
              name={`subject.${index}`}
              key={id}
              render={({ field }) => {
                return (
                  <Row gap={3} align="center" key={id} width="100%">
                    <TextInput
                      width="100%"
                      {...field}
                      placeholder="Enter a subject line..."
                    />
                    <IconButton
                      icon={CloseIcon}
                      aria-label="Delete subject"
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </Row>
                );
              }}
            />
          ))}
        </Column>
      </FormField>
      <Button
        icon={PlusIcon}
        variant="secondary"
        onClick={() => {
          append("");
        }}
      >
        Add subject
      </Button>
    </Column>
  );
};
