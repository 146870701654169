import { ReactElement, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ChangeIcon,
  Dialog,
  FilterIcon,
  FilterMenu,
  FilterMenuButton,
  FilterMenuGroup,
  FilterMenuList,
  FilterMenuOption,
  Pill,
  PlusIcon,
  Row,
  SearchInput,
  SubtractIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import { useUser } from "src/contexts/user-context";
import {
  FormkitModel,
  FormkitSync,
} from "src/formkit/components/formkit-context";
import {
  AttemptedRowsByPrimaryKeyQuery,
  AttemptedRowsQuery,
  SyncOp,
  useSanityErrorCodeQuery,
  useTransformedSyncRunConfigurationQuery,
} from "src/graphql";
import { ErrorCodeDetails, SyncRequestErrorInfo } from "src/types/sync-errors";
import { SimplePagination, Table, TableColumn } from "src/ui/table";
import { DEPRECATED_ERROR, SyncRunStatus } from "src/utils/syncs";
import { openUrl } from "src/utils/urls";

import { SyncRequestErrorModal } from "src/pages/syncs/sync/components/error-modals";
import { ErrorOriginInfoModal } from "src/pages/syncs/sync/components/error-origin-info-modal";
import ShipImage from "src/pages/syncs/sync/components/ship.svg";
import { RowExportModal } from "./row-export";
import { get } from "lodash";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import { commaNumber } from "src/utils/numbers";

type RowsProps = {
  addedRows?: number | null;
  attemptedRowsByPKData?: AttemptedRowsByPrimaryKeyQuery;
  attemptedRowsByPKLoading: boolean;
  attemptedRowsData?: AttemptedRowsQuery;
  attemptedRowsLoading: boolean;
  attemptedRowsQueryError: Error | null;
  changedRows?: number | null;
  disableRowClick?: boolean;
  page: number;
  pages: number;
  plannerType?: string | null;
  primaryKey?: string | null;
  redirectPrefix?: string | null;
  removedRows?: number | null;
  search: string;
  searchInput: string;
  setPage: (page) => void;
  setPageKeys: (keys) => void;
  setSearch: (search) => void;
  setSearchInput: (input) => void;
  setSyncOpFilters: (filters: Record<SyncOp, boolean>) => void;
  syncOpFilters: Record<SyncOp, boolean>;
  showRejected: boolean;
  source?: FormkitModel["connection"];
  sync?: FormkitSync;
  syncError: any;
  syncRequest: any;
};

export const Rows = ({
  addedRows,
  attemptedRowsByPKData,
  attemptedRowsByPKLoading,
  attemptedRowsData,
  attemptedRowsLoading,
  attemptedRowsQueryError,
  changedRows,
  disableRowClick,
  page,
  pages,
  plannerType,
  primaryKey,
  redirectPrefix = "",
  removedRows,
  search,
  searchInput,
  setPage,
  setPageKeys,
  setSearch,
  setSearchInput,
  setSyncOpFilters,
  syncOpFilters,
  showRejected,
  source,
  sync,
  syncError,
  syncRequest,
}: RowsProps) => {
  const navigate = useNavigate();
  const { run_id: runId, sync_id: syncId } = useParams<{
    run_id: string;
    sync_id: string;
  }>();

  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);
  const [runError, setRunError] = useState<SyncRequestErrorInfo>();
  const [rowError, setRowError] = useState<string>("");
  const [errorRowId, setErrorRowId] = useState<string>("");
  const [errorInfo, setErrorInfo] = useState<ErrorCodeDetails | null>(null);
  const [errorOrigin, setErrorOrigin] = useState<
    "destination" | "source" | null
  >(null);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [showUnsupported, setShowUnsupported] = useState<boolean>(false);

  // Want to refer to the config of the given run, instead on the sync
  const { data: syncRunConfig } = useTransformedSyncRunConfigurationQuery(
    { id: syncId ?? "", runId: runId ?? "" },
    {
      enabled: Boolean(runId && syncId),
      select: (data) => data.getTransformedSyncRunConfiguration,
    },
  );
  const syncRanWithMatchBooster = syncRunConfig
    ? isSyncMatchBoosted(syncRunConfig)
    : false;

  // XXX: As a hack, we disable showing row-level information for regular
  // viewers for one of our customers. This is not meant to be secure, and
  // just prevents accidentally viewing PII data. We should remove this
  // once we have first-class support for this via our permissioning
  // system.
  const { user } = useUser();
  const userId = user?.id;

  const isDataProtected =
    // We don't populate the results-index for clean room sources anyway,
    // but we can show a better UI for those sources.
    Boolean(source?.definition.cleanRoomType) ||
    // This is a hack for a specific customer.
    // See https://github.com/hightouchio/hightouch/pull/2887.
    (syncId === "27126" && userId !== 63366);

  const data = search
    ? attemptedRowsByPKData?.getAttemptedRowsByPrimaryKey?.rows
    : attemptedRowsData?.getAttemptedRows?.rows;

  const rows = useMemo(
    () =>
      (data || [])
        .filter(isPresent)
        .map(
          (
            {
              id,
              opType,
              rejectionReason,
              fields,
              batchId,
              requestInfoKeys,
              isBatchError,
              error,
            },
            index,
          ) => ({
            hightouchRowIndex: index,
            hightouchRowId: id,
            opType,
            rejectionReason,
            batchId,
            requestInfoKeys,
            isBatchError,
            fields,
            error,
            ...JSON.parse(fields),
          }),
        ),
    [data],
  );

  // Rows are no longer available when the run is older than a week
  const isRowsDataExpired = useMemo(() => {
    if (!syncRequest || rows.length > 0) {
      return false;
    }

    const weekAgo = moment().subtract(1, "week");
    return moment(syncRequest.created_at).isBefore(weekAgo);
  }, [syncRequest, rows]);

  const rowValue = (row, key) => {
    const value = get(row, key);
    return typeof value === "object" ? JSON.stringify(value) : String(value);
  };

  const columns: TableColumn[] = useMemo(() => {
    const columns: TableColumn[] = [
      {
        name: "Type",
        max: "max-content",
        cell: ({ opType }) => syncOpTypeLabel[opType],
      },
    ];

    if (primaryKey) {
      columns.push({
        name: `${primaryKey} (Primary Key)`,
        cell: (row) => rowValue(row, primaryKey),
      });
    }

    if (showRejected && plannerType !== "all") {
      columns.push({
        name: "Error message",
        cell: ({ rejectionReason, hightouchRowId, error }) => {
          if (!rejectionReason) return null;

          const sanityQuery = useSanityErrorCodeQuery(
            { errorCode: error?.rejectedRowErrorCode },
            {
              select: (data) => data.getErrorCode,
              enabled: Boolean(error?.rejectedRowErrorCode),
            },
          );
          const sanityErrorInfo = error?.rejectedRowErrorCode
            ? sanityQuery
            : null;
          const origin = error?.scope || "destination";

          return (
            <Row alignItems="center" gap={2}>
              <Button
                size="sm"
                onClick={(event) => {
                  event.stopPropagation();
                  setRowError(rejectionReason);
                  setErrorRowId(hightouchRowId);
                  setErrorInfo(
                    sanityErrorInfo?.isLoading ? null : sanityErrorInfo?.data,
                  );
                  setErrorOrigin(origin);
                }}
              >
                More info
              </Button>
              <Box
                as={Badge}
                variant="danger"
                border="none"
                maxWidth="md"
                justifyContent="flex-start"
              >
                <Text size="sm" color="inherit" isMonospace isTruncated>
                  {rejectionReason}
                </Text>
              </Box>
            </Row>
          );
        },
      });
    }

    const row = data?.[0];

    if (row) {
      // Sort fields and remove primary key from the list
      const keys = Object.keys(JSON.parse(row.fields))
        .filter((x) => x !== primaryKey)
        .sort((a, b) => a.localeCompare(b));

      keys.forEach((key, i) => {
        columns.push({
          name: key,
          cell: (row) => rowValue(row, key),
          divider: !primaryKey && i === 0,
        });
      });
    }

    return columns;
  }, [showRejected, plannerType, data]);

  const redirectBackToSync = () => {
    navigate(`${redirectPrefix}/syncs/${syncId}`);
  };

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [rows, setPage, setNextLoading, setPreviousLoading]);

  const operationTypeFilters = [
    syncOpFilters.ADDED ? SyncOp.Added : null,
    syncOpFilters.CHANGED ? SyncOp.Changed : null,
    syncOpFilters.REMOVED ? SyncOp.Removed : null,
  ].filter(isPresent);

  const setOperationTypeFilters = (filters: SyncOp[]) =>
    setSyncOpFilters(
      filters.reduce<Record<SyncOp, boolean>>(
        (acc, filter) => ({ ...acc, [filter]: true }),
        { ADDED: false, CHANGED: false, REMOVED: false },
      ),
    );

  const { search: searchParams } = useLocation();

  return (
    <>
      <Row
        align="center"
        justify="space-between"
        w="100%"
        flexWrap="wrap"
        mb={6}
        gap={4}
      >
        {plannerType !== "all" && (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setSearch(searchInput);
            }}
          >
            <Row align="center" gap={2}>
              <SearchInput
                placeholder={primaryKey ? `Search by ${primaryKey}` : ""}
                value={searchInput}
                onChange={(event) => {
                  const value = event.target.value;
                  setSearchInput(value);
                  if (value === "") {
                    setSearch("");
                  }
                }}
              />
              <Button type="submit">Search</Button>
            </Row>
          </form>
        )}
        <Row align="center" gap={4}>
          <FilterMenu>
            <FilterMenuButton icon={FilterIcon}>
              Operation type
            </FilterMenuButton>
            <FilterMenuList>
              <FilterMenuGroup
                title="View"
                type="checkbox"
                value={operationTypeFilters}
                onChange={(filters) =>
                  setOperationTypeFilters(filters as SyncOp[])
                }
              >
                <FilterMenuOption value={SyncOp.Added} isDisabled={!addedRows}>
                  <Row align="center" justifyContent="space-between">
                    {addLabel}
                    <Pill size="sm">{commaNumber(addedRows ?? 0)}</Pill>
                  </Row>
                </FilterMenuOption>
                <FilterMenuOption
                  value={SyncOp.Changed}
                  isDisabled={!changedRows}
                >
                  <Row align="center" justifyContent="space-between">
                    {changeLabel}
                    <Pill size="sm">{commaNumber(changedRows ?? 0)}</Pill>
                  </Row>
                </FilterMenuOption>
                <FilterMenuOption
                  value={SyncOp.Removed}
                  isDisabled={!removedRows}
                >
                  <Row align="center" justifyContent="space-between">
                    {removeLabel}
                    <Pill size="sm">{commaNumber(removedRows ?? 0)}</Pill>
                  </Row>
                </FilterMenuOption>
              </FilterMenuGroup>
            </FilterMenuList>
          </FilterMenu>
          {!isDataProtected && (
            <>
              {syncError &&
                ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(
                  syncError.message,
                ) && (
                  <Button
                    variant="warning"
                    onClick={() => setRunError(syncError)}
                  >
                    View run error
                  </Button>
                )}
              <Tooltip
                isDisabled={!(plannerType === "all" || isRowsDataExpired)}
                message={
                  plannerType === "all"
                    ? "You cannot export rows for this type of sync"
                    : "You cannot export rows for sync runs older than 7 days"
                }
              >
                <Button
                  isDisabled={plannerType === "all" || isRowsDataExpired}
                  onClick={() => {
                    setShowExport(true);
                  }}
                >
                  Export rows
                </Button>
              </Tooltip>
            </>
          )}
        </Row>
      </Row>
      <Table
        primaryKey="hightouchRowIndex" // we use index rather than the rowId because the rowId is not unique
        scrollable
        columns={columns}
        data={isDataProtected ? [] : rows}
        error={!!attemptedRowsQueryError}
        loading={attemptedRowsLoading || attemptedRowsByPKLoading}
        placeholder={{
          image: isRowsDataExpired ? ShipImage : undefined,
          title: isDataProtected
            ? "The run data is protected"
            : search
              ? `No rows match your search`
              : isRowsDataExpired
                ? "Sorry, that ship has sailed"
                : "No operations logged",
          body: search ? (
            <Text>
              No rows with <strong>{primaryKey}</strong> equal to{" "}
              <strong>{search}</strong>
            </Text>
          ) : isRowsDataExpired ? (
            `Debugger logs are retained for 7 days`
          ) : undefined,
          button: isRowsDataExpired ? (
            <Button variant="secondary" onClick={redirectBackToSync}>
              Go back to sync
            </Button>
          ) : undefined,
          error: attemptedRowsQueryError?.message,
        }}
        onRowClick={
          disableRowClick
            ? undefined
            : (row, event) =>
                syncRanWithMatchBooster
                  ? setShowUnsupported(true)
                  : openUrl(
                      `${redirectPrefix}/syncs/${syncId}/runs/${runId}/debug/${row?.hightouchRowId}${searchParams}`,
                      navigate,
                      event,
                    )
        }
      />
      <SimplePagination
        nextLoading={nextLoading}
        page={page}
        pages={pages}
        previousLoading={previousLoading}
        onNext={() => {
          setNextLoading(true);
          setPageKeys((pageKeys) =>
            [
              ...pageKeys,
              attemptedRowsData?.getAttemptedRows?.nextPageKey,
            ].filter(isPresent),
          );
        }}
        onPrevious={() => {
          setPreviousLoading(true);
          setPageKeys((pageKeys) => {
            if (page === 1) {
              return [];
            } else {
              return pageKeys.slice(0, -1);
            }
          });
        }}
      />

      {/* Modal for sync-level errors */}
      <SyncRequestErrorModal
        isOpen={Boolean(runError)}
        onClose={() => setRunError(undefined)}
        sync={sync}
        syncRequestError={runError}
        syncStatus={sync?.status as SyncRunStatus}
      />
      {/* Modal for row errors */}
      <ErrorOriginInfoModal
        errorType="row"
        isOpen={Boolean(rowError)}
        onClose={() => {
          setRowError("");
          setErrorRowId("");
          setErrorInfo(null);
          setErrorOrigin(null);
        }}
        originInfo={
          errorOrigin === "source"
            ? {
                scope: "source",
                operation: "query",
              }
            : {
                scope: "destination",
                operation: "add",
              }
        }
        rowLevelError={{
          rowId: errorRowId,
          message: rowError,
          errorCodeDetail: errorInfo,
        }}
        sync={sync}
      />
      <RowExportModal
        runId={runId || ""}
        open={showExport}
        setOpen={setShowExport}
      />
      <Dialog
        isOpen={showUnsupported}
        variant="info"
        title="Run debugger unsupported"
        actions={
          <Button onClick={() => setShowUnsupported(false)}>Close</Button>
        }
        onClose={() => setShowUnsupported(false)}
      >
        <Text>
          Run debugger is not supported for match boosted syncs to prevent
          exposing regulated third-party data.
        </Text>
      </Dialog>
    </>
  );
};

const addLabel = (
  <Row gap={1} fontSize={20} align="center">
    <PlusIcon color="text.secondary" />
    <Text>Add</Text>
  </Row>
);

const changeLabel = (
  <Row gap={1} fontSize={20} align="center">
    <ChangeIcon color="text.secondary" />
    <Text>Change</Text>
  </Row>
);

const removeLabel = (
  <Row gap={1} fontSize={20} align="center">
    <SubtractIcon color="text.secondary" />
    <Text>Remove</Text>
  </Row>
);

export const syncOpTypeLabel: Record<SyncOp, ReactElement> = {
  [SyncOp.Added]: addLabel,
  [SyncOp.Changed]: changeLabel,
  [SyncOp.Removed]: removeLabel,
};

export const syncOpTypeBadge: Record<SyncOp, ReactElement> = {
  [SyncOp.Added]: (
    <Badge size="sm" icon={PlusIcon}>
      Add
    </Badge>
  ),
  [SyncOp.Changed]: (
    <Badge size="sm" icon={ChangeIcon}>
      Change
    </Badge>
  ),
  [SyncOp.Removed]: (
    <Badge size="sm" icon={SubtractIcon}>
      Remove
    </Badge>
  ),
};
