import { createContext, useContext, useState, FC, ReactNode } from "react";

import { TraitCondition as TraitDefinitionCondition } from "@hightouch/lib/query/visual/types/trait-definitions";
import { ConfirmationDialog, Paragraph } from "@hightouchio/ui";
import pluralize from "pluralize";
import { To, useNavigate } from "src/router";
import { noop } from "ts-essentials";

import { AudienceEventTraitForm } from "src/components/audiences/audience-event-trait-form";
import { CreateTraitEnrichmentModal } from "src/components/audiences/create-trait-enrichment-modal";
import { CreateTraitModal } from "src/components/audiences/create-trait-modal";
import { useAddAdditionalColumn } from "src/components/audiences/trait-enrichments/utils";
import { toSingleCondition } from "src/components/audiences/utils";
import {
  useRelatedAudiencesQuery,
  useTraitsForParentModelQuery,
} from "src/graphql";
import {
  AdditionalColumn,
  Audience,
  EventCondition,
  isTraitCondition,
  Relationship,
  TraitDefinition,
  FilterableColumn,
  ConditionType,
  Condition,
  PropertyCondition,
  InlineTraitCondition,
} from "src/types/visual";
import { ParentModel } from "src/pages/audiences/types";

type Props = {
  audience?: Audience;
  parentModel?: ParentModel | null;
  children: ReactNode;
  columns?: FilterableColumn[];
};

type Requirement = {
  condition: "related model" | "event" | "audience";
  to: To;
};

const STATIC_ARRAY = [];

type SelectedCondition = {
  condition: PropertyCondition | InlineTraitCondition | EventCondition;
  update: (condition: Condition) => void;
} | null;

export type QueryBuilderContextType = {
  columns: FilterableColumn[];
  events: Relationship[];
  relatedAudiences: { label: string; value: string }[];
  relationships: Relationship[];
  traits: (TraitDefinition & { parentModel?: ParentModel | null })[];
  selectedCondition: SelectedCondition;
  selectCondition: (input: SelectedCondition) => void;
};

const defaultContextValue: QueryBuilderContextType = {
  columns: [],
  events: [],
  relatedAudiences: [],
  relationships: [],
  traits: [],
  selectedCondition: null,
  selectCondition: noop,
};

export const QueryBuilderContext =
  createContext<QueryBuilderContextType>(defaultContextValue);

export const useQueryBuilderContext = () => useContext(QueryBuilderContext);

export const QueryBuilderProvider: FC<Readonly<Props>> = ({
  audience,
  children,
  columns: overrideColumns,
  parentModel,
}) => {
  const navigate = useNavigate();

  const relatedAudiencesQuery = useRelatedAudiencesQuery(
    {
      parentModelId: parentModel?.id,
      modelId: audience?.id,
    },
    { enabled: Boolean(parentModel) },
  );

  const { data: traitDefinitions, refetch: refetchTraits } =
    useTraitsForParentModelQuery(
      {
        parentModelId: parentModel?.id ?? "",
      },
      { enabled: false, select: (data) => data.trait_definitions },
    );

  const relationships = parentModel?.relationships;

  // Inject each trait with the parent model.
  // We could augment the trait to include the parent model via graphql, but this will let us
  // avoid an N+1 query, since we already know the trait's parent model.
  // We need to know which parent model the trait comes from so we can label formula traits.
  const traits = (traitDefinitions ?? parentModel?.traits)?.map((trait) => ({
    ...trait,
    parentModel,
  }));

  const columns = overrideColumns ?? parentModel?.filterable_audience_columns;

  const events = relationships?.filter(({ to_model: { event } }) =>
    Boolean(event),
  );
  const relatedAudiences =
    relatedAudiencesQuery.data?.segments.map(({ id, name }) => ({
      value: id as string,
      label: name,
    })) ?? [];

  // Used for creating traits or additional columns inline via the query builder
  const [_selectedCondition, setSelectedCondition] =
    useState<SelectedCondition>(null);
  const selectedCondition = _selectedCondition?.condition;
  const updateSelectedCondition = _selectedCondition?.update;

  const [missingRequirement, setMissingRequirement] = useState<
    Requirement | undefined
  >();

  const addAdditionalColumn = useAddAdditionalColumn();
  const handleAddAdditionalColumn = async (
    additionalColumn: AdditionalColumn,
  ) => {
    if (!audience) return;
    await addAdditionalColumn({ audience, additionalColumn });
  };

  const isTrait = selectedCondition && isTraitCondition(selectedCondition);
  const selectedTrait =
    isTrait &&
    parentModel?.traits?.find(
      (t) => t.id === selectedCondition.property?.column.traitDefinitionId,
    );

  const onTraitCreated = async (conditionWithTrait: PropertyCondition) => {
    // Refetch the traits so the query builder knows about the newly created trait
    await refetchTraits();
    // Update the condition with the new trait
    updateSelectedCondition?.(conditionWithTrait);
  };

  const showCreateTraitEnrichmentModal =
    selectedCondition?.type === ConditionType.Property &&
    selectedCondition?.propertyOptions?.traitType !== "inline_trait" &&
    selectedTrait;

  const showCreateTraitModal =
    selectedCondition?.type === ConditionType.Property &&
    selectedCondition?.propertyOptions?.traitType === "inline_trait";

  return (
    <QueryBuilderContext.Provider
      value={{
        columns: columns ?? STATIC_ARRAY,
        events: events ?? STATIC_ARRAY,
        relationships: relationships ?? STATIC_ARRAY,
        traits: traits ?? STATIC_ARRAY,
        relatedAudiences,
        selectedCondition: _selectedCondition,
        selectCondition: setSelectedCondition,
      }}
    >
      {children}

      <ConfirmationDialog
        confirmButtonText="Go to set up"
        isOpen={Boolean(missingRequirement)}
        title={`No ${pluralize(
          missingRequirement?.condition ?? "objects",
        )} found`}
        variant="warning"
        onClose={() => setMissingRequirement(undefined)}
        onConfirm={() => navigate(missingRequirement?.to ?? "")}
      >
        <Paragraph>
          In order to use this condition you need to set up{" "}
          {missingRequirement?.condition === "event" ? "an" : "a"}{" "}
          {missingRequirement?.condition}. Would you like to create one now?
        </Paragraph>
      </ConfirmationDialog>

      {showCreateTraitEnrichmentModal && (
        <CreateTraitEnrichmentModal
          parentModel={parentModel}
          conditions={
            (toSingleCondition(selectedCondition.property.column.conditions) ??
              []) as TraitDefinitionCondition[]
          }
          trait={selectedTrait}
          onClose={() => setSelectedCondition(null)}
          onSubmit={handleAddAdditionalColumn}
        />
      )}

      {showCreateTraitModal && parentModel && (
        <CreateTraitModal
          condition={selectedCondition}
          parent={parentModel}
          onClose={() => setSelectedCondition(null)}
          onTraitCreated={onTraitCreated}
        />
      )}

      {selectedCondition && selectedCondition.type === ConditionType.Event && (
        <AudienceEventTraitForm
          condition={selectedCondition}
          parent={parentModel}
          title="Add event trait"
          onClose={() => setSelectedCondition(null)}
          onSubmit={handleAddAdditionalColumn}
        />
      )}
    </QueryBuilderContext.Provider>
  );
};
