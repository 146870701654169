import { FC } from "react";

import { TraitConfig } from "@hightouch/lib/query/visual/types/trait-definitions";
import { AddBoxIcon, Column } from "@hightouchio/ui";
import {
  Controller,
  Control,
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { ParentModel } from "src/pages/audiences/types";
import {
  ConditionType,
  FilterableColumn,
  initialPropertyCondition,
  initialRootPropertyCondition,
} from "src/types/visual";

import { DetailButton } from "../explore/visual/condition-buttons";
import { NestedPropertyFilter } from "../explore/visual/nested-property-filter";

type PartialFormValues =
  | {
      config: TraitConfig;
    }
  | { conditions: [] };

type MinimalRelationship = {
  to_model: {
    filterable_audience_columns: FilterableColumn[];
  };
};

type TraitConditionsProps = {
  parentModel: ParentModel;
  relationship: MinimalRelationship | undefined | null;
  isTraitEnrichment?: boolean;
};

export const TraitConditions: FC<Readonly<TraitConditionsProps>> = ({
  parentModel,
  relationship,
  isTraitEnrichment,
}) => {
  const formContext = useFormContext();

  const pathToConditions = isTraitEnrichment
    ? "conditions"
    : "config.conditions";

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const topLevelConditions = useFieldArray<
    PartialFormValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    typeof pathToConditions,
    "id"
  >({
    control: formContext.control as unknown as Control<PartialFormValues>,
    name: pathToConditions,
  });

  const topLevelCondition = useWatch({
    control: formContext.control,
    name: `${pathToConditions}.0`,
    defaultValue: initialRootPropertyCondition,
  });

  const changeWrappingConditionType = (
    conditionIndex: number,
    newConditionType: ConditionType.And | ConditionType.Or,
  ) => {
    topLevelConditions.update(conditionIndex, {
      ...topLevelCondition,
      type: newConditionType,
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  // Note: There can only be one top-level condition, so we only want to add a top-level condition if there are none present
  if (topLevelConditions.fields.length === 0) {
    return (
      <AddConditionButton
        isDisabled={!relationship}
        onClick={() => topLevelConditions.append(initialRootPropertyCondition)}
      />
    );
  }

  return (
    <FormProvider {...formContext}>
      <Column>
        {/* Note: there should only be one top-level condition due to the conditional above*/}
        {topLevelConditions.fields.map((condition, index) => (
          <SubConditions
            key={condition.id}
            pathToConditions={`${pathToConditions}.${index}.conditions`}
            isWrappedWithAndCondition={condition.type === ConditionType.And}
            parentConditionIndex={index}
            parentModel={parentModel}
            relationship={relationship}
            onToggleWrappingConditionType={changeWrappingConditionType}
          />
        ))}
      </Column>
    </FormProvider>
  );
};

type SubConditionsProps = {
  parentModel: ParentModel;
  relationship: MinimalRelationship | undefined | null;
  pathToConditions: string;
  parentConditionIndex: number;
  isWrappedWithAndCondition: boolean;
  onToggleWrappingConditionType: (
    parentConditionIndex: number,
    newConditionType: ConditionType.And | ConditionType.Or,
  ) => void;
};

const SubConditions: FC<Readonly<SubConditionsProps>> = ({
  pathToConditions,
  isWrappedWithAndCondition,
  parentConditionIndex,
  parentModel,
  relationship,
  onToggleWrappingConditionType,
}) => {
  const { control } = useFormContext();

  const conditions = useFieldArray({
    control,
    name: pathToConditions,
  });

  return (
    <Column gap={conditions.fields.length ? 4 : 0}>
      {conditions.fields.map((condition, conditionIndex) => (
        <Controller
          key={condition.id}
          name={`${pathToConditions}.${conditionIndex}`}
          control={control}
          render={({ field }) => (
            <NestedPropertyFilter
              showSecondaryFilter
              audience={undefined}
              condition={field.value}
              columns={relationship?.to_model?.filterable_audience_columns}
              traits={[]}
              parent={parentModel}
              isFirstCondition={conditionIndex === 0}
              isWrappedWithAndCondition={isWrappedWithAndCondition}
              onToggleWrappingConditionType={(newConditionType) =>
                onToggleWrappingConditionType(
                  parentConditionIndex,
                  newConditionType,
                )
              }
              onChange={(value) => field.onChange({ ...field.value, ...value })}
              onRemove={() => conditions.remove(conditionIndex)}
            />
          )}
        />
      ))}
      <AddConditionButton
        isDisabled={!relationship}
        onClick={() => conditions.append(initialPropertyCondition)}
      />
    </Column>
  );
};

type AddConditionButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
};

const AddConditionButton: FC<Readonly<AddConditionButtonProps>> = ({
  isDisabled,
  onClick,
}) => (
  <DetailButton
    icon={AddBoxIcon}
    isDisabled={isDisabled}
    size="sm"
    onClick={onClick}
  >
    Where...
  </DetailButton>
);
