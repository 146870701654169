import { FC, Suspense } from "react";

import {
  Box,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
  Spinner,
  useToast,
} from "@hightouchio/ui";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import { OutletContext as OrganizationOutletContext } from "src/pages/organizations";
import {
  UserGroupQuery,
  useDeleteUserGroupMutation,
  useUpdateUserGroupMutation,
  useUserGroupQuery,
} from "src/graphql";
import { Warning } from "src/components/warning";
import { PermissionedEditableHeading } from "src/components/permission";
import { isDefaultRole } from "src/pages/organizations/roles/utils";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";
import { DeleteButton } from "src/components/delete-button";
import { captureException } from "@sentry/react";
import { RouteTabs } from "src/components/route-tabs";

export type OutletContext = OrganizationOutletContext & {
  userGroup: NonNullable<UserGroupQuery["user_groups_by_pk"]>;
};

export const ViewRole: FC = () => {
  const { organization } = useOutletContext<OrganizationOutletContext>();
  const { roleId } = useParams();

  const { data: userGroup } = useUserGroupQuery(
    {
      id: roleId ?? "",
    },
    {
      select: (data) => data.user_groups_by_pk,
      enabled: Boolean(roleId),
      suspense: true,
    },
  );

  const tabs = [
    { title: "Workspaces", path: "workspaces" },
    { title: "Members", path: "members" },
  ];

  if (!userGroup) return <Warning title="Role not found" />;

  return (
    <>
      <GroupHeading userGroup={userGroup} />
      <RouteTabs tabs={tabs} depth={4} px={6} />
      <Suspense fallback={<Spinner size="lg" m="auto" />}>
        <Outlet context={{ organization, userGroup }} />
      </Suspense>
    </>
  );
};

const GroupHeading = ({
  userGroup,
}: {
  userGroup: OutletContext["userGroup"];
}) => {
  const { toast } = useToast();
  const { isOrganizationAdmin } = useOrganizationPermissions();
  const navigate = useNavigate();
  const updateUserGroup = useUpdateUserGroupMutation();
  const deleteUserGroup = useDeleteUserGroupMutation();
  const isDefault = isDefaultRole(userGroup);

  return (
    <Row p={6} w="100%" justify="space-between">
      <PermissionedEditableHeading
        isDisabled={isDefault}
        value={userGroup.name}
        onChange={async (name) => {
          try {
            await updateUserGroup.mutateAsync({
              id: userGroup?.id,
              input: {
                name,
              },
            });
            toast({
              id: "update",
              title: "Group name updated",
              variant: "success",
            });
          } catch (error) {
            captureException(error);
            toast({
              id: "update",
              title: "There was an error updating the group name",
              variant: "error",
            });
          }
        }}
      />
      <Box
        visibility={
          isDefaultRole(userGroup) || !isOrganizationAdmin
            ? "hidden"
            : "visible"
        }
      >
        <Menu>
          <MenuActionsButton />
          <MenuList>
            <DeleteButton
              label="group"
              variant="menu"
              onSuccess={() => {
                navigate("/organization/groups");
              }}
              onDelete={async () => {
                await deleteUserGroup.mutateAsync({
                  id: userGroup.id,
                });
              }}
            />
          </MenuList>
        </Menu>
      </Box>
    </Row>
  );
};
