import { FC, useEffect } from "react";

import {
  ArrowRightIcon,
  Badge,
  Column,
  Combobox,
  ExternalLinkIcon,
  Heading,
  IconButton,
  Row,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import noop from "lodash/noop";
import { Helmet } from "react-helmet";

import { PageHeader } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { PageAlert } from "src/components/page-alert";
import { RouteTabs } from "src/components/route-tabs";
import {
  useParentModelsWithInteractionModelsQuery,
  useSavedChampaignViewQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import {
  Link,
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "src/router";

type CampaignProps = {
  parentModelOptions: {
    label: string;
    value: string;
    sourceId: string;
    icon: string;
  }[];
  interactionModelIdsByParentModelId: Map<string, string[]>;
};

const Layout: FC<CampaignProps> = ({
  parentModelOptions,
  interactionModelIdsByParentModelId,
}) => {
  const { enableCampaignAds } = useFlags();

  const { savedViewId } = useParams<{
    assetType: string;
    savedViewId?: string;
  }>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const urlId = searchParams.get("id");
  const parentModelId = urlId
    ? // Parent model id needs to be a number, but the listed type is a string (bigint problem)
      (parseInt(urlId) as unknown as string)
    : undefined;
  const sourceId = parentModelOptions.find(
    (option) => option.value === parentModelId,
  )?.sourceId;

  const interactionModelIds = interactionModelIdsByParentModelId.get(
    parentModelId ?? "",
  );

  const savedCampaignViewQuery = useSavedChampaignViewQuery(
    {
      id: savedViewId ?? "",
    },
    {
      enabled: Boolean(savedViewId),
      select: (data) => data.saved_campaign_views_by_pk,
    },
  );

  const savedView = savedCampaignViewQuery.data;

  // Set parent model when the saved view is loaded
  useEffect(() => {
    if (savedView?.parent_model) {
      setSearchParams({ id: savedView.parent_model.id });
    }
  }, [savedView]);

  // Navigate back to base page if saved view does not exist
  useEffect(() => {
    if (savedViewId && !savedView && !savedCampaignViewQuery.isLoading) {
      navigate(`/campaigns/emails?id=${savedViewId}`);
    }
  }, [savedViewId, savedView, savedCampaignViewQuery.isLoading]);

  // Automatically select the parent model if none is selected
  useEffect(() => {
    if (!savedViewId && !parentModelId && parentModelOptions.length > 0) {
      const firstOption = parentModelOptions?.[0];

      if (firstOption) {
        setSearchParams({ id: firstOption.value });
      }
    }
  }, [savedViewId, parentModelId, parentModelOptions]);

  return (
    <>
      <PageHeader stickToTop={false}>
        {parentModelOptions.length === 0 && (
          <PageAlert
            title="First, you need to configure a parent model"
            message="This workspace doesn’t contain any parent models that can be used in Campaigns."
            link={
              <Link href="/schema-v2">
                Go to schema setup <ArrowRightIcon />
              </Link>
            }
          />
        )}
        <Column gap={4}>
          <Row bg="white" width="100%" gap={4} px={6} py={4}>
            <Heading size="xl">Campaigns</Heading>
            <Row align="center" gap={2}>
              <Combobox
                placeholder="Select a parent model..."
                isLoading={savedCampaignViewQuery.isLoading}
                optionAccessory={(option) => ({
                  type: "image",
                  url: option.icon,
                })}
                options={parentModelOptions}
                value={parentModelId}
                onChange={(id) => {
                  if (id) {
                    navigate(`/campaigns/emails?id=${id}`);
                  }
                }}
                width="xs"
              />
              <Link
                href={`/schema-v2/view/query?id=${parentModelId}&source=${sourceId}`}
                isExternal
              >
                <Tooltip message="View parent model">
                  <IconButton
                    icon={ExternalLinkIcon}
                    aria-label="Link to selected audience"
                    variant="tertiary"
                    onClick={noop}
                  />
                </Tooltip>
              </Link>
            </Row>
          </Row>
          <RouteTabs
            tabs={[
              {
                title: "Emails",
                path: "emails",
              },
              {
                title: (
                  <Row gap={2}>
                    Ads
                    {!enableCampaignAds && (
                      <Badge variant="beta" size="sm">
                        Coming soon!
                      </Badge>
                    )}
                  </Row>
                ),
                path: "ads",
                isDisabled: !enableCampaignAds,
              },
            ]}
            px={6}
          />
        </Column>
      </PageHeader>

      <Column height="100%">
        <Outlet
          context={{
            sourceId,
            parentModelId,
            interactionModelIds: interactionModelIds ?? [],
            savedView,
            savedViewLoading: savedCampaignViewQuery.isLoading,
          }}
        />
      </Column>
    </>
  );
};

const Loader = () => {
  const { data: entitlementsData } = useEntitlements(true, true);
  const campaignsEnabled = entitlementsData.entitlements.campaigns;

  const parentModelsWithInteractionModelsQuery =
    useParentModelsWithInteractionModelsQuery();

  const interactionModelIdsByParentModelId = new Map<string, string[]>();

  const parentModelOptions =
    parentModelsWithInteractionModelsQuery.data?.segments?.map((segment) => {
      interactionModelIdsByParentModelId.set(
        segment.id,
        segment.relationships.map(({ to_model }) => to_model.id),
      );

      return {
        label: segment.name,
        value: segment.id,
        sourceId: segment.connection?.id,
        icon: segment.connection?.definition.icon ?? "",
      };
    }) ?? [];

  if (!campaignsEnabled) {
    return <Navigate to="/" replace />;
  }

  if (parentModelsWithInteractionModelsQuery.isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>

      <Layout
        parentModelOptions={parentModelOptions}
        interactionModelIdsByParentModelId={interactionModelIdsByParentModelId}
      />
    </>
  );
};

export default Loader;
