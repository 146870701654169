import { FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Column,
  DeleteIcon,
  FolderIcon,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Paragraph,
  Row,
  SearchInput,
  SparkleIcon,
  TagIcon,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import audiencePlaceholder from "src/assets/placeholders/audience.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { AudiencesDemo } from "src/components/audiences/audiences-demo";
import {
  isFilterActive,
  labelFilter,
} from "src/components/folders/filter-helpers";
import {
  Filters,
  createdByFilterConfig,
  labelFilterConfig,
  parentModelFilterConfigForAudiences,
  subsetsFilterConfigForAudiences,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { PageSidebar } from "src/components/layout/page-sidebar";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PageAlert } from "src/components/page-alert";
import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  AudiencesQuery,
  AudiencesQueryVariables,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAddLabelsToAudiencesMutation,
  useAudienceFiltersQuery,
  useAudiencesQuery,
  useDeleteModelsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import { QueryType } from "src/types/models";
import {
  OrderBy,
  PageTable,
  SortOption,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { QueryTypeBadge } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof SegmentsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SegmentsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  {
    key: "syncs_aggregate.count" as any,
    direction: OrderBy.Desc,
    label: "Number of syncs",
  },
  { key: "last_run_size", direction: OrderBy.DescNullsLast, label: "Largest" },
  { key: "last_run_size", direction: OrderBy.AscNullsLast, label: "Smallest" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const useFastAudiencesQuery = getQueryWithOpts<
  AudiencesQuery,
  AudiencesQueryVariables
>(useAudiencesQuery, {
  useFastEndpoint: true,
});

export const Audiences: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [search, setSearch] = useQueryState("search");

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const {
    selectedFolder,
    setSelectedFolder,
    setMovingToFolder,
    movingToFolder,
    header,
    refetchFolders,
    audienceCount,
    nestedFolders: folders,
    loadingFolders,
  } = useFolderState({
    search,
    resourceType: "models",
    folderType: "audiences",
  });

  const { limit, offset, page, setPage } = useTableConfig<SegmentsOrderBy>();
  const orderBy = useTableSort<SegmentsOrderBy>(initialSort, sortOptions);

  const { mutateAsync: addLabels } = useAddLabelsToAudiencesMutation();
  const { mutateAsync: bulkDelete } = useDeleteModelsMutation();
  const { data: allAudiences, isLoading: filtersLoading } =
    useAudienceFiltersQuery(undefined, {
      select: (data) => data.segments,
    });

  const memodLabelFilterConfig = useMemo(
    () => labelFilterConfig(allAudiences || []),
    [allAudiences],
  );

  const memodCreatedByFilterConfig = useMemo(
    () => createdByFilterConfig(allAudiences || []),
    [allAudiences],
  );

  const memodParentModelFilterConfig = useMemo(
    () =>
      parentModelFilterConfigForAudiences(
        (allAudiences?.map((a) => a.parent).filter(Boolean) as {
          name: string;
          id: string;
        }[]) || [],
      ),
    [allAudiences],
  );

  const memodSubsetFilterConfig = useMemo(
    () =>
      subsetsFilterConfigForAudiences(
        allAudiences?.flatMap(
          (a) =>
            a.subsets?.map((s) => ({
              name: s.subset_value.name,
              groupName: s.subset_value.subset_group.name,
              id: s.subset_value.id,
            })) ?? [],
        ) ?? [],
      ),
    [allAudiences],
  );

  const filterDefinitions = useMemo(() => {
    const filters: {
      viewKey: string;
      loading: boolean;
      filters: Record<string, any>;
    } = {
      viewKey: "audiences",
      loading: filtersLoading,
      filters: {
        parent: {
          options: memodParentModelFilterConfig,
          title: "Parent model",
        },
        created: { options: memodCreatedByFilterConfig, title: "Created by" },
        label: { options: memodLabelFilterConfig, title: "Labels" },
        duplicate_keys: {
          options: [
            { id: "true", label: "True" },
            { id: "false", label: "False" },
          ],
          title: "Duplicate primary keys",
        },
        subsets: {
          options: memodSubsetFilterConfig,
          title: "Subsets",
        },
      },
    };

    if (allAudiences?.some((f) => f.parent?.matchboosting_enabled)) {
      filters.filters.matchbooster_enabled = {
        canSearch: false,
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
        title: "Match Boosted",
      };
    }

    return filters;
  }, [
    memodSubsetFilterConfig,
    memodLabelFilterConfig,
    memodCreatedByFilterConfig,
    memodParentModelFilterConfig,
  ]);

  const {
    result: { state: filterState, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    if (!allAudiences?.length) {
      return {};
    }

    const folderFilter = (): SegmentsBoolExp => {
      const folderIds: string[] = [];

      if (selectedFolder?.id) {
        folderIds.push(selectedFolder.id);
      }
      if (selectedFolder?.flattenedChildren?.length) {
        folderIds.push(...selectedFolder.flattenedChildren.map((f) => f.id));
      }

      if (folderIds.length) {
        return {
          folder_id: { _in: folderIds },
        };
      } else {
        return {};
      }
    };

    const matchboosterFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.matchbooster_enabled)) {
        if (filterState.matchbooster_enabled.selected.length !== 1) {
          return {};
        }
        if (
          filterState.matchbooster_enabled.selected.map((f) => f.id)[0] ===
          "true"
        ) {
          return {
            parent: { matchboosting_enabled: { _eq: true } },
          };
        }
        if (
          filterState.matchbooster_enabled.selected.map((f) => f.id)[0] ===
          "false"
        ) {
          return {
            parent: {
              matchboosting_enabled: { _eq: false },
            },
          };
        }
      }
      return {};
    };

    const subsetsFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.subsets)) {
        return {
          subsets: {
            subset_value_id: {
              _in: filterState.subsets.selected.map((f) => f.id),
            },
          },
        };
      }
      return {};
    };

    const parentFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.parent)) {
        return {
          visual_query_parent_id: {
            _in: filterState.parent.selected.map((f) => f.id),
          },
        };
      }
      return {};
    };

    const duplicateKeyFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.duplicate_keys)) {
        if (filterState.duplicate_keys.selected.length !== 1) {
          return {};
        }
        if (
          filterState.duplicate_keys.selected.map((f) => f.id)[0] === "true"
        ) {
          return {
            last_run_duplicate_primary_keys: { _gt: 0 },
            destination_instances: {
              last_run_planner_type: { _neq: "all", _is_null: false },
            },
          };
        }
        if (
          filterState.duplicate_keys.selected.map((f) => f.id)[0] === "false"
        ) {
          return {
            _and: [
              {
                _or: [
                  { last_run_duplicate_primary_keys: { _eq: 0 } },
                  { last_run_duplicate_primary_keys: { _is_null: true } },
                  {
                    _not: {
                      destination_instances: {
                        last_run_planner_type: { _neq: "all", _is_null: false },
                      },
                    },
                  },
                ],
              },
            ],
          };
        }
      }
      return {};
    };

    const createdFilter = () => {
      if (isFilterActive(filterState.created)) {
        return {
          _or: [
            {
              created_by: {
                _in: filterState.created.selected.map((f) => f.id),
              },
            },
            {
              created_by: { _is_null: true },
            },
          ],
        };
      }
      return {};
    };

    const andFilter = () => {
      const filter = {
        ...parentFilter(),
      };
      if (Object.keys(filter).length) {
        return {
          _and: [filter],
        };
      }
      return {};
    };

    const hasuraFilters: SegmentsBoolExp = {
      ...subsetsFilter(),
      ...andFilter(),
      ...folderFilter(),
      ...duplicateKeyFilter(),
      ...labelFilter(filterState.label),
      ...createdFilter(),
      ...matchboosterFilter(),
    };

    return hasuraFilters;
  }, [selectedFolder, filterState]);

  const hasuraFiltersWithSearch = useMemo(() => {
    if (search) {
      return { ...hasuraFilters, name: { _ilike: `%${search}%` } };
    } else {
      return hasuraFilters;
    }
  }, [hasuraFilters, search]);

  const audiencesQuery = useFastAudiencesQuery(
    {
      filters: { ...hasuraFiltersWithSearch, query_type: { _eq: "visual" } },
      offset,
      limit,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const audiences = audiencesQuery.data?.segments;
  const filteredAudienceCount =
    audiencesQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  const bulkDeleteAudiences = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("audience", count);

    try {
      await bulkDelete({ where: { id: { _in: selectedRows.map(String) } } });

      toast({
        id: "bulk-delete-audiences",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      if (
        error.message.startsWith("Foreign key violation") &&
        error.message.includes("priority_list")
      ) {
        const message =
          count > 1
            ? `One or more audiences are being used in a priority list. Remove the audiences from the priority list(s) and try again.`
            : `The audience is being used in a priority list. Remove the audience from the priority list and try again.`;

        toast({
          id: "bulk-delete-audiences",
          title: `Failed to delete ${pluralizedLabel}`,
          message,
          variant: "error",
        });

        setConfirmingDelete(false);
      } else if (error.message.startsWith("Foreign key violation")) {
        toast({
          id: "bulk-delete-audiences",
          title: `Failed to delete ${pluralizedLabel}`,
          message:
            "One or more audiences cannot be deleted because other resources rely on them.",
          variant: "error",
        });
      } else {
        toast({
          id: "bulk-delete-audiences",
          title: `Failed to delete ${pluralizedLabel}`,
          variant: "error",
        });

        Sentry.captureException(error);
      }
    }
  };

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No audiences found",
      error: "Audiences failed to load, please try again.",
    }),
    [],
  );

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/audiences/${id}/query`, navigate, event),
    [navigate],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  return (
    <>
      <PermissionProvider
        permission={{
          v1: { resource: "audience", grant: "update" },
        }}
      >
        <Page
          sidebar={
            <PageSidebar
              header={
                <SearchInput
                  placeholder="Search all audiences..."
                  value={search ?? ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              }
            >
              <Folders
                folders={folders ?? []}
                loading={loadingFolders}
                audienceCount={audienceCount}
                audiencesRootName="All audiences"
                refetchFolders={refetchFolders}
                rootFolder="audiences"
                selectedFolder={selectedFolder}
                setRootFolder={() => undefined}
                setSelectedFolder={setSelectedFolder}
                viewType="models"
              />
              <Box borderBottom="1px solid" borderColor="base.divider" />
              <Filters
                clearFilters={clearFilters}
                createView={createView}
                creatingView={creatingView}
                deleteView={deleteView}
                filters={filterData}
                resetFilters={resetViewFilters}
                resource="audience"
                selectView={selectView}
                selectedView={selectedView}
                updateCurrentView={updateCurrentView}
                updatingView={updatingView}
                viewNotSaved={viewNotSaved}
                views={views}
              />
            </PageSidebar>
          }
          title="Audiences"
        >
          <PageTable
            header={
              <>
                <Heading isTruncated size="xl">
                  {header}
                </Heading>

                <Row flexShrink={0} gap={3}>
                  {selectedRows.length > 0 && (
                    <Row align="center" flexShrink={0} gap={2}>
                      <Text>{`${pluralize(
                        "audience",
                        selectedRows.length,
                        true,
                      )} selected`}</Text>
                      <Menu>
                        <MenuButton>Actions</MenuButton>
                        <MenuList>
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "update",
                              },
                            }}
                            icon={FolderIcon}
                            onClick={() => {
                              setMovingToFolder(true);
                            }}
                          >
                            Move to folder
                          </PermissionedMenuItem>
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "update",
                              },
                            }}
                            icon={TagIcon}
                            onClick={() => {
                              setAddingLabels(true);
                            }}
                          >
                            Add labels
                          </PermissionedMenuItem>
                          <MenuDivider />
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "delete",
                              },
                            }}
                            icon={DeleteIcon}
                            variant="danger"
                            onClick={() => {
                              setConfirmingDelete(true);
                            }}
                          >
                            Delete
                          </PermissionedMenuItem>
                        </MenuList>
                      </Menu>
                    </Row>
                  )}
                  <PermissionedLinkButton
                    href={`/audiences/new${
                      selectedFolder ? `?folder=${selectedFolder.id}` : ""
                    }`}
                    permission={{
                      v1: { resource: "audience", grant: "create" },
                    }}
                    variant="primary"
                    onClick={() => {
                      analytics.track("Add Audience Clicked");
                    }}
                  >
                    Add audience
                  </PermissionedLinkButton>
                </Row>
              </>
            }
            rowHeight="80px"
            columns={[
              {
                name: "Name",
                cell: ({ name, query_runs, splits_aggregate }) => (
                  <Column gap={1} overflow="hidden">
                    <TextWithTooltip message={name} fontWeight="medium">
                      {name ?? "Private model"}
                    </TextWithTooltip>
                    <Row gap={2}>
                      <QueryTypeBadge type={QueryType.Visual}>
                        {query_runs?.[0]
                          ? `${abbreviateNumber(query_runs?.[0]?.size)}`
                          : "Unknown size"}
                      </QueryTypeBadge>
                      {splits_aggregate?.aggregate?.count &&
                      splits_aggregate.aggregate.count > 0 ? (
                        <Badge size="sm">
                          {splits_aggregate.aggregate.count} splits
                        </Badge>
                      ) : null}
                    </Row>
                  </Column>
                ),
              },
              {
                name: "Parent model",
                max: "0.5fr",
                cell: ({ parent }) => {
                  return (
                    <Row align="center" gap={2} overflow="hidden">
                      <IntegrationIcon
                        src={parent?.connection?.definition?.icon}
                        name={parent?.connection?.definition?.name}
                      />
                      <Column gap={1} overflow="hidden">
                        <Text isTruncated fontWeight="medium">
                          {parent?.name ?? "Private model"}
                        </Text>
                        {parent?.matchboosting_enabled && (
                          <Badge
                            size="sm"
                            icon={SparkleIcon}
                            iconColor="warning.400"
                          >
                            Boosted
                          </Badge>
                        )}
                      </Column>
                    </Row>
                  );
                },
              },
              {
                name: "Syncs",
                min: "232px",
                max: "max-content",
                cell: ({ syncs }) => {
                  return <SyncsCell syncs={syncs} />;
                },
                breakpoint: "sm",
              },
              {
                ...LastUpdatedColumn,
                breakpoint: "md",
              },
              {
                name: "Folder",
                max: ".5fr",
                cell: ({ folder }) => {
                  if (folder) {
                    return (
                      <Row gap={2} align="center" overflow="hidden">
                        <Box as={FolderIcon} fontSize="16px" />
                        <Text isTruncated fontWeight="medium">
                          {folder.name}
                        </Text>
                      </Row>
                    );
                  }
                  return "--";
                },
                breakpoint: "lg",
              },
              {
                name: "Labels",
                max: ".5fr",
                cell: ({ labels }) => {
                  if (isEmpty({ labels })) {
                    return "--";
                  }
                  return <Labels labels={labels} />;
                },
                breakpoint: "lg",
              },
            ]}
            data={audiences}
            error={Boolean(audiencesQuery.error)}
            loading={audiencesQuery.isFetching}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
            pagination={{
              count: filteredAudienceCount,
              label: "audiences",
              page,
              setPage,
            }}
            sortOptions={sortOptions}
          />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="audience"
        content={
          <Column gap={2}>
            <Paragraph>
              Are you sure you want to delete {selectedRows.length}{" "}
              {pluralize("audience", selectedRows.length)}? You will not be able
              to undo this.
            </Paragraph>

            <Paragraph>
              All syncs associated with the selected audiences will also be
              deleted.
            </Paragraph>
          </Column>
        }
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteAudiences}
      />
      <EditLabelModal
        rows={selectedRows.length}
        isOpen={addingLabels}
        resourceType={ResourceType.Model}
        onClose={() => setAddingLabels(false)}
        onSubmit={async (labels) => {
          await addLabels({ ids: selectedRows.map(String), labels });
          onRowSelect([]);
        }}
      />

      {movingToFolder && (
        <MoveFolder
          folder={null}
          folderType="audiences"
          modelIds={selectedRows.map((id) => id.toString())}
          viewType="models"
          onClose={() => {
            setMovingToFolder(false);
            audiencesQuery.refetch();
            onRowSelect([]);
          }}
        />
      )}
    </>
  );
};

const Loader = () => {
  const { resources } = useUser();
  const { data: entitlementsData } = useEntitlements(true, true);
  const { schemaV2 } = useFlags();
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  if (!audiencesEnabled) {
    return <AudiencesDemo />;
  }

  if (resources?.audience) {
    return <Audiences />;
  }

  const pageAlertProps = {
    source: {
      title: "First, you need to configure a data source",
      message:
        "Hightouch must be connected to least one data source before you can create an audience. Your source can be a data warehouse, spreadsheet, or other data system.",
      button: (
        <PermissionedLinkButton
          href="/sources/new"
          permission={{ v2: { resource: "source", grant: "can_create" } }}
          variant="primary"
        >
          Configure data source
        </PermissionedLinkButton>
      ),
      href: "/sources/new",
    },
    parentModel: {
      title: "First, you need to configure a parent model",
      message:
        "A parent model typically defines users, companies, or anything else that represents a customer. Later, when creating an audience, you'll filter a parent model to include only customers who have certain attributes or have performed specific actions.",
      button: (
        <PermissionedLinkButton
          permission={{
            v1: {
              resource: "audience_schema",
              grant: "create",
            },
          }}
          href={schemaV2 ? `/schema-v2/new` : "/schema/parent-models/new"}
          variant="primary"
        >
          Configure parent model
        </PermissionedLinkButton>
      ),
      href: schemaV2 ? `/schema-v2/new` : "/schema/parent-models/new",
    },
  };

  let props;

  if (!resources?.source) {
    props = pageAlertProps.source;
  } else if (!resources?.parentModel) {
    props = pageAlertProps.parentModel;
  }

  return (
    <Page
      fullWidth
      outsideTopbar={props ? <PageAlert {...props} /> : null}
      title="Audiences"
    >
      <Row align="center" justify="space-between" mb={8}>
        <Heading isTruncated size="xl">
          Audiences
        </Heading>
      </Row>

      <Placeholder
        content={{
          image: audiencePlaceholder,
          title: "No audiences in this workspace",
          body: "An audience is a list of customers defined using a visual segment builder. This feature allows marketers to query the data warehouse and define experiments tailored to their campaigns using a no-code interface that doesn't require SQL knowledge.",
          button: props ? null : (
            <PermissionedLinkButton
              href="/audiences/new"
              permission={{ v1: { resource: "audience", grant: "create" } }}
              variant="primary"
            >
              Add audience
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;
