import { FC, ReactNode } from "react";
import { Column } from "@hightouchio/ui";

interface Props {
  children: ReactNode;
  top?: string;
}

export const Sidebar: FC<Props> = ({ children, top = "192px" }) => {
  return (
    <Column
      position="sticky"
      top={top}
      maxWidth="280px"
      width="100%"
      ml={12}
      alignSelf="flex-start"
      height="max-content"
    >
      {children}
    </Column>
  );
};
