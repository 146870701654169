import { ModelDraft } from "src/components/models/draft";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { EventsRouter } from "src/events";
import { Analytics } from "src/pages/analytics";
import { Audience } from "src/pages/audiences/audience";
import Audiences from "src/pages/audiences/audiences";
import { CloneAudience } from "src/pages/audiences/clone-audience";
import { CreateAudience } from "src/pages/audiences/create-audience";
import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { SetupAudiences } from "src/pages/audiences/setup/setup-audiences";
import { SyncTemplate } from "src/pages/audiences/setup/sync-template";
import { Copilot } from "src/pages/copilot";
import { DecisionEngineRoutes } from "src/pages/decision-engines";
import { GovernanceRoutes } from "src/pages/governance";
import { Home } from "src/pages/home";
import { IdentityResolutionRoutes } from "src/pages/identity-resolution";
import { JourneysRoutes } from "src/pages/journeys";
import { CloneModel } from "src/pages/models/model/clone";
import { CreateModel } from "src/pages/models/create-model";
import { ModelWrapper } from "src/pages/models/model";
import { ModelQueryWrapper } from "src/pages/models/model/query";
import Models from "src/pages/models/models";
import { MonitoringRoutes } from "src/pages/monitoring";
import { OrganizationRoutes } from "src/pages/organizations";
import { SchemaPage } from "src/pages/schema";
import { SequenceRoutes } from "src/pages/sequences";
import { SettingsRouter } from "src/pages/settings";
import { Setup } from "src/pages/setup";
import { SyncsRoutes } from "src/pages/syncs";
import { Traits } from "src/pages/traits/traits";
import { MetricsRoutes } from "src/pages/metrics/index";
import { CampaignsRoutes } from "src/pages/campaigns";
import { MiscRoutes } from "src/pages/misc";
import { SourcesRouter } from "src/pages/sources";
import { ExtensionsRouter } from "src/pages/extensions";
import { DestinationsRouter } from "src/pages/destinations";
import { PriorityListsRouter } from "src/pages/priority-lists";
import { Navigate, Route, Routes } from "src/router";
import { Layout } from "src/components/layout";

export const SlugRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="onboarding/*" element={<Setup />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="models/:model_id/clone"
          element={
            <PermissionedRoute
              permission={{ v1: { resource: "model", grant: "create" } }}
              redirect="/models"
            >
              <CloneModel />
            </PermissionedRoute>
          }
        />
        <Route path="models/:model_id/query" element={<ModelQueryWrapper />} />
        <Route
          path="models/new"
          element={
            <PermissionedRoute
              permission={{ v1: { resource: "model", grant: "create" } }}
              redirect="/models"
            >
              <CreateModel />
            </PermissionedRoute>
          }
        />
        <Route path="models/:model_id/*" element={<ModelWrapper />} />
        <Route path="models/:model_id/draft" element={<ModelDraft />} />
        <Route
          path="models"
          element={
            <PermissionedRoute
              permission={{ v1: { resource: "model", grant: "read" } }}
              redirect="/"
            >
              <Models />
            </PermissionedRoute>
          }
        />
        <Route path="syncs/*" element={<SyncsRoutes />} />
        <Route path="sequences/*" element={<SequenceRoutes />} />
        <Route path="/sources/*" element={<SourcesRouter />} />
        <Route path="destinations/*" element={<DestinationsRouter />} />
        <Route path="alerting/*" element={<MonitoringRoutes />} />
        <Route path="extensions/*" element={<ExtensionsRouter />} />
        <Route path="settings/*" element={<SettingsRouter />} />
        <Route
          path="schema/sync-templates/new"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "sync_template", grant: "create" },
              }}
              redirect="/schema/sync-templates"
            >
              <CreateSyncTemplate />
            </PermissionedRoute>
          }
        />
        <Route
          path="metrics/*"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect="/home"
            >
              <MetricsRoutes />
            </PermissionedRoute>
          }
        />
        <Route
          path="schema/sync-templates/:sync_template_id"
          element={<SyncTemplate />}
        />
        <Route
          path="schema/:object/:id/query"
          element={<AudienceObjectQuery />}
        />
        <Route
          path="schema/:object/new"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect="/schema/:object"
            >
              <CreateAudienceObject />
            </PermissionedRoute>
          }
        />
        <Route path="schema/:object/:id" element={<AudienceObject />} />
        <Route path="schema/*" element={<SetupAudiences />} />
        <Route
          path="schema/"
          element={<Navigate to="/schema/parent-models" replace />}
        />
        <Route path="schema-v2/*" element={<SchemaPage />} />
        <Route
          path="audiences/:id/clone"
          element={
            <PermissionedRoute
              permission={{ v1: { resource: "audience", grant: "create" } }}
              redirect="/audiences/:id"
            >
              <FormErrorProvider>
                <CloneAudience />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route
          path="audiences/new"
          element={
            <PermissionedRoute
              permission={{ v1: { resource: "audience", grant: "create" } }}
              redirect="/audiences"
            >
              <FormErrorProvider>
                <CreateAudience />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route path="audiences/:id/*" element={<Audience />} />
        <Route path="audiences" element={<Audiences />} />
        <Route path="priority-lists/*" element={<PriorityListsRouter />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="analytics/:id" element={<Analytics />} />
        <Route path="/copilot" element={<Copilot />} />
        <Route path="/campaigns/*" element={<CampaignsRoutes />} />
        <Route path="traits/*" element={<Traits />} />
        <Route path="idr/*" element={<IdentityResolutionRoutes />} />
        <Route path="events/*" element={<EventsRouter />} />
        <Route path="organization/*" element={<OrganizationRoutes />} />
        <Route path="journeys/*" element={<JourneysRoutes />} />
        <Route path="ai/*" element={<DecisionEngineRoutes />} />
        <Route path="governance/*" element={<GovernanceRoutes />} />
        <Route path="misc/*" element={<MiscRoutes />} />
      </Route>
    </Routes>
  );
};
