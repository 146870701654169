import { FC, useEffect, useState } from "react";

import {
  PropertyCondition,
  isInlineTraitColumn,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  Button,
  useToast,
  CloseIcon,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  Row,
  Heading,
  IconButton,
  ChakraModalBody,
  ChakraModalFooter,
  FormField,
  Text,
  TextInput,
  Textarea,
  Box,
  Alert,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import immutableUpdate, { Spec } from "immutability-helper";
import { useForm, Controller } from "react-hook-form";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  TraitDefinitionsConstraint,
  TraitDefinitionsInsertInput,
  useCreateTraitMutation,
} from "src/graphql";
import { isRelatedColumn, getInitialTraitColumn } from "src/types/visual";
import { ParentModel } from "src/pages/audiences/types";

import { CalculationSummary } from "../traits/calculation-summary";
import { FormProps, validationSchema } from "../traits/create-trait";
import { PreviewTrait } from "../traits/preview-trait";

type Props = {
  condition: PropertyCondition;
  parent: ParentModel;
  onClose: () => void;
  onTraitCreated: (conditionWithTrait: PropertyCondition) => Promise<void>;
};

/*
 * Takes a condition with an InlineTraitColumn and creates a top-level trait
 */
export const CreateTraitModal: FC<Readonly<Props>> = ({
  condition,
  parent,
  onClose,
  onTraitCreated,
}) => {
  const { hasValidationErrors } = useFormErrorContext();
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);

  const form = useForm<FormProps>({
    defaultValues: {
      is_template: false,
    },
    resolver: yupResolver(validationSchema),
  });
  const { control, reset, watch } = form;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const name = watch("name");
  const type = watch("type");
  const config = watch("config");
  const relationshipId = watch("relationship_id");

  const relatedModel = parent?.relationships.find(
    ({ id }) => id === relationshipId,
  )?.to_model;

  useEffect(() => {
    const property = condition.property;

    if (isRelatedColumn(property) && isInlineTraitColumn(property.column)) {
      const inlineTrait = property.column;
      reset({
        type: inlineTrait.traitType,
        parent_model_id: parent?.id,
        relationship_id: inlineTrait.relationshipId,
        config: {
          ...inlineTrait.traitConfig,
          conditions: inlineTrait.conditions,
        },
        is_template: false,
      });
    }
  }, [reset, parent?.id, condition.property]);

  const { mutateAsync: createTrait } = useCreateTraitMutation({
    onSuccess: () => {
      // XXX: This callback is intentionally blank.
      // After a mutation finishes, all active queries get invalidated.
      // That causes the audience query builder to refresh its query and we lose our in-progress conditions.
      // We want to retain them so that we can inject the newly created trait into the condition.
      // Adding an onSuccess to the mutation hook prevents this issue from happening.
    },
  });

  const wrappedOnSubmit = async (data: FormProps) => {
    try {
      if (hasValidationErrors()) {
        setShowValidationAlert(true);
        toast({
          id: "create-trait",
          title: "There was a problem saving your trait",
          message: "Please check your configuration and try again",
          variant: "error",
        });
        return;
      }

      setSubmitting(true);

      const traitInput: TraitDefinitionsInsertInput = {
        name: data.name,
        description: data.description,
        parent_model_id: data.parent_model_id,
        relationship_id: data.relationship_id,
        type: data.type,
        config: data.config,
        is_template: false,
      };

      const createdTrait = await createTrait({
        input: traitInput,
      });

      toast({
        id: "create-trait",
        title: "Trait successfully created",
        variant: "success",
      });

      // Inject the newly created trait into the condition
      if (createdTrait.insert_trait_definitions_one) {
        const updatedCondition = immutableUpdate(condition, {
          property: {
            $set: getInitialTraitColumn(
              createdTrait.insert_trait_definitions_one,
            ),
          },
          propertyOptions: {
            $merge: {
              traitType: "trait",
            },
          },
        } as Spec<PropertyCondition>);

        await onTraitCreated(updatedCondition);
      }

      onClose();
    } catch (error) {
      toast({
        id: "create-trait",
        title: "Trait failed to be created",
        message: error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? `There is an existing trait named "${data.name}" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onError = () => {
    toast({
      id: "create-trait",
      variant: "error",
      title: "Trait failed to be saved",
      message: "There were errors in your submission",
    });
  };

  return (
    <ChakraModal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      isOpen
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent
        maxHeight="90vh"
        maxWidth="700px"
        padding={0}
        my="auto"
      >
        <ChakraModalHeader
          alignItems="center"
          padding={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Row alignItems="center" justifyContent="space-between" width="100%">
            <Heading>New trait</Heading>
            <IconButton
              aria-label="Close modal"
              color="text.secondary"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </ChakraModalHeader>

        <ChakraModalBody m={0}>
          <Column flex={1} p={6} gap={6} overflowY="auto">
            <FormField
              label="Name"
              tip="Shows up in the audience query builder when selecting from a list of traits."
            >
              <Column gap={6}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextInput
                      placeholder="Enter a name"
                      width="100%"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <Textarea
                      placeholder="Enter a description"
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </Column>
            </FormField>

            <Column>
              <Text fontWeight="medium" mb={1}>
                Calculation summary
              </Text>
              {relatedModel && (
                <Box
                  borderRadius="6px"
                  border="1px solid"
                  borderColor="base.border"
                  p={4}
                >
                  <CalculationSummary
                    model={relatedModel}
                    type={type}
                    config={config}
                  />
                </Box>
              )}
            </Column>

            <PreviewTrait
              parentModel={parent}
              trait={{
                name,
                type,
                config,
                relationshipId,
              }}
            />

            {showValidationAlert && (
              <Alert
                variant="inline"
                type="error"
                title="Incomplete trait"
                message="Please check your trait calculation and fix all errors before saving."
              />
            )}
          </Column>
        </ChakraModalBody>

        <ChakraModalFooter
          borderTop="solid 1px"
          borderTopColor="base.border"
          bg="white"
          m={0}
          px={6}
          py={4}
          gap={3}
          borderRadius="inherit"
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isDisabled={submitting || showValidationAlert}
            isLoading={submitting}
            variant="primary"
            onClick={form.handleSubmit(wrappedOnSubmit, onError)}
          >
            Save trait
          </Button>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
