import { FC, useEffect, useState } from "react";

import {
  Alert,
  Button,
  Column,
  FormField,
  Select,
  SlugInput,
  TextInput,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import slugify from "@sindresorhus/slugify";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "src/router";
import { useDebounce } from "use-debounce";
import { number, object, string } from "yup";

import { topLevelRoutes } from "src/components/router/router";
import { SelectRegion } from "src/components/workspaces/select-region";
import { useUser } from "src/contexts/user-context";
import {
  useAccessibleOrganizationsQuery,
  useCreateWorkspaceMutation,
  useGenerateWorkspaceSlugQuery,
  useIsWorkspaceSlugAvailableQuery,
  usePartnerConnectLinkWorkspaceMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { HightouchRegionOptions } from "src/utils/regions";

const RESERVED_SLUGS = ["login", "signin", "signup"];
const NEW_WORKSPACE_ID = -1;

type Props = {
  onboarding?: {
    sourceType: string | undefined;
    destinationType: string | undefined;
  };
  onSubmit: (data: { id: number; slug: string }) => void;
};

type SlugValidationState = "valid" | "invalid" | "validating" | undefined;

export const CreateWorkspace: FC<Readonly<Props>> = ({
  onSubmit,
  onboarding,
}) => {
  const { user } = useUser();
  const { multiRegionEnabled } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as { partnerConnection: any } | undefined;

  const [error, setError] = useState<string | null>(null);

  // only pass through an org to restrict them to if they are an sso user
  const { defaultOrganization, organizations } =
    useAvailableOrganizationOptions();
  const hasOrganizations = Boolean(organizations.length);

  const { mutateAsync: linkWorkspace } =
    usePartnerConnectLinkWorkspaceMutation();
  const { mutateAsync: createWorkspace } = useCreateWorkspaceMutation();
  const validationSchema = object().shape({
    name: string().required("Name is required."),
    slug: string().required("Slug is required."),
    region: string().required("Region is required."),
    organization: number().nullable(),
  });
  const { control, handleSubmit, watch, formState, setValue } = useForm<{
    name: string;
    slug: string;
    region: string;
    organization: number;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: undefined,
      slug: undefined,
      region:
        onboarding?.sourceType === "bigquery" && !import.meta.env.DEV
          ? HightouchRegionOptions.GCP_US_EAST_4
          : HightouchRegionOptions.AWS_US_EAST_1,
      organization: defaultOrganization?.value,
    },
  });
  const { errors } = formState;

  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);

  const submit = async ({ name, slug, region, organization }) => {
    const lowercaseSlug = slug.toLowerCase();
    if (
      topLevelRoutes.includes(lowercaseSlug) ||
      RESERVED_SLUGS.includes(lowercaseSlug)
    ) {
      setError(
        `${slug} is a reserved slug. Pick a different slug for your workspace.`,
      );
      return;
    }

    try {
      setError(null);
      setIsCreatingWorkspace(true);

      const data = await createWorkspace({
        name,
        slug,
        region,
        organization:
          organization === NEW_WORKSPACE_ID ? undefined : organization,
        onboarding,
      });
      analytics.track("New Workspace Created", {
        workspace_id: data?.createWorkspace?.id,
      });

      if (state?.partnerConnection) {
        await linkWorkspace({
          uuid: state.partnerConnection.uuid,
          workspaceId: Number(data?.createWorkspace?.id),
        });
        navigate(`/partner-connect/${state.partnerConnection.uuid}`);
      } else {
        onSubmit({ id: data.createWorkspace.id, slug });
      }
    } catch (error: unknown) {
      setIsCreatingWorkspace(false);
      handleWorkspaceCreateError(error as Error);
    }
  };

  const handleWorkspaceCreateError = (error: Error) => {
    Sentry.captureException(error);

    if (
      error?.message.startsWith(
        "SSO users are not allowed to create workspaces",
      )
    ) {
      setError(`${error.message}.`);
      return;
    }

    setError("There was a problem creating your workspace.");
  };

  // Two flows for validating slugs:
  // 1. User enters a name and we generate a slug, potentially adding extra entropy.
  // 2. User has manually entered a slug and we validate it.
  const name = watch("name");
  const slug = watch("slug");

  const shouldGenerateSlug = !formState.dirtyFields.slug;

  const setSlug = (slug: string) => {
    setValue("slug", slug, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
  };

  // Use this to clear the slug if the name is cleared
  // The hook below will also do this, but there will be a delay from debouncing
  useEffect(() => {
    if (shouldGenerateSlug && !name) {
      setSlug("");
    }
  }, [name]);

  // Watch for changes to the name field
  // If a user has not manually entered a slug, generate one for them
  const generatedSlug = useGeneratedSlug({
    name,
    enabled: !isCreatingWorkspace && shouldGenerateSlug,
  });

  useEffect(() => {
    if (shouldGenerateSlug && generatedSlug.slug) {
      setSlug(generatedSlug.slug);
    }
  }, [generatedSlug.slug]);

  const isSlugAvailable = useIsSlugAvailable({
    slug,
    enabled: !isCreatingWorkspace && !shouldGenerateSlug,
  });

  const slugValidationState = shouldGenerateSlug
    ? generatedSlug.state
    : isSlugAvailable.state;

  if (user && user.is_impersonating) {
    return (
      <Column gap={4}>
        <Alert
          message="You cannot create a workspace while impersonating."
          title="Impersonator"
          type="warning"
          variant="inline"
        />
        <Button onClick={() => navigate(-1)}>Back</Button>
      </Column>
    );
  }

  return (
    <Column gap={8}>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <FormField error={error?.message} label="Workspace name">
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            <TextInput
              autoFocus
              {...field}
              placeholder="The ACME Company"
              width="100%"
            />
          </FormField>
        )}
      />

      <Controller
        control={control}
        name="slug"
        render={({
          field: { value, onChange },
          fieldState: { error, isDirty },
        }) => {
          const isDisabled = slugValidationState === "validating" && !isDirty;

          return (
            <FormField
              error={
                slugValidationState === "invalid"
                  ? "This slug is not available."
                  : error?.message
              }
              label="Workspace URL"
            >
              <SlugInput
                value={value}
                onChange={(event) =>
                  onChange(
                    slugify(event?.target.value, {
                      preserveTrailingDash: true,
                    }),
                  )
                }
                prefix="app.hightouch.com/"
                width="auto"
                isDisabled={isDisabled}
                validationState={slugValidationState}
              />
            </FormField>
          );
        }}
      />

      {multiRegionEnabled && (
        <FormField error={errors?.region?.message} label="Workspace region">
          <Controller
            control={control}
            name="region"
            render={({ field }) => <SelectRegion {...field} />}
          />
        </FormField>
      )}

      {hasOrganizations && (
        <FormField error={errors?.organization?.message} label="Organization">
          <Controller
            control={control}
            name="organization"
            render={({ field }) => (
              <Select
                options={organizations}
                {...field}
                isDisabled={organizations.length === 1}
                placeholder="Select an organization..."
                width="100%"
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </FormField>
      )}

      {error && (
        <Alert
          message={error}
          title="Something went wrong"
          type="error"
          variant="inline"
        />
      )}

      <Button
        isDisabled={slugValidationState !== "valid" || !name}
        isLoading={isCreatingWorkspace}
        size="lg"
        variant="primary"
        onClick={handleSubmit(submit)}
      >
        Create workspace
      </Button>
    </Column>
  );
};

const useGeneratedSlug = ({
  name,
  enabled,
}: {
  name: string;
  enabled: boolean;
}) => {
  const [debouncedName, { isPending }] = useDebounce(name, 1000);

  // Watch for changes to the name field
  // If a user has not manually entered a slug, generate one for them
  const generatedSlug = useGenerateWorkspaceSlugQuery(
    { slug: debouncedName ? slugify(debouncedName) : "" },
    {
      enabled: Boolean(debouncedName) && enabled,
      select: (data) => data.generateWorkspaceSlug,
    },
  );

  let state: SlugValidationState = undefined;

  if (!name) {
    state = undefined;
  } else if (generatedSlug.isLoading || isPending()) {
    state = "validating";
  } else if (generatedSlug.data) {
    state = "valid";
  }

  return {
    slug: generatedSlug.data || "",
    state,
  };
};

const useIsSlugAvailable = ({
  slug,
  enabled,
}: {
  slug: string;
  enabled: boolean;
}) => {
  const [debouncedSlug, { isPending }] = useDebounce(slug, 1000);

  const availableSlug = useIsWorkspaceSlugAvailableQuery(
    { slug: debouncedSlug ?? "" },
    {
      enabled: Boolean(debouncedSlug) && enabled,
      select: (data) => data.isWorkspaceSlugAvailable,
    },
  );

  let state: SlugValidationState = undefined;

  if (!slug) {
    state = undefined;
  } else if (availableSlug.isLoading || isPending()) {
    state = "validating";
  } else if (availableSlug.data !== undefined) {
    state = availableSlug.data ? "valid" : "invalid";
  }

  return { state };
};

/**
 *
 * @returns Options for the organization select dropdown
 */
const useAvailableOrganizationOptions = (): {
  defaultOrganization: { label: string; value: number } | null;
  organizations: { label: string; value: number }[];
} => {
  const { user } = useUser();
  const { data: organizations } = useAccessibleOrganizationsQuery(
    {},
    {
      select: (data) =>
        data.getAccessibleOrganizations.organizations.filter(
          (o) => o.can_create_workspaces,
        ),
    },
  );

  const isSso = Boolean(user?.auth0_auth_id);

  const options =
    organizations?.map((org) => ({
      label: org.name,
      value: org.id,
    })) ?? [];

  const defaultOption = options[0];

  if (options.length > 0 && defaultOption) {
    // if the user is an SSO user, they should only be able to create workspaces in that organization
    if (isSso) {
      return {
        defaultOrganization: defaultOption[0], // they should only be part of workspaces in one org
        organizations: [defaultOption],
      };
    }

    return {
      defaultOrganization: defaultOption,
      organizations: [
        { label: "New organization", value: NEW_WORKSPACE_ID },
        ...options,
      ],
    };
  }

  return { defaultOrganization: null, organizations: [] };
};
