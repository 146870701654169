import { FC, useEffect, useState } from "react";

import {
  Column,
  Row,
  Combobox,
  FormField,
  TextInput,
  useToast,
  Button,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useOutletContext } from "src/router";

import { Explore } from "src/components/explore";
import { useUpdateModelMutation } from "src/graphql";
import { BottomBar } from "src/partner/bottom-bar";
import { OutletContext } from "src/partner/sync";
import { useModelRun, useUpdateQuery } from "src/utils/models";
import { useModelState } from "src/hooks/use-model-state";

export const Model: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState<string>(sync.segment?.name ?? "");

  const updateMutation = useUpdateModelMutation();
  const updateQuery = useUpdateQuery();

  const model = sync.segment!;
  const source = model?.connection;

  const supportsResultSchema = source?.definition?.supportsResultSchema;

  const modelState = useModelState(model);

  const {
    runQuery,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    asyncPagination,
    rowsCount,
    rawColumns,
    page,
    getSchema,
    setPage,
  } = useModelRun(modelState.state);

  const save = async () => {
    setIsSaving(true);
    let newColumns = rawColumns?.length ? rawColumns : model.columns;
    try {
      await updateMutation.mutateAsync({
        id: model?.id,
        input: {
          name,
          primary_key: modelState.state.primary_key,
          query_type: modelState.state.query_type,
        },
      });
      if (supportsResultSchema && !modelState.isPreviewFresh) {
        const { data, error } = await getSchema();
        if (error) {
          toast({
            id: "query-schema-error",
            title: "Error previewing query schema",
            variant: "error",
          });
          captureException(error);
        } else if (data?.columns.length) {
          newColumns = data.columns;
        }
      }
      await updateQuery({
        model: modelState.state,
        columns: newColumns,
        overwriteMetadata: true,
      });
      toast({
        id: "update-model-query",
        title: "Model was updated",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-model-query",
        title: "Couldn't update model",
        variant: "error",
      });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    modelState.reset(model);
    resetRunState();
  }, [model]);

  useEffect(() => {
    if (columns?.length) {
      modelState.setIsPreviewFresh(true);
    }
  }, [rows, columns]);

  if (!model) {
    return null;
  }

  return (
    <>
      <Column p={4} flex={1}>
        <Row gap={4} mb={6} maxWidth="300px">
          <FormField label="Model name">
            <TextInput value={name} onChange={(e) => setName(e.target.value)} />
          </FormField>
          <FormField label="Primary key">
            <Combobox
              options={model.columns}
              onChange={(primary_key) => {
                modelState.onChange({ primary_key });
              }}
              value={modelState.state.primary_key}
              optionLabel={(column) => column.alias ?? column.name}
              optionDescription={(column) => column.description ?? ""}
              optionValue={(column) => column.name}
            />
          </FormField>
        </Row>
        <Explore
          limit
          isEmbeddedFlow
          modelState={modelState}
          isPlaceholder={false}
          asyncPagination={asyncPagination}
          cancelQuery={cancelQuery}
          columns={columns}
          error={queryError}
          errorAtLine={queryErrorAtLine}
          isResultTruncated={Boolean(isResultTruncated)}
          loading={queryLoading}
          numRowsWithoutLimit={numRowsWithoutLimit}
          page={page}
          rows={rows}
          rowsCount={rowsCount}
          runQuery={runQuery}
          source={source}
          onPageChange={setPage}
          rowsPerPage={100}
        />
      </Column>

      <BottomBar>
        <Button variant="primary" size="lg" onClick={save} isLoading={isSaving}>
          Save changes
        </Button>
      </BottomBar>
    </>
  );
};
