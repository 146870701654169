import {
  Button,
  Checkbox,
  EditableDescription,
  EditableHeading,
  EditableText,
  IconButton,
  MenuItem,
  Switch,
  Tooltip,
  TooltipProps,
} from "@hightouchio/ui";

import { LinkButton } from "src/router";

import { ResourceToPermission as V1ResourceToPermission } from "./types";
import { V2ResourceToPermission } from "./types-v2";
import {
  OrganizationPermissionInput,
  useOrganizationPermission,
} from "./use-organization-permissions";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "./use-resource-permission";

interface Props<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
> {
  placement?: TooltipProps["placement"];
  permission?: ResourcePermissionInput<V2Resource, V1Resource>;
  organizationPermission?: OrganizationPermissionInput;
  /**
   * Tooltip for the component, always shown
   */
  tooltip?: string | boolean;
  /**
   * Tooltip to show in the event permissions are insufficient
   * @default "You do not have permission to perform this action"
   */
  unauthorizedTooltip?: string;
}

const permissionText = "You do not have permission to perform this action";

// WrappedComponent must forwardRef and pass through all props
export function withPermission<P extends { isDisabled?: boolean }>(
  WrappedComponent: React.ComponentType<P>,
) {
  function HOC<
    V2Resource extends V2ResourceToPermission,
    V1Resource extends V1ResourceToPermission,
  >({
    permission,
    organizationPermission,
    tooltip,
    unauthorizedTooltip = permissionText,
    placement = "auto",
    ...props
  }: Props<V2Resource, V1Resource> & P) {
    const resourcePermission = useResourcePermission(permission);
    const orgPermission = useOrganizationPermission(organizationPermission);

    if (permission || organizationPermission) {
      const isPermitted = permission
        ? resourcePermission.isPermitted
        : orgPermission;

      return (
        <Tooltip
          isDisabled={
            tooltip ? false : resourcePermission.isLoading ? true : isPermitted
          }
          message={isPermitted ? String(tooltip) : unauthorizedTooltip}
          placement={placement}
        >
          <WrappedComponent
            {...(props as P)}
            isDisabled={!isPermitted || props.isDisabled}
          />
        </Tooltip>
      );
    }

    if (tooltip) {
      return (
        <Tooltip message={String(tooltip)}>
          <WrappedComponent {...(props as P)} />
        </Tooltip>
      );
    }
    return <WrappedComponent {...(props as P)} />;
  }

  HOC.displayName = `WithPermission(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return HOC;
}

export const PermissionedButton = withPermission(Button);
export const PermissionedLinkButton = withPermission(LinkButton);
export const PermissionedMenuItem = withPermission(MenuItem);
export const PermissionedIconButton = withPermission(IconButton);
export const PermissionedSwitch = withPermission(Switch);
export const PermissionedEditableHeading = withPermission(EditableHeading);
export const PermissionedEditableDescription =
  withPermission(EditableDescription);
export const PermissionedEditableText = withPermission(EditableText);
export const PermissionedCheckbox = withPermission(Checkbox);
