import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  InitialQuery,
  InitialQueryVariables,
  ResourcesQuery,
  ResourcesQueryVariables,
  useInitialQuery,
  useResourcesQuery,
} from "src/graphql";
import { HttpError } from "src/utils/fetcher";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import { HightouchRegionOptions } from "src/utils/regions";
import { isPersonalEmail } from "src/utils/user";
import { verifyWorkspace } from "src/utils/workspaces";

type WorkspaceError = {
  message: string;
  sessionWorkspace?: {
    id?: number;
    name?: string;
  };
};

type Workspace = InitialQuery["workspaces"][0] | undefined;

export type UserContextType = {
  error: string | undefined;
  user: InitialQuery["getCurrentUser"] | undefined;
  workspace: Workspace;
  // this is the organization that is the parent of the current
  // workspace, not the organization that an SSO user is a direct member of
  workspaceError: WorkspaceError | undefined;
  onboarding: boolean | undefined;
  resources: Resources | undefined;
  slug: string | undefined | null;
  featureFlags: Record<string, boolean>;
  getWorkspaceIdFromSlug: (slug: string) => string | undefined;
  region: string;
  isCompanyRequired: boolean;
  isLoading: boolean;
  refetch: () => Promise<any>;
  isEmbedded: boolean;
};

const defaultUserContext: UserContextType = {
  error: undefined,
  user: undefined,
  workspace: undefined,
  onboarding: undefined,
  resources: undefined,
  slug: undefined,
  workspaceError: undefined,
  featureFlags: {},
  getWorkspaceIdFromSlug: () => undefined,
  region: HightouchRegionOptions.AWS_US_EAST_1,
  isCompanyRequired: false,
  isLoading: true,
  refetch: () => Promise.resolve(),
  isEmbedded: true,
};

export const UserContext = createContext<UserContextType>(defaultUserContext);

export const useUser = () => useContext(UserContext);

const COMPANY_REQUIRED_TIMESTAMP = "Mon Nov 29 2022 13:30:00 GMT-0500";

interface UserProviderProps {
  children: ReactNode;
}

const useFastInitialQuery = getQueryWithOpts<
  InitialQuery,
  InitialQueryVariables
>(useInitialQuery, {
  useFastEndpoint: true,
});

const useFastResourcesQuery = getQueryWithOpts<
  ResourcesQuery,
  ResourcesQueryVariables
>(useResourcesQuery, {
  useFastEndpoint: true,
});

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [workspaceError, setWorkspaceError] = useState<
    WorkspaceError | undefined
  >();

  const { data, error, isFetching, refetch } = useFastInitialQuery(
    {},
    {
      suspense: true,
      useErrorBoundary: false,
    },
  );
  const { data: resources } = useFastResourcesQuery(
    {},
    {
      select: (data) => ({
        destination: data.destinations[0],
        source: data.sources[0],
        model: data.models[0],
        sync: data.syncs[0],
        audience: data.audiences[0],
        parentModel: data.parentModels[0],
        decisionEngine: data.decisionEngines[0],
        canSequence: data.syncs.length > 1,
      }),
      suspense: true,
      useErrorBoundary: false,
    },
  );

  // Only pass down a user if there wasn't an error. Most commonly, the error
  // will be a unauthenticated error if the user isn't logged in.
  const user = !error && data ? data.getCurrentUser : undefined;
  const workspaces = data?.workspaces;
  const workspace = data?.workspaces?.find(
    ({ id }) => id == user?.current_workspace_id,
  );

  const featureFlags = workspace?.feature_flags;

  const onboarding =
    !resources?.destination ||
    !resources?.model ||
    !resources?.source ||
    !resources?.sync;

  const isCompanyRequired = Boolean(
    user &&
      user.created_at &&
      new Date(String(user.created_at)) >
        new Date(COMPANY_REQUIRED_TIMESTAMP) &&
      isPersonalEmail(user.email) &&
      !user.personalization?.company_name,
  );

  const getWorkspaceIdFromSlug = (slug: string): string | undefined => {
    const workspace = workspaces?.find((w) => w.slug === slug);
    if (workspace) {
      return String(workspace.id);
    }

    return undefined;
  };

  useEffect(() => {
    // Set the region and workspace id on the window.
    if (typeof window.Hightouch === "object") {
      window.Hightouch.apiRegion = workspace?.region;
      window.Hightouch.workspaceId = workspace?.id;
    }

    // Check for workspace error.
    const checkForWorkspaceError = () => {
      // Note: We don't clear the workspace error on success
      //       because we always assume that atleast one network
      //       request had also failed so we don't have to whack-a-mole
      //       trying to find every case. User reload should clear it.
      verifyWorkspace().catch((e) => {
        // Primarily try to catch 409s.
        if (e instanceof HttpError && e.code === 409) {
          setWorkspaceError({
            message: e.message,
            sessionWorkspace: {
              id: e.body?.details?.session?.id,
              name: e.body?.details?.session?.name,
            },
          });
        }
        // Ignore other possible errors (ie. 500) since those
        // doesn't necessarily tell if there is something
        // wrong in the workspace.
      });
    };

    window.addEventListener("focus", checkForWorkspaceError);
    return () => {
      window.removeEventListener("focus", checkForWorkspaceError);
    };
  }, [workspace]);

  return (
    <UserContext.Provider
      value={{
        workspaceError,
        error: error?.message,
        user,
        workspace,
        featureFlags,
        resources,
        onboarding,
        slug: workspace?.slug,
        region: workspace?.region ?? HightouchRegionOptions.AWS_US_EAST_1,
        getWorkspaceIdFromSlug,
        isCompanyRequired,
        isLoading: isFetching,
        refetch,
        isEmbedded: user?.email === "guest",
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export interface Resources {
  source: ResourcesQuery["sources"][0] | undefined;
  model: ResourcesQuery["models"][0] | undefined;
  destination: ResourcesQuery["destinations"][0] | undefined;
  sync: ResourcesQuery["syncs"][0] | undefined;
  audience: ResourcesQuery["audiences"][0] | undefined;
  parentModel: ResourcesQuery["parentModels"][0] | undefined;
  decisionEngine: ResourcesQuery["decisionEngines"][0] | undefined;
  canSequence: boolean;
}
