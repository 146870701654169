import { Route, Routes } from "src/router";
import { Fivetran, FivetranConfiguration, FivetranOverview } from "./fivetran";
import { FC } from "react";
import {
  DbtModels,
  DbtModelsConfiguration,
  DbtModelsOverview,
  DbtModelsSourceForm,
} from "./dbt-models";
import { DbtCloud, DbtCloudConfiguration, DbtCloudOverview } from "./dbt-cloud";
import { Looker, LookerConfiguration, LookerOverview } from "./looker";
import { Alerting, AlertingConfiguration, AlertingOverview } from "./alerting";
import {
  GitSync,
  GitSyncConfiguration,
  GitSyncOverview,
  GitSyncRuns,
} from "./git-sync";
import { Sigma, SigmaConfiguration, SigmaOverview } from "./sigma";
import {
  Monitoring,
  MonitoringConfiguration,
  MonitoringOverview,
} from "./monitoring";
import { Extensions } from "./extensions";

export const ExtensionsRouter: FC = () => {
  return (
    <Routes>
      <Route index element={<Extensions />} />
      <Route path="fivetran/*" element={<Fivetran />}>
        <Route index element={<FivetranOverview />} />
        <Route path="configuration" element={<FivetranConfiguration />} />
      </Route>
      <Route path="dbt-models/*" element={<DbtModels />}>
        <Route index element={<DbtModelsOverview />} />
        <Route path="configuration" element={<DbtModelsConfiguration />} />
        <Route
          path="configuration/:sourceId"
          element={<DbtModelsSourceForm />}
        />
      </Route>
      <Route path="dbt-cloud/*" element={<DbtCloud />}>
        <Route index element={<DbtCloudOverview />} />
        <Route path="configuration" element={<DbtCloudConfiguration />} />
      </Route>
      <Route path="looker/*" element={<Looker />}>
        <Route index element={<LookerOverview />} />
        <Route path="configuration" element={<LookerConfiguration />} />
      </Route>
      <Route path="alerting/*" element={<Alerting />}>
        <Route index element={<AlertingOverview />} />
        <Route path="configuration" element={<AlertingConfiguration />} />
      </Route>
      <Route path="git-sync/*" element={<GitSync />}>
        <Route index element={<GitSyncOverview />} />
        <Route path="configuration" element={<GitSyncConfiguration />} />
        <Route path="runs" element={<GitSyncRuns />} />
      </Route>
      <Route path="sigma/*" element={<Sigma />}>
        <Route index element={<SigmaOverview />} />
        <Route path="configuration" element={<SigmaConfiguration />} />
      </Route>
      <Route path="monitoring/*" element={<Monitoring />}>
        <Route index element={<MonitoringOverview />} />
        <Route path="configuration" element={<MonitoringConfiguration />} />
      </Route>
    </Routes>
  );
};
