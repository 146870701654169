import { FC, useEffect, useMemo, useState } from "react";

import {
  Alert,
  Button,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  Dialog,
  EditIcon,
  Paragraph,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Helmet } from "react-helmet";
import { Controller } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "src/router";
import { ReactFlowProvider } from "reactflow";

import { DiscardButton, Form, SaveButton } from "src/components/form";
import { PageHeader } from "src/components/layout";
import { NAV_WIDTHS_PER_BREAKPOINT } from "src/components/layout/constants";
import { PageSpinner } from "src/components/loading";
import { PermissionedEditableHeading } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { Warning } from "src/components/warning";
import {
  JourneyQuery,
  useJourneyQuery,
  useLatestJourneyRunQuery,
} from "src/graphql";
import { JourneyStatus } from "src/types/journeys";

import { JourneyActionButtons, JourneyStatusBadge } from "./components";
import {
  JOURNEY_UPDATE_PERMISSION,
  LIVE_STATES,
  NULL_EXIT_CRITERIA,
} from "./constants";
import { Graph, GraphProvider, useJourneyGraph } from "./graph";
import {
  getJourneyState,
  getNumberOfUsersInJourney,
  transformEdgeToReactFlowEdge,
  transformNodeToReactFlowNode,
} from "./utils";

type JourneyProps = {
  journey: NonNullable<JourneyQuery["journeys"][0]>;
  isRefetching?: boolean;
};

export const Journey: FC<JourneyProps> = ({ journey, isRefetching }) => {
  const { node_id } = useParams<{ node_id?: string }>();

  const [isEditMode, setIsEditMode] = useState(journey.nodes.length === 0);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showJourneyRunErrorDialog, setShowJourneyRunErrorDialog] =
    useState(false);

  const { isLoading, isPermitted } = useResourcePermission(
    JOURNEY_UPDATE_PERMISSION,
  );

  const unauthorized = isLoading ? true : !isPermitted;

  const latestJourneyRunQuery = useLatestJourneyRunQuery(
    {
      id: journey.id,
    },
    {
      select: (data) => data.journey_runs,
    },
  );

  const formattedNodes = useMemo(
    () => journey.nodes.map(transformNodeToReactFlowNode),
    [journey],
  );

  const formattedEdges = useMemo(
    () => journey.edges.map(transformEdgeToReactFlowEdge),
    [journey],
  );

  const toggleEditMode = () => setIsEditMode((prev) => !prev);

  const isSettings = location.pathname.endsWith("/settings");
  const isDrawerOpen = Boolean(node_id) || isSettings;
  const journeyStatus = journey.status as JourneyStatus;
  const isJourneyDraining = journeyStatus === JourneyStatus.Draining;
  const numberOfUsersInJourney = getNumberOfUsersInJourney(journey.nodes);
  const numberOfPreviousRuns = journey.runs_aggregate.aggregate?.count ?? 0;
  const parentModelId = Number(journey.parent_model_id); // This comes back as `any` type, but it's a number.
  const journeyState = getJourneyState({
    status: journeyStatus,
    numberOfUsersInJourney,
    numberOfPreviousRuns,
  });
  const isJourneyRunning = LIVE_STATES.includes(journeyState);

  const latestJourneyRun = latestJourneyRunQuery.data?.[0];
  const journeyRunError = latestJourneyRun?.error;
  const nodeRunErrors =
    latestJourneyRun?.journey_node_runs.filter(
      (run): run is { node_id: string; error: string; status: string } =>
        run.error !== null,
    ) ?? [];

  const { form, ...journeyGraphActions } = useJourneyGraph({
    id: journey.id ?? "",
    name: journey.name,
    description: journey.description,
    status: journeyStatus,
    exitCriteria:
      journey.exit_criteria == null
        ? NULL_EXIT_CRITERIA
        : journey.exit_criteria,
    schedule: journey.schedule,
    selectedNodeId: node_id,
    nodes: formattedNodes,
    edges: formattedEdges,
    onSave: () => setIsEditMode(false),
  });

  const showJourneyRunErrors = () => setShowJourneyRunErrorDialog(true);

  const cancelOrShowModal = () => {
    if (form.formState.isDirty) {
      setShowCancelConfirmation(true);
    } else {
      cancel();
    }
  };

  const cancel = () => {
    form.reset();
    setShowCancelConfirmation(false);
    setIsEditMode(false);
  };

  // Enable edit mode on mount for new, empty journeys
  useEffect(() => {
    if (node_id && !isEditMode && journeyState === "draft") {
      setIsEditMode(true);
    }
  }, [node_id, isEditMode, journeyState]);

  return (
    <Form form={form}>
      <GraphProvider
        versionId={journey.version_id}
        parentModelId={parentModelId}
        isEditMode={isEditMode}
        journeyState={journeyState}
        latestJourneyRun={latestJourneyRun}
        nodeRunErrors={nodeRunErrors}
        unauthorized={unauthorized}
        onToggleEditMode={toggleEditMode}
        onShowJourneyRunErrors={showJourneyRunErrors}
        {...journeyGraphActions}
      >
        <Column width="100%" height="100%">
          <Row
            align="center"
            gap={6}
            height="64px"
            flexShrink={0}
            boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
            justify="center"
            zIndex={1}
          >
            <Row justify="center" flex={1} minWidth={0}>
              <Row
                align="center"
                justify="space-between"
                gap={2}
                px={6}
                minWidth={0}
                flex={1}
              >
                <Row align="center" gap={2}>
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore Conditions type circularly references itself */}
                  <Controller
                    name="journey.name"
                    control={form.control}
                    render={({ field }) => (
                      <Text fontWeight="medium">
                        <PermissionedEditableHeading
                          isDisabled={!isEditMode}
                          permission={JOURNEY_UPDATE_PERMISSION}
                          width="auto"
                          size="lg"
                          // eslint-disable-next-line
                          // @ts-ignore Column circularly references itself
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </Text>
                    )}
                  />
                  <JourneyStatusBadge status={journeyState} />
                </Row>

                {isEditMode ? (
                  !isDrawerOpen && (
                    <Button onClick={cancelOrShowModal}>Exit edit mode</Button>
                  )
                ) : (
                  <JourneyActionButtons
                    isLoading={isRefetching}
                    numberOfPreviousRuns={numberOfPreviousRuns}
                    numberOfUsersInJourney={numberOfUsersInJourney}
                    status={journeyStatus}
                  />
                )}
              </Row>
            </Row>
          </Row>

          {journeyRunError && (
            <Alert
              variant="banner"
              type="error"
              justify="center"
              title="An error occurred during journey execution"
              message={null}
              actions={
                <Button onClick={showJourneyRunErrors}>View details</Button>
              }
            />
          )}

          <Row
            position="relative"
            minHeight={0}
            flex={1}
            mb={isDrawerOpen ? 0 : "72px"}
          >
            <Graph numberOfUsersInJourney={numberOfUsersInJourney} />
          </Row>
        </Column>
      </GraphProvider>
      {(!isEditMode || !isDrawerOpen) && (
        <Row
          borderTop="1px solid"
          borderColor="base.border"
          bottom={0}
          bg="white"
          boxShadow="0px 18px 48px -12px #1018282E"
          position="fixed"
          gap={2}
          left={NAV_WIDTHS_PER_BREAKPOINT}
          height="72px"
          align="center"
          right={0}
          width="100%"
          px={6}
          zIndex={1}
        >
          {isEditMode ? (
            <ButtonGroup>
              <SaveButton permission={JOURNEY_UPDATE_PERMISSION} />
              <DiscardButton onClick={() => setIsEditMode(false)} />
            </ButtonGroup>
          ) : (
            <Tooltip
              isDisabled={!isJourneyRunning && !isJourneyDraining}
              placement="right"
              message={
                isJourneyDraining
                  ? "Journey must be fully drained to edit"
                  : "Pause or stop the journey to edit"
              }
            >
              <Button
                icon={EditIcon}
                isDisabled={isJourneyRunning}
                size="lg"
                variant="primary"
                onClick={toggleEditMode}
              >
                Edit journey
              </Button>
            </Tooltip>
          )}
        </Row>
      )}

      <ConfirmationDialog
        isOpen={showCancelConfirmation}
        title="Cancel editing"
        confirmButtonText="Discard updates"
        variant="warning"
        onCancel={() => setShowCancelConfirmation(false)}
        onClose={() => setShowCancelConfirmation(false)}
        onConfirm={cancel}
      >
        <Paragraph>
          Are you sure you want to cancel editing this journey? Your updates
          will be lost.
        </Paragraph>
      </ConfirmationDialog>

      <Dialog
        isOpen={showJourneyRunErrorDialog}
        title="Journey error details"
        variant="info"
        actions={
          <Button
            variant="primary"
            onClick={() => setShowJourneyRunErrorDialog(false)}
          >
            Close
          </Button>
        }
        onClose={() => setShowJourneyRunErrorDialog(false)}
      >
        <Column gap={2}>
          <Paragraph>{journeyRunError}</Paragraph>
        </Column>
      </Dialog>
    </Form>
  );
};

const Loader = () => {
  const navigate = useNavigate();
  const { appEnableJourneys } = useFlags();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!appEnableJourneys) {
      navigate("/home");
    }
  }, [appEnableJourneys]);

  const journeyQuery = useJourneyQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.journeys[0] ?? null,
    },
  );

  const journey = journeyQuery.data;

  const header = (
    <>
      <PageHeader />

      <Helmet>
        <title>{journey?.name ?? "Journey"}</title>
      </Helmet>
    </>
  );

  if (journeyQuery.isLoading) {
    return <PageSpinner />;
  }

  if (!id || journey?.deleted) {
    return <Navigate replace to="/journeys" />;
  }

  if (!journey) {
    return (
      <>
        {header}
        <Warning
          subtitle="It may have been deleted"
          title="Journey not found"
        />
      </>
    );
  }

  return (
    <ReactFlowProvider>
      {header}
      <Journey journey={journey} isRefetching={journeyQuery.isRefetching} />
    </ReactFlowProvider>
  );
};

export default Loader;
