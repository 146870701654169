import { Buffer } from "buffer";

import { useCallback, useMemo } from "react";

import * as Sentry from "@sentry/react";
import { NavigateOptions, useSearchParams } from "src/router";

/**
 * Encode an object to a string using Base64 encryption.
 */
const encode = <T>(value: T) => {
  return Buffer.from(JSON.stringify(value)).toString("base64");
};

/**
 * Decode a string from Base64 format to an object.
 */
const decode = <T>(encodedValue: string): T => {
  return JSON.parse(Buffer.from(encodedValue, "base64").toString("utf8"));
};

export const useEncodedSearchParams = <T extends Record<string, unknown>>(
  key = "data",
): [
  T | null,
  (value: Partial<T>, navigateOptions?: NavigateOptions) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const get = useCallback((): T | null => {
    try {
      const value = searchParams.get(key);

      return value !== null ? decode<T>(value) : value;
    } catch (error) {
      Sentry.captureException(error, { extra: { key } });
      return null;
    }
  }, [searchParams.get, key, location.search]);

  const set = (value: Partial<T>, options?: NavigateOptions) => {
    setSearchParams(
      { [key]: encode({ ...data, ...value }) },
      { replace: true, ...options },
    );
  };

  const data = useMemo(get, [get, location.search]);

  return [data, set];
};
