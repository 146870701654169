import { Outlet, Route, Routes } from "src/router";
import { CreateSync } from "./create/create-sync";
import Syncs from "./syncs";
import { Sync } from "./sync";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { RunDebug } from "./sync/run/debug";
import { SyncDraft } from "./sync/draft";
import { SyncRun } from "./sync/run";

export const SyncsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Syncs />} />
      <Route path=":sync_id/*" element={<Outlet />}>
        <Route index element={<Sync />} />
        <Route path="draft" element={<SyncDraft />} />
        <Route
          path="runs/:run_id"
          element={
            <PermissionedRoute
              permission={{
                v2: { resource: "sync", grant: "can_debug", id: "sync_id" },
              }}
              redirect="/syncs/:sync_id"
            >
              <SyncRun />
            </PermissionedRoute>
          }
        >
          <Route
            path="debug/:row_id"
            element={
              <PermissionedRoute
                permission={{
                  v2: { resource: "sync", grant: "can_debug", id: "sync_id" },
                }}
                redirect="/syncs/:sync_id"
              >
                <RunDebug />
              </PermissionedRoute>
            }
          />
        </Route>
      </Route>

      <Route
        path="new"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "sync", grant: "create" },
            }}
            redirect="/syncs"
          >
            <CreateSync />
          </PermissionedRoute>
        }
      />
    </Routes>
  );
};
