import { FC, useEffect, useState } from "react";

import {
  Alert,
  Box,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  EditableDescription,
  EditIcon,
  FolderIcon,
  Heading,
  InformationIcon,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuList,
  Paragraph,
  PlayIcon,
  RefreshIcon,
  Row,
  Skeleton,
  SkeletonBox,
  Spinner,
  TabList,
  TabPanels,
  Text,
  Tooltip,
  UnlinkIcon,
  useToast,
  WarningIcon,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, useNavigate, useParams } from "src/router";

import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { DeployButton } from "src/components/deployments/button";
import { DestinationForm } from "src/components/destinations/sync-form";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { PageSpinner } from "src/components/loading";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import {
  PermissionedButton,
  PermissionedMenuItem,
  PermissionedSwitch,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { Schedule } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SplitsSyncAssignmentForm } from "src/components/splits-v2/splits-sync-assignment-form";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationInstancesSetInput,
  ResourceToPermission,
  SyncDraft,
  useDeleteSyncMutation,
  useExternalSegmentsQuery,
  useLatestJourneyVersionFromDestinationInstanceIdQuery,
  useSyncQuery,
  useSyncTemplateQuery,
  useUpdateSplitSyncAssignmentsMutation,
  useUpdateSyncMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { ResourceMonitoring } from "src/components/resource-alert-triggers";
import {
  isAudienceStatusProcessing,
  isMatchedUsersCountSettling,
} from "src/utils/match-boosting";
import { syncActivityMappers } from "./activity";
import { SyncAlerts } from "./alerts";
import {
  DestinationMetadataDrawer,
  processAudienceSize,
} from "./components/external-data";
import { SyncName } from "./components/sync-name";
import { WarehouseSyncLogs } from "./components/warehouse-sync-logs";
import { useCurrentRun } from "./utils/use-current-run";
import { SyncRuns } from "./runs";
import { syncHealthOrUnknown, SyncHealthStatus } from "src/utils/syncs";
import { SyncHealthBadge } from "src/components/syncs/sync-health-badge";
import {
  RouteableTabs,
  RouteableTab,
  RouteableTabPanel,
} from "src/components/routeable-tabs";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import { Overview } from "./overview";
import { syncTabParam, SyncTabSlug } from "./utils/tabs";
import type { Sync as SyncQuery } from "./utils/types";
import { AlertsAndWarnings } from "./overview/alerts-and-warnings";
import { ResyncWarning } from "src/pages/syncs/components/full-resync-warning";
import { SyncSchedule } from "./schedule";
import { useDestinationMetadata } from "./utils/use-destination-metadata";

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") &&
    error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

export const Sync: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();

  const {
    data: sync,
    refetch,
    remove,
    error,
    isLoading,
  } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.syncs[0],
    },
  );

  if (!id) {
    return <Navigate to="/syncs" />;
  }

  if (error) {
    return (
      <Warning title="Something went wrong" subtitle="Please try again later" />
    );
  }

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!sync) {
    return <Warning title="Sync not found" />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={sync?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <SyncPage
        sync={sync}
        onRefetch={() => {
          remove();
          refetch();
        }}
      />
    </DraftProvider>
  );
};

interface SyncProps {
  sync: NonNullable<SyncQuery>;
  onRefetch: () => void;
}

const SyncPage: FC<SyncProps> = ({ sync, onRefetch }: SyncProps) => {
  const id = String(sync.id);
  const { workspace } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [deleting, setDeleting] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [viewMetadata, setViewMetadata] = useState(false);
  const [confirmSyncTemplateDetach, setConfirmSyncTemplateDetach] =
    useState(false);
  const flags = useFlags();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isResetCDCModalOpen, setIsResetCDCModalOpen] = useState(false);
  const [isFullResyncModalOpen, setIsFullResyncModalOpen] = useState(false);
  const [showConfirmRunDialog, setShowConfirmRunDialog] = useState(false);

  const updateSyncMutation = useUpdateSyncMutation();
  const updateSyncMutationAndInvalidateCatche = useUpdateSyncMutation({
    onSuccess: () => {
      onRefetch();
    },
  });
  const { mutateAsync: deleteSyncById } = useDeleteSyncMutation();

  const [_, setTab] = useSearchParam<SyncTabSlug>(syncTabParam);

  const { isCancelling, isRunning, startRun, cancelRun, isRunDisabled } =
    useCurrentRun(id);

  const syncTemplateQuery = useSyncTemplateQuery(
    {
      id: sync?.sync_template_id ?? -1,
    },
    { enabled: Boolean(sync?.sync_template_id) },
  );

  const syncAlerts = sync?.sync_alerts;
  const model = sync?.segment;
  const destination = sync?.destination;
  const source = model?.connection;
  const usesSyncTemplate = sync?.sync_template_id;
  const currentLabels = sync?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);
  const isAudienceModel = model?.query_type === QueryType.Visual;
  const splits = (sync?.destination_instance_splits ?? []).map(
    ({ split }) => split,
  );
  const syncTemplateName = syncTemplateQuery.data?.sync_templates_by_pk?.name;
  const isJourneyTriggered =
    sync?.schedule?.type === ScheduleType.JOURNEY_TRIGGERED;

  const journeyQuery = useLatestJourneyVersionFromDestinationInstanceIdQuery(
    { destination_instance_id: id ?? "" },
    {
      enabled: Boolean(isJourneyTriggered && id),
      select: (data) => data.journeys,
    },
  );
  // TODO(samuel): Can this be pulled off of the sync instead? >.<
  const journey = journeyQuery.data?.[0];
  const journeyId = journey?.id;

  const updateSplitSyncAssignments = useUpdateSplitSyncAssignmentsMutation();

  const deleteSync = async () => {
    if (!id) {
      return;
    }

    try {
      await deleteSyncById({
        id,
        stringId: id.toString(),
      });

      analytics.track("Sync Deleted", {
        sync_id: id,
        destination_type: destination?.definition?.name,
        schedule_type: sync.schedule?.type,
        source_type: model?.connection?.type,
      });

      navigate("/syncs");
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "delete-sync",
        title: "Couldn't delete this sync",
        message: getDeleteSyncErrorMessage(error),
        variant: "error",
      });
    }
  };

  const saveSplitSyncAssignments = async (selectedSplitIds) => {
    // In order to do an update, we remove the existing destination_instance-split rows (for this sync)
    // and then insert the selected destination_instance-splits (for this sync)
    const removeObjects = splits.map((split) => ({
      split_id: { _eq: split.id },
      destination_instance_id: { _eq: sync?.id },
    }));

    const addObjects = selectedSplitIds.map((splitId: string) => ({
      split_id: splitId,
      destination_instance_id: sync?.id,
    }));

    try {
      await updateSplitSyncAssignments.mutateAsync({
        addObjects,
        removeObjects,
      });
    } catch (error) {
      toast({
        id: "change-split-sync-assignments",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      throw error;
    }

    toast({
      id: "change-split-sync-assignments",
      title: "Successfully updated split group assignments.",
      variant: "success",
    });
  };

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    updateResourceOrDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const newResource = draft?.new_resource as SyncDraft;
  const draftSchedule = newResource?._set?.schedule;

  const { data: externalSegmentsData } = useExternalSegmentsQuery(
    { syncId: sync?.id },
    { enabled: !!sync },
  );
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort(
    (a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    },
  )?.[0];

  const {
    isSupported: isDestinationMetadataSupported,
    isLoading: isDestinationMetadataLoading,
    metadata: destinationMetadata,
    firstRun,
  } = useDestinationMetadata(sync?.id);

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const toggleSyncPause = async (enabled: boolean) => {
    if (!id) {
      return;
    }

    try {
      await updateSyncMutation.mutateAsync({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sync-pause",
        title: `Sync was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "toggle-sync-pause",
        title: `Sync could not be ${enabled ? "enabled" : "disabled"}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const showStatusCircleConfiguration =
    newResource?._set?.config && editingDraft;
  const showStatusCircleSchedule = draftSchedule !== undefined && editingDraft;

  const showOverviewTab = flags.appSyncOverviewPageEnabled;
  const showSplitsTab = isAudienceModel;
  const showMonitoringTab = workspace?.alerting_v2_enabled;
  // Hiding the old alerting config tab if the new alerting v2 is enabled
  const showAlertsTab =
    !usesSyncTemplate && !showMonitoringTab && !editingDraftChanges && id;
  const showSyncLogs =
    source?.definition?.supportsInWarehouseDiffing && !editingDraftChanges;

  const [autorun, __] = useSearchParam("autorun");

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destination?.definition?.name,
      schedule_type: sync.schedule?.type,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }
  };

  const detachSyncTemplate = async () => {
    if (!id || !sync) {
      return;
    }

    try {
      if (updateResourceOrDraft) {
        const updatePayload: DestinationInstancesSetInput = {
          destination_id: sync.destination_id,
          sync_template_id: null,
          config: sync.config,
          is_streaming: sync.is_streaming,
          schedule: sync.schedule,
          schedule_paused: sync.schedule_paused,
          row_threshold_total: sync.row_threshold_total,
          row_threshold_attempted: sync.row_threshold_attempted,
          // we null the draft id, it gets added on the backend and we want to be consistent
          // if a workspace turns off approvals again =
          approved_draft_id: null,
        };

        await updateResourceOrDraft(
          { _set: updatePayload },
          onUpdate,
          () =>
            updateSyncMutationAndInvalidateCatche.mutateAsync({
              id,
              object: updatePayload,
            }),
          sync.draft || false,
        );
      }
    } catch (error) {
      toast({
        id: "detach-sync-template",
        title: "Could not detach template",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const updateConfig = async (config) => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      config: { ...config, configVersion: sync?.config?.configVersion },
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        () =>
          updateSyncMutation.mutateAsync({
            id,
            object: updatePayload,
          }),
        sync.draft || false,
      );
    }
  };

  const updateDescription = async (description: string) => {
    if (!id) {
      return;
    }

    try {
      await updateSyncMutation.mutateAsync({
        id: id,
        object: {
          description,
        },
      });

      onUpdate();
    } catch (error) {
      toast({
        id: "update-sync-description",
        title: "Couldn't update description",
        message: error.message,
        variant: "error",
      });
    }
  };

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "sync", grant: "can_update", id: sync?.id },
  });

  const folder = useFolder({
    folderId: sync?.segment?.folder?.id || null,
    folderType: sync?.segment?.query_type === "visual" ? "audiences" : "models",
    viewType: "syncs",
  });

  const crumbs: Crumb[] = [{ label: "All syncs", link: "/syncs" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/syncs?folder=" + folder.id,
      });
    });
  }

  return (
    <>
      <PermissionProvider
        permission={{
          v2: {
            resource: "sync",
            grant: "can_update",
            id: sync?.id ?? "",
          },
        }}
      >
        <Page
          crumbs={crumbs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Sync}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          sync={sync}
          title={`${model?.name ?? "Private model"} to ${
            destination?.name ??
            destination?.definition?.name ??
            "private destination"
          } - Syncs`}
        >
          <Row
            align="center"
            justify="space-between"
            width="100%"
            mb={4}
            gap={4}
          >
            <SyncName
              // Make sure journey is loaded
              isDisabled={journeyQuery.isLoading}
              destination={destination}
              model={model}
              source={source}
              journeyId={journeyId}
              sync={sync}
            />
            <ButtonGroup>
              {!isJourneyTriggered && (
                <Row align="center" gap={2}>
                  <Text
                    textTransform="uppercase"
                    size="sm"
                    fontWeight="semibold"
                    color="text.tertiary"
                  >
                    {enabled ? "Enabled" : "Disabled"}
                  </Text>
                  <PermissionedSwitch
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_run",
                        id: sync?.id,
                      },
                    }}
                    isChecked={enabled}
                    onChange={(value) => {
                      setEnabled(value);
                      toggleSyncPause(value);
                    }}
                  />
                </Row>
              )}

              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_update",
                        id: sync?.id,
                      },
                    }}
                    icon={FolderIcon}
                    onClick={() => {
                      setIsFolderModalOpen(true);
                    }}
                  >
                    Move to folder
                  </PermissionedMenuItem>
                  <PermissionedMenuItem
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_update",
                        id: sync?.id,
                      },
                    }}
                    icon={EditIcon}
                    onClick={() => {
                      setIsEditLabelModalOpen(true);
                    }}
                  >
                    Edit labels
                  </PermissionedMenuItem>
                  {isJourneyTriggered ? (
                    <PermissionedMenuItem
                      permission={{
                        v1: {
                          // journeys permission runs on sync create for v1
                          resource: "sync",
                          grant: "create",
                        },
                        v2: {
                          resource: "sync",
                          grant: "can_run",
                          id: sync?.id,
                        },
                      }}
                      isDisabled={
                        isRunDisabled || overageLockout || !!sync?.draft
                      }
                      icon={PlayIcon}
                      tooltip={overageLockout && overageText}
                      onClick={() => setShowConfirmRunDialog(true)}
                    >
                      Run sync
                    </PermissionedMenuItem>
                  ) : (
                    <PermissionedMenuItem
                      permission={{
                        v2: {
                          resource: "sync",
                          grant: "can_run",
                          id: sync?.id,
                        },
                      }}
                      icon={RefreshIcon}
                      isDisabled={isRunning}
                      onClick={() => {
                        setIsFullResyncModalOpen(true);
                      }}
                    >
                      Resync full query
                    </PermissionedMenuItem>
                  )}

                  {flags.emptySyncs && (
                    <>
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "sync",
                            grant: "can_run",
                            id: sync?.id,
                          },
                        }}
                        icon={WarningIcon}
                        isDisabled={isRunning}
                        onClick={() => {
                          setIsResetCDCModalOpen(true);
                        }}
                      >
                        Reset CDC
                      </PermissionedMenuItem>
                    </>
                  )}

                  {!isJourneyTriggered && (
                    <>
                      <MenuDivider />
                      <PermissionedMenuItem
                        permission={{
                          v2: {
                            resource: "sync",
                            grant: "can_delete",
                            id: sync?.id,
                          },
                        }}
                        icon={DeleteIcon}
                        isDisabled={isRunning}
                        variant="danger"
                        onClick={() => {
                          setDeleting(true);
                        }}
                      >
                        Delete
                      </PermissionedMenuItem>
                    </>
                  )}
                </MenuList>
              </Menu>

              {!isJourneyTriggered &&
                sync &&
                sync?.segment?.query_type !== "visual" && (
                  <DeployButton
                    permission={{
                      v2: {
                        resource: "model",
                        grant: "can_create",
                        creationOptions: {
                          type: "model",
                          sourceId:
                            sync?.segment?.connection?.id?.toString() ?? "",
                        },
                      },
                    }}
                    isDisabled={Boolean(!sync.slug || sync.draft || draft)}
                    deployment={{
                      resourceName: "Sync",
                      sourceResourceId: sync.id,
                      resourceType: "destination_instances",
                      metadata: {
                        destinationId: sync.destination?.id,
                        modelId: sync.segment?.id,
                      },
                      isDraft: Boolean(sync.draft || draft),
                    }}
                  />
                )}

              {isRunning || isCancelling ? (
                <PermissionedButton
                  isDisabled={isCancelling}
                  onClick={cancelRun}
                  permission={{
                    v2: {
                      resource: "sync",
                      grant: "can_run",
                      id: sync?.id,
                    },
                  }}
                >
                  <Spinner size="sm" mr={2} />
                  {isCancelling ? "Canceling..." : "Cancel run"}
                </PermissionedButton>
              ) : !isJourneyTriggered ? (
                <PermissionedButton
                  permission={{
                    v2: {
                      resource: "sync",
                      grant: "can_run",
                      id: sync?.id,
                    },
                  }}
                  isDisabled={isRunDisabled || overageLockout || !!sync?.draft}
                  icon={PlayIcon}
                  tooltip={overageLockout && overageText}
                  onClick={() => {
                    startRun();
                  }}
                >
                  Run sync
                </PermissionedButton>
              ) : null}
            </ButtonGroup>
          </Row>
          {!showOverviewTab && (
            <Row>
              <EditableDescription
                isDisabled={!hasUpdatePermission}
                value={sync?.description ?? ""}
                onChange={updateDescription}
              />
            </Row>
          )}

          <>
            {!showOverviewTab && (
              <MetadataBar>
                {workspace?.alerting_v2_enabled && (
                  <Column>
                    <MetadataLabel>Sync status</MetadataLabel>
                    <Row height="32px" alignItems="center">
                      <SyncHealthBadge
                        health={syncHealthOrUnknown(
                          sync?.last_run_at
                            ? sync?.health
                            : SyncHealthStatus.Pending,
                        )}
                        syncEnabled={!sync?.schedule_paused}
                      />
                      <Heading>
                        <Tooltip message="Sync status is derived from your alert triggers for this sync.">
                          <InformationIcon color="gray.base" ml={1} />
                        </Tooltip>
                      </Heading>
                    </Row>
                  </Column>
                )}
                <Column>
                  <MetadataLabel>Schedule</MetadataLabel>
                  <Row height="32px" alignItems="center">
                    <Schedule schedule={sync?.schedule} />
                  </Row>
                </Column>
                <Column>
                  <MetadataLabel>Sync ID</MetadataLabel>
                  <Row height="32px" alignItems="center">
                    <Text>{sync?.id}</Text>
                  </Row>
                </Column>
                {model?.query_type !== QueryType.Visual && (
                  <Column>
                    <MetadataLabel mb="-2px">Slug</MetadataLabel>
                    <Row height="32px" alignItems="center">
                      <DisplaySlug currentSlug={sync?.slug} />
                    </Row>
                  </Column>
                )}
                {sync?.config?.mode && (
                  <Column>
                    <MetadataLabel>Mode</MetadataLabel>
                    <Row height="32px" alignItems="center">
                      <Text textTransform="capitalize">
                        {sync?.config?.mode}
                      </Text>
                    </Row>
                  </Column>
                )}
                {isDestinationMetadataSupported && !!sync ? (
                  <Column>
                    <MetadataLabel>Matched users</MetadataLabel>

                    {!destinationMetadata ? (
                      <Row height="32px" alignItems="center">
                        <Skeleton isLoading={isDestinationMetadataLoading}>
                          <SkeletonBox width="100px">
                            <Text>--</Text>
                          </SkeletonBox>
                        </Skeleton>
                      </Row>
                    ) : (
                      <Row gap={2} height="32px" alignItems="center">
                        <Row
                          onClick={() => {
                            setViewMetadata(true);
                          }}
                        >
                          <Link href="">
                            {isAudienceStatusProcessing(destinationMetadata)
                              ? "Processing... check back later."
                              : processAudienceSize(
                                  destinationMetadata.audienceSize,
                                  destinationMetadata.matchRate,
                                  sync.config.useExistingSegment,
                                  sync.config.deleteMode,
                                )}

                            {isMatchedUsersCountSettling(
                              firstRun?.created_at,
                              destinationMetadata,
                              sync.config.useExistingSegment,
                            ) && (
                              <Tooltip message="Matched users count may still be updating">
                                <Box
                                  as={WarningIcon}
                                  color="warning.border"
                                  _hover={{ color: "warning.800" }}
                                  fontSize="16px"
                                  ml={1}
                                />
                              </Tooltip>
                            )}
                          </Link>
                        </Row>
                      </Row>
                    )}
                  </Column>
                ) : null}
                {!editingDraftChanges && labelKeys.length > 0 && (
                  <Column>
                    <MetadataLabel>Labels</MetadataLabel>
                    <Row height="32px" alignItems="center">
                      <Labels labels={currentLabels} />
                    </Row>
                  </Column>
                )}
              </MetadataBar>
            )}

            <Column gap={6} width="100%">
              <RouteableTabs tabParam={syncTabParam}>
                <TabList>
                  {showOverviewTab && (
                    <RouteableTab slug={SyncTabSlug.OVERVIEW}>
                      Overview
                    </RouteableTab>
                  )}
                  <RouteableTab slug={SyncTabSlug.CONFIGURATION}>
                    <Row align="center" gap={2}>
                      Configuration
                      {showStatusCircleConfiguration && (
                        <DraftCircle sx={{ ml: 2 }} />
                      )}
                    </Row>
                  </RouteableTab>
                  <RouteableTab slug={SyncTabSlug.SCHEDULE}>
                    <Row align="center" gap={2}>
                      Schedule
                      {showStatusCircleSchedule && (
                        <DraftCircle sx={{ ml: 2 }} />
                      )}
                    </Row>
                  </RouteableTab>
                  <RouteableTab slug={SyncTabSlug.RUNS}>Runs</RouteableTab>
                  {showAlertsTab && (
                    <RouteableTab slug={SyncTabSlug.ALERTS}>
                      Alerts
                    </RouteableTab>
                  )}
                  {showMonitoringTab && (
                    <RouteableTab slug={SyncTabSlug.ALERTING}>
                      Alerting
                    </RouteableTab>
                  )}
                  {showSyncLogs && (
                    <RouteableTab slug={SyncTabSlug.SYNC_LOGS}>
                      Sync logs
                    </RouteableTab>
                  )}
                  {showSplitsTab && (
                    <RouteableTab slug={SyncTabSlug.SPLITS}>
                      Splits
                    </RouteableTab>
                  )}
                  <RouteableTab slug={SyncTabSlug.ACTIVITY}>
                    Activity
                  </RouteableTab>
                </TabList>
                <TabPanels>
                  {showOverviewTab && (
                    <RouteableTabPanel slug={SyncTabSlug.OVERVIEW}>
                      <Overview sync={sync} />
                    </RouteableTabPanel>
                  )}

                  <RouteableTabPanel slug={SyncTabSlug.CONFIGURATION}>
                    {overageLockout && <OverageContentAlert />}
                    {!overageLockout &&
                      !usesSyncTemplate &&
                      model &&
                      source?.definition &&
                      destination &&
                      destination?.definition && (
                        <DestinationForm
                          permission={{
                            v2: {
                              resource: "sync",
                              grant: "can_update",
                              id: id ?? "",
                            },
                          }}
                          testPermission={{
                            v2: {
                              resource: "sync",
                              grant: "can_test",
                              id: id ?? "",
                            },
                          }}
                          destination={destination}
                          destinationDefinition={destination.definition}
                          externalSegment={externalSegment}
                          model={model}
                          slug={destination?.definition?.type}
                          sourceDefinition={source.definition}
                          sync={sync}
                          onSubmit={updateConfig}
                        />
                      )}

                    {!overageLockout && usesSyncTemplate && (
                      <Alert
                        type="info"
                        variant="inline"
                        title="Sync template"
                        message="This sync uses a shared configuration template"
                        actions={
                          <ButtonGroup>
                            {!workspace?.sync_templates_only && (
                              <PermissionedButton
                                permission={{
                                  v2: {
                                    resource: "sync",
                                    grant: "can_update",
                                    id: sync?.id,
                                  },
                                }}
                                icon={UnlinkIcon}
                                onClick={() =>
                                  setConfirmSyncTemplateDetach(true)
                                }
                              >
                                Detach from template
                              </PermissionedButton>
                            )}
                            <LinkButton
                              href={
                                flags.schemaV2
                                  ? `/schema-v2/settings/sync-templates/${sync?.sync_template_id}`
                                  : `/schema/sync-templates/${sync?.sync_template_id}`
                              }
                            >
                              Edit the template
                            </LinkButton>
                          </ButtonGroup>
                        }
                      />
                    )}
                  </RouteableTabPanel>

                  <RouteableTabPanel slug={SyncTabSlug.SCHEDULE}>
                    <SyncSchedule />
                  </RouteableTabPanel>

                  <RouteableTabPanel slug={SyncTabSlug.RUNS}>
                    <Column gap={6}>
                      {!showOverviewTab && <AlertsAndWarnings sync={sync} />}
                      {sync && (
                        <SyncRuns
                          sync={sync}
                          onRun={() => {
                            startRun();
                          }}
                        />
                      )}
                    </Column>
                  </RouteableTabPanel>

                  {showAlertsTab && (
                    <RouteableTabPanel slug={SyncTabSlug.ALERTS}>
                      <SyncAlerts
                        permission={{
                          v2: { resource: "sync", grant: "can_update", id },
                        }}
                        alerts={syncAlerts || []}
                        rowThresholdAttempted={sync?.row_threshold_attempted}
                        rowThresholdTotal={sync?.row_threshold_total}
                        syncId={Number(id)}
                      />
                    </RouteableTabPanel>
                  )}

                  {showMonitoringTab && (
                    <RouteableTabPanel slug={SyncTabSlug.ALERTING}>
                      <ResourceMonitoring
                        resourceId={id ?? ""}
                        resourceType={MonitoredResourceType.Sync}
                        parentResourceType={ParentResourceTypes.Destination}
                        parentResourceId={destination?.id ?? ""}
                      />
                    </RouteableTabPanel>
                  )}

                  {showSyncLogs && (
                    <RouteableTabPanel slug={SyncTabSlug.SYNC_LOGS}>
                      <WarehouseSyncLogs
                        config={sync?.warehouse_history_config}
                        id={id}
                        source={source ?? undefined}
                        isAudience={Boolean(model?.visual_query_parent_id)}
                      />
                    </RouteableTabPanel>
                  )}

                  {showSplitsTab && (
                    <RouteableTabPanel slug={SyncTabSlug.SPLITS}>
                      <SplitsSyncAssignmentForm
                        audienceId={model?.id}
                        splitsAssignedToCurrentSync={splits}
                        onSubmit={saveSplitSyncAssignments}
                      />
                    </RouteableTabPanel>
                  )}

                  <RouteableTabPanel slug={SyncTabSlug.ACTIVITY}>
                    {id && (
                      <ResourceActivityTimeline
                        primaryResource={{
                          mappers: syncActivityMappers,
                          resource: "Sync",
                          resourceId: String(id),
                        }}
                      />
                    )}
                  </RouteableTabPanel>
                </TabPanels>
              </RouteableTabs>
            </Column>
          </>
        </Page>
      </PermissionProvider>

      <EditLabelModal
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        resourceType={ResourceType.Sync}
        onClose={() => setIsEditLabelModalOpen(false)}
        onSubmit={(labels) =>
          updateSyncMutation.mutateAsync({
            id: id ?? "",
            object: {
              tags: labels,
            },
          })
        }
      />

      {sync?.segment && isFolderModalOpen && (
        <MoveFolder
          folder={sync.segment.folder}
          folderType={
            sync.segment.query_type === "visual" ? "audiences" : "models"
          }
          modelIds={[sync.segment.id]}
          viewType="syncs"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <DestinationMetadataDrawer
        isOpen={viewMetadata}
        onClose={() => {
          setViewMetadata(false);
        }}
        model={model}
        sync={sync}
        metadata={destinationMetadata}
        syncConfigTabCallBack={() => {
          setViewMetadata(false);
          setTab(SyncTabSlug.CONFIGURATION);
        }}
        firstSyncRun={firstRun}
      />

      <ConfirmationDialog
        confirmButtonText="Detach"
        isOpen={confirmSyncTemplateDetach}
        title="Detach from template"
        variant="warning"
        onClose={() => setConfirmSyncTemplateDetach(false)}
        onConfirm={detachSyncTemplate}
      >
        <Paragraph>
          Detaching this sync from template &quot;{syncTemplateName}&quot; means
          future updates to the template will not be propagated to this sync.
          Are you sure you want to detach?
        </Paragraph>
      </ConfirmationDialog>

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={deleteSync}
      />

      <ConfirmationDialog
        isOpen={isResetCDCModalOpen}
        title="Are you sure?"
        confirmButtonText="Reset CDC"
        variant="danger"
        onClose={() => setIsResetCDCModalOpen(false)}
        onConfirm={async () => {
          startRun({ reset_cdc: true });
        }}
      >
        <Paragraph>
          By resetting change data capture for this sync, you are instructing
          Hightouch to ignore the current state of your model and to track only
          rows that are added, changed, or removed in the future. Proceeding
          with this change will trigger a sync run to capture a current snapshot
          of your model. This special sync run will not send any data to the
          destination — i.e., it will not perform a backfill of existing rows.
          Thereafter, future sync runs will track changes normally.
        </Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={isFullResyncModalOpen}
        title="Are you sure?"
        confirmButtonText="Trigger full resync"
        variant="danger"
        onClose={() => setIsFullResyncModalOpen(false)}
        onConfirm={async () => {
          startRun({ resync: true });
        }}
      >
        <ResyncWarning />
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showConfirmRunDialog}
        title="Are you sure?"
        confirmButtonText="Start manual run"
        variant="danger"
        onClose={() => setShowConfirmRunDialog(false)}
        onConfirm={async () => startRun()}
      >
        <Paragraph>
          By forcing a manual sync run, you are instructing Hightouch to run
          this sync outside of the normal journey execution schedule. This can
          be helpful if certain rows failed to sync in previous sync runs.
        </Paragraph>
        <Paragraph mt={6}>
          Beware: for “trigger”-type syncs (e.g. if this sync triggers an email
          send), forcing a manual run may cause messages to be triggered right
          now for members who failed to sync in previous sync runs.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
