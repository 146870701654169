import { useEffect, useState } from "react";

import { useToast } from "@hightouchio/ui";

import {
  SyncRunsQuery,
  useStartResetCdcRunMutation,
  useStartSyncRunMutation,
  useSyncRunsQuery,
  useUpdateSyncRequestMutation,
} from "src/graphql";
import { SyncRunStatus, syncStatusIsTerminal } from "src/utils/syncs";

export const useCurrentRun = (
  id: string | undefined,
): {
  latestRun: SyncRunsQuery["sync_requests"][0] | undefined;
  isCancelling: boolean;
  isRunning: boolean;
  isRunDisabled: boolean;
  startRun: (options?: {
    resync?: boolean;
    reset_cdc?: boolean;
  }) => Promise<void>;
  cancelRun: () => Promise<void>;
} => {
  const [isRunDisabled, setIsRunDisabled] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelledSyncId, setCancelledSyncId] = useState(false);

  const { mutateAsync: cancelSyncRequest } = useUpdateSyncRequestMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();
  const { mutateAsync: forceResetCDCSyncRun } = useStartResetCdcRunMutation();

  const { toast } = useToast();

  const { data: latestRun, refetch: refetchLastRun } = useSyncRunsQuery(
    {
      filter: {
        destination_instance_id: { _eq: id },
        // We only want to use the status of the last run that is not queued,
        // because a run can have at most one active attempt and one queued attempt
        status: { _neq: "queued" },
      },
      limit: 1,
    },
    {
      enabled: Boolean(id),
      select: (data) => data.sync_requests?.[0],
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const syncAttempt = latestRun?.sync_attempts?.[0];
  const isRunning = syncAttempt?.status === SyncRunStatus.ACTIVE;

  useEffect(() => {
    // if the latest run id no longer match the cancelled id then it means cancel is successful.
    if (
      syncAttempt?.status === SyncRunStatus.CANCELLED ||
      cancelledSyncId !== latestRun?.id
    ) {
      setIsCancelling(false);
    }
  }, [syncAttempt?.status, latestRun?.id, cancelledSyncId]);

  useEffect(() => {
    if (syncStatusIsTerminal(syncAttempt?.status as SyncRunStatus)) {
      setIsRunDisabled(false);
    }
  }, [syncAttempt?.status]);

  const cancelRun = async (): Promise<void> => {
    setIsCancelling(true);
    setCancelledSyncId(latestRun?.id);
    cancelSyncRequest({
      id: latestRun?.id,
      object: {
        trigger_cancel: true,
      },
    });

    await refetchLastRun();
  };

  const startRun = async ({
    resync = false,
    reset_cdc = false,
  }: { resync?: boolean; reset_cdc?: boolean } = {}): Promise<void> => {
    setIsRunDisabled(true);
    let startSyncRun;
    if (reset_cdc) {
      ({ startSyncRun } = await forceResetCDCSyncRun({
        id: Number(id),
        reset_cdc: reset_cdc,
      }));
    } else {
      ({ startSyncRun } = await forceRun({
        id: Number(id),
        full_resync: resync,
      }));
    }
    // Only render a popup message if we actually scheduled a sync.
    const scheduled = startSyncRun?.scheduled;

    if (scheduled) {
      if (resync) {
        toast({
          id: "start-sync-run",
          title: "Resync will begin shortly",
          variant: "success",
        });
      } else {
        toast({
          id: "start-sync-run",
          title: "Manual run will begin shortly",
          variant: "success",
        });
      }
    }

    await refetchLastRun();
  };

  return {
    latestRun,
    isCancelling,
    isRunning,
    isRunDisabled,
    startRun,
    cancelRun,
  };
};
