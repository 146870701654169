import { createContext, FC, ReactNode, useContext } from "react";

import { Brand } from "@hightouch/core/server/routes/partner/types";

export type PartnerContextValue = { brand: Brand; cobranded: boolean };

export const PartnerContext = createContext<PartnerContextValue>({
  brand: {} as Brand,
  cobranded: true,
});

export const usePartner = () => useContext(PartnerContext);

const cobrandExclusions = ["iterable", "hooli"];

export const PartnerProvider: FC<
  Readonly<{ brand: Brand; children: ReactNode }>
> = ({ brand, children }) => {
  return (
    <PartnerContext.Provider
      value={{
        brand,
        cobranded: cobrandExclusions.includes(brand.name.toLowerCase())
          ? false
          : true,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};
