import { useLocation } from "src/router";

import { useEventCollectionSection } from "./sections/event-collection";
import { useIntelligenceSection } from "./sections/intelligence";
import { useActivateSection } from "./sections/activate";
import { useCustomerStudioSection } from "./sections/customer-studio";
import { useIntegrationsSection } from "./sections/integrations";
import { useMemo } from "react";
import { useSettingsSection } from "./sections/settings";
import { useDecisionEnginesSection } from "./sections/decision-engine";

export type ActiveSectionProps = {
  openSection: string | undefined;
  activeSection: string | undefined;
  activeLinkIndex: number;
};

export const useActiveSection = (): ActiveSectionProps => {
  const router = useLocation();

  const eventCollection = useEventCollectionSection();
  const decisioning = useDecisionEnginesSection();
  const intelligence = useIntelligenceSection();
  const activate = useActivateSection();
  const customerStudio = useCustomerStudioSection();
  const integrations = useIntegrationsSection();
  const settings = useSettingsSection();

  const sections = [
    eventCollection,
    intelligence,
    activate,
    customerStudio,
    decisioning,
    integrations,
    settings,
  ];

  return useMemo(() => {
    const enabledSections = sections.filter((section) => section.isEnabled);

    let activeLinkIndex = 0;
    let activeSection: string | undefined;

    for (const section of enabledSections) {
      activeLinkIndex = section.links
        .filter(Boolean)
        .findIndex((link) =>
          isLinkActive(router.pathname, link.href, link.isSub),
        );

      if (activeLinkIndex !== -1) {
        activeSection = section.title;
        break;
      }
    }

    // Open all sections only if Activate and Integrations are enabled, with the exception of settings
    if (
      enabledSections.includes(activate) &&
      enabledSections.includes(integrations) &&
      (enabledSections.length === 2 ||
        (enabledSections.includes(settings) && enabledSections.length === 3))
    ) {
      return { openSection: "all", activeSection, activeLinkIndex };
    } else {
      return { openSection: activeSection, activeSection, activeLinkIndex };
    }
  }, [router.pathname, sections]);
};

export const useActiveLink = ({
  href,
  isSub = false,
}: {
  href: string | undefined;
  isSub?: boolean | undefined;
}) => {
  const router = useLocation();
  return isLinkActive(router.pathname, href, isSub);
};

const isLinkActive = (
  pathname: string,
  href: string | undefined,
  isSub: boolean | undefined,
) => {
  const paths = pathname.split("/");
  const path = isSub ? `/${paths[1]}/${paths[2]}` : `/${paths[1]}`;
  return path === href;
};
