import { Heading, Tabs, Tab, TabList } from "@hightouchio/ui";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "src/router";

import { Page } from "src/components/layout";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { SyncTemplate } from "src/pages/audiences/setup/sync-template";
import { SyncTemplates } from "src/pages/audiences/setup/sync-templates";

export const SchemaSettings = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          index
          element={<Navigate to="sync-templates" replace />}
        />
        <Route path="/sync-templates" element={<SyncTemplates />} />
      </Route>
      <Route path="sync-templates/new" element={<CreateSyncTemplate />} />
      <Route
        path="/sync-templates/:sync_template_id"
        element={<SyncTemplate />}
      />
    </Routes>
  );
};

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabRoutes = ["/schema-v2/settings/sync-templates"];

  return (
    <Page
      title="Schema - Settings"
      crumbs={[{ label: "Schema", link: "/schema-v2" }]}
    >
      <Heading size="xl" mb={4}>
        Settings
      </Heading>
      <Tabs
        onChange={(index) => navigate(tabRoutes[index]!)}
        index={tabRoutes.indexOf(location.pathname)}
      >
        <TabList>
          <Tab>Sync templates</Tab>
        </TabList>
      </Tabs>
      <Outlet />
    </Page>
  );
};
