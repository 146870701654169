import {
  EventForwardingDestinationType,
  EventSourceType,
  EventWarehouseDestinationType,
} from "@hightouch/core/server/resources/events/types";

export * from "@hightouch/core/events/types";

export type EventSourceDefinition<T extends EventSourceType = EventSourceType> =
  {
    name: string;
    type: T;
    icon: string;
    isQueue?: boolean;
    tunneling?: boolean;
    provider?: string;
  };

export const eventSourceDefinitions: {
  [K in EventSourceType]: EventSourceDefinition<K>;
} = {
  [EventSourceType.JAVASCRIPT]: {
    name: "Javascript",
    type: EventSourceType.JAVASCRIPT,
    icon: "https://simpleicons.org/icons/javascript.svg",
  },
  [EventSourceType.NODE]: {
    name: "Node",
    type: EventSourceType.NODE,
    icon: "https://simpleicons.org/icons/nodedotjs.svg",
  },
  [EventSourceType.PYTHON]: {
    name: "Python",
    type: EventSourceType.PYTHON,
    icon: "https://simpleicons.org/icons/python.svg",
  },
  [EventSourceType.RUBY]: {
    name: "Ruby",
    type: EventSourceType.RUBY,
    icon: "https://simpleicons.org/icons/ruby.svg",
  },
  [EventSourceType.GO]: {
    name: "Go",
    type: EventSourceType.GO,
    icon: "https://simpleicons.org/icons/go.svg",
  },
  [EventSourceType.PHP]: {
    name: "PHP",
    type: EventSourceType.PHP,
    icon: "https://simpleicons.org/icons/php.svg",
  },
  [EventSourceType.REACT_NATIVE]: {
    name: "React Native",
    type: EventSourceType.REACT_NATIVE,
    icon: "https://simpleicons.org/icons/react.svg",
  },
  [EventSourceType.IOS]: {
    name: "iOS",
    type: EventSourceType.IOS,
    icon: "https://simpleicons.org/icons/ios.svg",
  },
  [EventSourceType.ANDROID]: {
    name: "Android",
    type: EventSourceType.ANDROID,
    icon: "https://simpleicons.org/icons/android.svg",
  },
  [EventSourceType.JAVA]: {
    name: "Java",
    type: EventSourceType.JAVA,
    icon: "https://simpleicons.org/icons/openjdk.svg",
  },
  [EventSourceType.HTTP]: {
    name: "HTTP",
    type: EventSourceType.HTTP,
    icon: "https://simpleicons.org/icons/curl.svg",
  },
  [EventSourceType.CSHARP]: {
    name: "C#",
    type: EventSourceType.CSHARP,
    icon: "https://simpleicons.org/icons/dotnet.svg",
  },
  [EventSourceType.WEBHOOK]: {
    name: "Webhook",
    type: EventSourceType.WEBHOOK,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/c7f8680a7496511235449a0c8ffaf3176798448f-800x800.svg",
  },
  [EventSourceType.KAFKA]: {
    name: "Kafka",
    type: EventSourceType.KAFKA,
    icon: "https://simpleicons.org/icons/apachekafka.svg",
    isQueue: true,
    tunneling: true,
  },
  [EventSourceType.CONFLUENT_KAFKA]: {
    name: "Confluent", // confluent prefers we don't mention the apache trademarked "Kafka" in the name
    type: EventSourceType.CONFLUENT_KAFKA,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/6616cf2a96b8bb0d9b4afd4dddf3a5b42d152567-48x48.svg",
    isQueue: true,
    tunneling: true,
  },
  [EventSourceType.PUBSUB]: {
    name: "Google PubSub",
    type: EventSourceType.PUBSUB,
    icon: "https://simpleicons.org/icons/googlepubsub.svg",
    isQueue: true,
    provider: "gcp",
  },
};

export const eventWarehouseDestinationDefinitions = {
  [EventWarehouseDestinationType.BIGQUERY]: {
    name: "BigQuery",
    type: EventWarehouseDestinationType.BIGQUERY,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/2a4f3d940e21dfa356bd993177586dab5e1b628f-2500x2500.svg",
  },
  [EventWarehouseDestinationType.DATABRICKS]: {
    name: "Databricks",
    type: EventWarehouseDestinationType.DATABRICKS,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/d8559d998096f27ffbbb4aee3682dc4f58677cd2-512x512.svg",
  },
  [EventWarehouseDestinationType.POSTGRES]: {
    name: "PostgreSQL",
    type: EventWarehouseDestinationType.POSTGRES,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/245cb2ccbc976d6dc38d90456ca1fd7cdbcb2dc6-2424x2500.svg",
  },
  [EventWarehouseDestinationType.REDSHIFT]: {
    name: "Redshift",
    type: EventWarehouseDestinationType.REDSHIFT,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/f3e8edc5f92315c09202e01a28f206e777084c12-512x512.svg",
  },
  [EventWarehouseDestinationType.S3]: {
    name: "S3",
    type: EventWarehouseDestinationType.S3,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/f964e22514dff38c0aae83ec4f34fefcb3870776-310x310.svg",
  },
  [EventWarehouseDestinationType.SNOWFLAKE]: {
    name: "Snowflake",
    type: EventWarehouseDestinationType.SNOWFLAKE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/a4ac9f5f978ab2446fc17bf116067cb7c74116a2-960x952.png",
  },
};

type EventDestinationDefinition<T extends EventForwardingDestinationType> = {
  name: string;
  type: T;
  icon: string;
};

export const eventForwardingDestinationDefinitions: {
  [K in EventForwardingDestinationType]: EventDestinationDefinition<K>;
} = {
  [EventForwardingDestinationType.GOOGLE_ANALYTICS]: {
    name: "Google Analytics",
    type: EventForwardingDestinationType.GOOGLE_ANALYTICS,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/44d59c83ddbb8d49cd6962240916853a3b79b448-2500x2498.svg",
  },
  [EventForwardingDestinationType.HTTP_REQUEST]: {
    name: "HTTP Request",
    type: EventForwardingDestinationType.HTTP_REQUEST,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/3418bc9ae0b7c230f485628f2dfbc5edc80ef15a-12x12.svg",
  },
  [EventForwardingDestinationType.BRAZE]: {
    name: "Braze",
    type: EventForwardingDestinationType.BRAZE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/dd757379e84ce72a0ebf486dd63659c00af4abed-512x512.svg",
  },
  [EventForwardingDestinationType.AMPLITUDE]: {
    name: "Amplitude",
    type: EventForwardingDestinationType.AMPLITUDE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/50da27aa7e26819bd62ac0bebf669ad0446fcf90-512x512.svg",
  },
  [EventForwardingDestinationType.ITERABLE]: {
    name: "Iterable",
    type: EventForwardingDestinationType.ITERABLE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/8069e1ed66b07e9047e685a6edbd1c75e433f33f-1010x1010.png",
  },
  [EventForwardingDestinationType.SFMC]: {
    name: "Salesforce Marketing Cloud",
    type: EventForwardingDestinationType.SFMC,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/e4fa4b01bbc006d21f215f7c629fb4120eaeaeb4-512x512.svg",
  },
  [EventForwardingDestinationType.KAFKA]: {
    name: "Kafka",
    type: EventForwardingDestinationType.KAFKA,
    icon: "https://simpleicons.org/icons/apachekafka.svg",
  },
  [EventForwardingDestinationType.CUSTOMER_IO]: {
    name: "Customer.io",
    type: EventForwardingDestinationType.CUSTOMER_IO,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/42ff2109f47a92abc13ef0f2fa4f9e47d030e6ee-512x512.svg",
  },
  [EventForwardingDestinationType.MOENGAGE]: {
    name: "MoEngage",
    type: EventForwardingDestinationType.MOENGAGE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/8f187ed8288697e7dcb16d01426f670ace5e16c9-400x400.png",
  },
};

export {
  EventSourceType,
  EventWarehouseDestinationType,
  EventForwardingDestinationType,
};
