import { useState } from "react";

import {
  Button,
  Column,
  IconButton,
  ChevronUpIcon,
  ChevronDownIcon,
  Row,
  DeleteIcon,
  Combobox,
  Box,
} from "@hightouchio/ui";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { Arrow } from "src/ui/arrow";

export const LookupMapper = ({
  destName,
  destColumns,
  destColumnsLoading,
  destColumnsDisabled,
  errors = false,
}) => {
  const { hightouchColumns, config, setConfig } = useDestinationForm();

  const [newMapping, setNewMapping] = useState({ from: null, to: null });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  console.log(hightouchColumns, config);

  return (
    <Column gap={4}>
      {config.externalIdMappings.map((mapping, idx) => {
        return (
          <Row key={idx} sx={{ alignItems: "center", gap: 2 }}>
            <Combobox
              isInvalid={errors}
              options={hightouchColumns[0]?.options ?? []}
              optionValue={(column) => column.label}
              optionLabel={(column) => column.label}
              placeholder="Select a Hightouch column"
              value={mapping.from}
              onChange={(selected) => {
                setNewMapping({ ...newMapping, from: selected! });
              }}
            />
            <Arrow />
            <Combobox
              isDisabled={destColumnsDisabled}
              isInvalid={errors}
              isLoading={destColumnsLoading}
              optionValue={(option) => option}
              optionLabel={(option) => option}
              options={(destColumns ?? []) as Array<string>}
              placeholder={`Select a ${destName} column`}
              value={mapping.to}
              onChange={(selected) => {
                setNewMapping({ ...newMapping, to: selected! });
              }}
            />
            <Row sx={{ alignItems: "center", gap: 1 }}>
              <IconButton
                aria-label="Move up"
                icon={ChevronUpIcon}
                isDisabled={idx === 0}
                onClick={() => {
                  const newMappings = reorder(
                    config.externalIdMappings,
                    idx,
                    idx - 1,
                  );

                  setConfig({ ...config, externalIdMappings: newMappings });
                }}
              />
              <IconButton
                icon={ChevronDownIcon}
                aria-label="Move down"
                isDisabled={idx >= config.externalIdMappings.length - 1}
                onClick={() => {
                  const newMappings = reorder(
                    config.externalIdMappings,
                    idx,
                    idx + 1,
                  );

                  setConfig({ ...config, externalIdMappings: newMappings });
                }}
              />
              <IconButton
                aria-label="Delete"
                icon={DeleteIcon}
                onClick={() => {
                  const newMappings = config.externalIdMappings.filter(
                    (_, index) => index !== idx,
                  );
                  setConfig({ ...config, externalIdMappings: newMappings });
                }}
              />
            </Row>
          </Row>
        );
      })}

      <Box
        as={Button}
        alignSelf="flex-start"
        onClick={() => {
          setConfig({
            ...config,
            externalIdMappings: [
              ...config.externalIdMappings,
              {
                from: "",
                to: "",
              },
            ],
          });
        }}
      >
        Add merge rule
      </Box>
    </Column>
  );
};
