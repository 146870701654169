import { FC, useState, useEffect } from "react";

import {
  Row,
  Column,
  Text,
  IconButton,
  SearchInput,
  Button,
  Spinner,
  RefreshIcon,
  Dialog,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { useDbtSyncModelsQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { DBTIcon } from "src/ui/icons";

import { Editor } from "../editor";
import { DbtQuery } from "./preview/dbt-query";
import { SelectorRow } from "./selector-row";

type Source = {
  id: string;
  name: string;
  type: string;
  definition: {
    name: string;
    icon: string;
    isSampleDataSource: boolean;
  };
};

type Props = {
  dbtModelId: number | null | undefined;
  onChange: (dbtModel: number) => void;
  source: Source;
};

export const DBTModelSelector: FC<Readonly<Props>> = ({
  dbtModelId,
  onChange,
  source,
}) => {
  const [search, setSearch] = useState<string>("");
  const [showSQL, setShowSQL] = useState<boolean>(false);

  const {
    data,
    isFetching: loading,
    refetch,
  } = useDbtSyncModelsQuery(
    {
      sourceId: source?.id,
    },
    { enabled: Boolean(source?.id) },
  );

  const noDBTModels = !loading && !data?.dbt_sync_models?.length;

  const dbtModel = data?.dbt_sync_models?.find(({ id }) => id === dbtModelId);
  const available = data?.dbt_sync_models?.filter(
    (model) => model?.removed !== true,
  );

  useEffect(() => {
    if (noDBTModels && !loading) {
      analytics.track("Add Model Query Mode Error", {
        source_type: source?.type,
        source_name: source?.name,
        query_mode: "dbt_model",
        error: "No dbt models were synced",
      });
    }
  }, [loading, noDBTModels]);

  if (noDBTModels) {
    return (
      <Column
        sx={{
          pt: 10,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text color="danger.base">
          No dbt models were synced from the Git repository associated with this
          source.
        </Text>
        <Text color="danger.base">
          Please add your Git credentials in the{" "}
          <Link href="/extensions/dbt-models">settings page</Link> and select a
          repository in your <Link href={`/sources/${source?.id}`}>source</Link>
          .
        </Text>
      </Column>
    );
  }

  const models = search
    ? available?.filter(({ schema, name, data }) => {
        const lowercaseSearch = search.toLowerCase();
        const displayName = schema ? `${schema}.${name}` : name;
        return (
          displayName.toLowerCase()?.includes(lowercaseSearch) ||
          data?.alias?.toLowerCase()?.includes(lowercaseSearch)
        );
      })
    : available;

  const isPlaceholder = loading || !models?.length;

  return (
    <>
      <Column overflow="hidden" flex={1} minWidth={0}>
        <Row overflow="hidden" gap={8}>
          <Column
            overflow="hidden"
            flex={1}
            minW={0}
            border="1px"
            borderColor="base.border"
            borderRadius="md"
          >
            <Row
              align="center"
              gap={2}
              p={4}
              borderBottom="1px"
              borderColor="base.border"
            >
              <SearchInput
                placeholder="Search dbt models..."
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                variant="secondary"
                aria-label="Refresh dbt models"
                icon={RefreshIcon}
                isLoading={loading}
                onClick={() => {
                  refetch();
                }}
              />
            </Row>
            <Column
              overflow="auto"
              flex={1}
              minWidth={0}
              p={isPlaceholder ? 10 : undefined}
              align={isPlaceholder ? "center" : undefined}
              justify={isPlaceholder ? "center" : undefined}
            >
              {loading ? (
                <Spinner size="lg" />
              ) : !models?.length ? (
                <>
                  <Text size="lg" fontWeight="semibold">
                    {search
                      ? "No dbt models match your search"
                      : "No dbt models found"}
                  </Text>
                  <Text color="text.secondary">
                    Try refreshing to populate new dbt models
                  </Text>
                </>
              ) : (
                <>
                  {models?.map((model) => {
                    const active = model?.id === dbtModelId;
                    return (
                      <SelectorRow
                        key={model?.id}
                        icon={
                          <DBTIcon
                            size={16}
                            color="var(--chakra-colors-text-secondary)"
                          />
                        }
                        selected={active}
                        onClick={() => onChange(model?.id)}
                      >
                        {`${model?.schema}.${
                          model?.data?.alias || model?.name
                        }`}
                      </SelectorRow>
                    );
                  })}
                </>
              )}
            </Column>
          </Column>
          {dbtModel && (
            <DbtQuery
              model={dbtModel}
              actions={
                <Button
                  onClick={() => {
                    setShowSQL(true);
                  }}
                >
                  View SQL
                </Button>
              }
            />
          )}
        </Row>
        <Dialog
          isOpen={showSQL}
          variant="info"
          width="auto"
          title="SQL"
          actions={<Button onClick={() => setShowSQL(false)}>Close</Button>}
          onClose={() => setShowSQL(false)}
        >
          <Editor
            readOnly
            language="sql"
            value={dbtModel?.data?.compiled_sql}
          />
        </Dialog>
      </Column>
    </>
  );
};
