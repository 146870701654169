import { FC } from "react";

import { StatusBadge, StatusBadgeProps, Tooltip } from "@hightouchio/ui";
import capitalize from "lodash/capitalize";

import { JourneyState } from "src/pages/journeys/types";

const STATUS_MAPPING: Record<
  JourneyState,
  { variant: StatusBadgeProps["variant"]; message: string }
> = {
  live: { variant: "success", message: "Journey is actively running" },
  draft: { variant: "draft", message: "Journey has not been started yet" },
  draining: {
    variant: "warning",
    message:
      "Not accepting new entries but existing members will complete the journey",
  },
  off: {
    variant: "error",
    message:
      "Not accepting new entries and all members have exited the journey",
  },
  paused: {
    variant: "inactive",
    message:
      "Not accepting new entries and existing members remain in their current step",
  },
};

type Props = {
  status: JourneyState;
};

export const JourneyStatusBadge: FC<Props> = ({ status }) => {
  const badge = STATUS_MAPPING[status];

  if (!badge) {
    return null;
  }

  return (
    <Tooltip message={badge.message}>
      <StatusBadge variant={badge.variant}>{capitalize(status)}</StatusBadge>
    </Tooltip>
  );
};
