import { Column, FormField, SectionHeading, Switch } from "@hightouchio/ui";
import { useOutletContext, useParams } from "src/router";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { OutletContext } from ".";
import {
  useUpdateGeneralGrantsMutation,
  useUserCreationGrantsQuery,
} from "src/graphql";
import { ActionBar } from "src/components/action-bar";
import { Controller } from "react-hook-form";

export const GeneralGrants = () => {
  const { isWorkspaceAdmin } = useOutletContext<OutletContext>();
  const { roleId, workspaceId } = useParams();

  const updateGeneralGrants = useUpdateGeneralGrantsMutation();

  const { data: { canCreateSource, canCreateDestination } = {} } =
    useUserCreationGrantsQuery(
      {
        userGroupId: roleId ?? "",
        workspaceId: workspaceId ?? "",
      },
      {
        select: (data) => {
          const userGroupGrants = data.user_group_workspaces_by_pk;
          return {
            canCreateSource: userGroupGrants?.can_create_sources,
            canCreateDestination: userGroupGrants?.can_create_destinations,
          };
        },
        suspense: true,
      },
    );

  const form = useHightouchForm({
    values: {
      canCreateSource,
      canCreateDestination,
    },
    onSubmit: async ({ canCreateSource, canCreateDestination }) => {
      await updateGeneralGrants.mutateAsync({
        userGroupId: roleId ?? "",
        workspaceId: workspaceId ?? "",
        // we should probably be relying on triggers to do this, but for now we'll just pass the values
        canCreateSource: canCreateSource ?? false,
        canCreateDestination: canCreateDestination ?? false,
      });
    },
  });

  return (
    <Form form={form}>
      <Column p={4} flex={1} gap={6}>
        <SectionHeading>General</SectionHeading>
        <Column gap={4}>
          <Controller
            name="canCreateSource"
            defaultValue={false}
            render={({ field }) => (
              <FormField
                label="Can this role create sources?"
                description="This allows users to create sources and assign roles to access the source upon creation. They can grant others the ability to manage and sync from the source."
              >
                <Switch
                  isDisabled={isWorkspaceAdmin}
                  isChecked={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          <Controller
            name="canCreateDestination"
            defaultValue={false}
            render={({ field }) => (
              <FormField
                label="Can this role create destinations?"
                description="This allows users to create destinations and assign roles to access the destinations upon creation. They can grant others the ability to manage and sync to the destination."
              >
                <Switch
                  isDisabled={isWorkspaceAdmin}
                  isChecked={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
        </Column>
      </Column>
      <ActionBar fit>
        <FormActions />
      </ActionBar>
    </Form>
  );
};
