import { FC, useState } from "react";
import {
  Text,
  Button,
  ButtonGroup,
  Column,
  Row,
  SearchInput,
  useToast,
  PlusIcon,
} from "@hightouchio/ui";
import { captureEvent } from "@sentry/react";
import { Outlet, useOutletContext } from "src/router";

import {
  useRemoveUsersFromUserGroupMutation,
  useUpdateUserGroupMembersMutation,
} from "src/graphql";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { PermissionedLinkButton } from "src/components/permission";
import { useRowSelect } from "src/ui/table/use-row-select";
import { OutletContext } from "..";
import { Member, Members } from "./components/members";

export const RoleUsers: FC = () => {
  const { toast } = useToast();
  const context = useOutletContext<OutletContext>();

  const removeUsersMutation = useRemoveUsersFromUserGroupMutation();
  const updateUsersMutation = useUpdateUserGroupMembersMutation();

  const { onRowSelect, selectedRows } = useRowSelect();
  const [search, setSearch] = useState("");

  const onRemove = async () => {
    try {
      await removeUsersMutation.mutateAsync({
        userGroupId: String(context.userGroup.id) ?? "",
        userIds: selectedRows.map(String),
      });
      toast({
        id: "remove",
        title: `${selectedRows.length} members removed from the group`,
        variant: "success",
      });
      // clear out the selected rows (they should be deleted)
      onRowSelect([]);
    } catch (e) {
      toast({
        id: "remove",
        title: "There was an error removing members from the group",
        variant: "error",
      });
      captureEvent(e);
    }
  };

  const initialMembers = context.userGroup.members.reduce((acc, member) => {
    // member can be in a user group via SSO or directly
    const existingUser = acc[member.user_id];
    return {
      ...acc,
      [member.user_id]: {
        user_id: member.user_id,
        user: member.user,
        is_member: !member.is_sso || Boolean(existingUser?.is_member),
        is_member_via_sso:
          member.is_sso || Boolean(existingUser?.is_member_via_sso),
        is_group_manager:
          member.is_group_manager || Boolean(existingUser?.is_group_manager),
      },
    };
  }, {}) as Record<string, Member>;

  const form = useHightouchForm({
    onSubmit: async ({ members }) => {
      const groups = Object.values(members);
      await updateUsersMutation.mutateAsync({
        objects: groups.map(({ user_id, is_group_manager }) => ({
          user_group_id: String(context.userGroup.id),
          user_id: String(user_id),
          is_group_manager,
        })),
      });
    },
    values: {
      members: initialMembers,
    },
  });

  return (
    <>
      <Form form={form}>
        <Row align="center" justify="space-between" p={4} gap={4}>
          <Text color="text.secondary">
            Add or remove members assigned to this group
          </Text>
          <ButtonGroup>
            <SearchInput
              value={search}
              placeholder="Search by name or email..."
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
            {selectedRows?.length > 0 && (
              <Button
                variant="warning"
                onClick={onRemove}
                isLoading={removeUsersMutation.isLoading}
              >
                Remove {selectedRows?.length} members
              </Button>
            )}
            <PermissionedLinkButton
              href="new"
              organizationPermission={{
                resource: "group",
                id: context.userGroup.id,
              }}
              icon={PlusIcon}
            >
              Add members
            </PermissionedLinkButton>
          </ButtonGroup>
        </Row>
        <Column flex={1} overflow="auto" p={4}>
          <Members
            organizationId={context.organization.id}
            members={Object.values(initialMembers) ?? []}
            isMemberDisabled={(member) =>
              Boolean(member.is_member_via_sso && !member.is_member)
            }
            search={search}
            onSelect={onRowSelect}
            selectedRows={selectedRows}
          />
        </Column>
        <ActionBar fit>
          <FormActions />
        </ActionBar>
      </Form>
      <Outlet context={context} />
    </>
  );
};
