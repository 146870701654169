import { PropsOf } from "@emotion/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@hightouchio/ui";
import { isEqual, omit } from "lodash";
import React from "react";
import { useSearchParamsTab } from "./utils";

export type RoutableTabProps<Slug extends string> = {
  slug: Slug;
} & PropsOf<typeof Tab>;

export function RouteableTab<Slug extends Readonly<string>>(
  props: RoutableTabProps<Slug>,
) {
  return <Tab {...omit(props, "slug")} />;
}

const makeTabSlug = (slug: string) =>
  encodeURIComponent(slug.replace(/\s/g, "-"));

export function RouteableTabs(
  props: Omit<PropsOf<typeof Tabs>, "index" | "onChange"> & {
    tabParam?: string;
    onChange?: () => void;
  },
) {
  const nonProdEnvironment = !import.meta.env.PROD || import.meta.env.PREVIEW;

  /**
   * Get the slugs of the children of this RouteableTabs component so
   * that we can use them to set the index of the underlying Tabs component.
   */
  const routeableTabSlugs = React.Children.toArray(
    React.Children.toArray(props.children)
      .filter((child) => (child as any).type?.name === TabList.name)
      .map((child) => (child as any).props.children),
  ).map((child) => {
    if (nonProdEnvironment && (child as any).type?.name !== RouteableTab.name)
      throw new Error(
        `A TabList within RouteableTabs should only contain RouteableTab children, found ${(
          child as any
        ).type?.name}`,
      );
    return makeTabSlug((child as any).props?.slug as string);
  });

  const routeableTabPanelSlugs = React.Children.toArray(
    React.Children.toArray(props.children)
      .filter((child) => (child as any).type?.name === TabPanels.name)
      .map((child) => (child as any).props.children),
  ).map((child) => {
    if (
      nonProdEnvironment &&
      (child as any).type?.name !== RouteableTabPanel.name
    )
      throw new Error(
        `A TabPanels within RouteableTabs should only contain RouteableTabPanel children, found ${(
          child as any
        ).type?.name}`,
      );
    return makeTabSlug((child as any).props?.slug as string);
  });

  if (
    nonProdEnvironment &&
    !isEqual(routeableTabSlugs, routeableTabPanelSlugs)
  ) {
    throw new Error(
      `Tried to create RouteableTabs with mismatched ReroutableTabPanels. Check that your TabList and TabPanels have identical slugs in the same order: ${routeableTabSlugs} !== ${routeableTabPanelSlugs}`,
    );
  }

  const [selectedTab, select] = useSearchParamsTab(
    routeableTabSlugs,
    props.tabParam,
  );
  return (
    <Tabs
      index={selectedTab}
      onChange={(index) => {
        if (props.onChange) props.onChange();
        select(index);
      }}
    >
      {props.children}
    </Tabs>
  );
}

export function RouteableTabPanel<Slug extends Readonly<string>>(
  props: PropsOf<typeof TabPanel> & { slug: Slug },
) {
  return TabPanel(omit(props, "slug"));
}
