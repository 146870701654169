import {
  Drawer,
  DrawerHeader,
  Row,
  DrawerBody,
  IconButton,
  CloseIcon,
  SectionHeading,
  SearchInput,
} from "@hightouchio/ui";
import { FC, useState } from "react";
import { useNavigate, useOutletContext } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, useHightouchForm, SaveButton } from "src/components/form";
import { OutletContext } from "..";
import { useUpsertUserGroupWorkspacesMutation } from "src/graphql";
import {
  Workspace,
  Workspaces,
  useUserGroupWorkspaces,
} from "./components/workspaces";

export const AddWorkspaces: FC = () => {
  const navigate = useNavigate();
  const context = useOutletContext<OutletContext>();
  const [search, setSearch] = useState("");

  const addWorkspacesMutation = useUpsertUserGroupWorkspacesMutation();

  const onClose = () => {
    navigate(`/organization/groups/${context.userGroup.id}/workspaces`);
  };

  const workspaces = useUserGroupWorkspaces({
    organizationId: context.organization.id,
    userGroupId: context.userGroup.id,
    search,
    excludeAssigned: true,
  });

  const form = useHightouchForm({
    onSubmit: async ({ workspaces }) => {
      await addWorkspacesMutation.mutateAsync({
        objects: Object.values(workspaces)
          .filter(Boolean)
          .map((workspace) => ({
            user_group_id: String(context.userGroup.id),
            workspace_id: String(workspace.id),
            is_workspace_admin: workspace.is_workspace_admin,
          })),
      });
      onClose();
    },
    success: (data) =>
      `Added ${Object.keys(data.workspaces).length} workspaces to group`,
    defaultValues: {
      workspaces: {} as Record<string, Workspace>,
    },
  });

  const values = form.watch("workspaces");
  const selected = Object.values(values)
    .filter(Boolean)
    .map(({ id }) => id);
  const count = selected.length;

  return (
    <Form form={form}>
      <Drawer isOpen={true} onClose={onClose} size="lg">
        <DrawerHeader>
          <Row justifyContent="space-between" alignItems="center" width="100%">
            Assign workspaces
            <IconButton
              aria-label="Close drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Row align="center" gap={4} justify="space-between" mb={4}>
            <Row gap={2} align="center">
              <SectionHeading>Select workspaces</SectionHeading>
            </Row>
            <SearchInput
              value={search}
              placeholder="Search by name..."
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Row>
          <Workspaces
            isEditable
            placeholder={{
              title: "No workspaces",
              error: "Workspaces failed to load, please try again.",
              body: search
                ? "No workspaces match your search."
                : "All workspaces are already assigned to this role.",
            }}
            workspaces={workspaces}
            onSelect={(selection) => {
              const newValues = { ...values };
              if (Array.isArray(selection)) {
                Object.keys(newValues).forEach((key) => delete newValues[key]);
                selection.forEach((id) => {
                  newValues[id] = workspaces.find((w) => w.id === id)!;
                });
              } else {
                if (newValues[selection]) {
                  delete newValues[selection];
                } else {
                  newValues[selection] = workspaces.find(
                    (w) => w.id === selection,
                  )!;
                }
              }
              form.setValue("workspaces", newValues, {
                shouldDirty: true,
              });
            }}
            selectedRows={selected}
          />
        </DrawerBody>
        <ActionBar fit>
          <SaveButton>
            {count ? `Assign ${count} workspaces` : "Assign workspaces"}
          </SaveButton>
        </ActionBar>
      </Drawer>
    </Form>
  );
};
