import {
  DecisionEngineChannelConfigType,
  DecisionEngineChannelType,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Controller, useFormContext } from "react-hook-form";
import { DestinationsQuery, useDestinationsQuery } from "src/graphql";
import { IdentifierField } from "./identifier-field";
import {
  Column,
  Combobox,
  FormField,
  Radio,
  RadioGroup,
  Row,
  Switch,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { FC } from "react";
import { SUPPORTED_DESTINATIONS } from "../../definitions";

type ChannelForm = (props: {
  type: DecisionEngineChannelType;
  destinations: DestinationsQuery["destinations"];
}) => JSX.Element;

export const EmailForm: ChannelForm = ({ type, destinations }) => {
  const { watch } = useFormContext();
  const destinationId = watch(`channels.${type}.destination_id`);
  const destination = destinations?.find((d) => d.id === destinationId);
  return (
    <>
      <Controller
        name={`channels.${type}.destination_id`}
        render={({ field }) => (
          <FormField label="Destination">
            <Combobox
              {...field}
              width="2xs"
              placeholder="Select destination..."
              optionAccessory={(option) => ({
                type: "image",
                url: option.definition.icon,
              })}
              optionValue={(option) => option.id}
              optionLabel={(option) => option.name ?? option.definition.name}
              options={destinations.filter((d) => d.type !== "s3")}
            />
          </FormField>
        )}
      />
      {destination?.type === "sfmc" && (
        <Controller
          name={`channels.${type}.config.type`}
          render={({ field }) => {
            const trigger: DecisionEngineChannelConfigType = "trigger";
            const dataExtensions: DecisionEngineChannelConfigType =
              "dataExtensions";
            return (
              <FormField label="Sync type">
                <RadioGroup {...field}>
                  <Radio
                    label="Triggered send"
                    value={trigger}
                    description="Hightouch will send messages directly using triggered sends"
                  />
                  <Radio
                    label="Data extension"
                    value={dataExtensions}
                    description="Hightouch will sync the messages to be sent to a data extension"
                  />
                </RadioGroup>
              </FormField>
            );
          }}
        />
      )}
      <IdentifierField type={type} destination={destination} />
    </>
  );
};

export const RawForm: ChannelForm = ({ type, destinations }) => {
  return (
    <>
      <Controller
        name={`channels.${type}.destination_id`}
        render={({ field }) => (
          <FormField label="Destination">
            <Combobox
              {...field}
              width="2xs"
              placeholder="Select destination..."
              optionAccessory={(option) => ({
                type: "image",
                url: option.definition.icon,
              })}
              optionValue={(option) => option.id}
              optionLabel={(option) => option.name ?? option.definition.name}
              options={destinations.filter((d) => d.type === "s3")}
            />
          </FormField>
        )}
      />
      <Controller
        name={`channels.${type}.config.objectKey`}
        render={({ field }) => (
          <FormField label="What object key would you like to send data to?">
            <TextInput width="100%" {...field} placeholder="Object key..." />
          </FormField>
        )}
      />
    </>
  );
};

const ChannelForms: Record<DecisionEngineChannelType, ChannelForm> = {
  email: EmailForm,
  sms: EmailForm,
  push: EmailForm,
  raw: RawForm,
};

export const Channel: FC<
  Readonly<{ label: string; type: DecisionEngineChannelType }>
> = ({ label, type }) => {
  const { watch } = useFormContext();
  const enabled = watch(`channels.${type}.enabled`);

  const { data: destinations } = useDestinationsQuery(
    {
      filters: { type: { _in: SUPPORTED_DESTINATIONS as unknown as string[] } },
    },
    { select: (data) => data.destinations, suspense: true },
  );

  const Form = ChannelForms[type];

  return (
    <Column
      gap={4}
      borderBottom="1px"
      borderColor="base.border"
      pb={6}
      _last={{ border: "none" }}
    >
      <Row gap={2} align="center" justify="space-between">
        <Row gap={2} align="center">
          <ChannelIcon type={type} />
          <Text fontWeight="medium">{label}</Text>
        </Row>
        <Controller
          name={`channels.${type}.enabled`}
          render={({ field }) => (
            <Switch onChange={field.onChange} isChecked={field.value} />
          )}
        />
      </Row>

      {enabled && type && (
        <Form destinations={destinations ?? []} type={type} />
      )}
    </Column>
  );
};
