import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";

import { SchemaModelType } from "src/types/schema";
import {
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  OrCondition,
} from "src/types/visual";

export function toSingleCondition(
  conditions: AndCondition[] | OrCondition[] | Condition[] | undefined,
): AndOrCondition<AndCondition | OrCondition>[] | undefined {
  if (!conditions) {
    return undefined;
  }

  // Tech debt: conditions may only have one condition at the top level.
  // See https://carryinternal.slack.com/archives/C024T56QQLW/p1669910261515869
  if (Array.isArray(conditions)) {
    if (conditions.length === 0) {
      return [];
    }

    if (conditions.length > 1) {
      return [{ type: ConditionType.And, conditions }];
    }

    /* Start: new format. One condition at the top level */

    const condition = conditions?.[0];
    const hasTopLevelAndOrCondition =
      condition?.type === ConditionType.And ||
      condition?.type === ConditionType.Or;

    // Verify top level condition is an and/or
    // If not, wrap it with `AND` condition
    if (
      // Has not been formatted yet
      !hasTopLevelAndOrCondition
    ) {
      return [{ type: ConditionType.And, conditions }];
    }

    // There is only one AND or OR condition that has been formatted correctly.
    // Its immediate children (if there are any) are OR conditions
    return conditions as AndCondition<AndCondition>[];

    /* End: new format */
  }

  return [conditions];
}

const schemaModelTypePaths: Record<SchemaModelType, string> = {
  [SchemaModelType.Parent]: "parent-models",
  [SchemaModelType.Related]: "related-models",
  [SchemaModelType.Event]: "events",
  [SchemaModelType.Catalog]: "catalogs",
  [SchemaModelType.Group]: "group",
  [SchemaModelType.Interaction]: "interactions",
  [SchemaModelType.Asset]: "assets",
  [SchemaModelType.AdStats]: "ad-stats",
};

export const getSchemaModelTypeFromPath = (path: string) => {
  const type = Object.entries(schemaModelTypePaths).find(
    ([, p]) => p === path,
  )?.[0];
  return type as SchemaModelType;
};

export const getSchemaModelType = (object: {
  type: string | null;
  event: {
    interaction: any;
  } | null;
  asset: {
    type: string;
  } | null;
  ad_stats: {
    __typename: string;
  } | null;
  primary_key: string | null;
}) => {
  if (object.type === "catalog") {
    return SchemaModelType.Catalog;
  }
  if (!object.event && object.primary_key) {
    return SchemaModelType.Parent;
  }
  if (object.ad_stats) {
    return SchemaModelType.AdStats;
  }
  if (object.event) {
    if (
      object.type === SchemaModelType.Interaction &&
      object.event.interaction
    ) {
      return SchemaModelType.Interaction;
    }

    return SchemaModelType.Event;
  }

  if (object.asset && object.type === SchemaModelType.Asset) {
    return SchemaModelType.Asset;
  }
  return SchemaModelType.Related;
};

export const getTypeName = (type: SchemaModelType) => {
  switch (type) {
    case SchemaModelType.Event:
      return "event";
    case SchemaModelType.Catalog:
      return "catalog";
    default:
      return `${type} model`;
  }
};

export const getSchemaModelTypeNames = (type: SchemaModelType) => {
  const typeName = getTypeName(type);
  const typePath = schemaModelTypePaths[type];
  const typeNameCapitalized = capitalize(typeName);

  return {
    typePath,
    typeName,
    typeNameCapitalized,
  };
};

export const SUPPORTED_JSON_ARRAY_SOURCES = [
  "postgres",
  "databricks",
  "bigquery",
  "snowflake",
  "trino",
];

export const SUPPORTED_BIGINT_SOURCES = ["databricks"];

export const useBigintSupport = (source: string) => {
  const { useNewBigintType } = useFlags();

  return {
    allowBigints: useNewBigintType && SUPPORTED_BIGINT_SOURCES.includes(source),
  };
};
