import { Navigate, Route, Routes } from "src/router";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { CreateDestination } from "./create-destination";
import { Destination } from "./destination";
import Destinations from "./destinations";
import { DestinationGrants } from "./destination/grants";
import { DestinationSyncs } from "./destination/syncs";
import { DestinationDataExtraction } from "./destination/data-extraction";
import { DestinationLinking } from "./destination/linking";
import { DestinationConfiguration } from "./destination/configuration";

export const DestinationsRouter = () => {
  return (
    <Routes>
      <Route index element={<Destinations />} />
      <Route
        path="new"
        element={
          <PermissionedRoute
            permission={{
              v2: { resource: "destination", grant: "can_create" },
            }}
            redirect="/destinations"
          >
            <CreateDestination />
          </PermissionedRoute>
        }
      />
      <Route path=":destination_id" element={<Destination />}>
        <Route index element={<Navigate to="configuration" replace />} />
        <Route path="configuration" element={<DestinationConfiguration />} />
        <Route path="syncs" element={<DestinationSyncs />} />
        <Route path="grants" element={<DestinationGrants />} />
        <Route path="data-extraction" element={<DestinationDataExtraction />} />
        <Route path="linking" element={<DestinationLinking />} />
      </Route>
    </Routes>
  );
};
