import { FC, Fragment, useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Column,
  Dialog,
  EmptyState,
  ExternalLinkIcon,
  Text,
  Row,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import capitalize from "lodash/capitalize";
import isEqual from "lodash/isEqual";
import pluralize from "pluralize";
import { useNavigate, useParams } from "src/router";

import { DestinationForm } from "src/components/destinations/sync-form";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { DependenciesModal } from "src/components/modals/dependencies-modal";
import {
  PermissionedButton,
  PermissionedEditableHeading,
} from "src/components/permission";
import { ScheduleManager } from "src/components/schedule";
import { Schedule, scheduleToLabel } from "src/components/schedule/types";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useUser } from "src/contexts/user-context";
import {
  UpdateSyncTemplateMutationVariables,
  useDeleteSyncTemplateMutation,
  useSyncsByTemplateIdQuery,
  useSyncTemplateQuery,
  useUpdateSyncTemplateMutation,
} from "src/graphql";
import { SyncAlerts } from "src/pages/syncs/sync/alerts";
import { MinimalSyncName } from "src/pages/syncs/sync/components/sync-name";

enum TabOptions {
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
  ALERTS = "Alerts",
}

type Config = UpdateSyncTemplateMutationVariables["input"]["config"];

export const SyncTemplate: FC = () => {
  const { sync_template_id } = useParams<{ sync_template_id?: string }>();
  const id = Number(sync_template_id);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { schemaV2 } = useFlags();
  const { workspace } = useUser();

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<Schedule | null>(null);
  const [updatedConfig, setUpdatedConfig] = useState<Config | undefined>();
  const [pendingUpdate, setPendingUpdate] = useState<TabOptions | undefined>();

  const { data: syncTemplateData, isLoading: syncTemplateLoading } =
    useSyncTemplateQuery(
      {
        id,
      },
      { enabled: Boolean(id) },
    );

  const syncTemplate = syncTemplateData?.sync_templates_by_pk;
  const syncAlerts = syncTemplate?.alert_instances;
  const destination = syncTemplate?.destination;
  const source = syncTemplate?.segment?.connection;

  const { data: dependentSyncsData, isLoading: dependentSyncsLoading } =
    useSyncsByTemplateIdQuery({ templateId: id }, { enabled: Boolean(id) });

  const dependentSyncs = dependentSyncsData?.syncs;

  const { mutateAsync: updateSyncTemplate, isLoading: updating } =
    useUpdateSyncTemplateMutation();
  const { mutateAsync: deleteSyncTemplate, isLoading: deletingSyncTemplate } =
    useDeleteSyncTemplateMutation();

  const loading =
    !source ||
    syncTemplateLoading ||
    !syncTemplate ||
    !destination ||
    dependentSyncsLoading ||
    !dependentSyncs;

  const onUpdate = () => {
    toast({
      id: "update-sync-template",
      title: `${syncTemplate?.name} was updated`,
      variant: "success",
    });
  };

  const updateConfig = async () => {
    await updateSyncTemplate({
      id,
      input: {
        config: {
          ...updatedConfig,
          configVersion: syncTemplate?.config?.configVersion,
        },
      },
    });

    setUpdatedConfig(undefined);

    onUpdate();
  };

  const updateSchedule = async () => {
    await updateSyncTemplate({
      id,
      input: {
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });

    onUpdate();
  };

  const updateName = async (name: string) => {
    await updateSyncTemplate({
      id,
      input: {
        name,
      },
    });

    onUpdate();
  };

  const onDelete = async () => {
    try {
      await deleteSyncTemplate({
        id,
      });

      toast({
        id: "delete-sync-template",
        title:
          dependentSyncs && dependentSyncs.length > 0
            ? `Sync template and ${pluralize(
                "sync",
                dependentSyncs.length,
                true,
              )} deleted`
            : "Sync template deleted",
        variant: "success",
      });

      navigate(
        schemaV2
          ? `/schema-v2/settings/sync-templates`
          : "/schema/sync-templates",
      );
    } catch (error) {
      toast({
        id: "delete-sync-template",
        title: "Couldn't delete this sync template",
        variant: "error",
      });

      Sentry.captureException(error);
      throw error;
    }
  };

  useEffect(() => {
    if (syncTemplate?.schedule || syncTemplate?.schedule === null) {
      setSchedule(
        syncTemplate?.schedule ? syncTemplate?.schedule : { type: "manual" },
      );
    }
  }, [syncTemplate]);

  if (loading) {
    return <PageSpinner />;
  }

  const scheduleLabel = scheduleToLabel(syncTemplate?.schedule);

  return (
    <>
      <Page
        crumbs={[
          {
            label: "All sync templates",
            link: schemaV2
              ? `/schema-v2/settings/sync-templates`
              : "/schema/sync-templates",
          },
        ]}
        title={`${syncTemplate.name} - Sync templates - Audiences`}
      >
        <Row
          justifyContent="space-between"
          alignItems="center"
          mb={5}
          gap={8}
          width="100%"
        >
          <PermissionedEditableHeading
            permission={{
              v1: { resource: "sync_template", grant: "update" },
              v2: {
                resource: "model",
                grant: "can_update",
                id: syncTemplate.segment.id,
              },
            }}
            size="lg"
            value={syncTemplate?.name ?? ""}
            onChange={updateName}
          />

          <PermissionedButton
            variant="warning"
            permission={{
              v1: { resource: "sync_template", grant: "delete" },
              v2: {
                resource: "model",
                grant: "can_delete",
                id: syncTemplate.segment.id,
              },
            }}
            onClick={() => {
              setConfirmingDelete(true);
            }}
          >
            Delete
          </PermissionedButton>
        </Row>

        <MetadataBar>
          <Column>
            <MetadataLabel>Parent model</MetadataLabel>
            <Row align="center" gap={2}>
              <IntegrationIcon
                src={source?.definition?.icon}
                name={source?.definition?.name}
              />
              <Link
                href={`/schema-v2/view?source=${source?.id}&id=${syncTemplate.segment.id}`}
              >
                <Text fontWeight="medium" color="inherit">
                  {syncTemplate.segment.name}
                </Text>
              </Link>
            </Row>
          </Column>
          <Column>
            <MetadataLabel>Destination</MetadataLabel>
            <Row align="center" gap={2}>
              <IntegrationIcon
                src={destination.definition?.icon}
                name={destination.definition?.name ?? ""}
              />
              <Link href={`/destinations/${destination.id}`}>
                <Text fontWeight="medium" color="inherit">
                  {syncTemplate.destination.definition?.name}
                </Text>
              </Link>
            </Row>
          </Column>
          {scheduleLabel && (
            <Column>
              <MetadataLabel>Schedule</MetadataLabel>
              <Text>{scheduleLabel}</Text>
            </Column>
          )}
          {syncTemplate.config.mode && (
            <Column>
              <MetadataLabel>Mode</MetadataLabel>
              <Text>{capitalize(syncTemplate.config.mode)}</Text>
            </Column>
          )}
          <Column>
            <MetadataLabel>Syncs</MetadataLabel>
            <Text>{dependentSyncs.length}</Text>
          </Column>
        </MetadataBar>

        <Tabs>
          <TabList>
            <Tab>Configuration</Tab>
            <Tab>Schedule</Tab>
            <Tab>Alerts</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DestinationForm
                testPermission={{
                  v1: { resource: "sync_template", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: syncTemplate.segment.id,
                  },
                }}
                permission={{
                  v1: { resource: "sync_template", grant: "update" },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: syncTemplate.segment.id,
                  },
                }}
                destination={destination}
                destinationDefinition={destination?.definition}
                model={syncTemplate.segment}
                slug={destination?.definition?.type}
                sourceDefinition={source.definition}
                syncConfig={syncTemplate.config}
                onSubmit={async (config) => {
                  setUpdatedConfig(config);
                  setPendingUpdate(TabOptions.CONFIGURATION);
                }}
              />
            </TabPanel>
            <TabPanel>
              <Row sx={{ width: "100%", justifyContent: "space-between" }}>
                <Column>
                  <ScheduleManager
                    schedule={schedule}
                    setSchedule={setSchedule}
                  />
                  <Alert
                    mt={8}
                    variant="inline"
                    type="info"
                    title="Sync triggers"
                    message={
                      <>
                        You can also trigger this sync via Airflow, Dagster, or
                        Prefect. For more information view our{" "}
                        <Link
                          href={`${
                            import.meta.env.VITE_DOCS_URL
                          }/extensions/airflow`}
                        >
                          Airflow Operator
                        </Link>
                        {", "}
                        <Link
                          href={`${
                            import.meta.env.VITE_DOCS_URL
                          }/extensions/dagster`}
                        >
                          Dagster
                        </Link>
                        {", or "}
                        <Link
                          href={`${
                            import.meta.env.VITE_DOCS_URL
                          }/extensions/prefect`}
                        >
                          Prefect docs
                        </Link>
                        . If you need an API key, you can create one in{" "}
                        <Link href="/settings/api-keys">Settings.</Link>
                      </>
                    }
                  />
                </Column>
                <PermissionedButton
                  permission={{
                    v1: { resource: "sync_template", grant: "update" },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: syncTemplate.segment.id,
                    },
                  }}
                  isDisabled={
                    syncTemplate.schedule
                      ? isEqual(schedule, syncTemplate.schedule)
                      : schedule?.type === "manual"
                  }
                  isLoading={updating}
                  onClick={() => setPendingUpdate(TabOptions.SCHEDULE)}
                >
                  Save
                </PermissionedButton>
              </Row>
            </TabPanel>
            <TabPanel>
              {workspace?.alerting_v2_enabled ? (
                <EmptyState
                  message={
                    <>
                      This sync template inherits the default alert recipients
                      and triggers from its destination. To edit those defaults,{" "}
                      <Link href="/alerting/recipients">click here.</Link>
                    </>
                  }
                />
              ) : (
                <SyncAlerts
                  permission={{
                    v1: { resource: "sync_template", grant: "update" },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: syncTemplate.segment.id,
                    },
                  }}
                  alerts={syncAlerts || []}
                  rowThresholdAttempted={syncTemplate.row_threshold_attempted}
                  rowThresholdTotal={syncTemplate.row_threshold_total}
                  templateId={id}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Page>

      <DependenciesModal
        isOpen={confirmingDelete && dependentSyncs.length > 0}
        isDeleting={deletingSyncTemplate}
        resourceType="Sync template"
        dependencies={[
          {
            name: "Syncs",
            resources: dependentSyncs.map((sync) => ({
              id: sync.id,
              name: <MinimalSyncName sync={sync} />,
              url: `/syncs/${sync.id}`,
              created_at: null,
              created_by_user: null,
              updated_at: null,
              updated_by_user: null,
            })),
          },
        ]}
        onClose={() => setConfirmingDelete(false)}
        onDelete={onDelete}
      />

      <DeleteConfirmationModal
        isOpen={confirmingDelete && dependentSyncs.length === 0}
        label="sync template"
        onClose={() => {
          setConfirmingDelete(false);
        }}
        onDelete={onDelete}
      />

      <Dialog
        isOpen={Boolean(pendingUpdate)}
        variant="form"
        width="xl"
        title="Confirm changes"
        actions={
          <>
            <Button
              onClick={() => {
                setPendingUpdate(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (pendingUpdate === TabOptions.SCHEDULE) {
                  updateSchedule();
                } else {
                  updateConfig();
                }
                setPendingUpdate(undefined);
              }}
            >
              Continue
            </Button>
          </>
        }
        onClose={() => setPendingUpdate(undefined)}
      >
        {dependentSyncs.length > 0 ? (
          <>
            <Row mb={6}>
              <Text fontWeight="medium">
                The following audience syncs will be affected:
              </Text>
            </Row>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={0}>
              <Row p={2} borderBottom="small">
                <Text
                  fontWeight="semibold"
                  size="sm"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Audience
                </Text>
              </Row>
              <Row p={2} borderBottom="small">
                <Text
                  fontWeight="semibold"
                  size="sm"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Destination
                </Text>
              </Row>
              {dependentSyncs.map(({ id, segment, destination }) => {
                return (
                  <Fragment key={id}>
                    <Box p={2} borderBottom="small">
                      <TextWithTooltip>{segment?.name}</TextWithTooltip>
                    </Box>
                    <Row alignItems="center" borderBottom="small" p={2} gap={2}>
                      <IntegrationIcon
                        name={destination?.definition?.name}
                        src={destination?.definition?.icon}
                      />
                      <TextWithTooltip>
                        {destination?.name ??
                          destination?.definition?.name ??
                          "Private destination"}
                      </TextWithTooltip>
                      <Link href={`/syncs/${id}`}>
                        <Box
                          as={ExternalLinkIcon}
                          color="text.tertiary"
                          fontSize="16px"
                        />
                      </Link>
                    </Row>
                  </Fragment>
                );
              })}
            </Box>
          </>
        ) : (
          <Text>Are you sure you want to make these changes?</Text>
        )}
      </Dialog>
    </>
  );
};
