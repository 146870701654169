import { FC, ReactNode, useEffect } from "react";

import { Column, Row, Select, Text, TextInput } from "@hightouchio/ui";
import moment from "moment";

import {
  AbsoluteRelativeTimestampOperators,
  DateIntervalOptions,
  FilterableColumn,
  IntervalOperators,
  IntervalOptions,
  IntervalUnit,
  IntervalValue,
  Operator,
  OperatorsWithoutValue,
  PropertyCondition,
  RelativeDirection,
  TimeType,
  TimestampOperator,
  ValueType,
  Window,
  isColumnTypeValue,
  isPropertyCondition,
} from "src/types/visual";
import { ParentModel } from "src/pages/audiences/types";
import { DateTimeSelect } from "src/ui/datetime-select";
import { isNumericString } from "src/utils/string";

import { isPresent } from "ts-extras";
import { ErrorMessage } from "./error-message";
import { PropertySelector } from "./property-selector";

type Props = {
  error?: ReactNode;
  condition: PropertyCondition | Window;
  onChange: (updates: Partial<PropertyCondition | Window>) => void;
  hideTime: boolean;
  showRelativeDirections?: boolean;
  columns?: FilterableColumn[] | undefined;
  parent?: ParentModel | undefined;
};

const isTimestampBetweenOperator = (
  operator: Operator,
): operator is
  | TimestampOperator.Between
  | TimestampOperator.BetweenInclusive => {
  return (
    operator === TimestampOperator.Between ||
    operator === TimestampOperator.BetweenInclusive
  );
};

export const TimestampInput: FC<Readonly<Props>> = ({
  condition,
  hideTime,
  error,
  showRelativeDirections = true,
  onChange,
  columns,
  parent,
}) => {
  // Set the default state to be a relative timestamp
  useEffect(() => {
    if (!condition?.timeType) {
      if (isColumnTypeValue(condition?.value)) {
        return;
      }

      const defaults: Partial<PropertyCondition | Window> = {
        timeType: TimeType.Relative,
      };

      // Between operator has a value of type `TimeRangeValue` consisting of `before` and `after` objects
      // so we set the relative direction where we initialize the default value (in TimeIntervalInput)
      if (isTimestampBetweenOperator(condition?.operator)) {
        defaults.value = {
          ...condition.value,
          direction: condition?.value?.direction ?? RelativeDirection.Backward,
        };
      }

      onChange(defaults);
    }
  }, [condition?.timeType, condition?.operator, condition?.value]);

  if (AbsoluteRelativeTimestampOperators.includes(condition.operator)) {
    return (
      <AbsoluteRelativeTimeInput
        condition={condition}
        error={error}
        hideTime={hideTime}
        onChange={onChange}
        columns={columns}
        parent={parent}
      />
    );
  }

  return (
    <Row gap={2}>
      {IntervalOperators.includes(condition.operator) ? (
        <TimeIntervalInput
          error={error}
          hideTime={hideTime}
          onChange={(value) => {
            onChange({ value });
          }}
          showRelativeDirections={showRelativeDirections}
          value={condition.value}
        />
      ) : (
        !OperatorsWithoutValue.includes(condition.operator) && (
          <TimestampComparisonInput
            hideTime={hideTime}
            onChange={(value) => {
              onChange({ value });
            }}
            value={condition.value}
          />
        )
      )}
    </Row>
  );
};

const AbsoluteRelativeTimeInput = ({
  condition,
  error,
  onChange,
  hideTime,
  columns,
  parent,
}: Props & {
  columns: FilterableColumn[] | undefined;
  parent: ParentModel | undefined;
}) => {
  const handleTimeComparisonChange = (
    timeComparisonType:
      | TimeType
      | RelativeDirection
      | ValueType.Column
      | undefined,
  ) => {
    if (timeComparisonType === undefined) {
      return;
    }

    if (
      timeComparisonType === TimeType.Relative ||
      timeComparisonType === TimeType.Absolute
    ) {
      onChange({ value: null, timeType: timeComparisonType });
    } else if (timeComparisonType === ValueType.Column) {
      onChange({
        value: { type: ValueType.Column },
        timeType: undefined,
      });
    } else {
      onChange({
        value: {
          ...condition.value,
          type: undefined,
          direction: timeComparisonType,
        },
        timeType: TimeType.Relative,
      });
    }
  };

  const timeTypeOptions = isTimestampBetweenOperator(condition.operator)
    ? [
        { label: "relative", value: TimeType.Relative },
        { label: "absolute", value: TimeType.Absolute },
      ]
    : [
        { label: "previous", value: RelativeDirection.Backward },
        { label: "next", value: RelativeDirection.Forward },
        { label: "absolute", value: TimeType.Absolute },
        isPropertyCondition(condition) && {
          label: "property",
          value: ValueType.Column,
        },
      ].filter(isPresent);

  return (
    <>
      <Select
        removePortal
        placeholder="Select an option"
        options={timeTypeOptions}
        value={
          condition?.value?.direction ??
          condition.timeType ??
          condition?.value?.type
        }
        width="auto"
        onChange={handleTimeComparisonChange}
      />
      {isTimestampBetweenOperator(condition.operator) ? (
        <TimeRangeInput
          afterValue={condition.value?.after}
          beforeValue={condition.value?.before}
          error={error}
          hideTime={hideTime}
          setAfterValue={(value) => {
            onChange({ value: { ...condition.value, after: value } });
          }}
          setBeforeValue={(value) => {
            onChange({ value: { ...condition.value, before: value } });
          }}
          timeType={condition.timeType ?? TimeType.Relative}
        />
      ) : condition.timeType === TimeType.Absolute ? (
        <TimestampComparisonInput
          hideTime={hideTime}
          onChange={(value) => {
            onChange({ value });
          }}
          value={condition.value}
        />
      ) : isPropertyCondition(condition) &&
        condition.value?.type === ValueType.Column ? (
        <PropertySelector
          condition={condition}
          error={error}
          onChange={(value) => {
            onChange({ value });
          }}
          value={condition.value}
          columns={columns}
          parent={parent}
        />
      ) : (
        <TimeIntervalInput
          error={error}
          hideTime={hideTime}
          onChange={(value) => {
            onChange({ value });
          }}
          showRelativeDirections={false}
          value={condition.value}
        />
      )}
    </>
  );
};

const TimeRangeInput: FC<
  Readonly<
    | {
        timeType: TimeType.Absolute;
        beforeValue: string;
        afterValue: string;
        setAfterValue: (value: string) => void;
        setBeforeValue: (value: string) => void;
        hideTime: boolean;
      }
    | {
        timeType: TimeType.Relative;
        beforeValue: IntervalValue;
        afterValue: IntervalValue;
        setAfterValue: (value: IntervalValue) => void;
        setBeforeValue: (value: IntervalValue) => void;
        hideTime: boolean;
      }
  > & { error?: ReactNode }
> = (props) => {
  if (props.timeType === TimeType.Absolute) {
    const { beforeValue, setBeforeValue, setAfterValue, afterValue, hideTime } =
      props;

    return (
      <>
        <TimestampComparisonInput
          hideTime={hideTime}
          onChange={setBeforeValue}
          value={beforeValue}
        />
        <Row as={Text} flexShrink={0} mt={1.5}>
          and
        </Row>
        <TimestampComparisonInput
          hideTime={hideTime}
          isRangeEnd={true}
          onChange={setAfterValue}
          value={afterValue}
        />
      </>
    );
  } else {
    const { beforeValue, setBeforeValue, setAfterValue, afterValue, hideTime } =
      props;

    return (
      <>
        <TimeIntervalInput
          error={props.error}
          hideTime={hideTime}
          onChange={setBeforeValue}
          showRelativeDirections={false}
          value={beforeValue}
        />
        <Row as={Text} flexShrink={0} mt={1.5}>
          and
        </Row>
        <TimeIntervalInput
          error={props.error}
          hideTime={hideTime}
          isRangeEnd={true}
          onChange={setAfterValue}
          showRelativeDirections={false}
          value={afterValue}
        />
      </>
    );
  }
};

const TimeIntervalInput = ({
  error,
  value,
  onChange,
  isRangeEnd = false,
  hideTime = false,
  showRelativeDirections = true,
}: {
  error?: ReactNode;
  value: IntervalValue;
  onChange: (value: IntervalValue) => void;
  isRangeEnd?: boolean;
  hideTime: boolean;
  showRelativeDirections?: boolean;
}) => {
  // Handles default values.
  if ((!value?.quantity && !value?.interval) || !value?.direction) {
    onChange({
      quantity: value?.quantity ?? (isRangeEnd ? 2 : 1),
      interval: value?.interval ?? IntervalUnit.Month,
      direction: value?.direction ?? RelativeDirection.Backward,
    });

    return null;
  }

  return (
    <>
      {showRelativeDirections && (
        <Select
          removePortal
          placeholder="Select an option..."
          options={[
            { label: "previous", value: RelativeDirection.Backward },
            { label: "next", value: RelativeDirection.Forward },
          ]}
          width="auto"
          value={value.direction}
          onChange={(direction: RelativeDirection | undefined) => {
            if (direction === undefined) {
              return;
            }

            onChange({ ...value, direction });
          }}
        />
      )}
      <Column width="70px" flex="0 0 auto">
        <TextInput
          isInvalid={Boolean(error)}
          placeholder="Time..."
          value={value?.quantity != null ? String(value.quantity) : ""}
          onChange={(event) => {
            onChange({
              ...value,
              quantity: isNumericString(event.target.value)
                ? Number(event.target.value)
                : undefined,
            });
          }}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Column>
      <Select
        removePortal
        options={hideTime ? DateIntervalOptions : IntervalOptions}
        placeholder="Unit..."
        width="auto"
        value={value?.interval}
        onChange={(interval) => {
          if (interval === undefined) {
            return;
          }

          onChange({ ...value, interval });
        }}
      />
    </>
  );
};

const formatValue = (value: Date) => {
  // Make sure seconds are always at 00
  return moment(value).utc(true).format("YYYY-MM-DD HH:mm:00");
};

const TimestampComparisonInput = ({
  value,
  onChange,
  isRangeEnd = false,
  hideTime = false,
}: {
  value: string;
  onChange: (value: string) => void;
  isRangeEnd?: boolean;
  hideTime: boolean;
}) => {
  // Handles default values.
  if (!value) {
    const now = new Date();

    if (isRangeEnd) {
      const oneMonthAgo = new Date(now);
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      // Handles end of the month edge cases when the amount of days
      // in consecutive months are not aligned.
      while (now.getMonth() === oneMonthAgo.getMonth()) {
        oneMonthAgo.setDate(oneMonthAgo.getDate() - 1);
      }

      onChange(formatValue(oneMonthAgo));
    } else {
      onChange(formatValue(now));
    }

    return null;
  }

  return (
    <DateTimeSelect
      hideTime={hideTime}
      value={new Date(value)}
      onChange={(value) => {
        onChange(formatValue(value));
      }}
    />
  );
};
