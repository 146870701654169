import { useSearchParams } from "src/router";

/**
 * Get/Set just one search param in the URL without affecting the others.
 */
export function useSearchParam<
  ParamValue extends string | undefined = string | undefined,
>(param: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  return [
    searchParams.get(param),
    (value: ParamValue) =>
      setSearchParams((oldParams) => {
        if (value === undefined || value === "") {
          oldParams.delete(param);
          return oldParams;
        }
        oldParams.set(param, value);
        return oldParams;
      }),
  ] as const;
}

export function useSearchParamsTab<TabSlug extends string>(
  orderedTabSlugs: TabSlug[],
  tabParam: string = "tab",
) {
  const [searchParamsTab, setSearchParamsTab] =
    useSearchParam<TabSlug>(tabParam);
  const foundTab = orderedTabSlugs.includes(searchParamsTab as TabSlug)
    ? orderedTabSlugs.indexOf(searchParamsTab as TabSlug)
    : 0;
  const setSearchParamsTabByIndex = (tabIndex: number) => {
    const tab = orderedTabSlugs[tabIndex];
    if (tab) setSearchParamsTab(tab);
  };

  return [foundTab, setSearchParamsTabByIndex] as const;
}
