import { Row } from "@hightouchio/ui";

import { FullResourceLinkForm } from "src/components/deployments/linking";
import { SidebarForm } from "src/components/page";
import { useOutletContext } from "src/router";
import { OutletContext } from ".";

export const DestinationLinking = () => {
  const { destination } = useOutletContext<OutletContext>();

  return (
    <Row justifyContent="space-between">
      <FullResourceLinkForm
        sourceResourceId={destination.id}
        resourceType="destinations"
      />
      <SidebarForm name="destination linking" docsUrl="" />
    </Row>
  );
};
