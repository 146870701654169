import { FC, useState } from "react";

import {
  Box,
  Column,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
  Textarea,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

import { isMergedColumn } from "src/types/visual";
import { flattenOptions } from "src/ui/select";
import { convertType, NEW_ICON_MAP } from "src/utils/destinations";
import { useFormkitContext } from "src/formkit/components/formkit-context";
import { FormProps, JsonColumnProps } from "src/formkit/components/types";

const contexts = [
  // XXX these are defined provided as context in
  // `/packages/backend/sdk/lib/liquid-template/liquid.ts`
  { label: "Model ID", value: "context['model_id']" },
  { label: "Model name", value: "context['model_name']" },
  { label: "Sync ID", value: "context['sync_id']" },
  { label: "Sync run ID", value: "context['sync_run_id']" },
  { label: "Current timestamp", value: "context['timestamp']" },
];

const rowSx = {
  alignItems: "center",
  borderBottom: "small",
  fontSize: 0,
  fontWeight: "semi",
  minHeight: "36px",
  px: 1,
  ":hover": { bg: "gray.100" },
};

export const TemplateInput: FC<
  Readonly<FormProps & { jsonColumnProperties: JsonColumnProps }>
> = ({
  columnOptions,
  onChange,
  overwriteColumnsWithArrayProps,
  templates,
  value,
}) => {
  const { loadingRows, columns: formkitColumns, model } = useFormkitContext();
  const columns = overwriteColumnsWithArrayProps
    ? columnOptions ?? []
    : formkitColumns;
  const [toggle, setToggle] = useState("data");

  const flatColumns = flattenOptions(columns);
  // TODO: Properly support merge columns
  // Boosted columns are intentionally unsupported
  const standardColumns = flatColumns.filter(
    (c) => typeof c.value === "string",
  );
  const normalizedStandardColumns = uniqBy(
    standardColumns.map((option) => ({
      customType: option.customType,
      label: option.label,
      type: option.type,
      value: option.value,
    })),
    "value",
  );

  const mergedColumns = flatColumns
    .filter((c) => isMergedColumn(c.value))
    .map((option) => {
      const pathIds = option.value?.path;
      const pathNames = pathIds?.map(
        (relationshipId: number | string) =>
          model?.parent?.relationships.find(
            (r) => String(r.id) === String(relationshipId),
          )?.name,
      );

      return {
        label: option.label,
        inlineDescription: option.modelName,
        value: `merged['${[...pathNames, option.value?.column?.name].join(
          ".",
        )}']`,
      };
    });

  const traitEnrichmentColumns = flatColumns.filter(
    (c) =>
      typeof c.value === "object" &&
      c.value.type === "additionalColumnReference",
  );
  const normalizedTraitColumns = uniqBy(
    traitEnrichmentColumns.map((option) => ({
      label: option.label,
      value: `traits['${option.value.columnAlias}']`,
    })),
    "value",
  );

  const sortedColumns = orderBy(normalizedStandardColumns, "label");
  const columnsWithContext = [
    ...sortedColumns,
    ...normalizedTraitColumns,
    ...mergedColumns,
  ];
  const unique = uniq(columnsWithContext);
  const sortedTemplates = orderBy(templates, "name");

  const insertVar = (val: string) => {
    let template = value.template;
    const isContext = contexts.find(({ value }) => val === value);
    const isTrait = Boolean(
      normalizedTraitColumns.find(({ value }) => val === value),
    );
    const isMergedColumn = Boolean(
      mergedColumns.find(({ value }) => val === value),
    );
    if (!template || template.length === 0) {
      template =
        isContext || isTrait || isMergedColumn
          ? `{{ ${val} }}`
          : `{{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    // if template already has closing braces, insert variable on its own
    template = template.trimRight();
    if (template.endsWith("}}")) {
      template =
        isContext || isTrait || isMergedColumn
          ? `${template} {{ ${val} }}`
          : `${template} {{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template ends with row, append field
    if (
      template.endsWith("row") ||
      template.endsWith("traits") ||
      template.endsWith("merged")
    ) {
      template =
        isContext || isTrait || isMergedColumn
          ? `${template.substring(0, template.length - 3)}${val} }}`
          : `${template}['${val}'] }}`;
    } else if (template.endsWith("row.") || template.endsWith("traits.")) {
      template =
        isContext || isTrait
          ? `${template.substring(0, template.length - 4)}${val} }}`
          : template.substring(0, template.length - 1);
      template = `${template}['${val}'] }}`;
    } else if (template.endsWith("{{")) {
      template =
        isContext || isTrait || isMergedColumn
          ? `${template} ${val} }}`
          : `${template} row['${val}'] }}`;
    } else {
      //add closing braces and append field
      template =
        isContext || isTrait || isMergedColumn
          ? `${template} }} {{ ${val} }}`
          : `${template} }} {{ row['${val}'] }}`;
    }
    onChange({ ...value, template: template });
  };

  const insertFunction = (val: string, placeholders?: string[]) => {
    let template = value.template;
    //function with placeholders i.e. includes: 'substring'
    const insertVal = placeholders
      ? `${val} : ${placeholders.join(", ")}`
      : `${val}`;

    //if template editor is empty fill with placeholder variable and append function
    if (!template || template.length === 0) {
      template = `{{ row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }
    template = template.trimRight();

    //if template ends with opening brackets, add placeholder var and append function
    if (template.endsWith("{{")) {
      template = `${template} row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template is closed, pipe function at end
    if (template.endsWith("}}"))
      template = template.substring(0, template.length - 2);
    template = template.trimRight();
    if (!template.endsWith("|")) {
      template = `${template} |`;
    }
    template = `${template} ${insertVal} }}`;
    onChange({ ...value, template: template });
  };

  return (
    <Row height="207px" mx={3} my={1}>
      <Box fontFamily="monospace" maxWidth="444px" mr={2} width="56.5%">
        <Textarea
          onChange={(event) =>
            onChange({ ...value, template: event.target.value })
          }
          placeholder="Click on any variable/filter on the right to inject into Liquid template..."
          resize="none"
          rows={10}
          value={value.template}
          width="100%"
        />
      </Box>
      <Column flex={1}>
        <Row alignItems="center" height="32px" p={2}>
          <ToggleButtonGroup onChange={setToggle} value={toggle}>
            <ToggleButton label="Columns" value="data" />
            <ToggleButton label="Metadata" value="metadata" />
            <ToggleButton label="Filters" value="functions" />
          </ToggleButtonGroup>
        </Row>
        <Column mt={2} overflowY="auto" overscrollBehaviorY="contain">
          {toggle === "data" && (
            <>
              {overwriteColumnsWithArrayProps && loadingRows ? (
                <ShimmerLoadingState />
              ) : (
                <DataList data={unique} hasIcon onClick={insertVar} />
              )}
            </>
          )}
          {toggle === "metadata" && (
            <DataList data={contexts} onClick={insertVar} />
          )}
          {toggle === "functions" && (
            <DataList data={sortedTemplates} onClick={insertFunction} />
          )}
        </Column>
      </Column>
    </Row>
  );
};

const DataList = ({ data, hasIcon = false, onClick }) => (
  <>
    {data.map(
      ({
        customType,
        description,
        inlineDescription,
        label,
        name,
        placeholders,
        type,
        value,
      }) => (
        <Row
          key={label || name}
          _hover={{
            bg: "gray.100",
          }}
          borderBottomWidth="1px"
          borderColor="base.border"
          cursor="pointer"
          display="flex"
          flex="none"
          gap={2}
          p={2}
          onClick={() => {
            onClick(value || name, placeholders);
          }}
        >
          {hasIcon && (
            <Box
              alignSelf="start"
              as={NEW_ICON_MAP[convertType(customType || type)]}
              boxSize={5}
              color="gray.500"
            />
          )}
          <Column maxWidth="266px">
            <Row gap={2}>
              <Text
                color="text.primary"
                fontWeight="medium"
                isTruncated
                size="md"
              >
                {label || name}
              </Text>
              {inlineDescription && (
                <Text color="text.secondary" isTruncated size="md">
                  {inlineDescription}
                </Text>
              )}
            </Row>
            {description && (
              <Box color="text.secondary" fontSize="sm">
                {description}
              </Box>
            )}
          </Column>
        </Row>
      ),
    )}
  </>
);

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {Array(4).map((index) => (
      <Row key={index} sx={rowSx}>
        <SkeletonBox borderRadius="sm" height="18px" width="100%" />
      </Row>
    ))}
  </Skeleton>
);
