import { ReactNode } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  ExternalLinkIcon,
  Paragraph,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import capitalize from "lodash/capitalize";

import { Table, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";

type Dependency = {
  id: string;
  name: string | ReactNode;
  description?: string;
  url: string;
  icon?: string;
  created_at: string | null;
  updated_at: string | null;
  created_by_user: {
    name: string;
  } | null;
  updated_by_user: {
    name: string;
  } | null;
};

export type DependencyTableData = {
  name: string;
  resources: Dependency[];
};

type Props = {
  isOpen: boolean;
  isDeleting?: boolean;
  dependencies: DependencyTableData[];
  resourceType: string;
  onDelete?: () => void;
  onClose: () => void;
};

export const DependenciesModal = ({
  isOpen,
  isDeleting = false,
  dependencies,
  resourceType,
  onDelete,
  onClose,
}: Props) => {
  return (
    <Dialog
      isOpen={isOpen}
      actions={
        <ButtonGroup>
          <Button onClick={onClose}>{onDelete ? "Cancel" : "Close"}</Button>
          {onDelete && (
            <Button isLoading={isDeleting} onClick={onDelete} variant="danger">
              Delete
            </Button>
          )}
        </ButtonGroup>
      }
      title={`${capitalize(resourceType)} has related dependencies`}
      variant="info"
      onClose={onClose}
    >
      <Column gap={6}>
        <Paragraph>
          We found some resources using this {resourceType}.
          {onDelete
            ? ` You will not be able to undo this. Deleting this ${resourceType} will delete the following resources:`
            : ` In order to delete the ${resourceType}, please first delete the following resources:`}
        </Paragraph>

        <Tabs>
          <TabList>
            {dependencies.map(({ name }) => (
              <Tab key={name}>{name}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {dependencies.map(({ name, resources }) => {
              const includeLastUpdatedColumn = resources.some(
                ({
                  updated_at,
                  updated_by_user,
                  created_at,
                  created_by_user,
                }) =>
                  updated_at ||
                  updated_by_user ||
                  created_at ||
                  created_by_user,
              );

              const columns: TableColumn<Dependency>[] = [
                {
                  name: "Name",
                  headerSx: { pl: "0 !important" },
                  cellSx: { pl: "0 !important" },
                  cell: ({ name, description, icon, url }) => (
                    <Column
                      sx={{
                        a: {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <Link isExternal href={url}>
                        {icon && (
                          <img
                            src={icon}
                            alt={`${name} icon`}
                            height="24px"
                            width="24px"
                            style={{
                              marginRight: "var(--chakra-space-2)",
                            }}
                          />
                        )}
                        {name}
                        <ExternalLinkIcon ml={2} />
                      </Link>
                      {description && (
                        <Text color="text.secondary" size="sm">
                          {description}
                        </Text>
                      )}
                    </Column>
                  ),
                },
              ];

              if (includeLastUpdatedColumn) {
                columns.push(LastUpdatedColumn);
              }

              return (
                <TabPanel key={`${name}-tab-panels`}>
                  <Table data={resources} columns={columns} />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Column>
    </Dialog>
  );
};
