import { FC, ReactNode } from "react";

import { DbtQuery } from "src/components/explore/preview/dbt-query";
import { SampleModelQuery } from "src/components/explore/preview/sample-model-query";
import { TableQuery } from "src/components/explore/preview/table-query";
import { CustomQueryView } from "src/components/sources/forms/custom-query";
import { SqlEditor } from "src/components/sql-editor";
import { QueryType } from "src/types/models";

import { LookerQuery } from "../explore/preview/looker-query";
import { SigmaQuery } from "../explore/preview/sigma-query";
import { ModelState } from "src/utils/models";

type Props = {
  actions?: ReactNode;
  model: Partial<ModelState> | null;
  source: {
    id: string;
    type: string;
    name: string;
    definition: { name: string; icon: string; isSampleDataSource: boolean };
    config: Record<string, any>;
  } | null;
};

export const Query: FC<Readonly<Props>> = ({ model, actions, source }) => {
  const type = model?.query_type;
  const dbtModelId = model?.query_dbt_model_id;
  const lookId = model?.query_looker_look_id;

  if (source?.definition?.isSampleDataSource) {
    return (
      <SampleModelQuery
        table={model?.query_table_name ?? ""}
        source={source}
        actions={actions}
      />
    );
  }

  switch (type) {
    case QueryType.Table:
      return (
        <TableQuery table={model?.query_table_name ?? ""} actions={actions} />
      );
    case QueryType.RawSql:
      return (
        <SqlEditor
          readOnly
          isBeautifyable={false}
          value={model?.query_raw_sql ?? ""}
          source={source}
          actions={actions}
        />
      );
    case QueryType.DbtModel:
      return <DbtQuery actions={actions} id={dbtModelId} />;
    case QueryType.LookerLook:
      return <LookerQuery lookId={lookId ?? ""} actions={actions} />;
    case QueryType.Sigma:
      return model?.query_integrations && source ? (
        <SigmaQuery
          sigma={model?.query_integrations}
          source={source}
          actions={actions}
        />
      ) : null;
    case QueryType.Custom:
      return model && model.custom_query && source ? (
        <CustomQueryView
          query={model.custom_query}
          source={source}
          actions={actions}
        />
      ) : null;
    default:
      return null;
  }
};
