export type GrantableResource = "source" | "destination" | "parent_model";

export const RESOURCE_GRANTS = {
  source: [
    "can_draft",
    "can_sync",
    "can_edit",
    "can_manage_schema",
    "can_access_data",
  ] as const,
  destination: ["can_draft", "can_sync", "can_edit", "can_run"] as const,
  parent_model: ["can_access_data", "can_sync"] as const,
} as const satisfies Record<GrantableResource, readonly string[]>;

export const RESOURCE_GRANT_OPTIONS = (approvalsRequired: boolean) =>
  ({
    source: approvalsRequired
      ? [
          {
            label: "View source data",
            value: "can_access_data",
            description:
              "Access row-level customer data in the source via model previews and debugger.",
          },
          {
            label: "Draft models & syncs",
            value: "can_draft",
            description:
              "Draft models and syncs that are syncing from this source. These drafts must be approved by someone with the 'Approve' permission.",
          },
          {
            label: "Approve models & syncs",
            value: "can_sync",
            description:
              "Approve drafts for models and syncs that are syncing from this source. Users with both 'Draft' and 'Approve' permissions can create and update models and syncs.",
          },
          {
            label: "Configure schema",
            value: "can_manage_schema",
            description:
              "Configure Customer Studio schema resources (such as parent models, traits, sync templates, etc.) for this source.",
          },
          {
            label: "Manage source",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this source.",
          },
        ]
      : [
          {
            label: "View source data",
            value: "can_access_data",
            description:
              "Access row-level customer data in the source via model previews and debugger.",
          },
          {
            label: "Configure models & syncs",
            value: "can_sync",
            description:
              "Manage models and syncs that are syncing from this source. This includes managing the sync configuration, model query, etc.",
          },
          {
            label: "Configure schema",
            value: "can_manage_schema",
            description:
              "Configure Customer Studio schema resources (such as parent models, traits, sync templates, etc.) for this source.",
          },
          {
            label: "Manage source",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this source.",
          },
        ],
    destination: approvalsRequired
      ? [
          {
            label: "Trigger syncs",
            value: "can_run",
            description: "Manually trigger syncs via the UI and the API.",
          },
          {
            label: "Draft models & syncs",
            value: "can_draft",
            description:
              "Draft models and syncs that are syncing to this destination. These drafts must be approved by someone with the 'Approve' permission.",
          },
          {
            label: "Approve models & syncs",
            value: "can_sync",
            description:
              "Approve drafts for models and syncs that are syncing to this destination. Users with both 'Draft' and 'Approve' permissions can create and update models and syncs.",
          },
          {
            label: "Manage destination",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this destination.",
          },
        ]
      : [
          {
            label: "Trigger syncs",
            value: "can_run",
            description: "Manually trigger syncs via the UI and the API.",
          },
          {
            label: "Configure models & syncs",
            value: "can_sync",
            description:
              "Manage models and syncs that are syncing to this destination. This includes managing the sync configuration, model query, etc.",
          },
          {
            label: "Manage destination",
            value: "can_edit",
            description:
              "Manage the configuration, credentials, and permissions for this destination.",
          },
        ],
    parent_model: [
      {
        label: "View parent model data",
        value: "can_access_data",
        description:
          "Access row-level customer data in the parent model via model previews and debugger.",
      },
      {
        label: "Configure audiences & syncs",
        value: "can_sync",
        description:
          "Manage audiences and syncs that are syncing from this parent model.",
      },
    ],
  }) satisfies {
    [key in GrantableResource]: readonly {
      label: string;
      value: ResourceGrantsFor<key>;
      description?: string;
    }[];
  };

export type ResourceGrantsFor<R extends GrantableResource> =
  (typeof RESOURCE_GRANTS)[R][number];

export type GrantObject<R extends GrantableResource> = Record<
  ResourceGrantsFor<R>,
  boolean
>;

export type Grants<R extends GrantableResource> = Record<
  string,
  GrantObject<R>
>;
