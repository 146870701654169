import { FC } from "react";

import { CodeIcon, Select, TableIcon, ModelIcon } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  BrowserImage,
  DbtImage,
  LookerImage,
  SigmaImage,
  SQLImage,
  TableImage,
} from "src/components/models";
import { QueryType } from "src/types/models";
import { DBTIcon, LookerIcon, SigmaIcon } from "src/ui/icons";

export const QUERY_TYPE_OPTIONS = {
  [QueryType.RawSql]: {
    label: "SQL query",
    description: "Filter, transform, and join your data using a SQL query.",
    image: SQLImage,
    icon: CodeIcon,
  },
  [QueryType.DbtModel]: {
    label: "dbt model",
    description: "Use a table or materialized view generated by dbt.",
    image: DbtImage,
    icon: DBTIcon,
  },
  [QueryType.LookerLook]: {
    label: "Looker model",
    description:
      "Convert a Look into a SQL query that runs on your data source.",
    image: LookerImage,
    icon: LookerIcon,
  },
  [QueryType.Sigma]: {
    label: "Sigma model",
    description:
      "Convert a workbook into a SQL query that runs on your data source.",
    image: SigmaImage,
    icon: SigmaIcon,
  },
  [QueryType.Table]: {
    label: "Table selector",
    description:
      "Read all rows from an existing table or view in your database.",
    image: TableImage,
    icon: TableIcon,
  },
  [QueryType.Custom]: {
    label: "Browse",
    description: "Select a file, spreadsheet, collection, or other dataset.",
    image: BrowserImage,
    icon: ModelIcon,
  },
};

type Props = {
  supportedQueries: string[];
  type: QueryType;
  onChange: (type: QueryType) => void;
};

export const QueryTypeMenu: FC<Readonly<Props>> = ({
  supportedQueries,
  type,
  onChange,
}) => {
  const { sigmaExtension } = useFlags();
  const options = supportedQueries
    .filter((type) => {
      if (type === QueryType.Sigma) {
        return sigmaExtension;
      }
      return QUERY_TYPE_OPTIONS[type];
    })
    .map((type) => {
      const config = QUERY_TYPE_OPTIONS[type];
      return {
        value: type,
        label: config.label,
        icon: config.icon,
      };
    });

  return (
    <Select
      width="3xs"
      value={type}
      optionAccessory={(option) => ({
        type: "icon",
        icon: option.icon,
      })}
      onChange={(value) => {
        if (value) {
          onChange(value);
        }
      }}
      options={options}
    />
  );
};
