import { FC, useEffect, useState } from "react";

import {
  Column,
  Row,
  SectionHeading,
  FormField,
  TextInput,
  useToast,
  ButtonGroup,
  Button,
} from "@hightouchio/ui";
import { isEqual, isMatch } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { SidebarForm } from "src/components/page";
import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { useUpdateIdentityResolutionGraphMutation } from "src/graphql";

import { OutletContext } from ".";
import { useScheduleState } from "src/components/schedule/schedule-manager";

export function getOutputTableTip(fieldValue: string | undefined) {
  const value = fieldValue || "<table_name>";
  return `The output will be two tables in the hightouch_planner schema called ${value}_resolved and ${value}_resolved_identities.`;
}

export const Configuration: FC = () => {
  const { toast } = useToast();
  const { graph } = useOutletContext<OutletContext>();
  const { validateSchedule } = useScheduleState("identity graph");
  const [scheduleValid, setScheduleValid] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = useForm({
    defaultValues: {
      output_table: graph.output_table,
      schedule: graph.schedule,
    },
  });

  const updateMutation = useUpdateIdentityResolutionGraphMutation();

  const update = async (data) => {
    try {
      await updateMutation.mutateAsync({
        id: graph.id,
        input: {
          output_table: data.output_table,
          schedule: data.schedule?.type === "manual" ? null : data.schedule,
          schedule_paused: data.schedule === null,
          schedule_updated_at:
            data.schedule !== graph.schedule
              ? new Date().toISOString()
              : undefined,
        },
      });
      toast({
        id: "update-success",
        title: "Configuration updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-error",
        title: "Error updating configuration",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name !== "schedule") {
        return;
      }

      const schedule = value.schedule;

      const isButtonDisabled =
        isEqual(schedule, graph?.schedule) ||
        (graph.schedule === null &&
          isMatch(schedule, { type: ScheduleType.MANUAL })) ||
        !validateSchedule(schedule);

      setScheduleValid(!isButtonDisabled);
    });
    return () => subscription.unsubscribe();
  }, [graph]);

  useEffect(() => {
    reset({
      output_table: graph.output_table,
      schedule: graph.schedule,
    });
  }, [graph]);

  return (
    <>
      <Row gap={10} justify="space-between" pb={24}>
        <Column gap={12}>
          <Column gap={6}>
            <SectionHeading>
              Where should Hightouch send the output of resolved identities?
            </SectionHeading>
            <Controller
              name="output_table"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormField
                    label="Table name"
                    tip={getOutputTableTip(field.value)}
                    error={error?.message}
                  >
                    <TextInput {...field} placeholder="Enter a table name..." />
                  </FormField>
                );
              }}
            />
          </Column>
          <Column
            borderTop="1px"
            borderColor="base.divider"
            borderStyle="solid"
            pt={12}
          >
            <Controller
              name="schedule"
              control={control}
              render={({ field }) => {
                return (
                  <ScheduleManager
                    resource="identity graph"
                    schedule={field.value || { type: "manual" }}
                    setSchedule={field.onChange}
                    types={[
                      ScheduleType.MANUAL,
                      ScheduleType.INTERVAL,
                      ScheduleType.CRON,
                      ScheduleType.CUSTOM,
                    ]}
                    includeStartAndEnd={false}
                  />
                );
              }}
            />
          </Column>
        </Column>
        <SidebarForm hideInviteTeammate name="identity resolution" docsUrl="" />
      </Row>
      <ActionBar>
        <ButtonGroup>
          <Button
            isDisabled={!isDirty || !scheduleValid}
            isLoading={updateMutation.isLoading}
            size="lg"
            variant="primary"
            onClick={handleSubmit(update)}
          >
            Save changes
          </Button>
          <Button isDisabled={!isDirty} size="lg" onClick={() => reset()}>
            Discard changes
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
