import { FC, useEffect, useState } from "react";

import {
  EventTraitColumn,
  RelatedColumn,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  Row,
  Heading,
  Button,
  useToast,
  Menu,
  MenuActionsButton,
  MenuList,
  MenuItem,
  IconButton,
  CloseIcon,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { Drawer } from "src/components/drawer";
import { AdditionalColumn, Audience } from "src/types/visual";

import {
  EventTraitForm,
  EventTraitFormProps,
  eventTraitValidationSchema,
} from "./event-trait-form";
import {
  FilteredEvent,
  useRemoveAdditionalColumn,
  useUpdateAdditionalColumn,
} from "./utils";
import { ParentModel } from "src/pages/audiences/types";

type Props = {
  audience: NonNullable<Audience>;
  parentModel: NonNullable<ParentModel>;
  eventTrait: {
    alias: string;
    traitType: TraitType;
    traitConfig: TraitConfig;
    filteredEvent: FilteredEvent;
  };
  index: number;
  onClose: () => void;
};

export const EditEventTrait: FC<Readonly<Props>> = ({
  audience,
  parentModel,
  eventTrait,
  index,
  onClose,
}) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<EventTraitFormProps>({
    resolver: yupResolver(eventTraitValidationSchema),
  });
  const { reset } = form;

  useEffect(() => {
    reset({
      alias: eventTrait.alias,
      filteredEvent: eventTrait.filteredEvent,
      traitType: eventTrait.traitType,
      traitConfig: eventTrait.traitConfig,
    });
  }, [reset, eventTrait]);

  const removeAdditionalColumn = useRemoveAdditionalColumn();
  const updateAdditionalColumn = useUpdateAdditionalColumn();

  const onDelete = async () => {
    await removeAdditionalColumn({
      audience,
      index: index,
      onSuccess: onClose,
    });
  };

  const onSubmit = async (data: EventTraitFormProps) => {
    setIsSubmitting(true);

    try {
      const eventTraitColumn: EventTraitColumn = {
        type: "event_trait",
        filteredEvent: data.filteredEvent,
        traitType: data.traitType,
        traitConfig: data.traitConfig,
      };

      const relatedColumn: RelatedColumn = {
        type: "related",
        path: [String(data.filteredEvent.relationshipId)],
        column: eventTraitColumn,
      };

      const additionalColumn: AdditionalColumn = {
        alias: data.alias,
        column: relatedColumn,
      };

      await updateAdditionalColumn({
        audience,
        additionalColumn,
        index,
        onSuccess: onClose,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError = () => {
    toast({
      id: "edit-trait-enrichment-validation",
      variant: "error",
      title: "Event trait failed to be saved",
      message: "There were errors in your submission",
    });
  };

  return (
    <Drawer isLoading={false} isOpen onClose={onClose}>
      <Row
        p={6}
        borderBottom="1px solid"
        borderBottomColor="base.border"
        alignItems="end"
        justifyContent="space-between"
      >
        <Heading>Edit event trait</Heading>
        <Row gap={4}>
          <Menu>
            <MenuActionsButton />
            <MenuList>
              <MenuItem variant="danger" onClick={onDelete}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
          <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
        </Row>
      </Row>
      <Column flex={1} p={6} gap={6} overflowY="auto">
        <FormProvider {...form}>
          <EventTraitForm audience={audience} parentModel={parentModel} />
        </FormProvider>
      </Column>

      <Row
        px={6}
        py={4}
        borderTop="solid 1px"
        borderTopColor="base.border"
        justifyContent="space-between"
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          variant="primary"
          onClick={form.handleSubmit(onSubmit, onError)}
        >
          Save changes
        </Button>
      </Row>
    </Drawer>
  );
};
