import { FC, useCallback, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Text,
  Row,
  SearchInput,
  useToast,
  PlusIcon,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import {
  useDeleteUserGroupWorkspacesMutation,
  useUpsertUserGroupWorkspacesMutation,
} from "src/graphql";

import { openUrl } from "src/utils/urls";
import { OutletContext } from "..";
import { captureEvent } from "@sentry/react";
import { useRowSelect } from "src/ui/table/use-row-select";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ActionBar } from "src/components/action-bar";
import {
  Workspace,
  Workspaces,
  useUserGroupWorkspaces,
} from "./components/workspaces";
import { isDefaultRole } from "src/pages/organizations/roles/utils";

export const RoleWorkspaces: FC = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const context = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const { onRowSelect, selectedRows } = useRowSelect();
  const [search, setSearch] = useState("");

  const updateWorkspacesMutation = useUpsertUserGroupWorkspacesMutation();

  const workspaces = useUserGroupWorkspaces({
    organizationId: context.organization.id,
    userGroupId: context.userGroup.id,
    excludeAssigned: false,
    search,
  });

  const form = useHightouchForm({
    onSubmit: async ({ workspaces }) => {
      await updateWorkspacesMutation.mutateAsync({
        objects: Object.values(workspaces)
          .filter(Boolean)
          .map((workspace) => ({
            user_group_id: String(context.userGroup.id),
            workspace_id: String(workspace.id),
            is_workspace_admin: workspace.is_workspace_admin,
          })),
      });
    },
    values: {
      workspaces: workspaces?.reduce(
        (acc, workspace) => ({
          ...acc,
          [workspace.id]: workspace,
        }),
        {},
      ) as Record<string, Workspace>,
    },
  });

  const deleteWorkspacesMutation = useDeleteUserGroupWorkspacesMutation();

  const onRemove = async () => {
    try {
      await deleteWorkspacesMutation.mutateAsync({
        userGroupId: String(context.userGroup.id) ?? "",
        workspaceIds: selectedRows.map(String),
      });
      onRowSelect([]);
      toast({
        id: "remove",
        title: `${selectedRows.length} workspaces removed from the group`,
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "remove",
        title: "There was an error removing workspaces from the group",
        variant: "error",
      });
      captureEvent(e);
    }
  };

  const onRowClick = useCallback(
    ({ id }, event) =>
      openUrl(
        `/organization/groups/${roleId}/workspaces/${id}`,
        navigate,
        event,
      ),
    [navigate],
  );

  return (
    <>
      <Form form={form}>
        <Row align="center" justify="space-between" p={4} gap={4}>
          <Row align="center" gap={4} justify="space-between" flex={1}>
            <Text color="text.secondary">
              Members assigned to this group have access to the following
              workspaces. Manage their level of access below.
            </Text>
            <SearchInput
              value={search}
              placeholder="Search by workspace..."
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Row>
          <ButtonGroup>
            {selectedRows?.length > 0 && (
              <Button
                variant="warning"
                onClick={onRemove}
                isLoading={deleteWorkspacesMutation.isLoading}
              >
                Remove {selectedRows?.length} workspaces
              </Button>
            )}
            {!isDefaultRole(context.userGroup) && (
              <LinkButton href="new" icon={PlusIcon}>
                Add workspaces
              </LinkButton>
            )}
          </ButtonGroup>
        </Row>
        <Column flex={1} overflow="auto" p={4}>
          <Workspaces
            isEditable={!isDefaultRole(context.userGroup)}
            workspaces={workspaces}
            onSelect={onRowSelect}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            placeholder={{
              title: "No workspaces",
              error: "Workspaces failed to load, please try again.",
              body: search
                ? "No workspaces match your search."
                : "No workspaces have been assigned to this group",
            }}
          />
        </Column>
        {!isDefaultRole(context.userGroup) && (
          <ActionBar fit>
            <FormActions />
          </ActionBar>
        )}
      </Form>
      <Outlet context={context} />
    </>
  );
};
