import { FC, Suspense } from "react";

import { Column, Spinner } from "@hightouchio/ui";
import { Outlet } from "src/router";

import { PartnerProvider } from "src/partner/context";

import { Config } from ".";

export const Layout: FC<Readonly<{ config: Config }>> = ({ config }) => {
  return (
    <PartnerProvider brand={config?.brand}>
      <Column width="100%" minHeight="100vh">
        <Column flex={1} width="100%" minHeight={0}>
          <Suspense fallback={<Spinner size="lg" m="auto" />}>
            <Outlet />
          </Suspense>
        </Column>
      </Column>
    </PartnerProvider>
  );
};
