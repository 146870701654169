import { Column, Row } from "@hightouchio/ui";
import { Sync } from "src/pages/syncs/sync/utils/types";
import { RecentRunsTable } from "./recent-runs-table";
import { AlertsAndWarnings } from "./alerts-and-warnings";
import { MetadataSidebar } from "./metadata-sidebar";
import { SyncRunCharts } from "./sync-run-charts";

type Props = {
  sync: Sync;
};

export const Overview = ({ sync }: Props) => {
  return (
    <Row gap={6}>
      <Column gap={6} flexGrow={1} minWidth={0}>
        <AlertsAndWarnings sync={sync} />
        <RecentRunsTable sync={sync} />
        <SyncRunCharts sync={sync} />
      </Column>
      <MetadataSidebar sync={sync} />
    </Row>
  );
};
