import { FC, Suspense } from "react";

import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useOutletContext,
  useParams,
} from "src/router";
import * as Sentry from "@sentry/browser";

import {
  Column,
  EditableDescription,
  EditableHeading,
  useToast,
} from "@hightouchio/ui";
import { DetailPage } from "src/components/layout";
import { Warning } from "src/components/warning";
import { PageSpinner } from "src/components/loading";

import {
  FunctionsSetInput,
  useFunctionVersionLatestQuery,
  useUpdateFunctionMutation,
} from "src/graphql";
import { FunctionOutletContext } from "src/events/functions/types";
import { FunctionSyncs } from "./connected-syncs";
import { FunctionDetails } from "./function";

export const FunctionRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/code" element={<FunctionDetails />} />
          <Route path="/syncs" element={<FunctionSyncs />} />
          <Route
            index
            element={
              <Navigate
                to={{ pathname: "code", search: location.search }}
                replace
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: fn } = useFunctionVersionLatestQuery(
    {
      id: String(id),
    },
    {
      enabled: Boolean(id),
      select: (data) => data.functions?.[0],
      suspense: true,
    },
  );

  if (!fn) {
    return (
      <Warning subtitle="It may have been deleted" title="Function not found" />
    );
  }

  const context: FunctionOutletContext = { function: fn };

  return <Outlet context={context} />;
};

export const Layout: FC = () => {
  const { function: fn } = useOutletContext<FunctionOutletContext>();
  const { toast } = useToast();
  const { "*": tab } = useParams<{ "*": "code" | "syncs" }>();

  const updateFunctionMutation = useUpdateFunctionMutation();

  const updateFunction = async (object: FunctionsSetInput) => {
    try {
      await updateFunctionMutation.mutateAsync({
        id: fn.id,
        versionNumber: fn.version_number,
        object,
      });

      toast({
        id: "update-function",
        title: "Function updated",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "update-function",
        title: "Failed to update function",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <DetailPage
      tabDepth={4}
      title={`Edit function | ${fn.name}`}
      crumbs={[{ label: "All functions", link: "/events/functions" }]}
      tabs={[
        { title: "Code", path: "code" },
        {
          title: "Connected syncs",
          path: "syncs",
          count: fn.function_resources.length,
        },
      ]}
      contentFullHeight={tab === "code"}
      contentFullWidth={tab === "code"}
      header={
        <Column minWidth={0} gap={2} pt={1}>
          <EditableHeading
            placeholder="Add a name..."
            size="lg"
            value={fn.name}
            onChange={(name) => updateFunction({ name })}
          />

          <EditableDescription
            placeholder="Add a description..."
            value={fn.description ?? ""}
            onChange={(description) => updateFunction({ description })}
          />
        </Column>
      }
    >
      <Suspense fallback={<PageSpinner />}>
        <Outlet context={{ function: fn }} />
      </Suspense>
    </DetailPage>
  );
};
