import { useState, FC } from "react";

import {
  Row,
  Column,
  Text,
  Pill,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  ArrowRightIcon,
  Badge,
  ChevronLeftIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useParams, useSearchParams } from "src/router";

import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import {
  ResourceToPermission,
  SyncOp,
  SyncQuery,
  useAttemptedRowsByPrimaryKeyQuery,
  useAttemptedRowsQuery,
  useSyncAttemptQuery,
  useSyncQuery,
  useTransformedSyncRunConfigurationQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import {
  getObjectName,
  getSyncAttemptDiff,
  syncRunStatusOrUnknownStatus,
} from "src/utils/syncs";
import * as time from "src/utils/time";

import { Configuration } from "src/pages/syncs/sync/run/configuration";
import { Rows } from "src/pages/syncs/sync/run/rows";
import pluralize from "pluralize";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionProvider } from "src/components/permission/permission-context";
import { SyncRunStatusIndicator } from "src/components/syncs/sync-run-status-indicator";
import { enumOrFallback } from "src/types/utils";
import { commaNumber } from "src/utils/numbers";

export enum RunTabOption {
  successful = "successful",
  rejected = "rejected",
  pending = "pending",
  configuration = "configuration",
}

export const TabOptionIndices = {
  successful: 0,
  rejected: 1,
  configuration: 2,
} as const;

export const MbTabOptionIndices = {
  successful: 0,
  pending: 1,
  rejected: 2,
  configuration: 3,
} as const;

const getRejectedAddedRows = (
  plannerType: string | undefined,
  syncError: unknown | undefined,
  syncRequest,
  diff,
) => {
  if (plannerType === "all") {
    // If there's an error, we treat all added rows as rejected rows
    return syncError ? syncRequest?.sync_request_diff?.added_count ?? 0 : 0;
  } else {
    return diff?.rejected?.add;
  }
};

export const SyncRun: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id) },
  );

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={syncData?.syncs?.[0]?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <Run syncData={syncData} syncLoading={syncLoading} />
    </DraftProvider>
  );
};

interface RunProps {
  syncData: Partial<SyncQuery> | undefined;
  syncLoading: boolean;
}

const Run: FC<RunProps> = ({ syncData, syncLoading }: RunProps) => {
  const { run_id: runId, sync_id: syncId } = useParams<{
    run_id: string;
    sync_id: string;
  }>();
  const { editingDraft } = useDraft();

  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);

  const resetPagination = () => {
    setPageKeys([]);
    setPage(0);
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [syncOpFilters, _setSyncOpFilters] = useState<Record<SyncOp, boolean>>({
    ADDED: true,
    CHANGED: true,
    REMOVED: true,
  });

  const setSyncOpFilters = (filters: Record<SyncOp, boolean>) => {
    resetPagination();
    _setSyncOpFilters(filters);
  };

  const sync = syncData?.syncs?.[0];
  const model = sync?.segment;
  const source = model?.connection;
  const destination = sync?.destination;

  const { data: attemptData, isLoading: syncAttemptLoading } =
    useSyncAttemptQuery(
      {
        syncRequestId: runId ?? "",
      },
      {
        refetchInterval: (attemptData) => {
          if (attemptData?.sync_attempts?.[0]?.finished_at) {
            // Stop polling if this sync attempt is finished.
            return false;
          }

          return 3000;
        },
      },
    );

  const { data: prevConfig } = useTransformedSyncRunConfigurationQuery(
    { id: syncId ?? "", runId: runId ?? "" },
    {
      enabled: Boolean(runId && syncId),
      select: (data) => data.getTransformedSyncRunConfiguration,
    },
  );

  const attempt = attemptData?.sync_attempts?.[0];
  const syncRequest = attempt?.sync_request;
  const syncError =
    syncRequest?.error ??
    (attempt?.error ? { message: attempt.error } : undefined);
  const primaryKey = syncRequest?.sync?.segment?.primary_key;
  const resyncReason = syncRequest?.resync_reason;
  const plannerType = syncRequest?.planner_type;
  const errorCodeDetail = syncRequest?.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const diff = getSyncAttemptDiff(attempt);

  const added =
    plannerType === "all" && !syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (syncRequest?.add_executed ?? 0) - (diff?.rejected?.add ?? 0);

  const changed =
    (syncRequest?.change_executed ?? 0) - (diff?.rejected?.change ?? 0);
  const removed =
    (syncRequest?.remove_executed ?? 0) - (diff?.rejected?.remove ?? 0);
  const successfulRows = added + changed + removed;

  const rejectedRows =
    plannerType === "all" && syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (diff?.rejected?.add ?? 0) +
        (diff?.rejected?.change ?? 0) +
        (diff?.rejected?.remove ?? 0);

  const pendingRows = syncRequest?.query_run?.pending_rows ?? 0;
  const showPendingRows = pendingRows > 0;

  const tabOptions = showPendingRows ? MbTabOptionIndices : TabOptionIndices;

  const [searchParams, setSearchParams] = useSearchParams();

  const selected = enumOrFallback(
    RunTabOption,
    RunTabOption.successful,
    true,
  )(searchParams.get("tab"));

  const [currentTab, setCurrentTab] = [
    tabOptions[selected],
    (tabIndex: number) => {
      if (currentTab !== tabIndex) {
        resetPagination();
      }

      setSearchParams({
        tab:
          Object.entries(tabOptions).find(
            ([_, value]) => value === tabIndex,
          )?.[0] || RunTabOption.successful,
      });
    },
  ];

  const showSuccessful = currentTab === tabOptions.successful;
  const showRejected = currentTab === tabOptions.rejected;

  const addedRows: number | undefined | null = showSuccessful
    ? added
    : getRejectedAddedRows(
        plannerType?.toString(),
        syncError,
        syncRequest,
        diff,
      );

  const changedRows: number | undefined | null = showSuccessful
    ? changed
    : diff?.rejected?.change;
  const removedRows: number | undefined | null = showSuccessful
    ? removed
    : diff?.rejected?.remove;

  let totalRows = 0;
  const opTypes: SyncOp[] = [];

  if (syncOpFilters.ADDED) {
    totalRows += addedRows ?? 0;
    opTypes.push(SyncOp.Added);
  }
  if (syncOpFilters.CHANGED && plannerType !== "all") {
    totalRows += changedRows ?? 0;
    opTypes.push(SyncOp.Changed);
  }
  if (syncOpFilters.REMOVED && plannerType !== "all") {
    totalRows += removedRows ?? 0;
    opTypes.push(SyncOp.Removed);
  }

  const limit = 10;
  const pages = Math.ceil(totalRows / limit);
  const hasRows =
    Number(addedRows || 0) +
      Number(changedRows || 0) +
      Number(removedRows || 0) >
    0;

  const {
    data: attemptedRowsData,
    isLoading: attemptedRowsLoading,
    isRefetching: attemptedRowsRefetching,
    error: attemptedRowsQueryError,
  } = useAttemptedRowsQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      pageKey: pageKeys.slice(-1)[0],
      opTypes,
      limit,
      plannerType: String(plannerType),
    },
    {
      keepPreviousData: true,

      // When disabled, this will return previous data.
      // Also, don't run this query if we are not on tab that has rows (eg. on config or pending tabs).
      enabled: hasRows && (showSuccessful || showRejected),
    },
  );

  const {
    data: attemptedRowsByPKData,
    isLoading: attemptedRowsByPKLoading,
    isRefetching: attemptedRowsByPKRefetching,
  } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      id: search,
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      plannerType: String(plannerType),
      syncRequestId: Number(runId),
    },
    {
      enabled: Boolean(search) && plannerType !== "all",
      keepPreviousData: true,
    },
  );

  if (syncLoading || syncAttemptLoading) {
    return <PageSpinner />;
  }

  const rows = (
    <Rows
      addedRows={addedRows}
      attemptedRowsByPKData={attemptedRowsByPKData}
      attemptedRowsByPKLoading={
        attemptedRowsByPKLoading || attemptedRowsByPKRefetching
      }
      attemptedRowsData={attemptedRowsData}
      attemptedRowsLoading={attemptedRowsLoading || attemptedRowsRefetching}
      attemptedRowsQueryError={attemptedRowsQueryError}
      disableRowClick={true}
      changedRows={changedRows}
      page={page}
      pages={pages}
      plannerType={plannerType}
      primaryKey={primaryKey}
      redirectPrefix="/partner"
      removedRows={removedRows}
      search={search}
      searchInput={searchInput}
      setPage={setPage}
      setPageKeys={setPageKeys}
      setSearch={setSearch}
      setSearchInput={setSearchInput}
      setSyncOpFilters={setSyncOpFilters}
      syncOpFilters={syncOpFilters}
      showRejected={showRejected}
      source={source}
      sync={sync}
      syncError={syncError}
      syncRequest={syncRequest}
    />
  );

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "sync",
          grant: "can_update",
          id: sync?.id,
        },
      }}
    >
      <Row fontSize="3xl" color="link.default" mx={4} mt={4}>
        <Link
          href={
            editingDraft
              ? `/partner/syncs/${syncId}?editing=true`
              : `/partner/syncs/${syncId}`
          }
        >
          <ChevronLeftIcon />
          Back to sync
        </Link>
      </Row>

      <Column width="100%" flex={1}>
        <Row
          align="center"
          justify="space-between"
          width="100%"
          mb={2}
          gap={6}
          p={4}
        >
          <Row align="center" gap={4}>
            <Row align="center" gap={3}>
              <IntegrationIcon
                size={8}
                src={sync?.segment?.connection?.definition.icon}
                name={sync?.segment?.connection?.definition.name}
              />
              <Column overflow="hidden">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.tertiary"
                  textTransform="uppercase"
                >
                  Model
                </Text>
                <Text fontWeight="medium" size="lg" isTruncated>
                  {sync?.segment?.name}
                </Text>
              </Column>
            </Row>
            <Row fontSize="3xl" color="text.placeholder">
              <ArrowRightIcon />
            </Row>
            <Row align="center" gap={3}>
              <IntegrationIcon
                size={8}
                src={sync?.destination?.definition.icon}
                name={sync?.destination?.definition.name}
              />
              <Column overflow="hidden">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.tertiary"
                  textTransform="uppercase"
                >
                  Destination
                </Text>
                <Row align="center" gap={3}>
                  <Text fontWeight="medium" size="lg" isTruncated>
                    {sync?.destination?.name}
                  </Text>
                  {sync?.config?.object && (
                    <Badge size="sm">{getObjectName(sync.config.object)}</Badge>
                  )}
                </Row>
              </Column>
            </Row>
          </Row>
        </Row>

        <Row align="center" mx={4}>
          <MetadataBar>
            <Column>
              <MetadataLabel>Run status</MetadataLabel>
              <SyncRunStatusIndicator
                status={syncRunStatusOrUnknownStatus(
                  syncRequest?.status_computed,
                )}
                completionRatio={syncRequest?.completion_ratio || null}
              />
            </Column>
            <Column>
              <MetadataLabel>Started at</MetadataLabel>
              <Text>
                {syncRequest?.created_at &&
                  time.formatDatetime(syncRequest.created_at)}
              </Text>
            </Column>
            {attempt?.finished_at && (
              <Column>
                <MetadataLabel>Duration</MetadataLabel>
                <Text>
                  {time.diff(attempt?.created_at, attempt?.finished_at)}
                </Text>
              </Column>
            )}
            <Column>
              <MetadataLabel>Run ID</MetadataLabel>
              <Text>{runId}</Text>
            </Column>
            {resyncReason && (
              <Column>
                <MetadataLabel>Resync reason</MetadataLabel>
                <Text>
                  {{
                    "explicit-request": "Triggered by user",
                    "added-fields": "Fields were added",
                    "changed-config": "Configuration was changed",
                    "changed-mappings": "Mappings changed",
                    "changed-source-types": "Source types changed",
                    "changed-primary-key": "Model primary key was changed",
                    "retry-failed-resync": "Retrying failed resync",
                    "archive-mode-resync": "Resyncing for archive mode",
                    "next-diff-mode-override": "Next sync run override",
                  }[resyncReason] ?? resyncReason}
                </Text>
              </Column>
            )}
          </MetadataBar>
        </Row>

        <Tabs index={currentTab} onChange={setCurrentTab} mx={4}>
          <TabList>
            <Tab>
              Successful
              <Pill size="sm" marginLeft={1}>
                {commaNumber(successfulRows)}
              </Pill>
            </Tab>
            {showPendingRows && (
              <Tab>
                Pending
                <Pill size="sm" marginLeft={1}>
                  {commaNumber(pendingRows)}
                </Pill>
              </Tab>
            )}
            <Tab>
              Rejected
              <Pill
                size="sm"
                marginLeft={1}
                variant={rejectedRows > 0 ? "danger" : "primary"}
              >
                {commaNumber(rejectedRows)}
              </Pill>
            </Tab>
            <Tab>Configuration</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>{rows}</TabPanel>
            {showPendingRows && (
              <TabPanel>
                <Column height="100%">
                  <Row alignItems="center" mb={2} mt={6} width="100%">
                    <Text>
                      {pendingRows > 0
                        ? `${pendingRows} ${pluralize(
                            "row",
                            pendingRows,
                          )} will sync after the next enrichment.`
                        : "All rows have been enriched and synced in the latest run."}
                    </Text>
                  </Row>
                </Column>
              </TabPanel>
            )}
            <TabPanel>{rows}</TabPanel>
            <TabPanel>
              <Configuration
                destination={destination}
                model={model}
                prevConfig={prevConfig}
                prevConfigDate={syncRequest?.created_at}
                sync={sync}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Column>
    </PermissionProvider>
  );
};
