import { SchemaModelType } from "src/types/schema";
import { AssetType, InteractionType } from "src/types/visual";

export const INTERACTION_TYPE_OPTIONS = [
  // emails
  { label: "Email delivered", value: InteractionType.EmailDelivered },
  { label: "Email opened", value: InteractionType.EmailOpened },
  { label: "Email clicked", value: InteractionType.EmailClicked },
  { label: "Unsubscribe", value: InteractionType.Unsubscribe },

  // ads
  { label: "Ad Sessions", value: InteractionType.Sessions },
];

export const ASSET_TYPE_OPTIONS = [
  { label: "Email", value: AssetType.Email },
  { label: "Ad", value: AssetType.Ad },
];

export const RelationshipModelTypes = [
  SchemaModelType.Event,
  SchemaModelType.Related,
  SchemaModelType.Interaction,
  SchemaModelType.Asset,
  SchemaModelType.AdStats,
];
