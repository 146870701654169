import { FC, useCallback, useMemo, useState } from "react";

import {
  Badge,
  Column,
  InformationIcon,
  Row,
  SparkleIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import placeholderSource from "src/components/permission/source.svg";
import { ResourceSelect } from "src/components/resource-select";
import {
  ParentModelsQuery,
  SegmentsBoolExp,
  useParentsQuery,
} from "src/graphql";
import { TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";

export type ParentModel = ParentModelsQuery["segments"][0];

interface Props {
  disableSourcesWithoutAudienceSnapshotting?: boolean;
  onSelect: (ParentModel: ParentModel) => void;
  isOptionAvailable?: (id: string) => boolean;
}

export const ParentModelSelect: FC<Readonly<Props>> = ({
  disableSourcesWithoutAudienceSnapshotting = false,
  onSelect,
  isOptionAvailable,
}) => {
  const [search, setSearch] = useState("");

  const filters = useMemo(() => {
    const baseFilters: SegmentsBoolExp = {
      is_schema: { _eq: true },
      primary_key: { _is_null: false },
    };

    if (search) {
      baseFilters._and = [{ name: { _ilike: `%${search}%` } }];
    }

    return baseFilters;
  }, [search]);

  const parents = useParentsQuery(
    {
      filters,
    },
    { select: (data) => data.segments },
  );

  const disabledRows = useCallback(
    (row: ParentModel) => {
      if (disableSourcesWithoutAudienceSnapshotting) {
        return !row?.connection?.audience_snapshotting_enabled;
      }

      return false;
    },
    [disableSourcesWithoutAudienceSnapshotting],
  );

  const sortedData = disableSourcesWithoutAudienceSnapshotting
    ? orderBy(
        parents.data,
        ["connection.audience_snapshotting_enabled", "name"],
        ["desc", "asc"],
      )
    : orderBy(parents.data, ["name"], ["asc"]);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: ({ name, connection: source, matchboosting_enabled }) => {
          return (
            <Row align="center" gap={2} overflow="hidden">
              <IntegrationIcon
                name={source?.definition?.name ?? "Private source"}
                src={source?.definition?.icon ?? placeholderSource}
              />

              <Column gap={1}>
                <Text isTruncated fontWeight="medium">
                  {name ?? "Private source"}
                </Text>

                {matchboosting_enabled && (
                  <Badge size="sm" icon={SparkleIcon} iconColor="warning.400">
                    Boosted
                  </Badge>
                )}
              </Column>

              {disableSourcesWithoutAudienceSnapshotting &&
                !source?.audience_snapshotting_enabled && (
                  <Tooltip message="Please turn on audience snapshotting in this parent model's source settings.">
                    <InformationIcon />
                  </Tooltip>
                )}
            </Row>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        max: "max-content",
        breakpoint: "sm",
      },
    ],
    [parents.data],
  );

  return (
    <ResourceSelect
      columns={columns}
      data={sortedData}
      disabled={(row) =>
        disabledRows(row) ||
        Boolean(isOptionAvailable && !isOptionAvailable(row.id))
      }
      error={Boolean(parents.error)}
      label="parent model"
      loading={parents.isLoading}
      search={search}
      onSearch={setSearch}
      onSelect={onSelect}
    />
  );
};
