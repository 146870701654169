import { FC, useMemo } from "react";

import { Column, Heading, Row, TagInput } from "@hightouchio/ui";

import { Controller } from "react-hook-form";
import { useOutletContext } from "src/router";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  useSsoGroupsQuery,
  useUpdateSsoGroupMappingsMutation,
  useUserGroupsForSsoQuery,
} from "src/graphql";
import { Table, TableColumn } from "src/ui/table";
import { OutletContext } from "..";
import { ActionBar } from "src/components/action-bar";

export const SSOGroupMapping: FC = () => {
  const { organization } = useOutletContext<OutletContext>();

  const { data: ssoGroups } = useSsoGroupsQuery(
    {
      organizationId: organization.id,
    },
    {
      select: (data) => data.sso_groups,
      suspense: true,
    },
  );

  const { data: userGroups } = useUserGroupsForSsoQuery(
    {
      organizationId: organization.id,
    },
    {
      select: (data) => data.user_groups,
      suspense: true,
    },
  );

  const updateSsoGroup = useUpdateSsoGroupMappingsMutation();

  const userGroupOptions = useMemo(() => {
    return (
      userGroups?.map((ug) => ({
        label: ug.name,
        value: ug.id,
      })) ?? []
    );
  }, [userGroups]);

  const columns: TableColumn[] = [
    {
      name: "SSO group",
      cell: ({ name }) => name,
    },
    {
      name: "Roles",
      cell: ({ id }) => (
        <Controller
          name={`ssoGroups.${id}`}
          render={({ field }) => (
            <Column py={2}>
              <TagInput
                placeholder="Select roles..."
                value={field.value ?? []}
                options={userGroupOptions}
                onChange={field.onChange}
              />
            </Column>
          )}
        />
      ),
    },
  ];

  const values =
    ssoGroups?.reduce(
      (acc, ssoGroup) => ({
        ...acc,
        [ssoGroup.id]: ssoGroup.sso_group_user_groups.map(
          (ug) => ug.user_group.id,
        ),
      }),
      {} as Record<string, string[]>,
    ) ?? {};

  const form = useHightouchForm({
    values: {
      ssoGroups: values,
    },
    onSubmit: async ({ ssoGroups }) => {
      const insertInput: { sso_group_id: string; user_group_id: string }[] = [];
      const deleteInput: {
        sso_group_id: { _eq: string };
        user_group_id: { _eq: string };
      }[] = [];
      for (const [ssoGroupId, userGroupIds] of Object.entries(ssoGroups)) {
        for (const userGroupId of userGroupIds) {
          insertInput.push({
            user_group_id: userGroupId,
            sso_group_id: ssoGroupId,
          });
          deleteInput.push({
            sso_group_id: { _eq: ssoGroupId },
            user_group_id: { _eq: userGroupId },
          });
        }
      }

      await updateSsoGroup.mutateAsync({
        insertInput,
        deleteInput,
        organizationId: organization.id,
      });
    },
  });

  return (
    <Form form={form}>
      <Column px={6} py={4} flex={1} minH={0}>
        <Row align="center" mb={4}>
          <Heading>Group mappings</Heading>
        </Row>
        <Column flex={1} minH={0} overflow="auto">
          <Table
            rowHeight="max-content"
            columns={columns}
            data={ssoGroups}
            placeholder={{
              title: "No SSO groups",
              error: "SSO groups failed to load, please try again.",
              body: "Make sure you are sending your SSO groups to Hightouch.",
            }}
          />
        </Column>
      </Column>
      <ActionBar fit>
        <FormActions />
      </ActionBar>
    </Form>
  );
};
