import { FC } from "react";

import {
  Checkbox,
  InformationIcon,
  Row,
  Select,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import {
  ColumnType,
  DefaultOperators,
  FilterableColumn,
  OperatorOptions,
  PropertyCondition,
} from "src/types/visual";

import { FilterProps } from "./condition";
import { Filter } from "./filter";
import { PropertyInput, PropertyInputProps } from "./property-input";
import { formatValue, updateConditionOperator } from "./utils";

type Props = Pick<
  FilterProps<PropertyCondition>,
  "parent" | "condition" | "onChange"
> & {
  loadingSuggestions: boolean;
  operatorError: string | null | undefined;
  suggestions: PropertyInputProps["suggestions"];
  showParameterizeCheckbox?: boolean;
  valueError: string | null | undefined;
  columns?: FilterableColumn[] | undefined;
};

export const PropertyOperatorValueFilter: FC<Readonly<Props>> = ({
  condition,
  loadingSuggestions,
  operatorError,
  parent,
  showParameterizeCheckbox,
  suggestions,
  valueError,
  onChange,
  columns,
}) => {
  const isParameterized = Boolean(condition.propertyOptions?.parameterize);
  const connectionType = parent?.connection?.definition.type;
  const operatorOptions = condition.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) =>
          !("supportedSources" in op) ||
          op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = isParameterized
    ? "is"
    : operatorOptions?.find((option) => option.value === condition.operator)
        ?.label;
  const formattedValue = formatValue(condition, condition.value, {
    showParameterizedLabel: true,
  });

  return (
    <Filter
      content={
        <Row alignItems="flex-start" gap={1}>
          {showParameterizeCheckbox && (
            <Row alignSelf="center" gap={1}>
              <Checkbox
                label="Parameterize"
                isChecked={isParameterized}
                onChange={(event) => {
                  const propertyType =
                    condition.propertyType ?? ColumnType.Unknown;
                  onChange({
                    operator: DefaultOperators[propertyType],
                    value: null,
                    propertyOptions: {
                      ...condition.propertyOptions,
                      parameterize: event.target.checked,
                    },
                  });
                }}
              />
              <Tooltip message="Parameterizing a filter allows users to select a value on the audience level. For example: set a “Revenue” metric with “Brand” as a parametrized filter and users can select the relevant brand for each audience.">
                <InformationIcon />
              </Tooltip>
            </Row>
          )}
          {!isParameterized && (
            <>
              <Select
                removePortal
                options={operatorOptions ?? []}
                placeholder="Filter on"
                value={condition.operator}
                width="auto"
                onChange={(value) =>
                  onChange(updateConditionOperator(condition, value))
                }
              />
              <PropertyInput
                condition={condition}
                error={valueError}
                loading={loadingSuggestions}
                suggestions={suggestions}
                onChange={onChange}
                columns={columns}
                parent={parent ?? undefined}
              />
            </>
          )}
        </Row>
      }
      error={operatorError || valueError}
    >
      <Row gap={1} overflow="hidden">
        <Text color="text.secondary">{operatorLabel}</Text>{" "}
        <Text color="text.primary" fontWeight="medium">
          {formattedValue}
        </Text>
      </Row>
    </Filter>
  );
};
