import { Column, Row, Text } from "@hightouchio/ui";
import { orderBy } from "lodash";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useOrganizationPermissions } from "src/components/permission/use-organization-permissions";
import { useOrganizationWorkspacesWithUserGroupQuery } from "src/graphql";

import {
  PermissionedButton,
  PermissionedSwitch,
} from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Table } from "src/ui/table";
import { RowClickHandler, TableColumn, TableProps } from "src/ui/table/table";

export type Workspace = {
  id: string;
  name: string;
  is_workspace_admin: boolean;
  count?: number;
};

type Props = {
  workspaces: Array<Workspace>;
  selectedRows?: Array<string | number>;
  onSelect?: (id: string) => void;
  onRowClick?: RowClickHandler<Workspace>;
  search?: string;
  placeholder: TableProps<Workspace>["placeholder"];
  isEditable: boolean;
};

export const useUserGroupWorkspaces = ({
  organizationId,
  userGroupId,
  search,
  excludeAssigned,
}): Array<Workspace> => {
  const { data } = useOrganizationWorkspacesWithUserGroupQuery(
    { organizationId, userGroupId },
    {
      select: (data) =>
        orderBy(data.workspaces, ["user_groups"], ["desc"])
          .filter((workspace) => {
            const isAssigned = workspace?.user_groups?.find(
              ({ user_group_id }) =>
                String(user_group_id) === String(userGroupId),
            );
            return excludeAssigned ? !isAssigned : isAssigned;
          })
          .map((workspace) => ({
            id: workspace?.id,
            name: workspace?.name,
            is_workspace_admin: Boolean(
              workspace?.user_groups?.[0]?.is_workspace_admin,
            ),
            count: workspace?.workspace_members_aggregate?.aggregate?.count,
          })) ?? [],
      suspense: true,
    },
  );
  return data
    ? data.filter((workspace) =>
        search
          ? workspace?.name?.toLowerCase().includes(search.toLowerCase())
          : true,
      )
    : [];
};

export const Workspaces: FC<Readonly<Props>> = ({
  workspaces,
  selectedRows,
  onSelect,
  onRowClick,
  isEditable,
  placeholder,
}) => {
  const { isWorkspaceAdmin } = useOrganizationPermissions();
  return (
    <Controller
      name="workspaces"
      render={({ field }) => {
        const columns: TableColumn<Workspace>[] = [
          {
            name: "Workspace",
            cell: ({ name }) => (
              <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
            ),
          },
          {
            name: "Number of members",
            cell: ({ count }) => <Text>{count ?? "0"}</Text>,
          },
          {
            name: "Is workspace admin",
            max: "max-content",
            cell: ({ id }) => {
              const value = field.value?.[id];
              return (
                <Row
                  justify="center"
                  flex={1}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <PermissionedSwitch
                    size="sm"
                    organizationPermission={{ resource: "workspace", id }}
                    isDisabled={!isEditable || !value}
                    isChecked={value?.is_workspace_admin}
                    onChange={(value) => {
                      field.onChange({
                        ...field.value,
                        [id]: {
                          id: id,
                          is_workspace_admin: value,
                        },
                      });
                    }}
                  />
                </Row>
              );
            },
          },
        ];

        if (onRowClick && isEditable) {
          columns.push({
            name: "",
            cell: (row) => (
              <Column alignItems="flex-end" width="100%">
                <PermissionedButton
                  size="sm"
                  onClick={(e) => onRowClick(row, e)}
                  organizationPermission={{ resource: "workspace", id: row.id }}
                >
                  Edit permissions
                </PermissionedButton>
              </Column>
            ),
          });
        }

        return (
          <Table
            selectedRows={!isEditable ? undefined : selectedRows}
            data={workspaces}
            disabled={(r) => !isWorkspaceAdmin(r.id)}
            columns={columns}
            placeholder={placeholder}
            onSelect={!isEditable ? undefined : onSelect}
          />
        );
      }}
    />
  );
};
