import { FC } from "react";

import {
  BoxProps,
  CheckIcon,
  Column,
  Row,
  SystemStyleObject,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { IconBox } from "src/components/icon-box";
import { DataTooltip } from "src/components/explore/filter-popover/data-tooltip";

import {
  EventColumn,
  FilterOption,
  MetadataIcons,
  MetadataType,
  RelationColumn,
} from "./constants";

type FilterPopoverOptionProps = {
  bg: string;
  columnType: FilterOption;
  description?: string | null;
  icon: JSX.Element;
  iconSx?: SystemStyleObject;
  isFocused?: boolean;
  isSelected?: boolean;
  label: string;
  disabledText?: string;
  metadata?: { modelName: string; type: MetadataType } | null;
  onClick: () => void;
};

export const FilterPopoverOption: FC<
  Readonly<FilterPopoverOptionProps & BoxProps>
> = ({
  bg,
  columnType,
  description,
  icon,
  iconSx,
  isSelected = false,
  label,
  metadata,
  disabledText,
  sx = {},
  ...props
}) => {
  const isDisabled = Boolean(disabledText);

  return (
    <Row
      as="button"
      disabled={Boolean(disabledText)}
      align="center"
      justify="space-between"
      role="option"
      _focus={{ boxShadow: "inset 0 0 0 4px #E0F3F5", borderRadius: "6px" }}
      _hover={{ bg: isSelected ? "forest.200" : "base.background" }}
      _active={{
        bg: isSelected ? "forest.300" : "gray.200",
        boxShadow: "none",
      }}
      _disabled={{
        cursor: "not-allowed",
        _hover: { bg: "transparent" },
      }}
      bg={isSelected ? "primary.background" : "transparent"}
      border="1px solid"
      borderColor="transparent"
      borderRadius="5px"
      height={12}
      outline="none !important"
      transition="border-color 80ms ease-in"
      width="100%"
      sx={sx}
      {...props}
    >
      <DataTooltip
        description={description}
        gutter={12}
        label={label}
        placement="right"
        subtitle={
          metadata?.modelName ? (
            <Row
              as={Text}
              align="center"
              color="base.border"
              flexWrap="wrap"
              columnGap={1}
              size="sm"
              textAlign="left"
              sx={{ svg: { height: "12px", width: "12px" } }}
            >
              {[FilterOption.Trait, FilterOption.TraitTemplate].includes(
                columnType,
              )
                ? "Based on"
                : "Merged from"}{" "}
              {metadata.type === MetadataType.Event
                ? EventColumn.icon
                : RelationColumn.icon}{" "}
              <Text color="base.border" wordBreak="break-all">
                {metadata.modelName}
              </Text>
            </Row>
          ) : null
        }
        title={columnType}
      >
        <Tooltip isDisabled={!disabledText} message={disabledText ?? ""}>
          <Row align="center" overflowX="hidden" width="100%" gap={4} p={2}>
            <Row
              align="center"
              gap={2}
              overflowX="hidden"
              width="100%"
              color={isDisabled ? "text.secondary" : "text.primary"}
            >
              <IconBox
                bg={bg}
                icon={icon}
                boxSize={6}
                iconSize={4}
                iconSx={iconSx}
              />
              <Column
                justify="start"
                overflow="hidden"
                sx={{ span: { textAlign: "left" } }}
              >
                <Row gap={2}>
                  <Text isTruncated color="inherit">
                    {label}
                  </Text>

                  {metadata?.modelName && (
                    <Row
                      align="center"
                      maxWidth="150px"
                      sx={{
                        "* > svg": { height: "12px", width: "12px", mr: 1 },
                      }}
                    >
                      <Text color="text.tertiary">
                        {MetadataIcons[metadata.type]}
                      </Text>
                      <Text isTruncated color="text.tertiary">
                        {metadata.modelName}
                      </Text>
                    </Row>
                  )}
                </Row>
                {description && (
                  <Text isTruncated color="text.tertiary" size="sm">
                    {description}
                  </Text>
                )}
              </Column>
            </Row>
            <Row color="primary.pressed" fontSize="24px" width="24px">
              {isSelected && <CheckIcon />}
            </Row>
          </Row>
        </Tooltip>
      </DataTooltip>
    </Row>
  );
};
