import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  isMergedColumn,
  SizeCap,
  VisualQueryFilter,
} from "@hightouch/lib/query/visual/types";
import {
  Alert,
  AudienceIcon,
  Box,
  Button,
  ButtonGroup,
  ChevronRightIcon,
  CloseIcon,
  CodeIcon,
  Column,
  Combobox,
  Dialog,
  IconButton,
  InformationIcon,
  Paragraph,
  RedoIcon,
  RefreshIcon,
  Row,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  UndoIcon,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { useQueryClient } from "react-query";

import { ActionBar } from "src/components/action-bar";
import AudienceIllustration from "src/components/audiences/audience-illustration.svg";
import { Editor } from "src/components/editor";
import { QueryBuilder } from "src/components/explore/query-builder";
import { PermissionedButton } from "src/components/permission";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  AudienceBreakdownResult,
  AudienceComparisonResult,
  CompareAudiencesQueryVariables,
  useAudienceBreakdownQuery,
  useAudiencesForDropdownsQuery,
  useCompareAudiencesQuery,
  useVisualQuerySqlQuery,
} from "src/graphql";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import * as analytics from "src/lib/analytics";
import { ParentModel } from "src/pages/audiences/types";
import {
  AndCondition,
  Audience,
  OrCondition,
  RootCondition,
} from "src/types/visual";
import { SIZES } from "src/ui/box/container";
import { EMPTY_AUDIENCE_DEFINITION, useModelRun } from "src/utils/models";
import { commaNumber } from "src/utils/numbers";
import { useCalculateAudienceSize } from "src/utils/use-calculate-audience-size";

import { Magic } from "src/components/explore/magic";
import { SubsetSelector } from "src/components/explore/subset-selector";
import {
  cssVariableBottomSectionHeight,
  cssVariableTopOffset,
  cssVariableTopSectionHeight,
} from "src/components/layout/detail-page";
import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { UseModelStateReturn } from "src/hooks/use-model-state";
import { AudienceBreakdowns, BreakdownColumn } from "./audience-breakdowns";
import { AudienceOverlap } from "./audience-overlap";
import { AudienceResults } from "./audience-results";
import { AudienceSizeCap } from "./audience-size-cap";
import { hasQueryChanged, useLastUsedQuery } from "./use-last-used-query";
import { toSingleCondition } from "./utils";

type Source = {
  id: string;
  definition: {
    type: string;
    supportsResultSchema: boolean;
  };
};

enum TabOptions {
  Results = "Results",
  Overlap = "Overlap",
  Breakdowns = "Breakdown",
}

const defaultSidebarWidth = 400;
const actionBarDefaultHeight = 48;
const footerDefaultHeight = 0;

const tabs = [TabOptions.Results, TabOptions.Overlap, TabOptions.Breakdowns];

export type AudienceExploreProps = {
  audience?: Audience;
  modelState: UseModelStateReturn;
  bodyOverflow?: "" | "hidden";
  parentModel?: ParentModel | null;
  source: Source | undefined | null;
  onSave?: (
    queryStateOverride?: { visual_query_filter: VisualQueryFilter },
    showToast?: boolean,
  ) => Promise<void>;
};

export const AudienceExplore: FC<Readonly<AudienceExploreProps>> = ({
  audience,
  bodyOverflow = "",
  parentModel,
  source,
  onSave,
  modelState,
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { aiAudienceGeneration, appDestinationRulesEnabled } = useFlags();

  const { hasValidationErrors } = useFormErrorContext();

  const hasSampledSegments = Boolean(
    parentModel?.enabled_sampled_segments.length,
  );
  const [isFastQueryEnabled, setIsFastQueryEnabled] =
    useState(hasSampledSegments);
  const [destinationId, setDestinationId] = useState<string | undefined>();

  const {
    runQuery,
    cancelQuery,
    resetRunState,
    rows,
    numRowsWithoutLimit,
    loading,
    error,
    usedSampledModels: usedFastQueryForRun,
  } = useModelRun(modelState.state, {
    columns: parentModel?.columns,
    useSampledModels: isFastQueryEnabled,
    onCompleted: (data, error) => {
      if (!error) {
        setTransformedSql((state) => ({
          ...state,
          sql: data?.transformedSql,
          fetchedAt: Date.now(),
          loading: false,
        }));
      }
    },
  });

  const visualQueryFilter = modelState.state.visual_query_filter;
  const subsetIds = useMemo(
    () =>
      modelState.state.subsets?.map((subset) => subset.subset_value.id) ?? [],
    [modelState.state.subsets],
  );
  const sizeCap = visualQueryFilter?.sizeCap;

  const {
    audienceSize,
    audienceSizeUpdatedAt,
    isLoading: calculatingAudienceSize,
    lastUsedQuery: lastUsedAudienceSizeQuery,
    calculateAudienceSize,
    cancelCalculateAudienceSize,
    updateAudienceSize,
  } = useCalculateAudienceSize({
    audienceId: modelState.state.id?.toString(),
    parentModelId: parentModel?.id?.toString() ?? "",
    sourceId: source?.id?.toString() ?? "",
    visualQueryFilter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
    subsetIds,
    isFastQuery: isFastQueryEnabled,
    destinationId,
  });

  const sizeLoading = loading || calculatingAudienceSize;

  const saveSizeCap = async (payload: SizeCap | undefined): Promise<void> => {
    const newVisualQueryFilter = {
      ...(visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION),
      sizeCap: payload,
    };

    // If the audience is being created or cloned, we don't want to actually "save" the size cap:
    //   1) the onSave callback prop is undefined
    //   2) that would attempt to save the full audience entity prematurely
    // Instead, we'll just update the visual query object.
    if (onSave == undefined) {
      modelState.onChange({ visual_query_filter: newVisualQueryFilter });
    } else {
      setSaveLoading(true);
      await onSave({ visual_query_filter: newVisualQueryFilter }, false);
      setSaveLoading(false);
    }
  };

  const { refetch: refetchVisualQuerySql } = useVisualQuerySqlQuery(
    {
      audience_id: modelState.state.id?.toString(),
      connection_id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parent_model_id:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      subset_ids: subsetIds,
      useSampledModels: isFastQueryEnabled,
      destinationId,
    },
    {
      enabled: false,
    },
  );

  const [transformedSql, setTransformedSql] = useState<{
    sql: string | undefined | null;
    fetchedAt: number | undefined;
    loading: boolean;
    visible: boolean;
  }>({
    sql: undefined,
    fetchedAt: undefined,
    loading: false,
    visible: false,
  });

  const actionBarRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tab, setTab] = useState<TabOptions>(TabOptions.Results);
  const [isDrawerOpen, setDrawerIsOpen] = useState(false);
  const [overlapQueryError, setOverlapQueryError] = useState<string | null>(
    null,
  );
  const [showCancelButton, setShowCancelButton] = useState(false);

  const actionBarHeight = useMeasureHeightOnce(
    actionBarRef,
    actionBarDefaultHeight,
  );
  const footerBarHeight = useMeasureHeightOnce(footerRef, footerDefaultHeight);

  const { isPermitted: hasPreviewPermission } = useResourcePermission({
    v2: modelState.state.id
      ? { resource: "model", id: modelState.state.id, grant: "can_preview" }
      : {
          resource: "model",
          grant: "can_preview",
          creationOptions: {
            type: "audience",
            parentModelId: parentModel?.id?.toString(),
          },
        },
    v1: { resource: "audience", grant: "preview", id: modelState.state.id },
  });

  const [comparedAudienceIds, setComparedAudienceIds] = useState<string[]>([]);
  const [comparisonData, setComparisonData] =
    useState<AudienceComparisonResult>();
  const [comparisonsLoading, setComparisonsLoading] = useState(false);

  const refetchBreakdown = useRef(false);
  const [breakdownColumns, setBreakdownColumns] = useState<BreakdownColumn[]>(
    [],
  );
  const [breakdownData, setBreakdownData] = useState<AudienceBreakdownResult>();

  const [lastUsedInsightsQuery, setLastUsedInsightsQuery] =
    useLastUsedQuery(null);

  const initialPreviewRun = isDrawerOpen
    ? Boolean(lastUsedInsightsQuery)
    : Boolean(lastUsedAudienceSizeQuery);

  const isDataStale =
    initialPreviewRun &&
    hasQueryChanged(
      {
        filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      },
      isDrawerOpen ? lastUsedInsightsQuery : lastUsedAudienceSizeQuery,
    );

  const copySQLToClipboard = () => {
    if (transformedSql.sql) {
      navigator.clipboard.writeText(transformedSql.sql);

      toast({
        id: "copy-sql",
        title: "SQL copied to clipboard",
        variant: "success",
      });
    }
  };

  const {
    error: breakdownError,
    isLoading: breakdownsInitialLoading,
    isRefetching: breakdownsRefetching,
    refetch: refetchBreakdowns,
  } = useAudienceBreakdownQuery(
    {
      id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parentModelId:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      columns: breakdownColumns,
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      audienceId: modelState.state.id?.toString(),
      perColumnLimit: 10,
      subsetIds,
      useSampledModels: isFastQueryEnabled,
      destinationId,
    },
    {
      enabled: false,
      keepPreviousData: breakdownColumns?.length > 0 ? false : true,
    },
  );

  const { data: audiencesData, isLoading: audiencesLoading } =
    useAudiencesForDropdownsQuery(
      { limit: 10000 },
      {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60, // 1 min
        notifyOnChangeProps: ["data", "isLoading"],
      },
    );

  const fetchBreakdowns = async () => {
    try {
      const visualQueryUsedToRunBreakdown = cloneDeep(visualQueryFilter);
      const { data } = await refetchBreakdowns();

      if (!data) {
        throw new Error("Audience breakdowns returned no data");
      }
      setLastUsedInsightsQuery({
        filter: visualQueryUsedToRunBreakdown ?? EMPTY_AUDIENCE_DEFINITION,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });
      updateAudienceSize({
        count: data.audienceBreakdown.audienceSize,
        isEstimate: Boolean(data.audienceBreakdown.usedSampledModels),
      });
      const formattedColumns: AudienceBreakdownResult["columns"] = [];

      for (const column of data?.audienceBreakdown.columns ?? []) {
        // replace name with alias
        const columnData = breakdownColumns.find(
          ({ name }) => name === column.name,
        );
        const modelId = columnData?.modelId || "";
        const columnName = columnData?.alias || column.name;

        const sumOfValues = column.values.reduce(
          (sum, value) => sum + value.count,
          0,
        );
        let newValues = column.values.map((value) => ({
          ...value,
          percentage: sumOfValues ? value.count / sumOfValues : "unknown",
        }));
        const numberOfRemainingValues =
          data.audienceBreakdown.audienceSize - sumOfValues;

        if (numberOfRemainingValues > 0) {
          newValues = newValues.map((value) => ({
            ...value,
            percentage: value.count / data.audienceBreakdown.audienceSize,
          }));
          newValues.push({
            value: "Other",
            count: numberOfRemainingValues,
            percentage:
              numberOfRemainingValues / data.audienceBreakdown.audienceSize,
          });
          newValues.sort((valueA, valueB) => valueB.count - valueA.count);
        }

        formattedColumns.push({
          ...column,
          name: columnName,
          modelId: modelId,
          values: newValues,
        });
      }

      setBreakdownData({
        ...data?.audienceBreakdown,
        columns: formattedColumns,
      });
    } catch (error) {
      toast({
        id: "audience-breakdowns",
        title: "Breakdown calculation failed",
        message: error.message,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const breakdownsLoading = breakdownsInitialLoading || breakdownsRefetching;
  const audiences = audiencesData?.segments ?? [];

  const columnsForBreakdowns = (
    parentModel?.filterable_audience_columns ?? []
  ).filter(
    ({ column_reference }) =>
      !column_reference?.disable_preview &&
      (column_reference?.type === "raw" || isMergedColumn(column_reference)) &&
      !breakdownColumns.some((selectedColumn) =>
        isEqual(omit(selectedColumn, "alias"), column_reference),
      ),
  );

  const destinationOptions =
    parentModel?.destination_rules.map(({ destination }) => ({
      label: destination.name ?? destination.definition.name,
      value: String(destination.id),
      logo: destination.definition.icon,
    })) ?? [];

  const splitTestGroupName =
    visualQueryFilter?.splitTestDefinition?.groupColumnName;

  const shouldFetchQuerySql = useMemo(() => {
    /**
     * If we have previewed or saved the query, then the generated SQL will have been cached in local state.
     * So we only need to regenerate the SQL if the filter conditions have changed.
     */
    if (transformedSql.sql && transformedSql.fetchedAt && isDataStale) {
      return audienceSizeUpdatedAt !== null
        ? audienceSizeUpdatedAt > transformedSql.fetchedAt
        : false;
    }

    /**
     * If the query hasn't been saved:
     * we don't have a way of knowing whether the query conditions have changed so we refetch to be safe.
     */
    return !transformedSql.sql || !rows;
  }, [
    audienceSizeUpdatedAt,
    transformedSql.sql,
    transformedSql.fetchedAt,
    isDataStale,
    rows,
  ]);

  const showSqlPreview = useCallback(async () => {
    if (shouldFetchQuerySql) {
      setTransformedSql((state) => ({
        ...state,
        loading: true,
        visible: true,
      }));
      try {
        const result = await refetchVisualQuerySql();
        setTransformedSql((state) => ({
          ...state,
          sql: result?.data?.visualQuerySQL?.sql,
          fetchedAt: Date.now(),
          loading: false,
        }));
      } catch (error) {
        toast({
          id: "sql-preview",
          title: "SQL preview failed",
          message: "Please try again.",
          variant: "error",
        });
      }
    } else {
      setTransformedSql((state) => ({ ...state, visible: true }));
    }
  }, [shouldFetchQuerySql, refetchVisualQuerySql, setTransformedSql]);

  const handleSave = async () => {
    if (hasValidationErrors()) {
      toast({
        id: "save-audience",
        title: "Unable to save audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    setSaveLoading(true);
    if (typeof onSave === "function") {
      await onSave();
    }
    setSaveLoading(false);
  };

  const resetOverlapData = () => {
    setComparedAudienceIds([]);
    setComparisonData(undefined);
  };

  const addBreakdown = (column: BreakdownColumn) => {
    if (hasValidationErrors()) {
      toast({
        id: "add-breakdown",
        title: "Unable to breakdown audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    const newBreakdownColumns = [...breakdownColumns, column];
    setBreakdownColumns(newBreakdownColumns);
  };

  const removeBreakdown = (columnName: string) => {
    refetchBreakdown.current = false;

    // remove data
    setBreakdownData((prevData) => {
      if (!prevData) {
        return undefined;
      }

      const newColumns = [...(prevData?.columns ?? [])].filter(
        (column) => column.name !== columnName,
      );
      if (newColumns.length === 0) {
        return undefined;
      }

      return {
        ...prevData,
        columns: newColumns,
      };
    });

    // remove column
    setBreakdownColumns((prevColumns) =>
      prevColumns.filter((column) => column.name !== columnName),
    );
  };

  useEffect(() => {
    if (refetchBreakdown.current && breakdownColumns.length > 0) {
      fetchBreakdowns();
    }

    refetchBreakdown.current = true;
  }, [breakdownColumns.length]);

  const compareAudiences = async (
    audienceIds: string[],
    { invalidateQuery }: { invalidateQuery: boolean } = {
      invalidateQuery: false,
    },
  ) => {
    if (hasValidationErrors()) {
      toast({
        id: "compare-audiences",
        title: "Unable to compare audiences",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    if (audienceIds.length === 0) {
      setComparedAudienceIds([]);
      setComparisonData(undefined);
      setOverlapQueryError(null);
      return;
    }

    setComparisonsLoading(true);

    const variables: CompareAudiencesQueryVariables = {
      id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parentModelId:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      compareToAudiences: audienceIds || comparedAudienceIds,
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      audienceId: modelState.state.id?.toString(),
      subsetIds,
      destinationId,
      // Audience comparison doesn't support using Fast Query
      // so we intentionally omit passing the variable to the mutation
    };

    const queryKey = useCompareAudiencesQuery.getKey(variables);

    if (invalidateQuery) {
      await client.invalidateQueries(queryKey);
    }

    try {
      const { audienceComparison } = await client.fetchQuery({
        queryFn: useCompareAudiencesQuery.fetcher(variables),
        queryKey,
      });

      setComparedAudienceIds(audienceIds || comparedAudienceIds);
      setLastUsedInsightsQuery({
        filter: variables.filter,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });
      updateAudienceSize({
        count: audienceComparison.audienceSize,
        // Audience comparison doesn't support fast query,
        // so the size that's returned will never be an estimate.
        isEstimate: false,
      });
      setComparisonData(audienceComparison);
      setOverlapQueryError(null);
    } catch (error) {
      // TODO: Log only 500 errors to sentry
      setOverlapQueryError(error.message);

      toast({
        id: "compare-audiences",
        title: "Audience comparison failed",
        message: error.message,
        variant: "error",
      });
    }

    setComparisonsLoading(false);
  };

  const removeComparison = (idToRemove: string) => {
    setComparedAudienceIds((previousComparedIds) => {
      const newIds = [...previousComparedIds];
      const indexToRemove = newIds.findIndex(
        (comparedId) => comparedId === idToRemove,
      );

      if (indexToRemove > -1) {
        newIds.splice(indexToRemove, 1);
      }

      return newIds;
    });

    setComparisonData((previousComparedData) => {
      if (!previousComparedData) {
        return previousComparedData;
      }

      const newComparedData = {
        ...previousComparedData,
        comparisons: [...previousComparedData.comparisons],
      };

      const indexToRemove = newComparedData.comparisons.findIndex(
        (comparedAudience) => comparedAudience.audienceId === idToRemove,
      );

      if (indexToRemove > -1) {
        newComparedData.comparisons.splice(indexToRemove, 1);
      }

      return newComparedData;
    });
  };

  const handleRunQuery = useCallback(
    ({ limitResults }: { limitResults: boolean }) => {
      if (hasValidationErrors()) {
        toast({
          id: "preview-audience",
          title: "Unable to preview audience",
          message: "Check your query and try again.",
          variant: "error",
        });

        return;
      }

      setLastUsedInsightsQuery({
        filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });

      // Run both the member preview and the size calculation in parallel
      runQuery({ limit: limitResults, disableRowCounter: true });
      return calculateAudienceSize();
    },
    [runQuery, visualQueryFilter],
  );

  const previewQuery = async () => {
    if (hasValidationErrors()) {
      toast({
        id: "preview-audience",
        title: "Unable to preview audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    await handleRunQuery({ limitResults: true });
    analytics.track("Model Query Previewed", {
      model_type: source?.definition?.type,
      query_mode: "visual",
    });
  };

  const toggleDrawer = () => {
    if (hasPreviewPermission) {
      if (!rows && !error) {
        previewQuery();
      }
      setDrawerIsOpen((prevValue) => !prevValue);
    }
  };

  useEffect(() => {
    if (numRowsWithoutLimit) {
      updateAudienceSize(
        {
          count: numRowsWithoutLimit,
          isEstimate: Boolean(usedFastQueryForRun),
        },
        {
          filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
          subsetIds,
          isFastQuery: isFastQueryEnabled,
          destinationId,
        },
      );
    }
  }, [numRowsWithoutLimit]);

  const refreshResults = () => {
    if (hasValidationErrors()) {
      toast({
        id: "refresh-audience-results",
        title: "Unable to refresh results",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    if (tab === TabOptions.Results) {
      previewQuery();
    }

    if (tab === TabOptions.Overlap && comparedAudienceIds.length) {
      compareAudiences(comparedAudienceIds, { invalidateQuery: true });
    }
    if (tab === TabOptions.Breakdowns && breakdownColumns.length > 0) {
      fetchBreakdowns();
    }
  };

  const refresh = () => {
    if (isDrawerOpen) {
      refreshResults();
    } else {
      if (hasValidationErrors()) {
        toast({
          id: "refresh-audience-results",
          title: "Unable to refresh results",
          message: "Check your query and try again.",
          variant: "error",
        });

        return;
      }

      calculateAudienceSize();
    }
  };

  const clickTab = (index: number) => {
    setTab(tabs?.[index] || TabOptions.Results);
  };

  useEffect(() => {
    if (error) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: "visual",
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        handleRunQuery({ limitResults: true });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [handleRunQuery]);

  useEffect(() => {
    return resetRunState();
  }, []);

  const displayBreakdownError =
    breakdownError &&
    !breakdownError?.message.includes("missing columns for breakdown") &&
    !breakdownsLoading;

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      const metaKeyPressed = evt.metaKey || evt.ctrlKey;
      const shiftKeyPressed = evt.shiftKey;

      if (
        modelState.canRedo &&
        evt.key === "z" &&
        metaKeyPressed &&
        shiftKeyPressed
      ) {
        modelState.redo();
      } else if (
        modelState.canUndo &&
        evt.key === "z" &&
        metaKeyPressed &&
        !shiftKeyPressed
      ) {
        modelState.undo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modelState]);

  // Set body height to stick action bar to top
  useEffect(() => {
    document.body.style.overflowY = bodyOverflow;

    return () => {
      // defaults are empty strings
      document.body.style.overflowY = "";
    };
  }, [bodyOverflow]);

  const setConditions = (
    conditions: AndCondition | OrCondition | null,
  ): void => {
    modelState.onChange({
      visual_query_filter: {
        ...modelState.state.visual_query_filter,
        conditions: conditions ? ([conditions] as RootCondition[]) : [],
      },
    });
  };

  // This is used for Copilot ONLY, not well tested for other use cases
  const addConditions = (
    conditions: AndCondition | OrCondition | null,
  ): void => {
    const existingConditions = toSingleCondition(
      visualQueryFilter?.conditions,
    )?.[0] ?? {
      type: "and",
      conditions: [],
    };
    const addedCondition = conditions ? conditions?.conditions : [];
    const newConditions = [
      {
        type: existingConditions.type,
        conditions: [...existingConditions.conditions, ...addedCondition],
      },
    ];

    modelState.onChange({
      visual_query_filter: {
        ...modelState.state.visual_query_filter,
        conditions: newConditions as RootCondition[],
      },
    });
  };

  return (
    <>
      <Row
        ref={actionBarRef}
        height={`${actionBarDefaultHeight}px`}
        position="sticky"
        sx={{ top: `var(${cssVariableTopOffset})` }}
        width="100%"
        zIndex={1}
      >
        <Row
          bg="white"
          borderBottom="1px solid"
          borderColor="base.border"
          flex={1}
          minWidth={0}
        >
          <Row
            alignItems="center"
            color="text.secondary"
            flex={1}
            height="48px"
            justifyContent="space-between"
            maxWidth={SIZES.page}
            minWidth={0}
            mx="auto"
            position="relative"
            px={DefaultPageContainerPadding.X}
          >
            <Row gap={2}>
              <Tooltip
                message="Undo"
                keyboardShortcut="mod+z"
                placement="bottom"
              >
                <IconButton
                  aria-label="Undo audience change."
                  icon={UndoIcon}
                  isDisabled={!modelState.canUndo}
                  size="lg"
                  onClick={modelState.undo}
                />
              </Tooltip>
              <Tooltip
                message="Redo"
                keyboardShortcut="mod+shift+z"
                placement="bottom"
              >
                <IconButton
                  aria-label="Redo audience change."
                  icon={RedoIcon}
                  isDisabled={!modelState.canRedo}
                  size="lg"
                  onClick={modelState.redo}
                />
              </Tooltip>
              <Tooltip message="View SQL" placement="bottom">
                <IconButton
                  aria-label="Open SQL preview."
                  icon={CodeIcon}
                  size="lg"
                  onClick={showSqlPreview}
                />
              </Tooltip>
            </Row>

            {hasPreviewPermission && (
              <>
                <Row alignItems="center" gap={2}>
                  {hasSampledSegments && (
                    <Row alignItems="center" gap={2} px={2}>
                      <Switch
                        isChecked={isFastQueryEnabled}
                        onChange={setIsFastQueryEnabled}
                      />
                      <Row gap={1}>
                        <Text fontWeight="medium">Fast queries</Text>
                        <Tooltip message="Fast queries generate an estimated size which is a close approximate to the exact size.">
                          <InformationIcon />
                        </Tooltip>
                      </Row>
                    </Row>
                  )}

                  {appDestinationRulesEnabled &&
                    Boolean(destinationOptions.length) && (
                      <Combobox
                        isClearable
                        optionAccessory={(option) => ({
                          type: "image",
                          url: option.logo,
                        })}
                        options={destinationOptions}
                        placeholder="Destination rule..."
                        value={destinationId}
                        width="4xs"
                        onChange={setDestinationId}
                      />
                    )}

                  {!calculatingAudienceSize &&
                    audienceSize === null &&
                    !sizeLoading && (
                      <Box sx={{ svg: { color: "text.secondary" } }}>
                        <Button icon={AudienceIcon} onClick={refresh}>
                          Calculate size
                        </Button>
                      </Box>
                    )}

                  {(audienceSize !== null || sizeLoading) && (
                    <Row align="center" gap={2} fontSize="20px">
                      {!sizeLoading && isDataStale && (
                        <Box
                          sx={{
                            button: {
                              color: "text.secondary",
                              _hover: { color: "text.secondary" },
                            },
                          }}
                        >
                          <Tooltip
                            message={
                              isDrawerOpen
                                ? "Refresh results"
                                : "Recalculate audience size"
                            }
                          >
                            <IconButton
                              aria-label={
                                isDrawerOpen
                                  ? "Refresh results."
                                  : "Recalculate audience size."
                              }
                              icon={RefreshIcon}
                              variant="secondary"
                              onClick={refresh}
                            />
                          </Tooltip>
                        </Box>
                      )}

                      <Row alignItems="center" gap={2} px={2}>
                        {sizeLoading ? (
                          <Row
                            justifyContent="center"
                            width="32px"
                            onMouseEnter={() => setShowCancelButton(true)}
                            onMouseLeave={() => setShowCancelButton(false)}
                          >
                            {showCancelButton ? (
                              <Row justifyContent="center">
                                <Box
                                  sx={{
                                    button: {
                                      color: "danger.base",
                                      _hover: { color: "danger.base" },
                                    },
                                  }}
                                >
                                  <Tooltip message="Cancel query">
                                    <IconButton
                                      aria-label="Cancel query."
                                      icon={CloseIcon}
                                      onClick={() => {
                                        cancelCalculateAudienceSize();
                                        cancelQuery();
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              </Row>
                            ) : (
                              <Box as={Spinner} size="sm" />
                            )}
                          </Row>
                        ) : (
                          <AudienceIcon />
                        )}

                        <Text
                          fontWeight="semibold"
                          color={sizeLoading ? "text.tertiary" : "text.primary"}
                          size="lg"
                        >
                          {audienceSize !== null
                            ? `${
                                audienceSize.isEstimate ? "~" : ""
                              }${commaNumber(audienceSize.count)}`
                            : "--"}
                        </Text>
                      </Row>
                    </Row>
                  )}

                  <AudienceSizeCap
                    properties={parentModel?.filterable_audience_columns || []}
                    data={sizeCap}
                    onSave={saveSizeCap}
                  />

                  <Box
                    as={Button}
                    height={8}
                    width="160px"
                    onClick={toggleDrawer}
                  >
                    {isDrawerOpen ? (
                      <Box
                        as={ChevronRightIcon}
                        color="text.secondary"
                        fontSize="24px"
                      />
                    ) : (
                      <Box
                        alignItems="end"
                        display="grid"
                        height="20px"
                        gridTemplateColumns="1fr 1fr 1fr 1fr"
                        mr={3}
                        width="20px"
                      >
                        <Box backgroundColor="cyan.400" height="5px" />
                        <Box backgroundColor="electric.600" height="10px" />
                        <Box backgroundColor="cyan.400" height="15px" />
                        <Box backgroundColor="electric.600" height="20px" />
                      </Box>
                    )}
                    {isDrawerOpen ? "Hide insights" : "Show insights"}
                  </Box>
                </Row>
              </>
            )}
          </Row>
        </Row>
      </Row>
      <Row
        maxWidth={SIZES.page}
        mb={footerBarHeight ? `${footerBarHeight}px` : undefined}
        mx="auto"
        position="relative"
        px={DefaultPageContainerPadding.X}
        width="100%"
      >
        <Column flex={1} minWidth={0} overflowX="auto" my={4} gap={4}>
          <Column>
            <Text fontWeight="medium" size="lg">
              Audience filters
            </Text>
            <Paragraph color="text.secondary">
              Filter down customers using anything in your underlying data.
            </Paragraph>
          </Column>
          <SubsetSelector
            parentModelId={parentModel?.id}
            value={subsetIds}
            onChange={(subsets) => {
              modelState.onChange({
                subsets: subsets.map((id) => ({ subset_value: { id } })),
              });
            }}
          />
          <Column pb={6}>
            <QueryBuilder
              audience={audience}
              // To allow for top level switching between and/or the conditions are nested within one single condition.
              filter={toSingleCondition(visualQueryFilter?.conditions)?.[0]}
              parent={parentModel}
              setConditions={setConditions}
            />
          </Column>
          {aiAudienceGeneration && (
            <Magic
              updateFilter={addConditions}
              parentModelId={String(parentModel?.id)}
            />
          )}
        </Column>

        {hasPreviewPermission && (
          <Box
            height={
              isDrawerOpen
                ? `calc(100vh - var(${cssVariableTopSectionHeight}) - ${actionBarHeight}px - ${footerBarHeight}px - var(${cssVariableBottomSectionHeight}) - 10px)` // if I don't add 10px, there's some small scroll
                : 0
            }
            flex="none"
            overflowY="auto"
            overscrollBehaviorY="contain"
            pl={isDrawerOpen ? 4 : 0}
            position="sticky"
            right={0}
            top={`calc(var(${cssVariableTopOffset}) + ${actionBarHeight}px)`}
            transition="all 120ms ease-in-out"
            transform={isDrawerOpen ? undefined : "translate(100%, 0)"}
            opacity={isDrawerOpen ? 1 : 0}
            width={isDrawerOpen ? `${defaultSidebarWidth}px` : 0}
          >
            <Column flex={1} minHeight={0} mt={4} position="relative">
              <Tabs
                index={tabs.findIndex((tabOption) => tab === tabOption)}
                onChange={clickTab}
              >
                <TabList>
                  <Tab>Members</Tab>
                  <Tab>Overlap</Tab>
                  <Tab>Breakdown</Tab>
                </TabList>

                <Box as={TabPanels} height="100%">
                  <Box as={TabPanel} height="100%" overflowY="auto">
                    {(rows || error) && !loading ? (
                      <AudienceResults
                        error={error}
                        parentModel={parentModel}
                        rows={rows}
                        splitTestGroupName={splitTestGroupName}
                      />
                    ) : (
                      <Column
                        sx={{
                          alignItems: "center",
                          borderTopRightRadius: 3,
                          flex: 1,
                          justifyContent: "center",
                          overflowY: "auto",
                          p: 4,
                        }}
                      >
                        {loading ? (
                          <>
                            <Spinner size="lg" />
                            <Text mt={6} color="base.6">
                              Querying your data...
                            </Text>
                            <Button
                              mt={6}
                              variant="secondary"
                              onClick={cancelQuery}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Box as="img" src={AudienceIllustration} />
                            <Text color="text.secondary" mt={8} mb={4}>
                              Preview this audience to see a sample of results
                            </Text>
                            <PermissionedButton
                              permission={{
                                v1: {
                                  resource: "audience",
                                  grant: "preview",
                                  id: modelState.state.id,
                                },
                                v2: modelState.state.id
                                  ? {
                                      resource: "model",
                                      grant: "can_preview",
                                      id: modelState.state.id,
                                    }
                                  : {
                                      resource: "model",
                                      grant: "can_preview",
                                      creationOptions: {
                                        type: "audience",
                                        parentModelId:
                                          parentModel?.id?.toString(),
                                      },
                                    },
                              }}
                              isLoading={loading}
                              mb={8}
                              onClick={previewQuery}
                            >
                              Preview results
                            </PermissionedButton>
                          </>
                        )}
                      </Column>
                    )}
                  </Box>
                  <Box as={TabPanel} height="100%">
                    {!overlapQueryError || comparisonsLoading ? (
                      <AudienceOverlap
                        audienceId={Number(modelState.state.id)}
                        audienceName="Current audience"
                        audiences={audiences ?? []}
                        comparedAudienceIds={comparedAudienceIds}
                        comparisonData={comparisonData}
                        loading={comparisonsLoading || audiencesLoading}
                        parentModelId={parentModel?.id}
                        onAddComparison={compareAudiences}
                        onClearComparisons={resetOverlapData}
                        onRemoveComparison={removeComparison}
                      />
                    ) : (
                      <Column flex={1} overflowY="auto">
                        <Alert
                          variant="inline"
                          actions={
                            <Button
                              variant="secondary"
                              onClick={() =>
                                compareAudiences([], { invalidateQuery: true })
                              }
                            >
                              Reset
                            </Button>
                          }
                          type="error"
                          message={overlapQueryError}
                          title="Error"
                          my={4}
                        />
                      </Column>
                    )}
                  </Box>
                  <Box as={TabPanel} height="100%">
                    {!displayBreakdownError ? (
                      <AudienceBreakdowns
                        breakdownData={breakdownData?.columns ?? []}
                        columns={
                          // Selected columns are filtered out.
                          // Pass unfiltered list while loading so that name
                          // may be displayed in combobox.
                          breakdownsLoading
                            ? parentModel?.filterable_audience_columns ?? []
                            : columnsForBreakdowns
                        }
                        graphWidth={defaultSidebarWidth}
                        loading={breakdownsLoading || breakdownsRefetching}
                        isFastQueryEnabled={isFastQueryEnabled}
                        onRemoveBreakdown={removeBreakdown}
                        onSubmit={addBreakdown}
                      />
                    ) : (
                      <Column flex={1} overflowY="auto">
                        <Alert
                          variant="inline"
                          actions={
                            <Button
                              variant="secondary"
                              onClick={() => setBreakdownColumns([])}
                            >
                              Reset
                            </Button>
                          }
                          type="error"
                          message={breakdownError.message}
                          title="Error"
                          my={4}
                        />
                      </Column>
                    )}
                  </Box>
                </Box>
              </Tabs>
            </Column>
          </Box>
        )}
      </Row>

      {onSave && (
        <ActionBar ref={footerRef}>
          <ButtonGroup>
            <PermissionedButton
              permission={{
                v1: {
                  resource: "audience",
                  grant: "update",
                  id: modelState.state.id,
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: modelState.state.id ?? "",
                },
              }}
              isLoading={saveLoading}
              // TODO(nishad): we probably want to track updates to subset changes so we can undo them.
              // We may need to merge the visual query and subset states if possible.
              // Otherwise only undo visual query changes.
              //isDisabled={!canUndo}
              size="lg"
              variant="primary"
              onClick={handleSave}
              isDisabled={!modelState.isDirty}
            >
              Save audience
            </PermissionedButton>
            <Button
              isDisabled={!modelState.canUndo || !modelState.isDirty}
              size="lg"
              onClick={() => {
                modelState.reset();
              }}
            >
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      )}

      <Dialog
        isOpen={transformedSql.visible}
        variant="info"
        width="xl"
        title="Transformed SQL"
        actions={
          <>
            <Button onClick={copySQLToClipboard}>Copy SQL</Button>
            <Button
              variant="primary"
              onClick={() =>
                setTransformedSql((state) => ({ ...state, visible: false }))
              }
            >
              OK
            </Button>
          </>
        }
        onClose={() =>
          setTransformedSql((state) => ({ ...state, visible: false }))
        }
      >
        {loading || transformedSql.loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner size="lg" />
            <Text mt={4}>Fetching transformed SQL...</Text>
          </Box>
        ) : (
          <Editor readOnly language="sql" value={transformedSql.sql ?? ""} />
        )}
      </Dialog>
    </>
  );
};
