import { EmptyState, Combobox } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { useEligbleOutcomeModelsQuery } from "src/graphql";

export const EventModelSelect = () => {
  const modelsQuery = useEligbleOutcomeModelsQuery(undefined, {
    select: (data) => data.segments,
    suspense: true,
  });

  if (!modelsQuery.data?.length) {
    return (
      <EmptyState
        title="No event models"
        message="Add an event model to build a decision engine outcome from."
      />
    );
  }

  return (
    <Controller
      name="model"
      render={({ field }) => (
        <Combobox
          value={field.value?.id}
          onChange={(value) => {
            field.onChange(
              modelsQuery.data?.find((model) => model.id === value),
            );
          }}
          optionValue={(option) => option.id}
          optionLabel={(option) => option.name}
          options={modelsQuery.data ?? []}
        />
      )}
    />
  );
};
