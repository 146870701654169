import { FC, ReactNode, useEffect, useRef, useState } from "react";

import {
  DestinationOperation,
  ErrorOriginInfo,
  isDestinationErrorOriginInfo,
  isInternalErrorOriginInfo,
  isSourceErrorOriginInfo,
} from "@hightouch/lib/sync/error-origin-types";
import {
  Box,
  Button,
  ChatIcon,
  Column,
  Dialog,
  EditIcon,
  GlobeIcon,
  InformationIcon,
  Row,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { truncate } from "lodash";

import { Private } from "src/components/private";
import { ErrorSyncName } from "src/pages/syncs/sync/components/error-sync-name";
import { QueryType } from "src/types/models";
import * as SyncErrors from "src/types/sync-errors";
import { Markdown } from "src/ui/markdown";
import { useUser } from "src/contexts/user-context";
import { newPylonMessage } from "src/lib/pylon";

type DocumentLink = {
  label?: string;
  type: string;
  url?: string;
};

type DestinationErrorInfo = {
  origin: string;
  rawErrorHeader: string;
  title: string;
  uncategorizedErrorDocsLabel: string;
  uncategorizedErrorDocsLink: string;
};

type ErrorCodeDetails = {
  links?: DocumentLink[];
  userFriendlyMessage: string;
};

type BaseProps = {
  isOpen: boolean;
  onClose: () => void;
  sync: Sync | undefined;
};

type SyncErrorProps = BaseProps & {
  errorType: "sync";
  originInfo: ErrorOriginInfo;
  syncRequestError: SyncErrors.SyncRequestErrorInfo | undefined;
};

type RowErrorProps = BaseProps & {
  errorType: "row";
  originInfo:
    | {
        scope: "destination";
        type?: string;
        operation: DestinationOperation;
      }
    | {
        scope: "source";
        operation: "query";
      };
  rowLevelError: SyncErrors.RowLevelErrorInfo | undefined;
};

const borderColor = "gray.200";

function checkOverflow(textContainer: HTMLSpanElement | null): boolean {
  if (textContainer) {
    return textContainer.offsetHeight < textContainer.scrollHeight;
  }
  return false;
}

type Destination = {
  definition: {
    docs: string;
    name: string;
    icon: string;
  };
  id: string;
  name: string | null;
};

type Model = {
  id: string;
  query_type: string | null;
};

type Source = {
  definition: {
    docs: string;
    name: string;
    icon: string;
  };
  id: string;
  name: string;
};

export type Sync = {
  id: string;
  segment: (Model & { connection: Source | null }) | null;
  destination: Destination | null;
};

function getDestinationErrorInfo(
  destination: Destination | null | undefined,
  originInfo: ErrorOriginInfo,
  source: Source | null | undefined,
  nonEnrichedRowError?: boolean,
): DestinationErrorInfo {
  let origin = "";
  let rawErrorHeader = "Error message";
  let title = "Run error";
  let uncategorizedErrorDocsLabel = "";
  let uncategorizedErrorDocsLink = "";
  if (isInternalErrorOriginInfo(originInfo)) {
    rawErrorHeader = "Internal error message";
    title = "Internal error";
  } else if (nonEnrichedRowError) {
    rawErrorHeader = "Hightouch message";
  } else if (isDestinationErrorOriginInfo(originInfo)) {
    const destinationName = destination?.definition?.name || "destination";
    origin = destinationName;
    rawErrorHeader = `Error message from ${destinationName}`;
    title = "Destination error";
    uncategorizedErrorDocsLabel = `Read docs for ${destinationName}`;
    uncategorizedErrorDocsLink = `${import.meta.env.VITE_DOCS_URL}/${destination
      ?.definition?.docs}`;
  } else if (isSourceErrorOriginInfo(originInfo)) {
    const sourceName = source?.definition?.name || "source";
    origin = sourceName;
    rawErrorHeader = `Error message from ${sourceName}`;
    title = "Source error";
    uncategorizedErrorDocsLabel = `Read docs for ${sourceName}`;
    uncategorizedErrorDocsLink = `${import.meta.env.VITE_DOCS_URL}/${source
      ?.definition?.docs}`;
  }
  return {
    origin,
    rawErrorHeader,
    title,
    uncategorizedErrorDocsLabel,
    uncategorizedErrorDocsLink,
  };
}

function generateLinkProps(
  link: DocumentLink,
  origin: string,
  destination: Destination | null | undefined,
  model: Model | null | undefined,
  source: Source | null | undefined,
  _sync: Sync | undefined,
) {
  switch (link?.type) {
    case "destination":
      return {
        icon: EditIcon,
        label: "Edit destination",
        url: `/destinations/${destination?.id}`,
      };
    case "model":
      return {
        icon: EditIcon,
        label: "Edit model",
        url:
          model?.query_type === QueryType.Visual
            ? `/audiences/${model?.id}`
            : `/models/${model?.id}`,
      };
    case "source":
      return {
        icon: EditIcon,
        label: "Edit source",
        url: `/sources/${source?.id}`,
      };
    case "sync":
      // Removed the edit sync button for now since there is no deeplink to the edit sync page, will add it back once we have the deeplink
      return null;
    case "external":
      return {
        icon: GlobeIcon,
        label: link?.label,
        url: link?.url,
      };
    case "internal":
      return {
        icon: InformationIcon,
        label: `Read docs ${origin && `for ${origin}`}`,
        url: link?.url,
      };
    default:
      return null;
  }
}

export const CodeWithOverflow: FC<{
  children: ReactNode;
  lineClampCount?: string;
  maxLines?: number;
  isError?: boolean;
}> = ({ children, maxLines, isError = false, lineClampCount = 2 }) => {
  const [overflowActive, setOverflowActive] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const overflowingText = useRef(null);
  const overflowLabel = showMore ? `Show less...` : `Show more...`;

  useEffect(() => {
    setOverflowActive(checkOverflow(overflowingText.current));
  }, []);

  return (
    <Box>
      <Box
        bg={isError ? "danger.background" : "gray.50"}
        p={2}
        borderRadius="md"
        border="1px"
        borderColor={isError ? "transparent" : "base.border"}
      >
        <Box
          ref={overflowingText}
          display="-webkit-box"
          overflow="hidden"
          textOverflow="ellipsis"
          color={isError ? "danger.base" : undefined}
          sx={{
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: showMore ? maxLines || "none" : lineClampCount,
          }}
        >
          {children}
        </Box>
      </Box>
      {overflowActive && (
        <Button
          mt={2}
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {overflowLabel}
        </Button>
      )}
    </Box>
  );
};

export const ErrorOriginInfoModal: FC<
  Readonly<SyncErrorProps | RowErrorProps>
> = (props) => {
  const { isOpen, onClose, sync } = props;

  const isSyncError = props.errorType === "sync";
  const errorInfo = props[isSyncError ? "syncRequestError" : "rowLevelError"];
  const errorCodeDetails: ErrorCodeDetails | null | undefined =
    errorInfo?.errorCodeDetail;

  const destination = sync?.destination;
  const model = sync?.segment;
  const source = model?.connection;

  const troubleshootingMessage =
    errorCodeDetails?.userFriendlyMessage ||
    errorInfo?.userFriendlyMessage ||
    errorInfo?.userFacingMessage;
  const rawMessage = errorInfo?.message || "No error message provided.";

  // XXX: temp solution for match booster - remove later!
  const partialNonEnrichedRowError = "row has not been enriched";
  const isNonEnrichedRowError = errorInfo?.message
    ?.toString()
    ?.toLowerCase()
    ?.includes(partialNonEnrichedRowError);

  const {
    origin,
    rawErrorHeader,
    title: errorInfoTitle,
    uncategorizedErrorDocsLabel,
    uncategorizedErrorDocsLink,
  } = getDestinationErrorInfo(
    destination,
    props.originInfo,
    source,
    isNonEnrichedRowError,
  );

  const title = isSyncError
    ? errorInfoTitle
    : isNonEnrichedRowError
      ? `Alert`
      : `Row error`;
  const displayUncategorizedErrorDocs =
    !errorCodeDetails && uncategorizedErrorDocsLabel;
  const isInternalError = isInternalErrorOriginInfo(props.originInfo);

  const orderedTypes = [
    "internal",
    "external",
    "sync",
    "model",
    "source",
    "destination",
  ];
  // We disregard the order of links in Sanity, and instead use the order defined in orderedTypes
  const orderedLinks = errorCodeDetails?.links?.sort((a, b) => {
    const aIndex = orderedTypes.indexOf(a.type);
    const bIndex = orderedTypes.indexOf(b.type);
    return aIndex - bIndex;
  });

  const { isEmbedded } = useUser();

  const Help = () => {
    if (isEmbedded) {
      return null;
    }

    return (
      <Column borderTop="1px solid" borderColor={borderColor} py={6}>
        <Text fontWeight="medium" size="lg">
          Need more help?
        </Text>
        <Text mt={2}>
          If you feel stuck, please reach out! We want to make sure you have all
          the help you need. Our team is available via chat to help you
          troubleshoot this error.
        </Text>
        <Row pt={4}>
          <Box
            onClick={() =>
              newPylonMessage(
                `I'm experiencing an issue with syncing from ${
                  source?.definition?.name || "source"
                } to ${
                  destination?.definition?.name || "destination"
                } and could use some assistance. The error message I'm receiving is: "${truncate(
                  rawMessage,
                  {
                    length: 355,
                  },
                )}." Here's a link to the sync: <a href="${
                  window.location.href
                }">${window.location.href}</a>.`,
              )
            }
          >
            <Button
              icon={ChatIcon}
              size="md"
              variant="secondary"
              onClick={() => {}}
            >
              Chat with support
            </Button>
          </Box>
          {displayUncategorizedErrorDocs && (
            <Row pl={2}>
              <Link href={uncategorizedErrorDocsLink}>
                <Button icon={InformationIcon} size="md" variant="secondary">
                  {uncategorizedErrorDocsLabel}
                </Button>
              </Link>
            </Row>
          )}
        </Row>
      </Column>
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      variant="info"
      width="xl"
      title={title}
      actions={<Button onClick={onClose}>Close</Button>}
      onClose={onClose}
    >
      <Private>
        {!isInternalError && (
          <Row
            borderBottom="1px solid"
            borderColor={borderColor}
            justifyContent="center"
            pb={4}
          >
            <ErrorSyncName
              destination={destination}
              originInfo={props.originInfo}
              source={source}
              isNonEnrichedRowError={isNonEnrichedRowError}
              disableRedirectToResources={isEmbedded}
            />
          </Row>
        )}
        <Column pb={6} pt={isInternalError ? 0 : 6} gap={2}>
          <Text fontWeight="medium" size="lg">
            {rawErrorHeader}
          </Text>

          <CodeWithOverflow>
            <Markdown>{rawMessage}</Markdown>
          </CodeWithOverflow>
        </Column>
        {troubleshootingMessage && (
          <Column borderTop="1px solid" borderColor={borderColor} py={6}>
            <Text fontWeight="medium" size="lg">
              Troubleshooting
            </Text>
            <Text mt={2}>
              <Markdown useParagraphMargins>{troubleshootingMessage}</Markdown>
            </Text>
            {orderedLinks && (
              <Row pt={2} sx={{ flexWrap: "wrap" }}>
                {orderedLinks?.map((link, idx) => {
                  const props = generateLinkProps(
                    link,
                    origin,
                    destination,
                    model,
                    source,
                    sync,
                  );
                  if (props) {
                    const { icon, label, url } = props;
                    return (
                      <Row key={idx} pr={2} pt={2}>
                        <Link href={url ?? "#"}>
                          <Button icon={icon} size="md" variant="secondary">
                            {label}
                          </Button>
                        </Link>
                      </Row>
                    );
                  }
                  return null;
                })}
              </Row>
            )}
          </Column>
        )}
        <Help />
      </Private>
    </Dialog>
  );
};
