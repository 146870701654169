import { FC } from "react";

import { Box, ErrorIcon, Row, Text, Tooltip } from "@hightouchio/ui";

import { LinkWithTooltip } from "src/components/link-with-tooltip";
import { Skeleton } from "src/components/loading";
import { useDecisionEngineCampaignsQuery } from "src/graphql";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

type Props = {
  destination: {
    id: string;
    type: string;
    config: Record<string, unknown>;
    definition: {
      icon: string;
      name: string;
    };
  };
  campaignId: string;
};

export const CampaignLink: FC<Readonly<Props>> = ({
  destination,
  campaignId,
}) => {
  const { campaign, isLoading, error, url } = useCampaign({
    destination,
    campaignId,
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <Text color="danger.base">Failed to load</Text>;
  }

  return (
    <Row
      gap={2}
      align="center"
      overflow="hidden"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <IntegrationIcon
        name={destination.definition.name}
        src={destination.definition.icon}
      />
      <LinkWithTooltip href={url}>{campaign?.name}</LinkWithTooltip>
      {campaign &&
        getChannelDefinition(destination.type).isCampaignInactive(campaign) && (
          <Tooltip message="Campaign not activated">
            <Box as={ErrorIcon} boxSize={5} color="danger.base" />
          </Tooltip>
        )}
    </Row>
  );
};

export const useCampaign = ({ destination, campaignId }: Props) => {
  const {
    data: campaigns,
    error,
    isLoading,
  } = useDecisionEngineCampaignsQuery(
    {
      destinationId: destination.id,
    },
    { select: (data) => data.getDecisionEngineCampaigns },
  );

  const campaign = campaigns?.find((c) => String(c.id) === String(campaignId));

  const getCampaignUrl = getChannelDefinition(destination.type).getCampaignUrl;
  const url = campaign ? getCampaignUrl(campaign, destination.config) : "";

  return {
    url,
    campaign,
    isLoading,
    error,
  };
};
