import { FC, useMemo, useState } from "react";

import {
  Button,
  ButtonGroup,
  DeleteIcon,
  Dialog,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Row,
  SearchInput,
  TagIcon,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import sourcePlaceholder from "src/assets/placeholders/source.svg";
import { labelFilter } from "src/components/folders/filter-helpers";
import {
  createdByFilterConfig,
  Filters,
  labelFilterConfig,
  sourceTypeFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { BulkDeleteSourcesModal } from "src/components/modals/bulk-delete-sources-modal";
import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  ConnectionsBoolExp,
  ConnectionsOrderBy,
  useAddLabelsToSourcesMutation,
  useDeleteSourcesMutation,
  useSourceDefinitionsQuery,
  useSourcesFiltersQuery,
  useSourcesQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import {
  OrderBy,
  PageTable,
  SortOption,
  TableColumn,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";
import { useUser } from "src/contexts/user-context";
import { PageSidebar } from "src/components/layout/page-sidebar";

const initialSort: SortOption<keyof ConnectionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof ConnectionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "type", direction: OrderBy.Asc, label: "Type" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const placeholder = {
  image: searchPlaceholder,
  title: "No sources found",
  error: "Sources failed to load, please try again.",
};

export const Sources: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [warningOpen, setWarningOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { workspace } = useUser();

  const { mutateAsync: bulkDelete, isLoading: isBulkDeleting } =
    useDeleteSourcesMutation();
  const { mutateAsync: addLabels } = useAddLabelsToSourcesMutation();

  const orderBy = useTableSort<ConnectionsOrderBy>(initialSort, sortOptions);

  const { data: allSourcesForFilters, isLoading: filtersLoading } =
    useSourcesFiltersQuery({}, { select: (data) => data.connections });
  const { data: sourceDefinitions } = useSourceDefinitionsQuery(
    {},
    { select: (data) => data.getSourceDefinitions },
  );

  // used for filtering
  const allSourcesWithDefinitionData = useMemo(() => {
    return (allSourcesForFilters ?? []).map((source) => {
      const definition = (sourceDefinitions ?? []).find(
        ({ type }) => type === source.type,
      );

      return { ...source, definition };
    });
  }, [sourceDefinitions, allSourcesForFilters]);

  const filterDefinitions = useMemo(() => {
    return {
      viewKey: "source",
      loading: filtersLoading,
      filters: {
        source: {
          options: sourceTypeFilterConfig(
            (allSourcesWithDefinitionData || []).map((source) => {
              return { connection: source, ...source };
            }),
          ),
          title: "Type",
        },
        created: {
          options: createdByFilterConfig(allSourcesWithDefinitionData || []),
          title: "Created by",
        },
        label: {
          options: labelFilterConfig(allSourcesWithDefinitionData || []),
          title: "Labels",
        },
      },
    };
  }, [allSourcesWithDefinitionData]);

  const {
    result: { state: filters, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const buildHasuraFilters = () => {
    const hasuraFilters: ConnectionsBoolExp = {
      _and: [
        labelFilter(filters.label),
        {
          type: !filters.source?.isAllSelected
            ? { _in: filters.source?.selected.map((filter) => filter.id) }
            : {},
        },

        !filters.created?.isAllSelected
          ? {
              _or: [
                {
                  created_by: {
                    _in: filters.created?.selected.map((f) => f.id),
                  },
                },
                {
                  created_by: { _is_null: true },
                },
              ],
            }
          : {},
      ],
    };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  };

  const hasuraFilters = useMemo(() => {
    return buildHasuraFilters();
  }, [search, filters]);

  const {
    data: sources,
    error,
    isLoading,
  } = useSourcesQuery(
    { filters: hasuraFilters, orderBy },
    { select: (data) => data.connections },
  );

  const { data: entitlementsData } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a source, upgrade your plan.";

  const deleteSources = async () => {
    try {
      await bulkDelete({ ids: selectedRows.map(String) });

      toast({
        id: "delete-sources",
        title: "Selected sources were deleted",
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      // XXX: This uses the old deletion modal, that doesn't automatically toast on error
      toast({
        id: "delete-sources",
        title: "Couldn't delete selected sources",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: (source) => (
          <Row align="center" gap={2} overflow="hidden">
            <Text isTruncated fontWeight="medium">
              {source.name ?? "Private source"}
            </Text>
            <SourceBadges
              isSampleDataSource={source?.definition?.isSampleDataSource}
            />
          </Row>
        ),
      },
      {
        name: "Type",
        cell: (source) => (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon
              name={source?.definition?.name}
              src={source?.definition?.icon}
            />
            <Text isTruncated fontWeight="medium">
              {source?.definition?.name ?? "Private source"}
            </Text>
          </Row>
        ),
      },
      {
        ...LastUpdatedColumn,
        breakpoint: "md",
      },
    ],
    [sourceDefinitions],
  );

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "destination",
          grant: "can_create",
        },
      }}
    >
      <Page
        sidebar={
          <PageSidebar
            header={
              <SearchInput
                placeholder="Search all sources..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            }
          >
            <Filters
              clearFilters={clearFilters}
              createView={createView}
              creatingView={creatingView}
              deleteView={deleteView}
              filters={filterData}
              resetFilters={resetViewFilters}
              resource="source"
              selectView={selectView}
              selectedView={selectedView}
              updateCurrentView={updateCurrentView}
              updatingView={updatingView}
              viewNotSaved={viewNotSaved}
              views={views}
            />
          </PageSidebar>
        }
        title="Sources"
      >
        <PageTable
          header={
            <>
              <Heading size="xl">Sources</Heading>
              <ButtonGroup>
                {selectedRows.length > 0 && (
                  <Row align="center" gap={2}>
                    <Text>{`${pluralize(
                      "source",
                      selectedRows.length,
                      true,
                    )} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      <MenuList>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "source", grant: "update" },
                          }}
                          icon={TagIcon}
                          onClick={() => {
                            setAddingLabels(true);
                          }}
                        >
                          Add labels
                        </PermissionedMenuItem>
                        <MenuDivider />
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "source", grant: "delete" },
                          }}
                          icon={DeleteIcon}
                          variant="danger"
                          onClick={() => {
                            setConfirmingDeletion(true);
                          }}
                        >
                          Delete
                        </PermissionedMenuItem>
                      </MenuList>
                    </Menu>
                  </Row>
                )}
                <PermissionedLinkButton
                  href="/sources/new"
                  isDisabled={overageLockout}
                  permission={{
                    v2: { resource: "source", grant: "can_create" },
                  }}
                  tooltip={overageLockout && overageText}
                  variant="primary"
                  onClick={() => {
                    analytics.track("Add Source Clicked");
                  }}
                >
                  Add source
                </PermissionedLinkButton>
              </ButtonGroup>
            </>
          }
          columns={columns}
          data={sources}
          error={Boolean(error)}
          loading={isLoading}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={({ id }, event) =>
            openUrl(`/sources/${id}`, navigate, event)
          }
          onSelect={onRowSelect}
          sortOptions={sortOptions}
        />

        <BulkDeleteSourcesModal
          isOpen={isConfirmingDeletion}
          loading={isBulkDeleting}
          sources={selectedRows as string[]}
          workspaceName={workspace?.name ?? ""}
          onCancel={() => {
            setConfirmingDeletion(false);
          }}
          onDelete={deleteSources}
        />
        <Dialog
          isOpen={warningOpen}
          variant="info"
          title="Demo source"
          actions={
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setWarningOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  navigate("/sources/new");
                }}
              >
                Add source
              </Button>
            </>
          }
          onClose={() => {
            setWarningOpen(false);
          }}
        >
          <Text>
            This is Hightouch's demo source. Create your own source in less than
            5 minutes!
          </Text>
        </Dialog>

        <EditLabelModal
          rows={selectedRows.length}
          isOpen={addingLabels}
          resourceType={ResourceType.Source}
          onClose={() => setAddingLabels(false)}
          onSubmit={async (labels) => {
            await Promise.all(
              selectedRows.map((r) => {
                return addLabels({ id: r.toString(), labels });
              }),
            );
            onRowSelect([]);
          }}
        />
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.source) {
    return <Sources />;
  }

  return (
    <Page fullWidth title="Sources">
      <Heading mb={8} size="xl">
        Sources
      </Heading>
      <Placeholder
        content={{
          image: sourcePlaceholder,
          title: "No sources in this workspace",
          body: `A source is where your data is stored and managed. Hightouch supports ${
            import.meta.env.VITE_SOURCES_COUNT
          }+ popular sources, including data warehouses like Snowflake and BigQuery, spreadsheets like Airtable and Google Sheets, and other data systems like SFTP, S3, and Tableau.`,
          button: (
            <PermissionedLinkButton
              href="/sources/new"
              permission={{ v2: { resource: "source", grant: "can_create" } }}
              variant="primary"
            >
              Add source
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;
