import { isEqual } from "lodash";
import { isPresent } from "ts-extras";

import {
  ColumnType,
  Condition,
  ConditionType,
  DefaultOperators,
  EventCondition,
  initialEventCondition,
  initialNumberOfCondition,
  initialPropertyCondition,
  initialSetCondition,
  isMergedColumn,
  isRelatedColumn,
  isTraitColumn,
  NumberOfCondition,
  PropertyCondition,
  SegmentSetCondition,
  TraitType,
} from "src/types/visual";

import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";
import {
  AudienceColumn,
  EventColumn,
  FilterOption,
  GlobalColumn,
  InlineTraitColumn,
  MetadataType,
  PropertyColumn,
  RelationColumn,
  TraitColumn,
  TraitTemplateColumn,
} from "./constants";
import { FilterColumnOption } from "./types";
import { RelationshipFragment } from "src/graphql";
import { isTransformedColumn } from "@hightouch/lib/query/visual/types";

export const getIconAndColor = (type: FilterOption | null | undefined) => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case FilterOption.All:
      return { color: GlobalColumn.color, icon: GlobalColumn.icon };
    case FilterOption.Property:
      return { color: PropertyColumn.color, icon: PropertyColumn.icon };
    case FilterOption.Relation:
      return { color: RelationColumn.color, icon: RelationColumn.icon };
    case FilterOption.Event:
      return { color: EventColumn.color, icon: EventColumn.icon };
    case FilterOption.Audience:
      return { color: AudienceColumn.color, icon: AudienceColumn.icon };
    case FilterOption.Trait:
      return { color: TraitColumn.color, icon: TraitColumn.icon };
    case FilterOption.TraitTemplate:
      return {
        color: TraitTemplateColumn.color,
        icon: TraitTemplateColumn.icon,
      };
    case FilterOption.InlineTrait:
      return {
        color: InlineTraitColumn.color,
        icon: InlineTraitColumn.icon,
        iconSx: InlineTraitColumn.iconSx,
      };
  }
};

export const getSearchPlaceholderText = (filterType: FilterOption) => {
  switch (filterType) {
    case FilterOption.All:
      return "Search all filters...";
    case FilterOption.Property:
      return "Search properties...";
    case FilterOption.Relation:
      return "Search relations...";
    case FilterOption.Event:
      return "Search events...";
    case FilterOption.Audience:
      return "Search audiences...";
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
    case FilterOption.InlineTrait:
      return "Search traits...";
  }
};

export const isColumnSelected = <TCondition extends Condition>(
  condition: TCondition | undefined,
  column: FilterColumnOption,
) => {
  if (!condition) {
    return false;
  }

  switch (column.type) {
    case FilterOption.Property:
      return (
        condition.type === ConditionType.Property &&
        isEqual(condition.property, column.value)
      );
    case FilterOption.Relation:
      return (
        condition.type === ConditionType.NumberOf &&
        condition.relationshipId === column.value.relationshipId
      );
    case FilterOption.Event:
      return (
        condition.type === ConditionType.Event &&
        condition.eventModelId === column.value.eventModelId &&
        condition.relationshipId === column.value.relationshipId
      );
    case FilterOption.Audience:
      return (
        condition.type === ConditionType.SegmentSet &&
        condition.modelId === column.value.modelId
      );
    case FilterOption.Trait:
      return (
        condition.type === ConditionType.Property &&
        (isRelatedColumn(condition.property) ||
          isTransformedColumn(condition.property)) &&
        isTraitColumn(condition.property.column) &&
        column.value.column.type === "trait" &&
        condition.property.column.traitDefinitionId ===
          column.value.column.traitDefinitionId
      );
    default:
      return false;
  }
};

export const buildConditionFromColumn = (
  column: FilterColumnOption,
):
  | PropertyCondition
  | NumberOfCondition
  | EventCondition
  | SegmentSetCondition => {
  switch (column.type) {
    case FilterOption.Property:
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
      return {
        ...initialPropertyCondition,
        type: ConditionType.Property,
        propertyType: column.propertyType ?? null,
        property: column.value,
        propertyOptions: {
          caseSensitive:
            column.type === FilterOption.Property
              ? column.case_sensitive ?? undefined
              : undefined,
          traitType:
            column.type === FilterOption.TraitTemplate
              ? "trait_template"
              : column.type === FilterOption.Trait
                ? "trait"
                : undefined,
        },
        operator: column.propertyType
          ? DefaultOperators[column.propertyType]
          : null,
        value: column.propertyType === "boolean" ? true : null,
      };
    case FilterOption.InlineTrait:
      return {
        type: ConditionType.Property,
        property: {
          type: "related",
          column: {
            type: "inline_trait",
            traitType: TraitType.Average,
            traitConfig: {},
            conditions: [],
            relationshipId: "",
          },
          path: [],
        },
        propertyType: ColumnType.Unknown,
        propertyOptions: {
          traitType: "inline_trait",
        },
        operator: null,
        value: null,
      };
    case FilterOption.Relation:
      return {
        ...initialNumberOfCondition,
        relationshipId: column.value.relationshipId,
      };
    case FilterOption.Event:
      return {
        ...initialEventCondition,
        relationshipId: column.value.relationshipId,
        eventModelId: column.value.eventModelId,
      };
    case FilterOption.Audience:
      return {
        ...initialSetCondition,
        modelId: column.value.modelId,
      };
  }
};

export const getSelectedColumn = (
  condition: Condition | undefined,
  columnOptions: FilterColumnOption[],
) => {
  if (!condition) {
    return null;
  }

  switch (condition.type) {
    case ConditionType.Property:
      // eslint-disable-next-line no-case-declarations
      const traitId =
        (isTransformedColumn(condition.property) ||
          isRelatedColumn(condition.property)) &&
        isTraitColumn(condition.property.column)
          ? condition.property.column.traitDefinitionId
          : null;

      if (traitId) {
        return columnOptions.find(
          ({ type, value }) =>
            [FilterOption.Trait, FilterOption.TraitTemplate].includes(type) &&
            (isTraitColumn(value.column) ||
              isTransformedColumn(value.column)) &&
            value.column.traitDefinitionId === traitId,
        );
      }

      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Property && isEqual(value, condition.property),
      );
    case ConditionType.NumberOf:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Relation &&
          condition.relationshipId === value.relationshipId,
      );
    case ConditionType.Event:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Event &&
          value.eventModelId === condition.eventModelId &&
          value.relationshipId === condition.relationshipId,
      );
    case ConditionType.SegmentSet:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Audience && value.modelId === condition.modelId,
      );

    default:
      return null;
  }
};

export const getValueText = (condition: Condition) => {
  switch (condition.type) {
    case ConditionType.Property:
      if (typeof condition.property === "string") {
        return condition.property;
      }

      return getPropertyNameFromColumn(condition.property);
  }

  return undefined;
};

export const getSetupLink = (type: FilterOption, parentModelId?: number) => {
  switch (type) {
    case FilterOption.All:
    case FilterOption.Property:
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
      return isPresent(parentModelId)
        ? `/schema/parent-models/${parentModelId}`
        : "/schema/parent-models";
    case FilterOption.Relation:
      return "/schema/related-models/new";
    case FilterOption.Event:
      return "/schema/events/new";
    case FilterOption.Audience:
      return "/audiences/new";
    case FilterOption.InlineTrait:
      return ""; // should never reach here because there is nothing to set up
  }
};

export const getMetadata = (column: FilterColumnOption | undefined | null) => {
  if (!column) {
    return null;
  }

  if (
    column.type === FilterOption.Trait ||
    column.type === FilterOption.TraitTemplate
  ) {
    // trait column
    return {
      modelName:
        column.value.type === "transformed"
          ? column.parentModel?.name ?? ""
          : column.relatedModel?.name ?? "",
      type: column.relatedModel?.event
        ? MetadataType.Event
        : MetadataType.Relation,
    };
  } else if (
    column.type === FilterOption.Property &&
    isMergedColumn(column.value)
  ) {
    // merged column
    return {
      modelName: column.modelName,
      type: MetadataType.MergedColumn,
    };
  }

  return null;
};

export const getModelName = (
  column: FilterColumnOption | undefined | null,
): string | null | undefined => {
  if (!column) {
    return null;
  }

  switch (column.type) {
    case FilterOption.Trait:
      return column.relatedModel?.name;
    case FilterOption.Property:
      return column.modelName;
    default:
      return null;
  }
};

export function getRelationshipLabel(
  relationshipName: string | null,
  modelName: string,
) {
  if (!relationshipName && !modelName) {
    return "";
  }

  if (!relationshipName) {
    return modelName;
  }

  return `${modelName} (via ${relationshipName})`;
}

export function countRelationsByModel(
  relations: RelationshipFragment[],
): Record<string, number> {
  return relations.reduce<Record<string, number>>((accum, event) => {
    const modelName = event.to_model.name;
    if (!(modelName in accum)) {
      accum[modelName] = 0;
    }

    accum[modelName]! += 1;
    return accum;
  }, {});
}
