import isObjectLike from "lodash/isObjectLike";
import { FC, useState } from "react";

import {
  FormulaTraitConfig,
  isFormulaTraitConfig,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import { Column, Row, Text, TextProps } from "@hightouchio/ui";
import { isPresent } from "ts-extras";

import PlaceholderSrc from "src/assets/placeholders/generic.svg";
import { PermissionedButton } from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import type { ParentModel } from "src/pages/audiences/types";
import { Pagination, Table } from "src/ui/table";
import { getColumnName } from "src/utils/models";

import { usePreviewTrait } from "./use-preview-trait";

type Props = {
  parentModel: ParentModel;
  trait:
    | {
        name?: string;
        type: TraitType;
        config: TraitConfig;
        relationshipId: string;
      }
    | {
        name?: string;
        type: TraitType.Formula;
        config: FormulaTraitConfig;
      };
  isDisabled?: boolean;
};

const ROWS_PER_PAGE = 10;
const MAX_CELL_WIDTH = 176;

export const PreviewTrait: FC<Props> = ({ parentModel, trait, isDisabled }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const {
    previewTrait,
    data: traitPreviewData,
    error,
    isLoading,
  } = usePreviewTrait({
    parentModel: parentModel,
    trait,
  });

  const primaryLabel = parentModel.visual_query_primary_label ?? "";
  const secondaryLabel = parentModel.visual_query_secondary_label ?? "";

  // Resolve column name to alias
  const primaryLabelColumnName = getColumnName(
    primaryLabel,
    parentModel.columns,
  );
  const secondaryLabelColumnName = getColumnName(
    secondaryLabel,
    parentModel.columns,
  );

  const rows = traitPreviewData?.rows ?? [];

  const showTable = rows.length > 0 || Boolean(error);

  return (
    <Column gap={2} align="flex-start" width="100%">
      <PermissionedButton
        permission={{
          v1: { resource: "audience_schema", grant: "create" },
          v2: {
            resource: "model",
            grant: "can_preview",
            id: parentModel.id,
          },
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={previewTrait}
      >
        Preview results
      </PermissionedButton>
      {showTable && (
        <Column gap={2} width="100%">
          <Table
            scrollable
            columns={[
              {
                max: `${MAX_CELL_WIDTH}px`,
                header: () => (
                  <TextWithTooltip
                    color="text.secondary"
                    textTransform="uppercase"
                  >
                    {primaryLabelColumnName}
                  </TextWithTooltip>
                ),
                cell: (data) => (
                  <CellValue value={data[primaryLabelColumnName]} />
                ),
              },
              {
                max: `${MAX_CELL_WIDTH}px`,
                header: () => (
                  <TextWithTooltip
                    color="text.secondary"
                    textTransform="uppercase"
                  >
                    {secondaryLabelColumnName}
                  </TextWithTooltip>
                ),
                cell: (data) => (
                  <CellValue value={data[secondaryLabelColumnName]} />
                ),
              },
              ...(isFormulaTraitConfig(trait.type, trait.config)
                ? trait.config.referencedColumns
                    .filter(
                      (column) =>
                        column.alias !== primaryLabelColumnName &&
                        column.alias !== secondaryLabelColumnName,
                    )
                    .map((column) => ({
                      max: `${MAX_CELL_WIDTH}px`,
                      header: () => (
                        <TextWithTooltip
                          color="text.secondary"
                          textTransform="uppercase"
                        >
                          {column.alias}
                        </TextWithTooltip>
                      ),
                      cell: (row) => <CellValue value={row[column.alias]} />,
                    }))
                : []),
              {
                max: `${MAX_CELL_WIDTH}px`,
                header: () => (
                  <Row width="100%" justifyContent="right">
                    <TextWithTooltip
                      color="text.secondary"
                      textTransform="uppercase"
                    >
                      {trait.name || "Trait Preview"}
                    </TextWithTooltip>
                  </Row>
                ),
                cell: ({ trait_preview }) => (
                  <CellValue value={trait_preview} />
                ),
                cellSx: {
                  bg: "primary.background !important",
                  justifyContent: "right",
                },
                headerSx: { bg: "primary.background" },
              },
            ]}
            data={
              rows.slice(
                0 + currentPage * ROWS_PER_PAGE,
                currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE,
              ) as any
            }
            isDense
            placeholder={{
              image: PlaceholderSrc,
              title: "No results",
              body: "The preview did not return any results. Please try again.",
              error,
            }}
            error={Boolean(error)}
          />
          <Row justifyContent="end">
            <Pagination
              count={rows.length}
              page={currentPage}
              rowsPerPage={ROWS_PER_PAGE}
              setPage={setCurrentPage}
            />
          </Row>
        </Column>
      )}
    </Column>
  );
};

const CellValue: FC<{ value: any } & Omit<TextProps, "children">> = ({
  value,
  ...props
}) => {
  if (!isPresent(value)) {
    return (
      <Text color="text.placeholder" {...props}>
        --
      </Text>
    );
  }

  return (
    <TextWithTooltip {...props}>
      {isObjectLike(value) ? JSON.stringify(value) : value.toString()}
    </TextWithTooltip>
  );
};
