import { Column, FormField, TextInput, Switch } from "@hightouchio/ui";
import { useOutletContext } from "src/router";
import { useUpdateOrganizationMutation } from "src/graphql";
import { OutletContext } from "..";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";

export const Organization = () => {
  const { organization } = useOutletContext<OutletContext>();
  const { mutateAsync: updateOrganization } = useUpdateOrganizationMutation();

  const form = useHightouchForm({
    onSubmit: async ({ name, can_invite_users }) =>
      updateOrganization({
        id: organization.id,
        input: {
          name,
          can_invite_users,
        },
      }),
    values: {
      can_invite_users: organization.can_invite_users,
      name: organization.name,
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <FormField label="Organization name">
          <Controller
            name="name"
            render={({ field }) => <TextInput {...field} />}
          />
        </FormField>
        <FormField
          label="Enforce single sign-on authentication"
          description="By enabling this setting, admins will no longer be able to invite users directly to the workspace.
          All new users must be authenticated via SSO."
        >
          <Controller
            name="can_invite_users"
            render={({ field }) => (
              <Switch
                isChecked={!field.value}
                onChange={(value) => {
                  field.onChange(!value);
                }}
              />
            )}
          />
        </FormField>
        <ActionBar center={false}>
          <FormActions />
        </ActionBar>
      </Column>
    </Form>
  );
};
