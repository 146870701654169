import { CheckboxProps, TooltipProps } from "@hightouchio/ui";
import { GrantObject, GrantableResource, Grants } from "./types";

export function getCheckboxProps({
  value,
  defaultValue,
  isWorkspaceAdmin,
  isBuiltIn,
}: {
  value: boolean | undefined;
  defaultValue: boolean | undefined;
  isWorkspaceAdmin: boolean;
  isBuiltIn: boolean;
}): {
  checkboxProps: Pick<CheckboxProps, "isChecked" | "isDisabled">;
  tooltipProps: Pick<TooltipProps, "message" | "isDisabled">;
} {
  // The default value applies to standalone resources (e.g. sources and destinations)
  const baseValue = Boolean(value || defaultValue);

  // Workspace admins always have full access
  if (isWorkspaceAdmin) {
    return {
      checkboxProps: { isChecked: baseValue, isDisabled: true },
      tooltipProps: {
        message: "This role is a workspace admin and has full access.",
        isDisabled: false,
      },
    };
  }

  // Built-in roles cannot be changed
  // Some built-in roles may have access (e.g. admin), while others may not (e.g. viewer)
  if (isBuiltIn) {
    return {
      checkboxProps: {
        isChecked: baseValue,
        isDisabled: true,
      },
      tooltipProps: {
        message: "This role is a built-in role and cannot be changed.",
        isDisabled: false,
      },
    };
  }

  // If a default value has been set, it cannot be changed at the resource level
  if (defaultValue) {
    return {
      checkboxProps: { isChecked: baseValue, isDisabled: true },
      tooltipProps: {
        message: "This access is set by default and cannot be overriden.",
        isDisabled: false,
      },
    };
  }

  // Otherwise, this is a standalone resource
  return {
    checkboxProps: { isChecked: baseValue },
    tooltipProps: {
      isDisabled: true,
      message: "",
    },
  };
}

export function convertGrants<R extends GrantableResource>(
  grants: Grants<R>,
): Array<GrantObject<R> & { user_group_id: string }> {
  return Object.entries(grants)
    .filter(([_ug, g]) => Boolean(g))
    .map(([ug, g]) => ({ user_group_id: ug, ...g }));
}
